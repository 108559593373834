import { isEmpty, isEqual, isNil, getOr, pickBy } from 'lodash/fp'

const removeMissingFilters = (search, searchConfig) =>
  pickBy((value, key) => {
    if (!key.startsWith('filter')) return true
    return !isNil((searchConfig.filters || []).find((filter) => filter.id === key))
  }, search)

const cleanSearchOptions = (search) =>
  pickBy((value, key) => {
    if (isNil(value)) return false
    if (Array.isArray(value) && isEmpty(value)) return false
    if (['query', 'queryField', 'orderBy', 'combinator'].includes(key)) return true
    if (key.startsWith('filter')) return true
    return false
  }, search)

export const isSavedSearch = (savedSearch, searchOptions) =>
  isEqual(savedSearch, cleanSearchOptions(searchOptions))
export const getSavedSearches = (searchConfig, settings) =>
  getOr([], searchConfig.savedSearchesId, settings)
export const saveSearch = (searchConfig, settings, searchOptions, name, postSetting) => {
  const updatedSearches = getSavedSearches(searchConfig, settings)
    .filter((search) => search.name !== name)
    .concat({
      name,
      searchOptions: removeMissingFilters(cleanSearchOptions(searchOptions), searchConfig),
    })
  postSetting(searchConfig.savedSearchesId, updatedSearches)
  return updatedSearches
}
export const removeSavedSearch = (searchConfig, settings, name, postSetting) => {
  const updatedSearches = getSavedSearches(searchConfig, settings).filter(
    (search) => search.name !== name,
  )
  postSetting(searchConfig.savedSearchesId, updatedSearches)
  return updatedSearches
}
