import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_LAUNCHPLAN = 'GET_LAUNCHPLAN'
export const GET_LAUNCHPLAN_REQUEST = 'GET_LAUNCHPLAN_REQUEST'
export const GET_LAUNCHPLAN_SUCCESS = 'GET_LAUNCHPLAN_SUCCESS'
export const GET_LAUNCHPLAN_FAILURE = 'GET_LAUNCHPLAN_FAILURE'
export const PATCH_LAUNCHPLAN = 'PATCH_LAUNCHPLAN'
export const PATCH_LAUNCHPLAN_REQUEST = 'PATCH_LAUNCHPLAN_REQUEST'
export const PATCH_LAUNCHPLAN_SUCCESS = 'PATCH_LAUNCHPLAN_SUCCESS'
export const PATCH_LAUNCHPLAN_FAILURE = 'PATCH_LAUNCHPLAN_FAILURE'
export const GET_LAUNCHPLAN_ACTIVITIES = 'GET_LAUNCHPLAN_ACTIVITIES'
export const GET_LAUNCHPLAN_ACTIVITIES_REQUEST = 'GET_LAUNCHPLAN_ACTIVITIES_REQUEST'
export const GET_LAUNCHPLAN_ACTIVITIES_SUCCESS = 'GET_LAUNCHPLAN_ACTIVITIES_SUCCESS'
export const GET_LAUNCHPLAN_ACTIVITIES_FAILURE = 'GET_LAUNCHPLAN_ACTIVITIES_FAILURE'
export const POST_LAUNCHPLAN_ACTIVITY_PACKAGE = 'POST_LAUNCHPLAN_ACTIVITY_PACKAGE'
export const POST_LAUNCHPLAN_ACTIVITY_PACKAGE_REQUEST = 'POST_LAUNCHPLAN_ACTIVITY_PACKAGE_REQUEST'
export const POST_LAUNCHPLAN_ACTIVITY_PACKAGE_SUCCESS = 'POST_LAUNCHPLAN_ACTIVITY_PACKAGE_SUCCESS'
export const POST_LAUNCHPLAN_ACTIVITY_PACKAGE_FAILURE = 'POST_LAUNCHPLAN_ACTIVITY_PACKAGE_FAILURE'
const GET_LAUNCHPLAN_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN'],
  messages,
)
export const getLaunchPlan = (workId) => (dispatch) =>
  dispatch({
    type: GET_LAUNCHPLAN,
    meta: {
      workId,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.work(`/v1/works/${workId}/launchplan`),
        errorMessages: GET_LAUNCHPLAN_ERROR_MESSAGES,
      }),
    },
  })
const PATCH_LAUNCHPLAN_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN'],
  messages,
)
export const patchLaunchPlan = (workId, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_LAUNCHPLAN,
    meta: {
      workId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/launchplan`),
        method: 'PATCH',
        body: patchSet,
        errorMessages: PATCH_LAUNCHPLAN_ERROR_MESSAGES,
      }),
    },
  })
const GET_LAUNCHPLAN_ACTIVITIES_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN'],
  messages,
)
export const getLaunchPlanActivities = (workId) => (dispatch) =>
  dispatch({
    type: GET_LAUNCHPLAN_ACTIVITIES,
    meta: {
      workId,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.work(`/v1/works/${workId}/activities`),
        errorMessages: GET_LAUNCHPLAN_ACTIVITIES_ERROR_MESSAGES,
      }),
    },
  })
const POST_LAUNCHPLAN_ACTIVITY_PACKAGE_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN', 'ACTIVITY_TEMPLATE_PACKAGE_NOT_FOUND'],
  messages,
)
export const postLaunchPlanActivityPackage =
  (workId, packageId = 1) =>
  (dispatch) =>
    dispatch({
      type: POST_LAUNCHPLAN_ACTIVITY_PACKAGE,
      meta: {
        workId,
        packageId,
      },
      payload: {
        promise: request({
          method: 'POST',
          body: {
            id: packageId,
          },
          url: services.work(`/v1/works/${workId}/addactivitytemplatepackage`),
          errorMessages: POST_LAUNCHPLAN_ACTIVITY_PACKAGE_ERROR_MESSAGES,
        }),
      },
    })
