import { requestStatus } from '../utils/net/statuses'
import { GENERIC_ERROR, GENERIC_ERROR_CLEAR } from './errorActions'

const initialState = {
  status: null,
  header: '',
  paragraph: '',
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GENERIC_ERROR:
      return {
        ...state,
        header: action.header,
        paragraph: action.paragraph,
        status: requestStatus.failure,
      }

    case GENERIC_ERROR_CLEAR:
      return { ...initialState }

    case '@@router/LOCATION_CHANGE':
      return state.status === null ? state : { ...initialState }

    default:
      return state
  }
}
