import { requestStatus } from '../utils/net/statuses'
import {
  GET_PRODUCTION_HISTORY_REQUEST,
  GET_PRODUCTION_HISTORY_SUCCESS,
  GET_PRODUCTION_HISTORY_FAILURE,
} from './productionHistoryActions'

const initialState = {
  data: {},
  status: {
    getStatus: undefined,
    getError: undefined,
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_HISTORY_REQUEST:
      return {
        ...state,
        workId: action.payload.workId,
        productionId: action.payload.productionId,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_PRODUCTION_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_PRODUCTION_HISTORY_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    default:
      return state
  }
}
