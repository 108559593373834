import { Component } from 'react'

import { Dialog } from 'Common/core'

type Props = {
  handleClose(..._args: unknown[]): unknown
  title: string
  error?: string
}

export default class ErrorDialog extends Component<Props> {
  render() {
    const { error, title, handleClose } = this.props
    return (
      <Dialog title={title} onClose={handleClose}>
        {error}
      </Dialog>
    )
  }
}
