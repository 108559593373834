import { services } from 'Common/environment'
import request from 'Common/utils/net/request'

export const GET_WORK_CALCULATION = 'GET_WORK_CALCULATION'
export const GET_WORK_CALCULATION_REQUEST = 'GET_WORK_CALCULATION_REQUEST'
export const GET_WORK_CALCULATION_SUCCESS = 'GET_WORK_CALCULATION_SUCCESS'
export const GET_WORK_CALCULATION_FAILURE = 'GET_WORK_CALCULATION_FAILURE'
export const GET_WORK_CALCULATION_BASE = 'GET_WORK_CALCULATION_BASE'
export const GET_WORK_CALCULATION_BASE_REQUEST = 'GET_WORK_CALCULATION_BASE_REQUEST'
export const GET_WORK_CALCULATION_BASE_SUCCESS = 'GET_WORK_CALCULATION_BASE_SUCCESS'
export const GET_WORK_CALCULATION_BASE_FAILURE = 'GET_WORK_CALCULATION_BASE_FAILURE'
export const getWorkCalculation =
  (work, saveCalculation = false) =>
  (dispatch) =>
    dispatch({
      type: GET_WORK_CALCULATION,
      meta: {
        workId: work.id,
      },
      payload: {
        promise: request({
          url: services.calculation(`/v1/works?save=${saveCalculation}`),
          method: 'POST',
          body: { ...work },
        }),
      },
    })
export const getCalculationBase = (workId) => (dispatch) =>
  dispatch({
    type: GET_WORK_CALCULATION_BASE,
    meta: {
      workId: workId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/calculationbase`),
        method: 'GET',
      }),
    },
  })
