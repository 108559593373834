import { Controller } from 'react-hook-form'

import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'

import FieldInfoHover, { FieldInfoProp } from './fieldInfo/FieldInfo'
import FieldInfoPropsNullCheck from './fieldInfo/FieldInfoPropsNullCheck'

type Props = {
  required?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  name: string
  control: any
  label?: string
  fullWidth?: boolean
  multiline?: boolean
  disabled?: boolean
  shrink?: boolean
  type?: string
  rules?: any
  minRows?: number
  showClearButton?: boolean
  fieldInfoProps?: FieldInfoProp
}

export default function TextInputController({
  name,
  control,
  label,
  fullWidth = true,
  multiline = false,
  disabled = false,
  shrink,
  type = 'text',
  rules = {},
  minRows = 1,
  showClearButton = false,
  variant = 'standard',
  required = false,
  fieldInfoProps,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error } }) => (
        <TextField
          required={required}
          onBlur={onBlur}
          inputRef={ref}
          hiddenLabel={!label}
          variant={variant}
          type={type}
          onChange={(e) => onChange(e.target.value === '' ? null : e.target.value)}
          value={value ?? ''}
          label={label}
          fullWidth={fullWidth}
          multiline={multiline}
          disabled={disabled}
          error={!!error}
          helperText={error?.message}
          minRows={minRows}
          InputLabelProps={{
            shrink: label ? shrink : undefined,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value && showClearButton ? (
                  <IconButton size="small" onClick={() => onChange(null)}>
                    <ClearIcon />
                  </IconButton>
                ) : null}
                {fieldInfoProps && FieldInfoPropsNullCheck(fieldInfoProps) ? (
                  <FieldInfoHover fieldInfoProps={fieldInfoProps} />
                ) : null}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
