import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
  GET_WORK_COSTS_REQUEST,
  GET_WORK_COSTS_SUCCESS,
  GET_WORK_COSTS_FAILURE,
  PUT_WORK_COSTS_REQUEST,
  PUT_WORK_COSTS_SUCCESS,
  PUT_WORK_COSTS_FAILURE,
} from '../actions/workActions'

const initialState = {
  status: {},
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_REQUEST: {
      return { ...initialState }
    }

    case GET_WORK_COSTS_REQUEST: {
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }
    }

    case GET_WORK_COSTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }
    }

    case GET_WORK_COSTS_FAILURE: {
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }
    }

    case PUT_WORK_COSTS_REQUEST: {
      return {
        ...state,
        status: { ...state.status, putStatus: requestStatus.request, putError: null },
      }
    }

    case PUT_WORK_COSTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, putStatus: requestStatus.success },
      }
    }

    case PUT_WORK_COSTS_FAILURE: {
      return {
        ...state,
        status: { ...state.status, putStatus: requestStatus.failure, putError: action.payload },
      }
    }

    default: {
      return state
    }
  }
}
