import { services } from 'Common/environment'
import { AppDispatch } from 'Common/store/createStore'
import { Claim, Dispatch } from 'Common/types'
import request from 'Common/utils/net/request'

export const GET_USER = 'GET_USER'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const PATCH_USER = 'PATCH_USER'
export const PATCH_USER_REQUEST = 'PATCH_USER_REQUEST'
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS'
export const PATCH_USER_FAILURE = 'PATCH_USER_FAILURE'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'
export const POST_USER_PASSWORD = 'POST_USER_PASSWORD'
export const POST_USER_PASSWORD_REQUEST = 'POST_USER_PASSWORD_REQUEST'
export const POST_USER_PASSWORD_SUCCESS = 'POST_USER_PASSWORD_SUCCESS'
export const POST_USER_PASSWORD_FAILURE = 'POST_USER_PASSWORD_FAILURE'

export type UserId = string
export type User = {
  accessFailedCount: number
  claims: Claim[]
  id: UserId
  isLockedOut: boolean
  lockoutEnabled: boolean
  propertyRules: any
  userName: string
}
export const getUser = (id: UserId) => (dispatch: Dispatch) =>
  dispatch({
    type: GET_USER,
    payload: {
      promise: request({
        method: 'GET',
        url: services.users(`/v1/users/${id}`),
      }),
    },
  })
export const patchUser = (id: UserId, patchSet: any) => (dispatch: AppDispatch) =>
  dispatch({
    type: PATCH_USER,
    meta: {
      id,
    },
    payload: {
      promise: request({
        url: services.users(`/v1/users/${id}`),
        method: 'PATCH',
        body: patchSet,
      }),
    },
  })
export const deleteUser = (id: UserId) => (dispatch: AppDispatch) =>
  dispatch({
    type: DELETE_USER,
    meta: {
      id,
    },
    payload: {
      promise: request({
        url: services.users(`/v1/users/${id}`),
        method: 'DELETE',
      }),
    },
  })
export const postUserPassword = (id: UserId, password: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: POST_USER_PASSWORD,
    payload: {
      promise: request({
        url: services.users(`/v1/users/${id}/password`),
        method: 'POST',
        body: {
          password,
        },
      }),
    },
  })
