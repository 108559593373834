import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_COMPANY_CONTACTS = 'GET_CONTACT_COMPANY_CONTACTS'
export const GET_CONTACT_COMPANY_CONTACTS_REQUEST = 'GET_CONTACT_COMPANY_CONTACTS_REQUEST'
export const GET_CONTACT_COMPANY_CONTACTS_SUCCESS = 'GET_CONTACT_COMPANY_CONTACTS_SUCCESS'
export const GET_CONTACT_COMPANY_CONTACTS_FAILURE = 'GET_CONTACT_COMPANY_CONTACTS_FAILURE'
const GET_CONTACT_COMPANY_CONTACTS_ERROR_MESSAGES = createErrorMessages(
  ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
  messages,
)
export const getContactCompanyContacts = (contactId) => (dispatch) =>
  dispatch({
    type: GET_CONTACT_COMPANY_CONTACTS,
    payload: {
      data: {
        contactId,
      },
      promise: request({
        method: 'GET',
        url: services.contact(`/v2/contacts/${contactId}/companycontacts`),
        errorMessages: GET_CONTACT_COMPANY_CONTACTS_ERROR_MESSAGES,
      }),
    },
  })
