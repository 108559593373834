import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_WORK_CALCULATION_REQUEST,
  GET_WORK_CALCULATION_SUCCESS,
  GET_WORK_CALCULATION_FAILURE,
  GET_WORK_CALCULATION_BASE_REQUEST,
  GET_WORK_CALCULATION_BASE_SUCCESS,
  GET_WORK_CALCULATION_BASE_FAILURE,
} from '../actions/workCalculationActions'

const initialState = {
  status: {},
  data: undefined,
  baseStatus: {},
  baseData: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_CALCULATION_REQUEST: {
      return {
        ...state,
        data: undefined,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }
    }

    case GET_WORK_CALCULATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }
    }

    case GET_WORK_CALCULATION_FAILURE: {
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }
    }

    case GET_WORK_CALCULATION_BASE_REQUEST: {
      return {
        ...state,
        baseStatus: { ...state.status, getStatus: requestStatus.request, getError: null },
      }
    }

    case GET_WORK_CALCULATION_BASE_SUCCESS: {
      const calculationBase = action.payload.json.calculationBase
      if (calculationBase?.workCalculationBase != null) {
        calculationBase.workCalculationBase.refreshedAt = Date.now()
      }

      return {
        ...state,
        baseData: calculationBase,
        baseStatus: { ...state.status, getStatus: requestStatus.success },
      }
    }

    case GET_WORK_CALCULATION_BASE_FAILURE: {
      return {
        ...state,
        baseStatus: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }
    }

    default:
      return state
  }
}
