import { isEqual } from 'lodash/fp'
import { useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import ThemaCodePicker from 'Common/components/thema/ThemaCodePicker'
import { SelectedThemaCodeFilter } from 'Common/thema/types'

import FilterSelectionList from './FilterSelectionList'

type Props = {
  updateFilter: (filterId: string, updatedFilter: SelectedThemaCodeFilter[]) => void
  filter: {
    id: string
    typeConfig?: {
      hideContact?: boolean
    }
  }
  active: SelectedThemaCodeFilter[]
  chipColor?: string
}

const ThemaCodeFilter = (props: Props) => {
  const { filter, active, updateFilter, chipColor } = props
  const [themaSearchValue, setThemaSearchValue] = useState<string>('')

  const onAddOption = (themaCode: SelectedThemaCodeFilter) => {
    updateFilter(filter.id, [...active, themaCode])
    setThemaSearchValue('')
  }

  const onRemoveOption = (themaCode: SelectedThemaCodeFilter) => {
    const updatedActive = active.filter((activeOption) => !isEqual(activeOption, themaCode))
    updateFilter(filter.id, updatedActive)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <ThemaCodePicker {...{ themaSearchValue, setThemaSearchValue, onAddOption }} />
      </Grid>
      <FilterSelectionList
        onRemoveOption={onRemoveOption}
        {...{
          filter,
          active,
          chipColor,
        }}
      />
    </Grid>
  )
}

export default ThemaCodeFilter
