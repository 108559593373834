import { Money } from 'Common/types'

const FormatMoney = (money: Money) => {
  return (
    <>{`${
      money?.value?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) ?? '-'
    }`}</>
  )
}
export default FormatMoney
