import { getOr, slice } from 'lodash/fp'
import { Component } from 'react'

import NavigationExpandLessIcon from '@mui/icons-material/ExpandLess'
import NavigationExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner, { SPINNER_SIZES } from 'Common/components/loader/Spinner'
import BasicPagingControl from 'Common/search/view/BasicPagingControl'
import { requestStatus } from 'Common/utils/net/statuses'

type Props = {
  updateSelectFields(..._args: unknown[]): unknown
  loadNextPage(..._args: unknown[]): unknown
  searchConfig: any
  viewConfig: {
    componentConfig: {
      ItemComponent(..._args: unknown[]): unknown
    }
  }
  searchState: {
    searchOptions: any
    results: unknown[]
    status?: string
    paging?: {
      nextOffset?: number
    }
  }
  updateSorting?(..._args: unknown[]): unknown
  viewProps?: Record<string, unknown>
}

type State = {
  showDetails: boolean
  offsetToShow?: number
}

class PublishingListSearchView extends Component<Props, State> {
  state = {
    offsetToShow: 0,
    showDetails: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const offset = getOr(0, 'searchState.searchOptions.offset', this.props)
    const nextOffset = getOr(0, 'searchState.searchOptions.offset', nextProps)

    if (offset !== nextOffset) {
      this.setState({
        offsetToShow: nextOffset,
      })
    }
  }

  onToggleShowDetails = () =>
    this.setState((state) => ({
      showDetails: !state.showDetails,
    }))

  showPreviousResults = () => {
    const { searchConfig } = this.props
    const pagingSize = getOr(25, 'pagingSize', searchConfig)
    this.setState((state) => ({
      offsetToShow: Math.max(0, state.offsetToShow - pagingSize),
    }))
  }

  showNextResults = () => {
    const { searchConfig, searchState, loadNextPage } = this.props
    const { offsetToShow } = this.state
    const { results, paging } = searchState
    const pagingSize = getOr(25, 'pagingSize', searchConfig)

    if (offsetToShow + pagingSize < results.length) {
      this.setState((state) => ({
        offsetToShow: state.offsetToShow + pagingSize,
      }))
    } else if (paging.nextOffset) {
      loadNextPage()
    }
  }

  renderErrorOrSpinner(spinner = true, reason?: any) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: 30,
        }}>
        {spinner ? <Spinner size={SPINNER_SIZES.LARGE} /> : reason}
      </div>
    )
  }

  renderResults(pendingResults, pagingSize, showDetails) {
    const { viewConfig, searchState, viewProps, updateSorting } = this.props
    const { searchOptions } = searchState
    const { offsetToShow } = this.state
    const { results } = searchState
    const HeaderComponent = getOr(null, 'componentConfig.HeaderComponent', viewConfig)
    const ItemComponent = getOr(null, 'componentConfig.ItemComponent', viewConfig)
    const resultsToShow: any = slice(offsetToShow, offsetToShow + pagingSize, results)
    if (pendingResults) return this.renderErrorOrSpinner(true)
    return (
      <Grid>
        {HeaderComponent ? (
          <HeaderComponent
            {...{
              searchOptions,
              viewProps,
              updateSorting,
            }}
          />
        ) : null}
        {resultsToShow.map((item) => (
          <ItemComponent
            key={item.document.id}
            result={item.document}
            viewProps={viewProps}
            showDetails={showDetails}
            {...{
              searchOptions,
              updateSorting,
            }}
          />
        ))}
      </Grid>
    )
  }

  render() {
    const { searchConfig, viewConfig, searchState } = this.props
    const { searchOptions } = searchState
    const { offsetToShow, showDetails } = this.state
    const { status, results } = searchState
    const ItemComponent = getOr(null, 'componentConfig.ItemComponent', viewConfig)
    if (!ItemComponent) return null
    const pagingSize = getOr<number>(25, 'pagingSize', searchConfig)

    if (status === requestStatus.failure) {
      return this.renderErrorOrSpinner(false, 'An error occured')
    }

    if (status === requestStatus.request && results.length === 0) {
      return this.renderErrorOrSpinner(true)
    }

    if (results.length === 0) {
      return this.renderErrorOrSpinner(false, 'No results')
    }

    const offset = getOr<number>(0, 'offset', searchOptions)
    const pendingResults = status === requestStatus.request && results.length < offset + pagingSize
    return (
      <Grid container spacing={2} xs={12}>
        <Grid xs={12}>
          <BasicPagingControl
            showPreviousResults={this.showPreviousResults}
            showNextResults={this.showNextResults}
            disableControls={pendingResults}
            {...{
              searchState,
              offsetToShow,
              pagingSize,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ display: { xs: 'flex', displayPrint: 'none' } }}>
          <Button
            variant="outlined"
            onClick={this.onToggleShowDetails}
            color="primary"
            endIcon={showDetails ? <NavigationExpandLessIcon /> : <NavigationExpandMoreIcon />}>
            {`${showDetails ? 'Hide' : 'Show'} extended information`}
          </Button>
        </Grid>
        <Grid xs={12}>{this.renderResults(pendingResults, pagingSize, showDetails)}</Grid>
        <Grid xs={12}>
          <BasicPagingControl
            showPreviousResults={this.showPreviousResults}
            showNextResults={this.showNextResults}
            disableControls={pendingResults}
            {...{
              searchState,
              offsetToShow,
              pagingSize,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default PublishingListSearchView
