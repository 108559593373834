import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useRouteMatch } from 'react-router'

import StatusLoader from 'Common/components/loader/StatusLoader'
import { RootState } from 'Common/types'

import { getMetaDataType as getMetaDataTypeAction } from '../../actions/metaDataTypeActions'
import MetaDataTypeMain from '../../components/metadata/type/MetaDataTypeMain'

type Props = {
  metaDataTypeId?: number
}

const mapState = (state: RootState) => {
  const { auth, metaDataType } = state

  return {
    auth,
    metaDataType: metaDataType.data,
    metaDataTypeStatus: metaDataType.status,
  }
}

const connector = connect(mapState, {
  getMetaDataType: getMetaDataTypeAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

const MetaDataType = (props: Props & PropsFromRedux) => {
  const { auth, metaDataType, metaDataTypeStatus, getMetaDataType } = props
  const { params } = useRouteMatch<{ metaDataTypeId: string }>()

  const metaDataTypeId = params.metaDataTypeId || props.metaDataTypeId

  useEffect(() => {
    getMetaDataType(metaDataTypeId)
  }, [metaDataTypeId])

  return (
    <StatusLoader
      statuses={[
        {
          requestStatus: metaDataTypeStatus.getStatus,
          error: metaDataTypeStatus.getError,
        },
      ]}>
      <MetaDataTypeMain
        {...{
          auth,
          metaDataType,
        }}
      />
    </StatusLoader>
  )
}

export default connector(MetaDataType)
