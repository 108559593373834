import { Component } from 'react'

import IconClose from '@mui/icons-material/Close'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { MetaData } from 'Common/metadata'

import { getFilterColorFromIndex } from './filter/filters'

const getFilterColor = (filter, expandedFilter, searchOptions) => {
  if (!searchOptions[filter.id]) return '#eeeeee'
  return expandedFilter === filter.id ? '#e6ee9c' : '#c0ca33'
}

type Props = {
  updateFilter(..._args: unknown[]): unknown
  handleToggleFilterExpand(..._args: unknown[]): unknown
  disableFilter(..._args: unknown[]): unknown
  searchConfig: {
    staticControls?: boolean
    accessModule?: string
  }
  searchOptions: any
  metaData: MetaData
  auth: Record<string, unknown>
  filter: {
    id: string
    name: string
    type: {
      Component: any
    }
  }
  index: number
  expandedFilter?: string
}

class SearchControlFilter extends Component<Props> {
  render() {
    const {
      searchConfig,
      searchOptions,
      metaData,
      auth,
      filter,
      index,
      expandedFilter,
      disableFilter,
      updateFilter,
    } = this.props
    const { staticControls = false, accessModule } = searchConfig
    const filterOptions = searchOptions[filter.id]
    return (
      <Accordion key={filter.id} defaultExpanded={expandedFilter === filter.id}>
        <AccordionSummary
          style={{
            backgroundColor: getFilterColor(filter, expandedFilter, searchOptions),
            borderBottom: '1px solid #eee',
          }}>
          <Grid container spacing={2} xs={12}>
            <Grid xs={11}>
              <Typography
                style={{
                  textTransform: 'uppercase',
                }}>
                {filter.name} {filterOptions && `(${filterOptions.length})`}
              </Typography>
            </Grid>
            <Grid xs={1}>
              {filterOptions && !staticControls ? (
                <IconButton
                  style={{
                    padding: 0,
                  }}
                  onClick={(event) => disableFilter(event, filter.id)}
                  size="large">
                  <IconClose />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <filter.type.Component
            filter={filter}
            active={searchOptions[filter.id] || []}
            updateFilter={updateFilter}
            metaData={metaData}
            auth={auth}
            accessModule={accessModule}
            chipColor={getFilterColorFromIndex(index)}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default SearchControlFilter
