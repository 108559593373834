import { Component } from 'react'

import { useAppbarTitleStore } from 'Common/store/AppBarTitleStore'

import MetaDataTypeOverview from './MetaDataTypeOverview'

type Props = {
  metaDataType: Record<string, unknown>
}

class MetaDataTypeMain extends Component<Props> {
  componentDidMount() {
    useAppbarTitleStore.setState({ appTitle: 'Metadata type' })
  }

  render() {
    const { metaDataType } = this.props
    return (
      <MetaDataTypeOverview
        {...{
          metaDataType,
        }}
      />
    )
  }
}

export default MetaDataTypeMain
