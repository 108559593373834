import { servicesLoaders } from 'Common/environment'
import { optionFilter, dateFilter, optionArrayFilter } from 'Common/search/filter/filters'
import BasicSearchView from 'Common/search/view/BasicSearchView'

import AssetViewHeader from '../components/assets/AssetViewHeader'
import AssetViewItem from '../components/assets/AssetViewItem'

export const searchConfig = {
  id: 'assets',
  searchService: servicesLoaders.assets('/v1/search/assets'),
  searchServiceExport: null,
  searchLocation: '/assets',
  searchMetaDataId: 'assets',
  accessModule: 'opus_asset',
  savedSearchesId: 'savedsearches-assets-assetssearch-v1',
  pagingSize: 50,
  filters: [
    {
      type: optionFilter,
      id: 'filterAssetTypeId',
      searchId: 'assetTypeId',
      metaDataId: 'assetTypes',
      name: 'Asset type',
    },
    {
      type: optionFilter,
      id: 'filterPublishingHouses',
      searchId: 'publishingHouseId',
      metaDataId: 'publishingHouses',
      typeConfig: {
        filterByPublishingHouse: true,
      },
      name: 'Publishing house',
    },
    {
      type: optionFilter,
      id: 'filterPublishingHouseGroups',
      searchId: 'publishingHouseGroupId',
      metaDataId: 'publishingHouseGroups',
      name: 'Publishing house group',
    },
    {
      type: optionFilter,
      id: 'filterContractTypeId',
      searchId: 'contractTypeId',
      metaDataId: 'assetContractTypes',
      name: 'Contract type',
    },
    {
      type: dateFilter,
      id: 'filterContractDate',
      searchId: 'contractDate',
      name: 'Contract date',
    },
    {
      type: optionArrayFilter,
      id: 'productionBindingCodeIds',
      searchId: 'productionBindingCodeIds',
      metaDataId: 'bindingCodes',
      name: 'Binding code',
    },
  ],
}
export const viewsConfig = {
  views: [
    {
      id: 'basic',
      label: 'Basic view',
      Component: BasicSearchView,
      componentConfig: {
        HeaderComponent: AssetViewHeader,
        ItemComponent: AssetViewItem,
      },
    },
  ],
}
