import IconAttachment from '@mui/icons-material/Attachment'
import IconImage from '@mui/icons-material/Image'
import IconPdf from '@mui/icons-material/PictureAsPdf'

export const getMimeTypeIcon = (mimeType, iconStyle) => {
  return mimeType === 'application/pdf' ? (
    <IconPdf style={iconStyle} />
  ) : mimeType && mimeType.includes('image/') ? (
    <IconImage style={iconStyle} />
  ) : (
    <IconAttachment style={iconStyle} />
  )
}
