import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,
  POST_CONTACT_LIST_REQUEST,
  POST_CONTACT_LIST_SUCCESS,
  POST_CONTACT_LIST_FAILURE,
  PATCH_CONTACT_LIST_REQUEST,
  PATCH_CONTACT_LIST_SUCCESS,
  PATCH_CONTACT_LIST_FAILURE,
  DELETE_CONTACT_LIST_MEMBER_REQUEST,
  DELETE_CONTACT_LIST_MEMBER_SUCCESS,
  DELETE_CONTACT_LIST_MEMBER_FAILURE,
  POST_CONTACT_LIST_MEMBER_REQUEST,
  POST_CONTACT_LIST_MEMBER_SUCCESS,
  POST_CONTACT_LIST_MEMBER_FAILURE,
} from '../actions/contactListActions'

const initialState = {
  status: {
    getStatus: undefined,
  },
  data: undefined,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_LIST_REQUEST:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              getStatus: requestStatus.request,
            },
          },
        },
      }

    case GET_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.json,
          status: {
            ...state.status,
            ...{
              getStatus: requestStatus.success,
            },
          },
        },
      }

    case GET_CONTACT_LIST_FAILURE:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              getStatus: requestStatus.failure,
            },
          },
        },
      }

    case POST_CONTACT_LIST_REQUEST:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              postStatus: requestStatus.request,
            },
          },
        },
      }

    case POST_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.json,
          status: {
            ...state.status,
            ...{
              postStatus: requestStatus.success,
            },
          },
        },
      }

    case POST_CONTACT_LIST_FAILURE:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              postStatus: requestStatus.failure,
            },
          },
        },
      }

    case PATCH_CONTACT_LIST_REQUEST:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              patchStatus: requestStatus.request,
            },
          },
        },
      }

    case PATCH_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.json,
          status: {
            ...state.status,
            ...{
              patchStatus: requestStatus.success,
            },
          },
        },
      }

    case PATCH_CONTACT_LIST_FAILURE:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              patchStatus: requestStatus.failure,
            },
          },
        },
      }

    case DELETE_CONTACT_LIST_MEMBER_REQUEST:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              deleteMemberStatus: requestStatus.request,
            },
          },
        },
      }

    case DELETE_CONTACT_LIST_MEMBER_SUCCESS:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              deleteMemberStatus: requestStatus.success,
            },
          },
        },
      }

    case DELETE_CONTACT_LIST_MEMBER_FAILURE:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              deleteMemberStatus: requestStatus.failure,
            },
          },
        },
      }

    case POST_CONTACT_LIST_MEMBER_REQUEST:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              postMemberStatus: requestStatus.request,
            },
          },
        },
      }

    case POST_CONTACT_LIST_MEMBER_SUCCESS:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              postMemberStatus: requestStatus.success,
            },
          },
        },
      }

    case POST_CONTACT_LIST_MEMBER_FAILURE:
      return {
        ...state,
        ...{
          status: {
            ...state.status,
            ...{
              postMemberStatus: requestStatus.failure,
            },
          },
        },
      }

    default:
      return state
  }
}
