import { Dialog } from 'Common/core'

import ConfirmationWrapper from '../../components/confirmation/ConfirmationWrapper'

type Props = {
  removeView(..._args: unknown[]): unknown
  closeModal(): void
  viewName: string
}

const SavedViewRemoval = (props: Props) => {
  const { viewName, removeView, closeModal } = props
  if (!viewName) return null
  return (
    <Dialog onClose={closeModal}>
      <ConfirmationWrapper
        confirmAction={() => removeView(viewName)}
        message={`Do you really want to remove the saved view "${viewName}"?`}
        cancelAction={closeModal}
        show>
        <span />
      </ConfirmationWrapper>
    </Dialog>
  )
}

export default SavedViewRemoval
