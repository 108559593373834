import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
  searchOptions: Record<string, unknown>
  updateSorting(..._args: unknown[]): unknown
}

class AssetViewHeader extends Component<Props> {
  render() {
    const { searchOptions, updateSorting } = this.props
    const { orderBy } = searchOptions
    return (
      <Grid xs={12}>
        <Grid container spacing={2} xs={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <SortableSearchHeader
            size={5}
            label="Asset"
            field="assetTypeName"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={3}
            label="Date uploaded"
            field="dateUploaded"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={4}
            label="Publishing house"
            field="publishingHouseName"
            {...{
              orderBy,
              updateSorting,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default AssetViewHeader
