import { convertFromHTML, convertToHTML } from 'draft-convert'
import {
  ContentState,
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  DefaultDraftBlockRenderMap,
  BlockMapBuilder,
} from 'draft-js'
import { Component } from 'react'

import IconClose from '@mui/icons-material/Close'
import IconBold from '@mui/icons-material/FormatBold'
import IconItalic from '@mui/icons-material/FormatItalic'
import IconUnderlined from '@mui/icons-material/FormatUnderlined'
import IconRedo from '@mui/icons-material/Redo'
import IconRemove from '@mui/icons-material/RemoveCircleOutline'
import IconUndo from '@mui/icons-material/Undo'
import { Divider, IconButton, Paper } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import commonMessages from '../messages'

type Props = {
  onValueChange(..._args: unknown[]): unknown
  value: any
  onDelete?(..._args: unknown[]): unknown
  disabled?: boolean
}

class DraftComponent extends Component<Props> {
  static defaultProps = {
    disabled: false,
  }

  state = {
    editorState: null,
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props
    this.setState({
      editorState: EditorState.createWithContent(convertFromHTML(value)),
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps
    const { editorState } = this.state
    if (value === convertToHTML(editorState.getCurrentContent())) return
    this.setState({
      editorState: EditorState.createWithContent(convertFromHTML(value)),
    })
  }

  onChangeEditorState = (editorState) => {
    const { onValueChange } = this.props
    this.setState(
      {
        editorState,
      },
      () => onValueChange(convertToHTML(editorState.getCurrentContent())),
    )
  }

  clear = () => {
    const newState = EditorState.push(this.state.editorState, ContentState.createFromText(''))
    this.onChangeEditorState(newState)
  }

  undo = () => {
    const newState = EditorState.undo(this.state.editorState)
    this.onChangeEditorState(newState)
  }

  redo = () => {
    const newState = EditorState.redo(this.state.editorState)
    this.onChangeEditorState(newState)
  }

  toggleInlineStyle = (inlineStyle) => {
    const { editorState } = this.state
    this.onChangeEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  handleKeyCommand = (command) => {
    const { editorState } = this.state
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (!newState) return false
    this.onChangeEditorState(newState)
    return true
  }

  render() {
    const { disabled, onDelete } = this.props
    const { editorState } = this.state
    return (
      <Grid container spacing={2} xs={12}>
        <Grid xs={12}>
          <Paper>
            <Grid container spacing={0} xs={12}>
              {!disabled ? (
                <Grid
                  container
                  spacing={0}
                  xs={12}
                  sx={{
                    backgroundColor: '#efefef',
                    display: 'flex',
                    displayPrint: 'none',
                  }}>
                  <Grid xs={10}>
                    <IconButton
                      onClick={() => this.toggleInlineStyle('BOLD')}
                      title="Bold"
                      color="primary"
                      size="large">
                      <IconBold />
                    </IconButton>
                    <IconButton
                      onClick={() => this.toggleInlineStyle('ITALIC')}
                      title="Italic"
                      color="primary"
                      size="large">
                      <IconItalic />
                    </IconButton>
                    <IconButton
                      onClick={() => this.toggleInlineStyle('UNDERLINE')}
                      title="Underlined"
                      color="primary"
                      size="large">
                      <IconUnderlined />
                    </IconButton>
                    <IconButton
                      onClick={this.undo}
                      disabled={editorState.getUndoStack().size === 0}
                      title={commonMessages.action.reset}
                      color="primary"
                      size="large">
                      <IconUndo />
                    </IconButton>
                    <IconButton
                      onClick={this.redo}
                      disabled={editorState.getRedoStack().size === 0}
                      title={commonMessages.action.repeat}
                      color="primary"
                      size="large">
                      <IconRedo />
                    </IconButton>
                  </Grid>
                  <Grid xs={2} display="flex" justifyContent="flex-end">
                    <IconButton
                      onClick={this.clear}
                      disabled={!editorState.getCurrentContent().hasText()}
                      title={commonMessages.action.clear}
                      color="primary"
                      size="large">
                      <IconRemove />
                    </IconButton>
                    {onDelete ? (
                      <IconButton
                        onClick={onDelete}
                        title={commonMessages.action.remove}
                        color="error"
                        size="large">
                        <IconClose />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              ) : null}
              {!disabled ? (
                <Grid xs={12}>
                  <Divider />
                </Grid>
              ) : null}
            </Grid>
            <Grid xs={12}>
              <Editor
                placeholder="Enter text here"
                readOnly={disabled}
                editorState={editorState}
                onChange={this.onChangeEditorState}
                handleKeyCommand={this.handleKeyCommand}
                handlePastedTest={(text, html) => {
                  const fragment = convertFromHTML(html)
                  const newContentState = Modifier.replaceWithFragment(
                    {},
                    {},
                    BlockMapBuilder.createFromArray(fragment),
                  )
                  this.onChange(editorState.push(editorState, newContentState))
                }}
                blockRenderMap={DefaultDraftBlockRenderMap.merge({
                  'header-one': {
                    element: 'h1',
                  },
                  'header-two': {
                    element: 'h2',
                  },
                  'header-three': {
                    element: 'h3',
                  },
                  'header-four': {
                    element: 'h4',
                  },
                  'header-five': {
                    element: 'h5',
                  },
                  'header-six': {
                    element: 'h6',
                  },
                  'unordered-list-item': {
                    element: 'p',
                  },
                  'ordered-list-item': {
                    element: 'p',
                  },
                  blockquote: {
                    element: 'p',
                  },
                  atomic: {
                    element: 'p',
                  },
                  'code-block': {
                    element: 'p',
                  },
                })}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  onChange(_arg: any) {
    throw new Error('Method not implemented.')
  }
}

export default DraftComponent
