import { getOr } from 'lodash/fp'

import { services } from 'Common/environment'
import { serializeSearchOutput } from 'Common/search/searchOutputSerializers'
import request from 'Common/utils/net/request'

export const PUT_PRODUCTION_EXPORT_RULES = 'PUT_PRODUCTION_EXPORT_RULES'
export const PUT_PRODUCTION_EXPORT_RULES_REQUEST = 'PUT_PRODUCTION_EXPORT_RULES_REQUEST'
export const PUT_PRODUCTION_EXPORT_RULES_SUCCESS = 'PUT_PRODUCTION_EXPORT_RULES_SUCCESS'
export const PUT_PRODUCTION_EXPORT_RULES_FAILURE = 'PUT_PRODUCTION_EXPORT_RULES_FAILURE'
export const POST_PRODUCTIONS_BATCH_EXPORT_RULES = 'POST_PRODUCTIONS_BATCH_EXPORT_RULES'
export const POST_PRODUCTIONS_BATCH_EXPORT_RULES_REQUEST =
  'POST_PRODUCTIONS_BATCH_EXPORT_RULES_REQUEST'
export const POST_PRODUCTIONS_BATCH_EXPORT_RULES_SUCCESS =
  'POST_PRODUCTIONS_BATCH_EXPORT_RULES_SUCCESS'
export const POST_PRODUCTIONS_BATCH_EXPORT_RULES_FAILURE =
  'POST_PRODUCTIONS_BATCH_EXPORT_RULES_FAILURE'
export const putProductionExportRules = (workId, productionId, body) => (dispatch) => {
  const url = services.work(`/v1/works/${workId}/productions/${productionId}/exportRules`)
  return dispatch({
    type: PUT_PRODUCTION_EXPORT_RULES,
    meta: {
      workId,
      productionId,
      body,
    },
    payload: {
      promise: request({
        method: 'PUT',
        url,
        body: body,
      }),
    },
  })
}
export const postProductionsBatchExportRules =
  (searchConfig, salesData) => (dispatch, getState) => {
    const limit = getOr(25, 'pagingSize', searchConfig)
    const searchOptions = getOr({}, `search[${searchConfig.id}].searchOptions`, getState())
    const itemsTotalCount = getOr(
      {},
      `search[${searchConfig.id}].paging.itemsTotalCount`,
      getState(),
    )
    const searchQuery = serializeSearchOutput(searchOptions, searchConfig, limit)
    const url = services.work(`/v1/productions/exportRules`)
    const body = {
      search: decodeURIComponent(searchQuery),
      change: { ...salesData },
      itemsTotalCount,
    }
    return dispatch({
      type: POST_PRODUCTIONS_BATCH_EXPORT_RULES,
      meta: {
        searchConfig,
        startTime: Date.now(),
      },
      payload: {
        promise: request({
          method: 'POST',
          url,
          body,
        }),
      },
    })
  }
