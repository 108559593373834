import Grid from '@mui/material/Unstable_Grid2'
import { lightGreen } from '@mui/material/colors'

import { MetaData } from 'Common/metadata'

import { FilterChip } from './filters'

type Props = {
  onRemoveOption(..._args: unknown[]): unknown
  filter: any
  active: unknown[]
  metaData?: MetaData
  chipColor?: string
}

const FilterSelectionList = ({ filter, active, metaData, chipColor, onRemoveOption }: Props) => {
  const displayFormat = filter.displayFormat || filter.type.displayFormat
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        {active.map((activeOption, index) => (
          <FilterChip
            key={index}
            {...{
              chipColor,
            }}
            onDelete={() => onRemoveOption(activeOption)}
            title={displayFormat(activeOption, filter, metaData)}
            label={displayFormat(activeOption, filter, metaData)}
          />
        ))}
      </Grid>
    </Grid>
  )
}

FilterSelectionList.defaultProps = {
  chipColor: lightGreen[300],
}
export default FilterSelectionList
