import { Component } from 'react'

import GlobalError from '../../error/GlobalError'

type Props = {
  children?: React.ReactNode
  point: string
  nodeEnv?: string
  errorMessageNode?: React.ReactNode
}

export default class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
    errorInfo: null,
    error: null,
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error,
    })
  }

  render() {
    const { hasError } = this.state
    const { errorMessageNode } = this.props

    if (hasError) {
      return errorMessageNode ? errorMessageNode : <GlobalError />
    } else {
      return this.props.children
    }
  }
}
