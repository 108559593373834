import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
  GET_PRODUCTION_ASSETS_REQUEST,
  GET_PRODUCTION_ASSETS_SUCCESS,
  GET_PRODUCTION_ASSETS_FAILURE,
  CONNECT_ASSET_PRODUCTION_SUCCESS,
  DISCONNECT_ASSET_PRODUCTION_SUCCESS,
} from '../actions/productionAssetsActions'

const initialState = {
  status: {
    getStatus: undefined,
    getError: undefined,
  },
  data: undefined,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_REQUEST:
      return { ...initialState }

    case GET_PRODUCTION_ASSETS_REQUEST:
      return {
        ...state,
        workId: action.payload.workId,
        productionId: action.payload.productionId,
        data: null,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case DISCONNECT_ASSET_PRODUCTION_SUCCESS:
    case CONNECT_ASSET_PRODUCTION_SUCCESS: {
      const assets = action.payload.json ? action.payload.json : null
      const shouldRefreshThumbnail = assets.includes((element) => element.id === '9')
      return {
        ...state,
        data: assets,
        status: { ...state.status, getStatus: requestStatus.success },
        refreshThumbnail: shouldRefreshThumbnail,
      }
    }

    case GET_PRODUCTION_ASSETS_SUCCESS: {
      const assets = action.payload.json ? action.payload.json : null
      return {
        ...state,
        data: assets,
        status: { ...state.status, getStatus: requestStatus.success },
      }
    }

    case GET_PRODUCTION_ASSETS_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    default:
      return state
  }
}
