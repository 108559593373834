import { services } from 'Common/environment'

import messages from '../messages'
import createErrorMessages from '../messages/createErrorMessages'
import request from '../utils/net/request'

export const CLEAR_ASSET = 'CLEAR_ASSET'
export const GET_ASSETS = 'GET_ASSETS'
export const GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST'
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS'
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE'
export const GET_ASSET = 'GET_ASSET'
export const GET_ASSET_REQUEST = 'GET_ASSET_REQUEST'
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS'
export const GET_ASSET_FAILURE = 'GET_ASSET_FAILURE'
export const PATCH_ASSET = 'PATCH_ASSET'
export const PATCH_ASSET_REQUEST = 'PATCH_ASSET_REQUEST'
export const PATCH_ASSET_SUCCESS = 'PATCH_ASSET_SUCCESS'
export const PATCH_ASSET_FAILURE = 'PATCH_ASSET_FAILURE'
export const POST_ASSET = 'POST_ASSET'
export const POST_ASSET_REQUEST = 'POST_ASSET_REQUEST'
export const POST_ASSET_SUCCESS = 'POST_ASSET_SUCCESS'
export const POST_ASSET_FAILURE = 'POST_ASSET_FAILURE'
export const PUT_ASSET = 'PUT_ASSET'
export const PUT_ASSET_REQUEST = 'PUT_ASSET_REQUEST'
export const PUT_ASSET_SUCCESS = 'PUT_ASSET_SUCCESS'
export const PUT_ASSET_FAILURE = 'PUT_ASSET_FAILURE'
export const DELETE_ASSET = 'DELETE_ASSET'
export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST'
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS'
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE'

export type Asset = any

export const clearAsset = () => {
  return {
    type: CLEAR_ASSET,
  }
}
export const getAssets = ({ query = '', orderBy = 'result' } = {}) => {
  let searchQuery = `q=(/.*${query}.*/|${query}|${query}*)`

  if (orderBy) {
    if (orderBy !== 'result') {
      searchQuery += `&$orderBy=${orderBy}`
    }
  }

  return (dispatch) =>
    dispatch({
      type: GET_ASSETS,
      payload: {
        data: {
          searchOptions: {
            query,
            orderBy,
          },
        },
        promise: request({
          method: 'GET',
          url: services.assets(`/v1/search/assets?${searchQuery}`),
        }),
      },
    })
}
const GET_ASSET_ERROR_MESSAGES = createErrorMessages(
  ['ASSET_NOT_FOUND', 'ASSET_FORBIDDEN', 'ASSET_FILE_NOT_FOUND'],
  messages,
)
export const getAsset = (id) => (dispatch) =>
  dispatch({
    type: GET_ASSET,
    payload: {
      data: id,
      promise: request({
        method: 'GET',
        url: services.assets(`/v1/assets/${id}`),
        errorMessages: GET_ASSET_ERROR_MESSAGES,
      }),
    },
  })
const PATCH_ASSET_ERROR_MESSAGES = createErrorMessages(
  ['ASSET_NOT_FOUND', 'ASSET_FORBIDDEN'],
  messages,
)
export const patchAsset = (id, asset) => (dispatch) =>
  dispatch({
    type: PATCH_ASSET,
    payload: {
      data: asset.id,
      promise: request({
        method: 'PATCH',
        body: asset,
        url: services.assets(`/v1/assets/${id}`),
        errorMessages: PATCH_ASSET_ERROR_MESSAGES,
      }),
    },
  })
const DELETE_ASSET_ERROR_MESSAGES = createErrorMessages(
  ['ASSET_NOT_FOUND', 'ASSET_FORBIDDEN'],
  messages,
)
export const deleteAsset = (id) => (dispatch) =>
  dispatch({
    type: DELETE_ASSET,
    payload: {
      data: id,
      promise: request({
        method: 'DELETE',
        url: services.assets(`/v1/assets/${id}`),
        errorMessages: DELETE_ASSET_ERROR_MESSAGES,
      }),
    },
  })
const PUT_ASSET_ERROR_MESSAGES = createErrorMessages(
  [
    'ASSET_NOT_FOUND',
    'ASSET_FORBIDDEN',
    'ASSET_MISSING_DATA',
    'ASSET_MISSING_CHANGED_FILES',
    'ASSET_FILE_TYPE_NOT_ALLOWED',
    'ASSET_IMAGE_HEIGHT_TO_LOW',
    'ASSET_IMAGE_WIDTH_TO_LOW',
    'UNABLE_TO_DETERMINE_FILETYPE',
  ],
  messages,
)
export const putAsset = (id, asset) => {
  const body = new FormData()
  Object.keys(asset).forEach((key) => {
    body.append(key, asset[key])
  })
  return (dispatch) =>
    dispatch({
      type: PUT_ASSET,
      payload: {
        data: asset,
        promise: request({
          method: 'PUT',
          isDataRequest: true,
          url: services.assets(`/v1/assets/${id}/file`),
          errorMessages: PUT_ASSET_ERROR_MESSAGES,
          body,
        }),
      },
    })
}
const POST_ASSET_ERROR_MESSAGES = createErrorMessages(
  [
    'ASSET_FORBIDDEN',
    'ASSET_MISSING_DATA',
    'ASSET_MISSING_CONTRACT_TYPE',
    'ASSET_MISSING_CHANGED_FILES',
    'ASSET_FILE_TYPE_NOT_ALLOWED',
    'ASSET_IMAGE_HEIGHT_TO_LOW',
    'ASSET_IMAGE_WIDTH_TO_LOW',
    'UNABLE_TO_DETERMINE_FILETYPE',
  ],
  messages,
)
export const postAsset = (asset) => {
  const body = new FormData()
  Object.keys(asset).forEach((key) => {
    body.append(key, asset[key])
  })
  return (dispatch) => {
    return dispatch({
      type: POST_ASSET,
      payload: {
        data: asset,
        promise: request({
          url: services.assets('/v1/assets'),
          method: 'POST',
          isDataRequest: true,
          errorMessages: POST_ASSET_ERROR_MESSAGES,
          body,
        }),
      },
    })
  }
}
