import { PublishingHouseGroups } from 'Common/types'

const NFG_PUBLISHINGHOUSEGROUP_ID = '1'
const GUTKIND_PUBLISHINGHOUSEGROUP_ID = '2'
const GUMMERUS_PUBLISHINGHOUSEGROUP_ID = '3'
const BNF_PUBLISHINGHOUSEGROUP_ID = '4'
const DEMO_PUBLISHINGHOUSEGROUP_ID = '5'
const BOOKBEAT_PUBLISHINGHOUSEGROUP_ID = '6'
const BF_PUBLISHINGHOUSEGROUP_ID = 'bf'
const SEMIC_PUBLISHINGHOUSEGROUP_ID = 'semic'
const WSOY_PUBLISHINGHOUSEGROUP_ID = 'bbf'

export const UseDescriptionCore = (publishingHouseGroupIds: string[]) => {
  return !publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID)
}

export const hasRoyalty = (publishingHouseGroups: PublishingHouseGroups) => {
  return publishingHouseGroups.publishingHouseGroups.some(
    (publishingHouseGroup) =>
      publishingHouseGroup.id === WSOY_PUBLISHINGHOUSEGROUP_ID ||
      publishingHouseGroup.id === DEMO_PUBLISHINGHOUSEGROUP_ID,
  )
}

export const DisplayRemoveCategory = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUTKIND_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const FilterBookCategory = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUTKIND_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ErpValidationCheck = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID)
}

export const HideArticleGroup = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUMMERUS_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ErpShowDiscountNumber = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID)
}

export const ChangeEstimatedNumberOfPages = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowInfoTextCostCenter = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID)
}

export const DisableCostCenter = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID)
}

export const DisplayCostCenterName = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID)
}

export const SendMailNewPrint = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowPublisherRetailPrice = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowReviewQuotes = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const DisableTaxCode = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowSales = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const CheckFinnishSocialSecurityNumber = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowPreCalculation = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowLibraryCode = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUMMERUS_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ShowPrimaryTexts = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(SEMIC_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUTKIND_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(BNF_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(DEMO_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(BOOKBEAT_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ShowCreateAndCalculate = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(SEMIC_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ShowCostCenter = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUTKIND_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ValidateMainAndSubGroup = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUTKIND_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const ShowERPConnectionOnWorkOverview = (publishingHouseGroupIds: string[]) => {
  return (
    publishingHouseGroupIds.includes(NFG_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(GUTKIND_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(BF_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(SEMIC_PUBLISHINGHOUSEGROUP_ID) ||
    publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
  )
}

export const RequireCountryAndCity = (publishingHouseGroupIds: string[]) => {
  return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
}

export const ShowPhysicalExportRules = (
  isPrintedBook: boolean,
  isCd: boolean,
  publishingHouseGroupIds: string[],
) => {
  if (isCd) return publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID)
  else if (isPrintedBook)
    return (
      publishingHouseGroupIds.includes(WSOY_PUBLISHINGHOUSEGROUP_ID) ||
      publishingHouseGroupIds.includes(GUMMERUS_PUBLISHINGHOUSEGROUP_ID)
    )
  else return true
}

export const PrintOrderFreeCopies = (publishingHouseGroupIds: string[], isFirstPrint: boolean) => {
  const publishingHouseGroupId = publishingHouseGroupIds.find((e) => typeof e !== 'undefined')
  if (isFirstPrint) {
    switch (publishingHouseGroupId) {
      case BF_PUBLISHINGHOUSEGROUP_ID:
        return 8
      case SEMIC_PUBLISHINGHOUSEGROUP_ID:
      case WSOY_PUBLISHINGHOUSEGROUP_ID:
      case GUMMERUS_PUBLISHINGHOUSEGROUP_ID:
        return 10
      case NFG_PUBLISHINGHOUSEGROUP_ID:
        return 4
      case GUTKIND_PUBLISHINGHOUSEGROUP_ID:
        return 1
      default:
        return 0
    }
  } else {
    switch (publishingHouseGroupId) {
      case BF_PUBLISHINGHOUSEGROUP_ID:
      case SEMIC_PUBLISHINGHOUSEGROUP_ID:
      case WSOY_PUBLISHINGHOUSEGROUP_ID:
      case GUMMERUS_PUBLISHINGHOUSEGROUP_ID:
        return 2
      case NFG_PUBLISHINGHOUSEGROUP_ID:
      case GUTKIND_PUBLISHINGHOUSEGROUP_ID:
        return 1
      default:
        return 0
    }
  }
}
