import { FormEvent } from 'react'

export { default as FormCheckbox } from './FormCheckbox'
export { default as FormChipSelectionArray } from './FormChipSelectionArray'
export { default as FormContactPicker } from './FormContactPicker'
export { default as FormDatePicker } from './FormDatePicker'
export { default as FormErrorDialog } from './FormErrorDialog'
export { default as FormMetaDataSearch } from './FormMetaDataSearch'
export { default as FormNumberFormatField } from './FormNumberFormatField'
export { default as FormSelectField } from './FormSelectField'
export { default as FormTextEditorField } from './FormTextEditorField'
export { default as FormTextEditorsArray } from './FormTextEditorsArray'
export { default as FormTextField } from './FormTextField'

type FormProps = {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => any
  children: React.ReactNode
}

export const Form = (props: FormProps) => {
  return <form {...props}>{props.children}</form>
}
