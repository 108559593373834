import { Component } from 'react'
import { NumericFormat } from 'react-number-format'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { FormFieldInfo, FormFieldInfoToggler } from './fieldinfo'
import { getFieldInfo, getLabelText, getErrorText, getHasError } from './formFieldHelpers'

const getClassName = (focused?: any, nrOfRows?: any, className?: any) => {
  let classesToAdd = ''

  if (nrOfRows && nrOfRows > 3) {
    classesToAdd = focused
      ? 'opusTextFieldExpandOnFocus'
      : 'opusTextFieldExpandOnFocus opusTextFieldExpandOnFocusBlurred'
  }

  return className ? `${className} ${classesToAdd}` : classesToAdd
}

type Props = {
  input: any
  meta: any
  muiRef?(..._args: unknown[]): unknown
  showMetaDataAdmin?: boolean
  label?: React.ReactNode
  style?: Record<string, unknown>
  required?: boolean
  disabled?: boolean
  floatingLabelText?: string
  expandOnFocus?: boolean
  onHandleChange?(..._args: unknown[]): unknown
  showInfoButton?: boolean
  isFactor?: boolean
  infoText?: string
  history?: string
  className?: string
  id?: string
  type?: string
  propertyRules?: Record<string, unknown>
  disableUnderline?: boolean
  disableLabel?: boolean
  lineThrough?: boolean
  dispatch?: any
}

type State = {
  nrOfRows?: any
  clickedInfoButton: boolean
  showInfoTextDialog: boolean
}

export class FormNumberFormatField extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
    expandOnFocus: true,
    required: false,
    propertyRules: {},
    isFactor: false,
    lineThrough: false,
    showMetaDataAdmin: false,
  }

  state = {
    clickedInfoButton: false,
    showInfoTextDialog: false,
    nrOfRows: undefined,
  }

  clickIcon = () => {
    this.setState({
      clickedInfoButton: !this.state.clickedInfoButton,
    })
  }

  render() {
    const {
      input,
      meta,
      id,
      infoText,
      history,
      expandOnFocus,
      propertyRules,
      muiRef,
      disableUnderline,
      disableLabel,
      lineThrough,
    } = this.props
    const { required, label, className, disabled, type } = this.props
    const { name } = input
    const { error, touched } = meta
    const { showInfoTextDialog, clickedInfoButton } = this.state
    const fieldInfo = getFieldInfo(propertyRules, infoText, history)
    const isDisabled = disabled || fieldInfo.isReadOnly
    return (
      <Grid xs={12}>
        <NumericFormat
          helperText={getErrorText(touched, error)}
          error={getHasError(touched, error)}
          className={getClassName(expandOnFocus, className)}
          style={{ textDecoration: lineThrough ? 'line-through' : 'unset' }}
          name={name}
          getInputRef={muiRef}
          value={input.value}
          disabled={isDisabled}
          label={disableLabel ? '' : getLabelText(label, propertyRules)}
          required={required}
          variant="standard"
          id={id || `FormNumberTextField-${name}-${label}-${type}`.replace(/[^A-Za-z0-9-]/gi, '')}
          customInput={TextField}
          valueIsNumericString
          onValueChange={(values) => {
            if (this.props.onHandleChange) {
              this.props.onHandleChange(values.value)
            } else {
              this.props.input.onChange(values.value)
            }
          }}
          fullWidth
          thousandSeparator=" "
          decimalSeparator=","
          decimalScale={2}
          InputProps={{
            disableUnderline: disableUnderline,
            endAdornment: (
              <InputAdornment position="end">
                <FormFieldInfoToggler
                  clickIcon={this.clickIcon}
                  showInfoButton={fieldInfo.showInfoButton}
                />
              </InputAdornment>
            ),
          }}
        />
        <FormFieldInfo
          {...{
            fieldInfo,
            propertyRules,
            history,
            infoText,
            showInfoTextDialog,
            clickedInfoButton,
          }}
        />
      </Grid>
    )
  }
}
export default FormNumberFormatField
