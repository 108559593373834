import { reverse } from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import IconShare from '@mui/icons-material/Share'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { RootState } from 'Common/store/createStore'

import Spinner, { SPINNER_SIZES } from '../../components/loader/Spinner'
import { getProductionHistory as getProductionHistoryAction } from '../../history/productionHistoryActions'
import { requestStatus } from '../../utils/net/statuses'

type Props = {
  history: string
}

const mapState = (state: RootState) => {
  const { productionHistory, production } = state
  return {
    production: production.data,
    productionHistory: productionHistory.data,
    productionHistoryStatus: productionHistory.status,
  }
}

const connector = connect(mapState, {
  getProductionHistory: getProductionHistoryAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

class FormFieldHistory extends Component<Props & PropsFromRedux> {
  UNSAFE_componentWillMount() {
    const { getProductionHistory, production, history } = this.props
    getProductionHistory(production.workId, production.id, history)
  }

  renderHistory() {
    const { history, productionHistory, productionHistoryStatus } = this.props

    if (productionHistoryStatus.getStatus === requestStatus.success && productionHistory[history]) {
      if (productionHistory[history].length === 0) {
        return <>Unfortunately there is no history.</>
      }

      return reverse(productionHistory[history]).map((element, index) => (
        <Typography key={index}>
          {element.value} ({new Date(element.updated).toLocaleDateString()}) changed by{' '}
          {element.updatedBy}
        </Typography>
      ))
    }

    return null
  }

  render() {
    const { productionHistoryStatus } = this.props
    return (
      <Grid
        xs={12}
        sx={{
          borderTop: '1px dashed black',
          backgroundColor: 'warning.main',
        }}>
        <Grid xs={12}>
          <Grid container spacing={1} xs={12}>
            <Grid xs={2} display="flex" justifyContent="center">
              <IconShare />
            </Grid>
            <Grid xs={10}>
              <Typography fontWeight="bold">HISTORY</Typography>
            </Grid>
            {productionHistoryStatus.getStatus !== requestStatus.success &&
            productionHistoryStatus.getStatus !== requestStatus.failure ? (
              <Grid xs={12}>
                <Spinner size={SPINNER_SIZES.XSMALL} />
              </Grid>
            ) : null}
            {productionHistoryStatus.getStatus === requestStatus.failure ? (
              <Grid xsOffset={2} xs={10}>
                Something went wrong while trying to fetch the history
              </Grid>
            ) : null}
            <Grid xsOffset={2} xs={10}>
              <Typography variant="body2">{this.renderHistory()}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default connector(FormFieldHistory)
