import { isEqual, omit } from 'lodash/fp'

import { requestStatus } from '../utils/net/statuses'
import { handlePaging } from '../utils/paging/paging'
import {
  GET_SEARCH_REQUEST,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_FAILURE,
  UPDATE_SEARCH_OPTIONS,
  CLEAR_SEARCH,
} from './searchActions'
import { TOGGLE_SINGLE, TOGGLE_ALL } from './selectSearchActions'

export const shouldAppendResults = (searchOptions: any, previousSearchOptions: any = {}) =>
  isEqual(omit('offset', previousSearchOptions), omit('offset', searchOptions)) &&
  (previousSearchOptions.offset || 0) < (searchOptions.offset || 0)
const initialState: any = {}
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_OPTIONS: {
      const { searchConfig, searchOptions } = action.meta
      const searchState = state[searchConfig.id] || {}
      const appendResults = shouldAppendResults(searchOptions, searchState.searchOptions)
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          searchOptions: isEqual(searchState.searchOptions, searchOptions)
            ? searchState.searchOptions
            : searchOptions,
          results: searchState.results || [],
          appendResults,
        },
      }
    }

    case GET_SEARCH_REQUEST: {
      const { searchConfig, startTime } = action.meta
      const searchState = state[searchConfig.id] || {}
      const { appendResults } = searchState
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          results: appendResults ? searchState.results : [],
          paging: appendResults ? searchState.paging : null,
          status: requestStatus.request,
          startTime,
        },
      }
    }

    case GET_SEARCH_SUCCESS: {
      const { searchConfig, startTime } = action.meta
      const searchState = state[searchConfig.id]
      if (startTime < searchState.startTime) return state
      const { searchOptions, selectedResult = [], globalSelectionStatus = 'none' } = searchState
      const { json } = action.payload
      const results = searchState.appendResults
        ? [...searchState.results, ...json.results]
        : json.results
      const nextSelectedResult = searchState.appendResults ? [...selectedResult] : []
      const nextGlobalSelectionStatus = searchState.appendResults ? globalSelectionStatus : 'none'
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          results,
          paging: handlePaging(searchConfig, json.pagination, searchOptions.offset),
          status: requestStatus.success,
          globalSelectionStatus: nextGlobalSelectionStatus,
          selectedResult: nextSelectedResult,
        },
      }
    }

    case GET_SEARCH_FAILURE: {
      const { searchConfig, startTime } = action.meta
      const searchState = state[searchConfig.id]
      if (startTime < searchState.startTime) return state
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          status: requestStatus.failure,
          errorStatus: action.payload.statusText,
          globalSelectionStatus: 'none',
          selectedResult: [],
        },
      }
    }

    case CLEAR_SEARCH: {
      const { searchConfig } = action.meta
      const searchState = state[searchConfig.id]
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          results: [],
          appendResults: false,
          paging: null,
          status: null,
        },
      }
    }

    case TOGGLE_SINGLE: {
      const { searchConfig, selectedResultId } = action.meta
      const searchState = state[searchConfig.id]
      const { selectedResult = [], results = [] } = searchState
      const nextSelectedResult = selectedResult.includes(selectedResultId)
        ? selectedResult.filter((selectedItem) => selectedItem !== selectedResultId)
        : [selectedResultId, ...selectedResult]
      const nextGlobalSelectionStatus =
        nextSelectedResult.length === 0
          ? 'none'
          : nextSelectedResult.length === results.length
          ? 'all'
          : 'some'
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          globalSelectionStatus: nextGlobalSelectionStatus,
          selectedResult: nextSelectedResult,
        },
      }
    }

    case TOGGLE_ALL: {
      const { searchConfig } = action.meta
      const searchState = state[searchConfig.id]
      const { selectedResult = [], results = [], globalSelectionStatus = 'none' } = searchState
      const nextGlobalSelectionStatus =
        globalSelectionStatus === 'all' || globalSelectionStatus === 'some' ? 'none' : 'all'
      const nextSelectedResult =
        nextGlobalSelectionStatus === 'all'
          ? results.map(({ document: { id } }) => id)
          : nextGlobalSelectionStatus === 'none'
          ? []
          : selectedResult
      return {
        ...state,
        [searchConfig.id]: {
          ...searchState,
          globalSelectionStatus: nextGlobalSelectionStatus,
          selectedResult: nextSelectedResult,
        },
      }
    }

    default:
      return state
  }
}
