import { Cell } from 'fixed-data-table-2'
import { PureComponent } from 'react'

import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox'
import { Checkbox } from '@mui/material'

type Props = {
  toggleAll(..._args: unknown[]): unknown
  globalSelectionStatus?: string
  disabled?: boolean
}

class HeaderCheckboxCell extends PureComponent<Props> {
  render() {
    const { toggleAll, globalSelectionStatus, disabled, ...otherProps } = this.props
    return (
      <Cell {...otherProps}>
        {!!globalSelectionStatus &&
        (globalSelectionStatus === 'all' || globalSelectionStatus === 'none') ? (
          <Checkbox
            style={{
              padding: 4,
            }}
            color="primary"
            checked={globalSelectionStatus === 'all'}
            disabled={disabled && globalSelectionStatus !== 'all'}
            onClick={() => toggleAll()}
          />
        ) : (
          <Checkbox
            color="primary"
            checkedIcon={<IndeterminateCheckBox />}
            style={{
              padding: 4,
            }}
            checked={globalSelectionStatus === 'some'}
            onClick={() => toggleAll()}
          />
        )}
      </Cell>
    )
  }
}

export default HeaderCheckboxCell
