import { Close as CloseIcon } from '@mui/icons-material'
import { DialogTitle as MuiDialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const PREFIX = 'DialogTitle'

const classes = {
  titleRoot: `${PREFIX}-titleRoot`,
  titleCloseButton: `${PREFIX}-titleCloseButton`,
}

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  [`&.${classes.titleRoot}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.titleCloseButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type Props = {
  children?: any
  onClose?(..._args: unknown[]): unknown
  submitting?: boolean
}

const DialogTitle = (props: Props) => {
  const { children, onClose, submitting = false } = props
  return (
    <StyledMuiDialogTitle className={classes.titleRoot}>
      <Typography>{children}</Typography>
      {onClose ? (
        <Tooltip title="Close" aria-label="Close">
          <IconButton
            disabled={submitting}
            aria-label="Close"
            className={classes.titleCloseButton}
            onClick={(e) => onClose(e)}
            size="large">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </StyledMuiDialogTitle>
  )
}
export default DialogTitle
