import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
  searchOptions: Record<string, unknown>
  updateSorting(..._args: unknown[]): unknown
}

class PublishingListViewHeader extends Component<Props> {
  render() {
    const { searchOptions, updateSorting } = this.props
    const { orderBy } = searchOptions
    return (
      <Grid xs={12}>
        <Grid container spacing={2} xs={12} sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <SortableSearchHeader
            size={4}
            label="Titel"
            field="title"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={2}
            label="Author"
            field="authors"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={2}
            label="Binding code"
            field="bindingCodeName"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={2}
            label="To print"
            field="activePrint_TimePlan_SendInsideToPrint"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={2}
            label="Publication date"
            field="publishingDate"
            {...{
              orderBy,
              updateSorting,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default PublishingListViewHeader
