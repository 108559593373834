type Props = {
  error?: string
}

const ErrorMessage = ({ error }: Props) =>
  error ? (
    <p
      style={{
        color: 'rgb(244, 67, 54)',
        textAlign: 'center',
      }}>
      {error}
    </p>
  ) : null

export default ErrorMessage
