import { isNil } from 'lodash/fp'
import { Component } from 'react'

import { MenuItem, FormControl, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type Props = {
  setActiveView(..._args: unknown[]): unknown
  searchConfig: {
    savedSearchesId?: string
    resultsLabel?: string
  }
  viewsConfig?: {
    views: {
      id: string
      label: string
      disabled: boolean
    }[]
  }
  resultsCount: number
  selectedCount?: number
  activeView?: Record<string, unknown>
  matchedSavedSearch?: Record<string, unknown>
  hasActions?: boolean
  toggleSingle: any
}

class SearchControlResults extends Component<Props> {
  handleSelectView = (event) => {
    const { setActiveView } = this.props
    const viewId = event.target.value
    setActiveView(viewId)
  }

  render() {
    const {
      searchConfig,
      viewsConfig,
      activeView,
      resultsCount,
      selectedCount,
      matchedSavedSearch,
      hasActions,
    } = this.props
    const { savedSearchesId } = searchConfig

    return (
      <Grid container spacing={0} xs={12} sx={{ display: 'flex', displayPrint: 'none' }}>
        <Grid
          container
          spacing={1}
          xs={12}
          sm={8}
          display="flex"
          alignItems="center"
          sx={{ display: { xs: 'flex', displayPrint: 'none' } }}>
          {savedSearchesId && !isNil(matchedSavedSearch) ? (
            <Grid>
              <Typography variant="h6" textTransform="uppercase">
                SAVED SEARCH:
              </Typography>
            </Grid>
          ) : (
            <Grid>
              <Typography variant="h6" textTransform="uppercase">
                {searchConfig.resultsLabel || 'Search results: '}
              </Typography>
            </Grid>
          )}
          <Grid>
            <Typography
              variant="h6"
              color="text.disabled"
              fontWeight="bold">{`${resultsCount}`}</Typography>
          </Grid>

          {hasActions ? (
            <Grid container spacing={1} xs={12}>
              <Grid>
                <Typography variant="h6" textTransform="uppercase">
                  Selected:
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="h6"
                  color="text.disabled"
                  fontWeight="bold">{`${selectedCount}`}</Typography>
              </Grid>
            </Grid>
          ) : null}
          {savedSearchesId && !isNil(matchedSavedSearch) ? (
            <Grid xs={12} className="hidden-print">
              <Typography fontWeight="bold">{matchedSavedSearch.name}</Typography>
            </Grid>
          ) : null}
        </Grid>
        {viewsConfig && activeView && viewsConfig.views.length > 1 ? (
          <Grid
            xs={12}
            sm={4}
            display="flex"
            justifyContent="flex-end"
            sx={{ display: 'flex', displayPrint: 'none' }}>
            <FormControl variant="standard" fullWidth>
              <TextField
                variant="standard"
                label="Search view"
                select
                onChange={this.handleSelectView}
                value={activeView.id}>
                {viewsConfig.views
                  .filter((view) => !view.disabled)
                  .map((view) => (
                    <MenuItem key={view.id} value={view.id}>
                      {view.label}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
    )
  }
}

export default SearchControlResults
