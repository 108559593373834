import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_WORK_COMMUNICATION_PLAN_REQUEST,
  GET_WORK_COMMUNICATION_PLAN_SUCCESS,
  GET_WORK_COMMUNICATION_PLAN_FAILURE,
  PATCH_WORK_COMMUNICATION_PLAN_REQUEST,
  PATCH_WORK_COMMUNICATION_PLAN_SUCCESS,
  PATCH_WORK_COMMUNICATION_PLAN_FAILURE,
} from '../actions/workActions'

const initialState = {
  data: null,
  status: {
    getStatus: undefined,
    getError: undefined,
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    case PATCH_WORK_COMMUNICATION_PLAN_REQUEST:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
      }

    case GET_WORK_COMMUNICATION_PLAN_REQUEST:
      return {
        ...state,
        data: null,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case PATCH_WORK_COMMUNICATION_PLAN_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, patchStatus: requestStatus.success },
      }

    case GET_WORK_COMMUNICATION_PLAN_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case PATCH_WORK_COMMUNICATION_PLAN_FAILURE:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.failure, patchError: action.payload },
      }

    case GET_WORK_COMMUNICATION_PLAN_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    default:
      return state
  }
}
