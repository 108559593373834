import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import Contact from 'Common/components/contacts/Contact'
import { WorkSearchDucument } from 'Common/types'
import { filterMemberByAuthor } from 'Common/utils/roles'

type Props = {
  result: WorkSearchDucument
}

class WorksViewItem extends Component<Props> {
  render() {
    const { result } = this.props
    const authors = result.members.filter(filterMemberByAuthor)
    return (
      <Grid xs={12}>
        <Grid container spacing={2} rowSpacing={2} xs={12} sx={{ borderTop: '1px solid #e0e0e0' }}>
          <Grid xs={8} sm={4} sx={{ wordBreak: 'break-all' }}>
            <Link underline="none" component={NavLink} to={`/book/${result.id}`}>
              {getOr('-', 'title', result)}
            </Link>
          </Grid>
          <Grid xs={8} sm={4}>
            {authors.length > 0 ? (
              authors.map((author, index) => (
                <span key={author.id}>
                  <Contact {...author.contact} lastNameFirst />
                  {index + 1 !== authors.length && ' /'}
                </span>
              ))
            ) : (
              <span>-</span>
            )}
          </Grid>
          <Grid xs={8} sm={4} sx={{ wordBreak: 'break-all' }}>
            {getOr('-', 'publishingHouseName', result)}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default WorksViewItem
