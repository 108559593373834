import { getOr } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

const styles = {
  productionRow: {
    borderTop: '1px solid #e0e0e0',
  },
}

type Props = {
  result: any
  viewProps: {
    linkPrefix: string
  }
}

class ActivityViewItem extends Component<Props> {
  render() {
    const { result, viewProps } = this.props
    const { linkPrefix } = viewProps
    return (
      <Grid xs={12}>
        <Grid container spacing={2} xs={12} style={styles.productionRow}>
          <Grid xs={8} sm={6} style={{ wordBreak: 'break-all' }}>
            <Link underline="none" component={NavLink} to={`${linkPrefix}/${result.id}`}>
              {getOr('-', 'name', result)}
            </Link>
          </Grid>
          <Grid xs={8} sm={2} style={{ wordBreak: 'break-all' }}>
            {getOr('-', 'categoryName', result)}
          </Grid>
          <Grid xs={8} sm={2}>
            {result.publishingDate ? DateTime.fromISO(result.publishingDate).toISODate() : '-'}
          </Grid>
          <Grid xs={8} sm={2}>
            {getOr('-', 'statusName', result)}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ActivityViewItem
