import { debounce, getOr, isNil, sortBy } from 'lodash/fp'
import { Component } from 'react'

import IconDelete from '@mui/icons-material/Delete'
import IconReplay from '@mui/icons-material/Replay'
import IconSave from '@mui/icons-material/Save'
import {
  IconButton,
  FormControl,
  Divider,
  MenuItem,
  Paper,
  TextField,
  InputAdornment,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { requestStatus } from '../utils/net/statuses'
import { RESET } from './SearchControl'

const UNSAVED_SEARCH_NAME = '::##UNSAVED_SEARCH_NAME##::'

const getMatchedSearchInfo = (matchedSavedSearch, lastSavedSearchName) => {
  if (matchedSavedSearch)
    return {
      name: matchedSavedSearch.name,
      value: matchedSavedSearch.name,
      label: matchedSavedSearch.name,
      showUnsavedItem: false,
    }
  if (lastSavedSearchName)
    return {
      name: lastSavedSearchName,
      value: UNSAVED_SEARCH_NAME,
      label: `${lastSavedSearchName} (not saved)`,
      showUnsavedItem: true,
    }
  return {
    name: null,
    value: '',
    label: null,
    showUnsavedItem: false,
  }
}

type Props = {
  updateQuery(..._args: unknown[]): unknown
  updateQueryField(..._args: unknown[]): unknown
  setSearchCombinator(..._args: unknown[]): unknown
  activateSavedSearch(..._args: unknown[]): unknown
  toggleSaveSearchModal(..._args: unknown[]): unknown
  toggleRemoveSearchModal(..._args: unknown[]): unknown
  searchConfig: {
    savedSearchesId?: string
    hideQuery?: boolean
    queryFields?: unknown[]
  }
  searchOptions: Record<string, unknown>
  isActivatingSavedSearch: boolean
  savedSearches?: unknown[]
  matchedSavedSearch?: Record<string, unknown>
  searchLabel?: string
  status?: string
  lastSavedSearchName?: string
}

type State = {
  query: any
  activeActionQuery: any
}

class SearchControlHeader extends Component<Props, State> {
  debouncedUpdateGlobalQuery: any

  constructor(props) {
    super(props)
    this.debouncedUpdateGlobalQuery = debounce(500, this.updateGlobalQuery)
    this.state = {
      query: this.props.searchOptions.query,
      activeActionQuery: undefined,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.searchOptions.query === this.state.activeActionQuery &&
      !(!nextProps.isActivatingSavedSearch && this.props.isActivatingSavedSearch)
    ) {
      return
    }

    this.setState({
      query: nextProps.searchOptions.query,
    })
  }

  updateGlobalQuery = (event, query) =>
    this.setState(
      {
        activeActionQuery: query,
      },
      () => this.props.updateQuery(event, query),
    )

  updateQuery = (event) => {
    event.persist()
    return this.setState(
      {
        query: event.target.value,
      },
      () => this.debouncedUpdateGlobalQuery(event, event.target.value),
    )
  }

  render() {
    const {
      searchConfig,
      searchOptions,
      savedSearches,
      matchedSavedSearch,
      searchLabel,
      status,
      isActivatingSavedSearch,
      setSearchCombinator,
      activateSavedSearch,
      toggleSaveSearchModal,
      toggleRemoveSearchModal,
      updateQueryField,
      lastSavedSearchName,
    } = this.props
    const { query } = this.state
    const { savedSearchesId, hideQuery = false, queryFields } = searchConfig
    if (hideQuery) return null
    const matchedSearchInfo = getMatchedSearchInfo(matchedSavedSearch, lastSavedSearchName)
    return (
      <Paper sx={{ display: 'flex', displayPrint: 'none' }}>
        <Grid xs={12}>
          <Grid container spacing={2} xs={12}>
            {queryFields ? (
              <Grid xs={12} sm={3}>
                {!isActivatingSavedSearch ? (
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      variant="standard"
                      select
                      label="Search field"
                      onChange={updateQueryField}
                      value={searchOptions.queryField}>
                      <MenuItem value="all">
                        <em>All</em>
                      </MenuItem>
                      {queryFields.map((queryField: any) => (
                        <MenuItem key={queryField.id} value={queryField.id}>
                          {queryField.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                ) : null}
              </Grid>
            ) : null}
            <Grid xs={12} sm={queryFields ? 5 : 8} md={queryFields ? 5 : 8} lg={4} xl={4}>
              <FormControl variant="standard" fullWidth>
                <TextField
                  variant="standard"
                  label={query && status === requestStatus.request ? 'Searching...' : searchLabel}
                  value={query}
                  type="search"
                  autoFocus
                  onChange={this.updateQuery}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={3} md={1}>
              {!isActivatingSavedSearch ? (
                <FormControl variant="standard" fullWidth>
                  <TextField
                    variant="standard"
                    select
                    label="Combination"
                    onChange={(event) => setSearchCombinator(event.target.value)}
                    value={getOr('and', 'combinator', searchOptions)}
                    style={{
                      width: '100%',
                    }}>
                    <MenuItem value="and">and</MenuItem>
                    <MenuItem value="or">or</MenuItem>
                  </TextField>
                </FormControl>
              ) : null}
            </Grid>
            {savedSearchesId ? (
              <Grid xs={12} sm={8} md={3}>
                {!isActivatingSavedSearch ? (
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      variant="standard"
                      select
                      label="My searches"
                      onChange={(event) => activateSavedSearch(event.target.value)}
                      value={matchedSearchInfo.value}
                      InputProps={{
                        startAdornment: isNil(matchedSavedSearch) ? (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={
                                isNil(matchedSavedSearch)
                                  ? () => toggleSaveSearchModal(matchedSearchInfo.name)
                                  : toggleRemoveSearchModal
                              }
                              size="large">
                              <IconSave
                                color={matchedSearchInfo.showUnsavedItem ? 'action' : undefined}
                              />
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={
                                isNil(matchedSavedSearch)
                                  ? () => toggleSaveSearchModal(matchedSearchInfo.name)
                                  : toggleRemoveSearchModal
                              }
                              size="large">
                              <IconDelete />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}>
                      <MenuItem value={RESET}>
                        <IconReplay />
                        Reset
                      </MenuItem>
                      <Divider />
                      {matchedSearchInfo.showUnsavedItem ? (
                        <MenuItem value={UNSAVED_SEARCH_NAME} disabled>
                          {matchedSearchInfo.label}
                        </MenuItem>
                      ) : null}
                      {sortBy((search: any) => search.name, savedSearches).map(
                        (search: any, index) => (
                          <MenuItem key={index} value={search.name}>
                            {search.name}
                          </MenuItem>
                        ),
                      )}
                    </TextField>
                  </FormControl>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
export default SearchControlHeader
