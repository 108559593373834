import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { WrappedFieldArrayProps } from 'redux-form'

import { TextField, MenuItem } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { RemoveButton } from 'Common/components/buttons'
import { Field, Dialog } from 'Common/core'

import FormTextEditorField from './FormTextEditorField'
import FormFieldInfo from './fieldinfo/FormFieldInfo'
import { getFieldInfo } from './formFieldHelpers'

type AddTextProps = {
  texts: any
  arrayFields: any
  textTypes: any
  fieldInfo: Record<string, unknown>
  propertyRules: Record<string, unknown>
  infoText?: string
}

const AddText = ({
  texts,
  arrayFields,
  textTypes,
  fieldInfo,
  propertyRules,
  infoText,
}: AddTextProps) => {
  const unusedTextTypes = textTypes.filter(
    (textType: any) =>
      textType.isActive !== false && !texts.some((text: any) => textType.id === text.textType.id),
  )
  if (unusedTextTypes.length === 0) return null
  return (
    <Grid container spacing={2} xs={12}>
      <Grid xs={12}>
        <TextField
          variant="standard"
          select
          value={-1}
          sx={{ width: '100%' }}
          onChange={(event: any) => {
            if (event.target.value < 0) return
            arrayFields.insert(0, {
              text: '',
              textType: unusedTextTypes.find((textType) => event.target.value === textType.id),
            })
          }}>
          <MenuItem value={-1}>Add text</MenuItem>
          {unusedTextTypes.map((textType, index) => (
            <MenuItem key={index} value={textType.id}>
              {textType.name}
            </MenuItem>
          ))}
        </TextField>
        <FormFieldInfo
          {...{
            fieldInfo,
            propertyRules,
            infoText,
          }}
        />
      </Grid>
    </Grid>
  )
}

type Props = {
  textTypes: unknown[]
  enableAdd?: boolean
  enableDelete?: boolean
  disabled?: boolean
  infoText?: string
  propertyRules?: Record<string, unknown>
}

type State = {
  deleteIndex?: number
  showDeleteConfirmation: boolean
}

export class FormTextEditorsArray extends Component<Props & WrappedFieldArrayProps, State> {
  static defaultProps = {
    enableAdd: true,
    enableDelete: true,
    disabled: false,
    propertyRules: {},
  }

  state = {
    showDeleteConfirmation: false,
    deleteId: null,
    deleteIndex: null,
  }

  closeDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      deleteIndex: null,
    })
  }

  showDeleteConfirmation = (index) => {
    this.setState({
      showDeleteConfirmation: true,
      deleteIndex: index,
    })
  }

  renderDeleteConfirmation() {
    const { fields } = this.props
    const { showDeleteConfirmation, deleteIndex } = this.state
    if (!showDeleteConfirmation) return null
    return (
      <Dialog
        title="Delete text"
        actions={[
          <RemoveButton
            key="remove"
            onClick={() => {
              fields.remove(deleteIndex)
              this.closeDeleteConfirmation()
            }}
          />,
        ]}
        onClose={this.closeDeleteConfirmation}>
        <div>Are you sure you want to delete this text?</div>
        <br />
        <div>
          Remember that you also need to click &quot;Save&quot; for this to disappear completely.
        </div>
      </Dialog>
    )
  }

  render() {
    const { fields, textTypes, enableAdd, enableDelete, disabled, propertyRules, infoText } =
      this.props
    const fieldInfo = getFieldInfo(propertyRules, infoText)
    const isDisabled = disabled || fieldInfo.isReadOnly
    const fieldValues = fields.getAll()
    return (
      <Grid container spacing={2} xs={12}>
        {enableAdd && !isDisabled ? (
          <Grid xs={12}>
            <AddText
              texts={fieldValues}
              arrayFields={fields}
              {...{
                textTypes,
                fieldInfo,
                infoText,
                propertyRules,
              }}
            />
          </Grid>
        ) : null}
        {fields.map((field, index) => {
          if (
            !textTypes.find(
              (textType: any) => getOr('', `[${index}.textType.id]`, fieldValues) === textType.id,
            )
          )
            return null
          return (
            <Grid xs={12} key={index}>
              <Field
                name={`${field}.text`}
                component={FormTextEditorField}
                label={getOr('', `[${index}.textType.name]`, fieldValues)}
                onDelete={
                  enableDelete && !isDisabled ? () => this.showDeleteConfirmation(index) : null
                }
                infoText={getOr('', `[${index}.textType.description]`, fieldValues)}
                textType={getOr('', `[${index}.textType]`, fieldValues)}
                {...{
                  disabled,
                  propertyRules,
                }}
              />
            </Grid>
          )
        })}
        {this.renderDeleteConfirmation()}
      </Grid>
    )
  }
}
export default FormTextEditorsArray
