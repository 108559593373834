import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
  searchOptions: Record<string, unknown>
  updateSorting(..._args: unknown[]): unknown
}

class ContactsViewHeader extends Component<Props> {
  render() {
    const { searchOptions, updateSorting } = this.props
    const { orderBy } = searchOptions
    return (
      <Grid xs={12}>
        <Grid container spacing={2} xs={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <SortableSearchHeader
            size={3}
            label="First name"
            field="firstName"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <SortableSearchHeader
            size={3}
            label="Last name"
            field="lastName"
            {...{
              orderBy,
              updateSorting,
            }}
          />
          <Grid xs={3}>
            <strong>Email</strong>
          </Grid>
          <Grid xs={3}>
            <strong>Phone number</strong>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ContactsViewHeader
