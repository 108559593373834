import { getOr } from 'lodash/fp'
import { $Values } from 'utility-types'

export const COLORS = {
  NEGATIVE_VALUE_COLOR: '#f9adad',
  POSITIVE_VALUE_COLOR: '#74cc6f',
  NEUTRAL_VALUE_COLOR: '#7383F5',
  WARNING_VALUE_COLOR: '#f9adad',
}
export const FIELDS = {
  AUDIOBOOK_COVER_DESIGN_COST: 'AudiobookCoverDesignCost',
  AUDIOBOOK_RECORDING_LEVEL1_COST: 'AudiobookRecordingLevel1Cost',
  AUDIOBOOK_RECORDING_LEVEL1_MANUAL_COST: 'AudiobookRecordingLevel1ManualCost',
  AUDIOBOOK_RECORDING_LEVEL1_HOUR_RATE: 'AudiobookRecordingLevel1HourRate',
  AUDIOBOOK_RECORDING_LEVEL1_HOURS: 'AudiobookRecordingLevel1Hours',
  AUDIOBOOK_RECORDING_LEVEL2_COST: 'AudiobookRecordingLevel2Cost',
  AUDIOBOOK_RECORDING_LEVEL2_MANUAL_COST: 'AudiobookRecordingLevel2ManualCost',
  AUDIOBOOK_RECORDING_LEVEL2_HOUR_RATE: 'AudiobookRecordingLevel2HourRate',
  AUDIOBOOK_RECORDING_LEVEL2_HOURS: 'AudiobookRecordingLevel2Hours',
  E_BOOK_PRODUCTION_COST: 'EBookProductionCost',
  E_BOOK_PRODUCTION_MANUAL_COST: 'EBookProductionManualCost',
  EDITORIAL_WORK_EFFORT_COST: 'EditorialWorkEffortCost',
  ESTEMATED_NUMBER_OF_PAGES: 'EstimatedNumberOfPages',
  ESTIMATED_SALES: 'EstimatedSales',
  GROSS_PROFIT: 'GrossProfit',
  GROSS_MARGIN: 'GrossMargin',
  IMAGE_COST: 'ImageCost',
  IMAGES_COSTS_OTHER: 'ImagesCostsOther',
  LAYOUT_EXPRESSION_COST: 'LayoutExpressionCost',
  LAYOUT_EXPRESSION_START_COST: 'LayoutExpressionStartCost',
  LAYOUT_EXPRESSION_UNIT_COST: 'LayoutExpressionUnitCost',
  PRINT_COST: 'PrintCost',
  TOTAL_PRINT_COST: 'TotalPrintCost',
  TOTAL_PRINT_COST_PER_COPY: 'TotalPrintCostPerCopy',
  CUSTOM_TOTAL_PRINT_COST_PER_COPY: 'CustomTotalPrintCostPerCopy',
  PRINT_TEMPLATE_COST: 'PrintTemplateCost',
  PRODUCTION_COST: 'ProductionCost',
  RESELLER_DISCOUNT_PERCENTAGE: 'ResellerDiscountPercentage',
  RESELLER_DISCOUNT_PRICE: 'ResellerDiscountPrice',
  RESELLER_PRICE: 'ResellerPrice',
  RESELLER_PRICE_NET: 'ResellerPriceNet',
  ROYALTY_COST: 'RoyaltyCost',
  ROYALTY_LEVEL1_COST: 'RoyaltyLevel1Cost',
  ROYALTY_LEVEL1_ENTRY_POINT_COST: 'RoyaltyLevel1EntryPoint',
  ROYALTY_LEVEL1_EXIT_POINT: 'RoyaltyLevel1ExitPoint',
  ROYALTY_LEVEL1_FACTOR: 'RoyaltyLevel1Factor',
  ROYALTY_LEVEL1_SALES_AMOUNT: 'RoyaltyLevel1SalesAmount',
  ROYALTY_LEVEL2_COST: 'RoyaltyLevel2Cost',
  ROYALTY_LEVEL2_ENTRY_POINT_COST: 'RoyaltyLevel2EntryPoint',
  ROYALTY_LEVEL2_EXIT_POINT: 'RoyaltyLevel2ExitPoint',
  ROYALTY_LEVEL2_FACTOR: 'RoyaltyLevel2Factor',
  ROYALTY_LEVEL2_SALES_AMOUNT: 'RoyaltyLevel2SalesAmount',
  ROYALTY_LEVEL3_COST: 'RoyaltyLevel3Cost',
  ROYALTY_LEVEL3_ENTRY_POINT_COST: 'RoyaltyLevel3EntryPoint',
  ROYALTY_LEVEL3_EXIT_POINT: 'RoyaltyLevel3ExitPoint',
  ROYALTY_LEVEL3_FACTOR: 'RoyaltyLevel3Factor',
  ROYALTY_LEVEL3_SALES_AMOUNT: 'RoyaltyLevel3SalesAmount',
  ROYALTY_LEVEL4_COST: 'RoyaltyLevel4Cost',
  ROYALTY_LEVEL4_ENTRY_POINT_COST: 'RoyaltyLevel4EntryPoint',
  ROYALTY_LEVEL4_EXIT_POINT: 'RoyaltyLevel4ExitPoint',
  ROYALTY_LEVEL4_FACTOR: 'RoyaltyLevel4Factor',
  ROYALTY_LEVEL4_SALES_AMOUNT: 'RoyaltyLevel4SalesAmount',
  ROYALTY_LEVEL5_COST: 'RoyaltyLevel5Cost',
  ROYALTY_LEVEL5_ENTRY_POINT_COST: 'RoyaltyLevel5EntryPoint',
  ROYALTY_LEVEL5_EXIT_POINT: 'RoyaltyLevel5ExitPoint',
  ROYALTY_LEVEL5_FACTOR: 'RoyaltyLevel5Factor',
  ROYALTY_LEVEL5_SALES_AMOUNT: 'RoyaltyLevel5SalesAmount',
  ROYALTY_LEVEL6_COST: 'RoyaltyLevel6Cost',
  ROYALTY_LEVEL6_ENTRY_POINT_COST: 'RoyaltyLevel6EntryPoint',
  ROYALTY_LEVEL6_EXIT_POINT: 'RoyaltyLevel6ExitPoint',
  ROYALTY_LEVEL6_FACTOR: 'RoyaltyLevel6Factor',
  ROYALTY_LEVEL6_SALES_AMOUNT: 'RoyaltyLevel6SalesAmount',
  ROYALTY_LEVEL7_COST: 'RoyaltyLevel7Cost',
  ROYALTY_LEVEL7_ENTRY_POINT_COST: 'RoyaltyLevel7EntryPoint',
  ROYALTY_LEVEL7_EXIT_POINT: 'RoyaltyLevel7ExitPoint',
  ROYALTY_LEVEL7_FACTOR: 'RoyaltyLevel7Factor',
  ROYALTY_LEVEL7_SALES_AMOUNT: 'RoyaltyLevel7SalesAmount',
  ROYALTY_LEVEL8_COST: 'RoyaltyLevel8Cost',
  ROYALTY_LEVEL8_ENTRY_POINT_COST: 'RoyaltyLevel8EntryPoint',
  ROYALTY_LEVEL8_EXIT_POINT: 'RoyaltyLevel8ExitPoint',
  ROYALTY_LEVEL8_FACTOR: 'RoyaltyLevel8Factor',
  ROYALTY_LEVEL8_SALES_AMOUNT: 'RoyaltyLevel8SalesAmount',
  TOTAL_ROYALTIES: 'TotalRoyalties',
  SCRIPT_COST: 'ScriptCost',
  SCRIPT_COSTS_OTHER: 'ScriptCostsOther',
  TOTAL_COST: 'TotalCost',
  TRANSLATION_COST: 'TranslationCost',
  TRANSLATION_TEMPLATE_COST: 'TranslationTemplateCost',
  TRANSLATION_HONORARIUM_PERCENT: 'HonorariumPercent',
  OTHER_INCOME: 'OtherIncome',
  MARKETING_AND_OTHER_EXPENSES: 'MarketingAndOtherExpenses',
  ESTIMATED_SALES_AT_RESELLER_PRICE: 'EstimatedSalesAtResellerPrice',
  ESTIMATED_SALES_AT_RESELLER_PRICE_NET: 'EstimatedSalesAtResellerPriceNet',
  ROYALTY_BASE: 'RoyaltyBase',
  PRICE_REDUCTION_DISCOUNT: 'PriceReductionDiscount',
  PRICE_REDUCTION_DISCOUNT_PERCENTAGE: 'PriceReductionDiscountPercentage',
  RESULT_AFTER_EXPENSES: 'ResultAfterExpenses',
  RESULT_AFTER_EXPENSES_PERCENTAGE: 'ResultAfterExpensesPercentage',
  ADVANCE: 'Advance',
  UNEARNED_ADVANCE: 'Unearned advance',
  UNEARNED_ADVANCE_2: 'UnearnedAdvance',
  PRINT_AND_CONVERSION_COST: 'PrintAndConversionCost',
  AUDIOBOOK_COVER_DESIGN_COST_PERCENTAGE: 'AudiobookCoverDesignCostPercentage',
  EDITORIAL_WORK_EFFORT_COST_PERCENTAGE: 'EditorialWorkEffortCostPercentage',
  AUTHOR_FEE_COST: 'AuthorFeeCost',
  AUTHOR_FEE_COST_2: 'Authorfee',
  AUTHOR_FEE_COST_PERCENTAGE: 'AuthorFeeCostPercentage',
  DESIGN_ENTIRE_BOOK_COST: 'DesignEntireBookCost',
  DESIGN_ENTIRE_BOOK_COST_2: 'Design,entirebook',
  DESIGN_ENTIRE_BOOK_COST_PERCENTAGE: 'DesignEntireBookCostPercentage',
  FOOD_COST: 'FoodCost',
  FOOD_COST_2: 'Food',
  FOOD_COST_PERCENTAGE: 'FoodCostPercentage',
  FACT_CHECKING_COST: 'FactCheckingCost',
  FACT_CHECKING_COST_2: 'Factchecking',
  FACT_CHECKING_COST_PERCENTAGE: 'FactCheckingCostPercentage',
  ILLUSTRATIONS_COST: 'IllustrationsCost',
  ILLUSTRATIONS_COST_2: 'Illustrations',
  ILLUSTRATIONS_COST_PERCENTAGE: 'IllustrationsCostPercentage',
  LICENSING_COST: 'LicensingCost',
  LICENSING_COST_2: 'Licensing',
  LICENSING_COST_PERCENTAGE: 'LicensingCostPercentage',
  TYPESETTING_EXTERNAL_COST: 'TypesettingExternalCost',
  TYPESETTING_EXTERNAL_COST_2: 'Typesetting(external)',
  TYPESETTING_EXTERNAL_COST_PERCENTAGE: 'TypesettingExternalCostPercentage',
  OTHER_COST: 'OtherCost',
  OTHER_COST_2: 'Othercost',
  OTHER_COST_PERCENTAGE: 'OtherCostPercentage',
  PHOTOS_OF_AUTHOR_COST: 'PhotosOfAuthorCost',
  PHOTOS_OF_AUTHOR_COST_2: 'Photosofauthor',
  PHOTOS_OF_AUTHOR_COST_PERCENTAGE: 'PhotosOfAuthorCostPercentage',
  PHOTOS_ON_COVER_COST: 'PhotosOnCoverCost',
  PHOTOS_ON_COVER_COST_2: 'Photosoncover',
  PHOTOS_ON_COVER_COST_PERCENTAGE: 'PhotosOnCoverCostPercentage',
  PHOTOS_IN_BOOK_COST: 'PhotosInBookCost',
  PHOTOS_IN_BOOK_COST_2: 'Photosinbook',
  PHOTOS_IN_BOOK_COST_PERCENTAGE: 'PhotosInBookCostPercentage',
  PROOF_READING_COST: 'ProofReadingCost',
  PROOF_READING_COST_2: 'Proofreading',
  PROOF_READING_COST_PERCENTAGE: 'ProofReadingCostPercentage',
  RECIPE_CHECKING_COST: 'RecipeCheckingCost',
  RECIPE_CHECKING_COST_2: 'Recipechecking',
  RECIPE_CHECKING_COST_PERCENTAGE: 'RecipeCheckingCostPercentage',
  REPRO_COST: 'ReproCost',
  REPRO_COST_2: 'Repro',
  REPRO_COST_PERCENTAGE: 'ReproCostPercentage',
  STYLING_COST: 'StylingCost',
  STYLING_COST_2: 'Styling',
  STYLING_COST_PERCENTAGE: 'StylingCostPercentage',
  PRINT_SURPLUS_COST: 'PrintSurplusCost',
  PRINT_SURPLUS_PERCENTAGE: 'PrintSurplusPercentage',
  DESIGN_COVER_COST: 'DesignCoverCost',
  DESIGN_COVER_COST_2: 'Design,cover',
  PRICE_NET: 'PriceNet',
  PRICE: 'Price',
  RETAILER_PRICE: 'RetailPrice',
  RETAILER_PRICE_PERCENTAGE: 'RetailPricePercentage',
  NUMBER_OF_CHARACTERS_THOUSANDS: 'NumberOfCharacters(thousands)',
  TRANSLATION_COST_1000_CHARACTERS: 'TranslationCostPer1000Characters',
  NARRATOR_FEE_COST: 'NarratorFeeCost',
  NARRATOR_FEE_COST_2: 'Narratorfee',
  EDITORIAL_WORK_EFFORT_MANUAL_COST: 'EditorialWorkEffortManualCost',
  TRANSLATION_MANUAL_COST: 'TranslationManualCost',
  SALES_TARGET_VOLUME: 'SalesTargetVolume',
  CALCULATED_REVENUE_GROSS: 'CalculatedRevenue(Gross)',
  SALES_TARGET_AT_RETAILER_PRICE_NET: 'SalesTargetAtRetailerPrice(Net)',
}
export type FieldType = $Values<typeof FIELDS>
export type Unit = 'count' | 'currency' | 'percentage'
export const unitTranslationMapper: Record<Unit, string> = {
  count: ' st',
  currency: ` SEK`,
  percentage: ' %',
}
type FieldTypeObejct = {
  label: string
  unit: Unit
  decimals?: number
}
type FieldSpecifier = Record<FieldType, FieldTypeObejct>
export const FIELD_TYPES: FieldSpecifier = {
  [FIELDS.ESTEMATED_NUMBER_OF_PAGES]: {
    label: 'Esitmated number of pages',
    unit: 'count',
  },
  [FIELDS.ESTIMATED_SALES]: {
    label: 'Estimated sales',
    unit: 'count',
  },
  [FIELDS.IMAGE_COST]: {
    label: 'Images',
    unit: 'currency',
  },
  [FIELDS.IMAGES_COSTS_OTHER]: {
    label: 'Image cost, other',
    unit: 'currency',
  },
  [FIELDS.RESELLER_DISCOUNT_PERCENTAGE]: {
    label: 'Discount (%)',
    unit: 'percentage',
  },
  [FIELDS.RESELLER_DISCOUNT_PRICE]: {
    label: 'Discount',
    unit: 'currency',
  },
  [FIELDS.RESELLER_PRICE]: {
    label: 'Retailer price',
    unit: 'currency',
  },
  [FIELDS.RESELLER_PRICE_NET]: {
    label: 'Retailer price, net',
    unit: 'currency',
  },
  [FIELDS.TRANSLATION_COST]: {
    label: 'Translation',
    unit: 'currency',
  },
  [FIELDS.TRANSLATION_TEMPLATE_COST]: {
    label: 'Cost per page',
    unit: 'currency',
  },
  [FIELDS.TRANSLATION_HONORARIUM_PERCENT]: {
    label: 'Honorarium percent',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL1_FACTOR]: {
    label: 'Royalty level 1',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL1_SALES_AMOUNT]: {
    label: 'Royalty level 1, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL1_ENTRY_POINT_COST]: {
    label: 'Royalty level 1 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL1_EXIT_POINT]: {
    label: 'Royalty level 1 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL1_COST]: {
    label: 'Royalty level 1',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL2_FACTOR]: {
    label: 'Royalty level 2',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL2_SALES_AMOUNT]: {
    label: 'Royalty level 2, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL2_ENTRY_POINT_COST]: {
    label: 'Royalty level 2 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL2_EXIT_POINT]: {
    label: 'Royalty level 2 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL2_COST]: {
    label: 'Royalty level 2',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL3_FACTOR]: {
    label: 'Royalty level 3',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL3_SALES_AMOUNT]: {
    label: 'Royalty level 3, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL3_ENTRY_POINT_COST]: {
    label: 'Royalty level 3 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL3_EXIT_POINT]: {
    label: 'Royalty level 3 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL3_COST]: {
    label: 'Royalty level 3',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL4_FACTOR]: {
    label: 'Royalty level 4',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL4_SALES_AMOUNT]: {
    label: 'Royalty level 4, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL4_ENTRY_POINT_COST]: {
    label: 'Royalty level 4 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL4_EXIT_POINT]: {
    label: 'Royalty level 4 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL4_COST]: {
    label: 'Royalty level 4',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL5_FACTOR]: {
    label: 'Royalty level 5',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL5_SALES_AMOUNT]: {
    label: 'Royalty level 5, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL5_ENTRY_POINT_COST]: {
    label: 'Royalty level 5 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL5_EXIT_POINT]: {
    label: 'Royalty level 5 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL5_COST]: {
    label: 'Royalty level 5',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL6_FACTOR]: {
    label: 'Royalty level 6',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL6_SALES_AMOUNT]: {
    label: 'Royalty level 6, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL6_ENTRY_POINT_COST]: {
    label: 'Royalty level 6 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL6_EXIT_POINT]: {
    label: 'Royalty level 6 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL6_COST]: {
    label: 'Royalty level 6',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL7_FACTOR]: {
    label: 'Royalty level 7',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL7_SALES_AMOUNT]: {
    label: 'Royalty level 7, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL7_ENTRY_POINT_COST]: {
    label: 'Royalty level 7 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL7_EXIT_POINT]: {
    label: 'Royalty level 7 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL7_COST]: {
    label: 'Royalty level 7',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_LEVEL8_FACTOR]: {
    label: 'Royalty level 8',
    unit: 'percentage',
  },
  [FIELDS.ROYALTY_LEVEL8_SALES_AMOUNT]: {
    label: 'Royalty level 8, sales',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL8_ENTRY_POINT_COST]: {
    label: 'Royalty level 8 starts at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL8_EXIT_POINT]: {
    label: 'Royalty level 8 ends at',
    unit: 'count',
  },
  [FIELDS.ROYALTY_LEVEL8_COST]: {
    label: 'Royalty level 8',
    unit: 'currency',
  },
  [FIELDS.ROYALTY_COST]: {
    label: 'Variable royalties',
    unit: 'currency',
  },
  [FIELDS.TOTAL_ROYALTIES]: {
    label: 'Total royalties',
    unit: 'currency',
  },
  [FIELDS.SCRIPT_COST]: {
    label: 'Editorial cost',
    unit: 'currency',
  },
  [FIELDS.SCRIPT_COSTS_OTHER]: {
    label: 'Editorial cost, other',
    unit: 'currency',
  },
  [FIELDS.EDITORIAL_WORK_EFFORT_COST]: {
    label: 'Editorial work effort',
    unit: 'currency',
  },
  [FIELDS.TOTAL_COST]: {
    label: 'Costs (KSV)',
    unit: 'currency',
  },
  [FIELDS.GROSS_PROFIT]: {
    label: 'Gross profit',
    unit: 'currency',
  },
  [FIELDS.GROSS_MARGIN]: {
    label: 'Gross margin %',
    unit: 'percentage',
  },
  [FIELDS.PRODUCTION_COST]: {
    label: 'Manufacturing cost',
    unit: 'currency',
  },
  [FIELDS.PRINT_COST]: {
    label: 'Print cost',
    unit: 'currency',
  },
  [FIELDS.TOTAL_PRINT_COST]: {
    label: 'Total print cost',
    unit: 'currency',
  },
  [FIELDS.TOTAL_PRINT_COST_PER_COPY]: {
    label: 'Total print cost per copy',
    unit: 'currency',
    decimals: 2,
  },
  [FIELDS.CUSTOM_TOTAL_PRINT_COST_PER_COPY]: {
    label: 'Custom total print cost per copy',
    unit: 'currency',
    decimals: 2,
  },
  [FIELDS.PRINT_TEMPLATE_COST]: {
    label: 'Base price per copy',
    unit: 'currency',
    decimals: 2,
  },
  [FIELDS.AUDIOBOOK_COVER_DESIGN_COST]: {
    label: 'Cover design',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL1_COST]: {
    label: 'Recording, level 1',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL1_MANUAL_COST]: {
    label: 'Recording level 1, manual cost',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL1_HOUR_RATE]: {
    label: 'Recordning cost, level 1 (per hour)',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL1_HOURS]: {
    label: 'Recording, level 1 hours',
    unit: 'count',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL2_COST]: {
    label: 'Recording, level 2',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL2_MANUAL_COST]: {
    label: 'Recording level 2, manual cost',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL2_HOUR_RATE]: {
    label: 'Recordning cost, level 2 (per hour)',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_RECORDING_LEVEL2_HOURS]: {
    label: 'Recording, level 2 hours',
    unit: 'count',
  },
  [FIELDS.E_BOOK_PRODUCTION_COST]: {
    label: 'E-book production',
    unit: 'currency',
  },
  [FIELDS.E_BOOK_PRODUCTION_MANUAL_COST]: {
    label: 'E-book production manual cost',
    unit: 'currency',
  },
  [FIELDS.LAYOUT_EXPRESSION_START_COST]: {
    label: 'Addition start cost',
    unit: 'currency',
  },
  [FIELDS.LAYOUT_EXPRESSION_UNIT_COST]: {
    label: 'Addition per copy',
    unit: 'currency',
  },
  [FIELDS.LAYOUT_EXPRESSION_COST]: {
    label: 'Additional print cost, expression',
    unit: 'currency',
    decimals: 2,
  },
  [FIELDS.OTHER_INCOME]: {
    label: 'Other income',
    unit: 'currency',
  },
  [FIELDS.MARKETING_AND_OTHER_EXPENSES]: {
    label: 'Marketing',
    unit: 'currency',
  },
  [FIELDS.ESTIMATED_SALES_AT_RESELLER_PRICE]: {
    label: 'Estimated revenue (gross)',
    unit: 'currency',
  },
  [FIELDS.ESTIMATED_SALES_AT_RESELLER_PRICE_NET]: {
    label: 'Estimated revenue (net)',
    unit: 'currency',
    decimals: 0,
  },
  [FIELDS.ROYALTY_BASE]: {
    label: 'Royalty base',
    unit: 'currency',
    decimals: 2,
  },
  [FIELDS.PRICE_REDUCTION_DISCOUNT]: {
    label: 'Discount',
    unit: 'currency',
  },
  [FIELDS.PRICE_REDUCTION_DISCOUNT_PERCENTAGE]: {
    label: 'Discount (%)',
    unit: 'percentage',
  },
  [FIELDS.RESULT_AFTER_EXPENSES]: {
    label: 'Result after expenses',
    unit: 'currency',
  },
  [FIELDS.RESULT_AFTER_EXPENSES_PERCENTAGE]: {
    label: 'Result after expenses %',
    unit: 'percentage',
  },
  [FIELDS.ADVANCE]: {
    label: 'Advance',
    unit: 'currency',
  },
  [FIELDS.UNEARNED_ADVANCE]: {
    label: 'Unearned advance',
    unit: 'currency',
  },
  [FIELDS.UNEARNED_ADVANCE_2]: {
    label: 'Unearned advance',
    unit: 'currency',
  },
  [FIELDS.PRINT_AND_CONVERSION_COST]: {
    label: 'Production cost',
    unit: 'currency',
  },
  [FIELDS.AUDIOBOOK_COVER_DESIGN_COST_PERCENTAGE]: {
    label: 'Audiobook cover design cost %',
    unit: 'percentage',
  },
  [FIELDS.EDITORIAL_WORK_EFFORT_COST_PERCENTAGE]: {
    label: 'Editorial work effort cost %',
    unit: 'percentage',
  },
  [FIELDS.AUTHOR_FEE_COST]: {
    label: 'Author fee cost',
    unit: 'currency',
  },
  [FIELDS.AUTHOR_FEE_COST_2]: {
    label: 'Author fee cost',
    unit: 'currency',
  },
  [FIELDS.AUTHOR_FEE_COST_PERCENTAGE]: {
    label: 'Author fee cost %',
    unit: 'percentage',
  },
  [FIELDS.DESIGN_ENTIRE_BOOK_COST]: {
    label: 'Design entire book cost',
    unit: 'currency',
  },
  [FIELDS.DESIGN_ENTIRE_BOOK_COST_2]: {
    label: 'Design entire book cost',
    unit: 'currency',
  },
  [FIELDS.DESIGN_ENTIRE_BOOK_COST_PERCENTAGE]: {
    label: 'Design entire book cost %',
    unit: 'percentage',
  },
  [FIELDS.FOOD_COST]: {
    label: 'Food cost',
    unit: 'currency',
  },
  [FIELDS.FOOD_COST_2]: {
    label: 'Food cost',
    unit: 'currency',
  },
  [FIELDS.FOOD_COST_PERCENTAGE]: {
    label: 'Food cost %',
    unit: 'percentage',
  },
  [FIELDS.FACT_CHECKING_COST]: {
    label: 'Fact checking cost',
    unit: 'currency',
  },
  [FIELDS.FACT_CHECKING_COST_2]: {
    label: 'Fact checking cost',
    unit: 'currency',
  },
  [FIELDS.FACT_CHECKING_COST_PERCENTAGE]: {
    label: 'Fact checking cost %',
    unit: 'percentage',
  },
  [FIELDS.ILLUSTRATIONS_COST]: {
    label: 'Illustrations cost',
    unit: 'currency',
  },
  [FIELDS.ILLUSTRATIONS_COST_2]: {
    label: 'Illustrations cost',
    unit: 'currency',
  },
  [FIELDS.ILLUSTRATIONS_COST_PERCENTAGE]: {
    label: 'Illustrations cost %',
    unit: 'percentage',
  },
  [FIELDS.LICENSING_COST]: {
    label: 'Licensing cost',
    unit: 'currency',
  },
  [FIELDS.LICENSING_COST_2]: {
    label: 'Licensing cost',
    unit: 'currency',
  },
  [FIELDS.LICENSING_COST_PERCENTAGE]: {
    label: 'Licensing cost %',
    unit: 'percentage',
  },
  [FIELDS.TYPESETTING_EXTERNAL_COST]: {
    label: 'Typesetting external cost',
    unit: 'currency',
  },
  [FIELDS.TYPESETTING_EXTERNAL_COST_2]: {
    label: 'Typesetting external cost',
    unit: 'currency',
  },
  [FIELDS.TYPESETTING_EXTERNAL_COST_PERCENTAGE]: {
    label: 'Typesetting external cost %',
    unit: 'percentage',
  },
  [FIELDS.OTHER_COST]: {
    label: 'Other cost',
    unit: 'currency',
  },
  [FIELDS.OTHER_COST_2]: {
    label: 'Other cost',
    unit: 'currency',
  },
  [FIELDS.OTHER_COST_PERCENTAGE]: {
    label: 'Other cost %',
    unit: 'percentage',
  },
  [FIELDS.PHOTOS_OF_AUTHOR_COST]: {
    label: 'Photos of author cost',
    unit: 'currency',
  },
  [FIELDS.PHOTOS_OF_AUTHOR_COST_2]: {
    label: 'Photos of author cost',
    unit: 'currency',
  },
  [FIELDS.PHOTOS_OF_AUTHOR_COST_PERCENTAGE]: {
    label: 'Photos of author cost %',
    unit: 'percentage',
  },
  [FIELDS.PHOTOS_ON_COVER_COST]: {
    label: 'Photos on cover cost',
    unit: 'currency',
  },
  [FIELDS.PHOTOS_ON_COVER_COST_2]: {
    label: 'Photos on cover cost',
    unit: 'currency',
  },
  [FIELDS.PHOTOS_ON_COVER_COST_PERCENTAGE]: {
    label: 'Photos on cover cost %',
    unit: 'percentage',
  },
  [FIELDS.PHOTOS_IN_BOOK_COST]: {
    label: 'Photos in book cost',
    unit: 'currency',
  },
  [FIELDS.PHOTOS_IN_BOOK_COST_2]: {
    label: 'Photos in book cost',
    unit: 'currency',
  },
  [FIELDS.PHOTOS_IN_BOOK_COST_PERCENTAGE]: {
    label: 'Photos in book cost %',
    unit: 'percentage',
  },
  [FIELDS.PROOF_READING_COST]: {
    label: 'Proof reading cost',
    unit: 'currency',
  },
  [FIELDS.PROOF_READING_COST_2]: {
    label: 'Proof reading cost',
    unit: 'currency',
  },
  [FIELDS.PROOF_READING_COST_PERCENTAGE]: {
    label: 'Proof reading cost %',
    unit: 'percentage',
  },
  [FIELDS.RECIPE_CHECKING_COST]: {
    label: 'Recipe checking cost',
    unit: 'currency',
  },
  [FIELDS.RECIPE_CHECKING_COST_2]: {
    label: 'Recipe checking cost',
    unit: 'currency',
  },
  [FIELDS.RECIPE_CHECKING_COST_PERCENTAGE]: {
    label: 'Recipe checking cost %',
    unit: 'percentage',
  },
  [FIELDS.REPRO_COST]: {
    label: 'Repro cost',
    unit: 'currency',
  },
  [FIELDS.REPRO_COST_2]: {
    label: 'Repro cost',
    unit: 'currency',
  },
  [FIELDS.REPRO_COST_PERCENTAGE]: {
    label: 'Repro cost %',
    unit: 'percentage',
  },
  [FIELDS.STYLING_COST]: {
    label: 'Styling cost',
    unit: 'currency',
  },
  [FIELDS.STYLING_COST_2]: {
    label: 'Styling cost',
    unit: 'currency',
  },
  [FIELDS.STYLING_COST_PERCENTAGE]: {
    label: 'Styling cost %',
    unit: 'percentage',
  },
  [FIELDS.PRINT_SURPLUS_COST]: {
    label: 'Print surplus cost',
    unit: 'currency',
  },
  [FIELDS.PRINT_SURPLUS_PERCENTAGE]: {
    label: 'Print surplus  %',
    unit: 'percentage',
  },
  [FIELDS.DESIGN_COVER_COST]: {
    label: 'Design cover cost',
    unit: 'currency',
  },
  [FIELDS.DESIGN_COVER_COST_2]: {
    label: 'Design cover cost',
    unit: 'currency',
  },
  [FIELDS.PRICE_NET]: {
    label: 'Price (net)',
    unit: 'currency',
  },
  [FIELDS.PRICE]: {
    label: 'Price',
    unit: 'currency',
  },
  [FIELDS.RETAILER_PRICE]: {
    label: 'Retail price',
    unit: 'currency',
  },
  [FIELDS.RETAILER_PRICE_PERCENTAGE]: {
    label: 'Retail price factor (%)',
    unit: 'currency',
  },
  [FIELDS.NUMBER_OF_CHARACTERS_THOUSANDS]: {
    label: 'Number of characters (thousands)',
    unit: 'count',
  },
  [FIELDS.TRANSLATION_COST_1000_CHARACTERS]: {
    label: 'Translation cost / 1000 Characters',
    unit: 'currency',
  },
  [FIELDS.NARRATOR_FEE_COST]: {
    label: 'Narrator fee',
    unit: 'currency',
  },
  [FIELDS.NARRATOR_FEE_COST_2]: {
    label: 'Narrator fee',
    unit: 'currency',
  },
  [FIELDS.EDITORIAL_WORK_EFFORT_MANUAL_COST]: {
    label: 'Editorial work effort (manually added)',
    unit: 'currency',
  },
  [FIELDS.TRANSLATION_MANUAL_COST]: {
    label: 'Translation cost (manually added)',
    unit: 'currency',
  },
  [FIELDS.SALES_TARGET_VOLUME]: {
    label: 'Estimated sales',
    unit: 'count',
  },
  [FIELDS.CALCULATED_REVENUE_GROSS]: {
    label: 'Estimated revenue (gross)',
    unit: 'currency',
  },
  [FIELDS.SALES_TARGET_AT_RETAILER_PRICE_NET]: {
    label: 'Estimated sales at retailer price net',
    unit: 'currency',
  },
}
export const getUnitTranslation = (unitType: Unit): string =>
  getOr(unitType, unitType, unitTranslationMapper)
export const getFieldTypes = (): FieldSpecifier => FIELD_TYPES
export const getFieldLabelByType = (type: FieldType): string =>
  getOr(type, 'label', getOr(false, type, FIELD_TYPES))
export const getFieldSuffixByType = (type: FieldType): string =>
  getUnitTranslation(getOr('', 'unit', getOr(false, type, FIELD_TYPES)))
export const getFieldSuffix = ({ type }) =>
  getUnitTranslation(getOr('', 'unit', getOr(false, type, FIELD_TYPES)))
export const getFieldDecimalByType = (type: FieldType): number =>
  getOr(0, 'decimals', getOr(false, type, FIELD_TYPES))
export const getFieldUnitByType = (type: FieldType): string =>
  getOr('', 'unit', getOr(false, type, FIELD_TYPES))
