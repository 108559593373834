import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

type Props = {
  result: any
}

class ContactsViewItem extends Component<Props> {
  render() {
    const { result } = this.props
    const phoneNumbers = getOr([], 'simplePhoneNumbers', result)
    const emailAddresses = getOr([], 'simpleEmailAddresses', result)
    return (
      <Grid xs={12}>
        <Grid
          container
          spacing={2}
          xs={12}
          sx={{ borderTop: '1px solid #e0e0e0' }}
          className="contactHolder">
          <Grid xs={8} sm={3}>
            <Link underline="none" component={NavLink} to={`/contact/${result.id}`}>
              {getOr('-', 'firstName', result)}
            </Link>
          </Grid>
          <Grid xs={8} sm={3}>
            <Link underline="none" component={NavLink} to={`/contact/${result.id}`}>
              {getOr('-', 'lastName', result)}
            </Link>
          </Grid>
          <Grid xs={8} sm={3} sx={{ wordBreak: 'break-all' }}>
            {emailAddresses.length > 0 && getOr(false, 'isSecret', result) && (
              <span
                style={{
                  marginRight: 5,
                  color: 'red',
                }}>
                SECRET:
              </span>
            )}
            {emailAddresses.length > 0
              ? emailAddresses.map((valueItem, index) => (
                  <span key={index}>
                    <Link
                      component={NavLink}
                      to={`mailto:${valueItem}`}
                      className="linkPrimary"
                      style={{
                        textDecoration: 'none',
                      }}>
                      {valueItem}
                    </Link>
                    {index + 1 < emailAddresses.length ? <span>, </span> : null}
                  </span>
                ))
              : '-'}
          </Grid>
          <Grid xs={8} sm={3} sx={{ wordBreak: 'break-all' }}>
            {phoneNumbers.length > 0 && getOr(false, 'isSecret', result) && (
              <span
                style={{
                  marginRight: 5,
                  color: 'red',
                }}>
                SECRET:
              </span>
            )}
            {phoneNumbers.length > 0
              ? phoneNumbers.map((valueItem, index) => (
                  <span key={index}>
                    <Link
                      component={NavLink}
                      to={`tel:${valueItem}`}
                      className="linkPrimary"
                      style={{
                        textDecoration: 'none',
                      }}>
                      {valueItem}
                    </Link>
                    {index + 1 < phoneNumbers.length ? <span>, </span> : null}
                  </span>
                ))
              : '-'}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ContactsViewItem
