import { Component } from 'react'

import IconInfo from '@mui/icons-material/InfoOutlined'
import { FormControlLabel, Checkbox, FormControl } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Unstable_Grid2'

import { FormFieldInfo } from './fieldinfo'
import { getFieldInfo, getLabelText } from './formFieldHelpers'

type Props = {
  input: any
  meta: any
  label: string
  labelStyle?: Record<string, unknown>
  showInfoButton?: boolean
  required?: boolean
  showMetaDataAdmin?: boolean
  onHandleChange?(..._args: unknown[]): unknown
  onChange?: any
  infoText?: string
  style?: Record<string, unknown>
  disabled?: boolean
  propertyRules?: Record<string, unknown>
  dispatch?: any
  history?: string
}

export class FormCheckbox extends Component<Props> {
  static defaultProps = {
    disabled: false,
    propertyRules: {},
    showMetaDataAdmin: false,
    required: false,
  }

  state = {
    clickedInfoButton: false,
    showInfoTextDialog: false,
  }

  clickIcon = () => {
    this.setState({
      clickedInfoButton: !this.state.clickedInfoButton,
    })
  }

  toggleInfoTextModal = () => {
    this.setState({
      showInfoTextDialog: !this.state.showInfoTextDialog,
    })
  }

  onChange(e) {
    if (this.props.onHandleChange) {
      this.props.onHandleChange(e.target.checked)
    } else {
      this.props.input.onChange(e.target.checked)
    }
  }

  render() {
    const {
      input,
      meta,
      infoText,
      propertyRules,
      showMetaDataAdmin,
      showInfoButton,
      dispatch,
      history,
      onHandleChange,
      ...otherProps
    } = this.props
    const { required, label, style, labelStyle, disabled } = this.props
    const { showInfoTextDialog, clickedInfoButton } = this.state
    const { value, onChange } = input
    const mergedStyle = { ...style }
    const fieldInfo = getFieldInfo(propertyRules, infoText)
    const isDisabled = disabled || fieldInfo.isReadOnly
    const nestedProps: any = {
      ...input,
      ...otherProps,
      value: undefined,
      color: 'primary',
      onChange: onChange,
      checked: value === '' ? false : value,
      style: { ...mergedStyle },
      disabled: isDisabled,
    }

    return (
      <Grid container spacing={2} xs={12}>
        <Grid xsOffset={0.1} xs={10.7}>
          <FormControl variant="standard">
            <FormControlLabel
              style={{
                ...labelStyle,
              }}
              control={
                <Checkbox
                  {...nestedProps}
                  required={required}
                  onChange={this.onChange.bind(this)}
                />
              }
              labelPlacement="end"
              label={getLabelText(label, propertyRules)}
            />
          </FormControl>
        </Grid>
        {fieldInfo.showInfoButton ? (
          <Grid xs={1.2}>
            {fieldInfo.showInfoButton ? (
              <IconButton onClick={this.clickIcon} aria-label="Info" size="large">
                <IconInfo />
              </IconButton>
            ) : null}
          </Grid>
        ) : null}
        <FormFieldInfo
          {...{
            fieldInfo,
            propertyRules,
            history,
            infoText,
            showInfoTextDialog,
            clickedInfoButton,
          }}
        />
      </Grid>
    )
  }
}
export default FormCheckbox
