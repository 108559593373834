import fileSaver from 'file-saver'
import { fromPairs } from 'lodash/fp'
import { getOr, isString } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'

import { Button } from '@mui/material'

import GenericErrorDialog from '../../components/error/GenericErrorDialog'
import Spinner, { SPINNER_SIZES } from '../../components/loader/Spinner'
import request from '../../utils/net/request'
import { plainSubmit } from '../../utils/net/submit'
import { serializeSearchOutput } from '../searchOutputSerializers'

const getFileName = (fileTitle, searchConfig, workingView) =>
  fileTitle ||
  [
    'mockingbird',
    searchConfig.id,
    workingView.name,
    DateTime.now().toLocaleString(DateTime.DATETIME_SHORT),
  ]
    .filter((_) => _)
    .join('_')

type Props = {
  searchConfig: {
    id: string
    searchServiceExport(..._args: unknown[]): unknown
  }
  viewConfig: any
  searchState: any
  activeColumns: unknown[]
  fileTitle?: string
  disabled?: boolean
  workingView?: Record<string, unknown>
  searchMetaData?: Record<string, unknown>
}

class SearchExportCsv extends Component<Props> {
  static defaultProps = {
    disabled: false,
  }

  state = {
    downloading: false,
    error: null,
  }

  closeErrorDialog = () =>
    this.setState({
      error: null,
    })

  download = () => {
    const { searchConfig, viewConfig, searchState, activeColumns, fileTitle, workingView } =
      this.props
    const selectFields = activeColumns.map((col: any) => viewConfig.columns[col].selectField)
    const columnHeaders = fromPairs(
      activeColumns.map((col: any) => [
        viewConfig.columns[col].selectField,
        viewConfig.columns[col].label,
      ]),
    )
    const exportSearchOptions = { ...searchState.searchOptions, selectFields }
    const exportUrl = `${searchConfig.searchServiceExport()}?${serializeSearchOutput(
      exportSearchOptions,
      searchConfig,
      -1,
    )}`
    this.setState(
      {
        downloading: true,
      },
      () =>
        plainSubmit(() =>
          request({
            url: exportUrl,
            method: 'GET',
            headers: {
              'Content-Type': 'text/csv',
              'x-column-headers': encodeURI(JSON.stringify(columnHeaders)),
            },
          }).then(({ response }) =>
            response
              .blob()
              .then((blob) => {
                fileSaver.saveAs(blob, `${getFileName(fileTitle, searchConfig, workingView)}.csv`)
              })
              .then(() =>
                this.setState({
                  downloading: false,
                }),
              ),
          ),
        ).catch((error) =>
          this.setState({
            error: isString(error) ? error : 'An error occured while exporting.',
            downloading: false,
          }),
        ),
    )
  }

  render() {
    const { disabled, searchMetaData } = this.props
    const { downloading, error } = this.state
    const excelExportEnabled = getOr(false, 'customProperties.excelExportEnabled', searchMetaData)
    if (!excelExportEnabled) return null
    return (
      <div
        style={{
          display: 'inline-block',
          margin: 5,
        }}>
        <Button
          variant="outlined"
          onClick={this.download}
          disabled={disabled || downloading}
          color="primary"
          startIcon={downloading ? <Spinner size={SPINNER_SIZES.BUTTON} /> : null}>
          {downloading ? 'Exporting...' : 'Export to Csv'}
        </Button>
        <GenericErrorDialog
          handleClose={this.closeErrorDialog}
          {...{
            error,
          }}
        />
      </div>
    )
  }
}

export default SearchExportCsv
