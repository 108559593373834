import loadable from '@loadable/component'
import { Switch, Redirect, Route } from 'react-router-dom'

import { State as AuthState } from 'Common/auth/authReducer'
import ErrorPage from 'Common/error/ErrorPage'

import PrivateRoute from './PrivateRoute'

const DashboardIndexLoader = loadable(() => import('Root/containers/Index'))
const ConceptsSearchLoader = loadable(() => import('Concept/containers/Concepts'))
const ConceptLoader = loadable(() => import('Concept/containers/Concept'))
const AdminIndexLoader = loadable(() => import('Admin/containers/Index'))
const AdminUsersLoader = loadable(() => import('Admin/containers/users/Users'))
const AdminSeriesLoader = loadable(() => import('Admin/containers/series/Series'))
const AdminCostTypesLoader = loadable(() => import('Admin/containers/costtypes/CostTypes'))
const AdminPrinterPricesLoader = loadable(
  () => import('Admin/containers/printerPrices/PrinterPrices'),
)
const AdminIsbnSeriesLoader = loadable(() => import('Admin/containers/isbnSeries/IsbnSeries'))
const AdminEmailTemplatesLoader = loadable(
  () => import('Admin/containers/emailTemplate/EmailTemplateContainer'),
)
const AdminRoyaltySalesChannelLoader = loadable(
  () => import('Admin/containers/royaltySalesChannels/RoyaltySalesChannels'),
)
const AdminPriceUpdateLoader = loadable(() => import('Admin/containers/erp/PriceUpdate'))
const AdminUserLoader = loadable(() => import('Admin/containers/users/User'))
const MetaDataTypesLoader = loadable(() => import('Admin/containers/metadata/MetaDataTypes'))
const SearchEditionsLoader = loadable(() => import('Work/containers/Productions'))
const SearchWorksLoader = loadable(() => import('Work/containers/Works'))
const SearchActivitiesLoader = loadable(() => import('Work/containers/Activities'))
const WorkActivityLoader = loadable(() => import('Work/components/Activities/ActivityDetails'))
const BookLoader = loadable(() => import('Work/containers/Book'))
const DownloadLoader = loadable(() => import('Common/components/downloads/DownloadsContainer'))
const ContactListsLoader = loadable(() => import('Contact/containers/Lists'))
const ContactListLoader = loadable(() => import('Contact/containers/List'))
const AssetsSearchLoader = loadable(() => import('Asset/containers/Assets'))
const AssetLoader = loadable(() => import('Asset/containers/Asset'))
const ContactsSearchLoader = loadable(() => import('Contact/containers/Contacts'))
const ContactLoader = loadable(() => import('Contact/containers/Contact'))
const ImportTransactionLoader = loadable(() => import('Imports/containers/Transactions'))
const ImportStockChangesLoader = loadable(() => import('Imports/containers/StockChanges'))
const ImportRetailerOrdersLoader = loadable(() => import('Imports/containers/RetailerOrders'))
const RoyaltyAgreementLoader = loadable(() => import('Royalty/containers/AgreementContainer'))
const RoyaltyMainLoader = loadable(() => import('Royalty/containers/RoyaltyMainContainer'))

type Props = {
  auth?: AuthState
}

function Routes({ auth }: Props) {
  return (
    <Switch>
      <PrivateRoute exact auth={auth} path="/my-work" component={DashboardIndexLoader} />
      <PrivateRoute exact auth={auth} path="/" component={DashboardIndexLoader} />
      <Route path="/dashboard" render={() => <Redirect to="/my-work" />} />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_concept']}
        path="/concept/search"
        component={ConceptsSearchLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_concept']}
        path="/concept/:conceptId"
        component={ConceptLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_concept']}
        path="/concept/:conceptId/:section"
        component={ConceptLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_concept']}
        path="/concept/:conceptId/:section/:editionId"
        component={ConceptLoader}
      />
      <PrivateRoute exact auth={auth} path="/admin/general" component={AdminIndexLoader} />
      <PrivateRoute exact auth={auth} path="/admin/series" component={AdminSeriesLoader} />
      <PrivateRoute exact auth={auth} path="/admin/series/:id" component={AdminSeriesLoader} />
      <PrivateRoute exact auth={auth} path="/admin/costtypes" component={AdminCostTypesLoader} />
      <PrivateRoute
        exact
        auth={auth}
        path="/admin/printerprices/"
        component={AdminPrinterPricesLoader}
      />
      <PrivateRoute exact auth={auth} path="/admin/isbnseries/" component={AdminIsbnSeriesLoader} />
      <PrivateRoute
        exact
        auth={auth}
        path="/admin/royaltySalesChannels/"
        component={AdminRoyaltySalesChannelLoader}
      />
      <PrivateRoute exact auth={auth} path="/admin/users" component={AdminUsersLoader} />
      <PrivateRoute
        exact
        auth={auth}
        path="/admin/price-update"
        component={AdminPriceUpdateLoader}
      />
      <PrivateRoute auth={auth} path="/admin/users/:userId" component={AdminUserLoader} />
      <PrivateRoute auth={auth} path="/admin/users/:userId/:section" component={AdminUserLoader} />
      <PrivateRoute auth={auth} path="/admin/metadata" component={MetaDataTypesLoader} />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_royalty']}
        path="/admin/emailtemplates"
        component={AdminEmailTemplatesLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_work']}
        path="/book/editions"
        component={SearchEditionsLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_work']}
        path="/book/works"
        component={SearchWorksLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_work']}
        path="/book/activities"
        component={SearchActivitiesLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_work']}
        path="/book/activities/:activityId"
        component={WorkActivityLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_work']}
        path="/book/:workId/edition/:productionId"
        component={BookLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_work']}
        path="/book/:workId"
        component={BookLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_asset']}
        path="/assets"
        component={AssetsSearchLoader}
      />
      <PrivateRoute exact auth={auth} path="/mydownloads" component={DownloadLoader} />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_asset']}
        path="/assets/:id"
        component={AssetLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_contacts']}
        path="/contact/lists"
        component={ContactListsLoader}
      />
      <PrivateRoute
        exact
        auth={auth}
        restrictToClaims={['opus_contacts']}
        path="/contact"
        component={ContactsSearchLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_contacts']}
        path="/contact/lists/:id"
        component={ContactListLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_contacts']}
        path="/contact/:contactId"
        component={ContactLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_royalty']}
        path="/imports/createretailerorders"
        component={ImportRetailerOrdersLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_royalty']}
        path="/imports/importtransaction"
        component={ImportTransactionLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_royalty']}
        path="/imports/importstockchanges"
        component={ImportStockChangesLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_royalty']}
        path="/royalty/agreement/:id"
        component={RoyaltyAgreementLoader}
      />
      <PrivateRoute
        auth={auth}
        restrictToClaims={['opus_royalty']}
        path="/royalty"
        component={RoyaltyMainLoader}
      />
      <PrivateRoute path="*" component={ErrorPage} />
    </Switch>
  )
}

export default Routes
