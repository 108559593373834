import { unitTranslationMapper } from 'Calculation/calculationConstants'
import { getFieldUnitByType } from 'Calculation/calculationConstants'
import { find, isNumber } from 'lodash/fp'
import { NumericFormat } from 'react-number-format'

import { getSetting } from 'Common/environment'
import FormatMoney from 'Common/form/hookForm/FormatMoney'
import { Money } from 'Common/types'

export const convertPercentagesToFactors = (royaltyLevels = []) =>
  royaltyLevels.map((royaltyLevel) => ({
    ...royaltyLevel,
    factor: royaltyLevel.isConverted
      ? (parseFloat(royaltyLevel.factor) / 100).toFixed(4)
      : royaltyLevel.factor,
    isConverted: false,
  }))
export const convertFactorsToPercentages = (royaltyLevels = []) =>
  royaltyLevels.map((royaltyLevel) => ({
    ...royaltyLevel,
    factor: !royaltyLevel.isConverted
      ? (parseFloat(royaltyLevel.factor) * 100).toFixed(2)
      : royaltyLevel.factor,
    isConverted: true,
  }))
export const convertFactorToEvenPercentage = (factor) => (parseFloat(factor) * 100).toFixed(0)

type Props = {
  value?: number
  suffix?: string
  showSuffix?: boolean
  decimalScale?: number
}

export const FormatCurrency = (props: Props) => {
  const { showSuffix = true, suffix, decimalScale = 2, value, ...otherProps } = props
  const displayValue = value && suffix === unitTranslationMapper.percentage ? value * 100 : value
  return (
    <NumericFormat
      {...otherProps}
      suffix={showSuffix ? suffix : ''}
      value={displayValue}
      decimalScale={decimalScale}
      displayType={'text'}
      decimalSeparator=","
      thousandSeparator=" "
      valueIsNumericString
    />
  )
}
export const getIdFieldByKey = (id, extendedFields) =>
  find(
    {
      id,
    },
    extendedFields,
  )
export const getIdFieldByType = (type, extendedFields) =>
  find(
    {
      type,
    },
    extendedFields,
  )
export const getFieldByTypeAndExternalId = (externalId, type, operations) => {
  return (
    (operations &&
      find(
        {
          externalId,
          type,
        },
        operations,
      )) ??
    {}
  )
}
export const fillOperation = (operation, extendedFields) => {
  const extendedOperation = {
    field: { ...getIdFieldByKey(operation.field, extendedFields) },
    operator: operation.operator,
  }

  if (extendedOperation.field.operations && extendedOperation.field.operations.length !== 0) {
    return {
      ...extendedOperation,
      field: {
        ...extendedOperation.field,
        operations: extendedOperation.field.operations.map((operation) =>
          fillOperation(operation, extendedFields),
        ),
      },
    }
  }

  return extendedOperation
}

export const formatFieldValue = (value: number, type: string) => {
  if (!value) return '-'

  const fieldtype = getFieldUnitByType(type)
  switch (fieldtype) {
    case 'currency': {
      const money: Money = {
        value: value,
        currency: getSetting('currency'),
      }
      return <FormatMoney {...money} />
    }
    case 'percentage': {
      const percentage = value * 100
      if (isNaN(percentage)) return '-'
      return `${percentage.toFixed(0)} %`
    }

    case 'count':
      return value.toLocaleString()
    default: {
      if (isNumber(value)) {
        const money: Money = {
          value: value,
          currency: getSetting('currency'),
        }
        return <FormatMoney {...money} />
      }
      return value ?? '-'
    }
  }
}
export const getHoursFromTotalMinutes = (totalMinutes) => Math.trunc(totalMinutes / 60)
export const getMinutesFromTotalMinutes = (totalMinutes) =>
  totalMinutes - getHoursFromTotalMinutes(totalMinutes) * 60
