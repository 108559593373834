import { isEmpty, isNil } from 'lodash/fp'
import { Component } from 'react'

import IconClose from '@mui/icons-material/Close'
import { Drawer, IconButton, TextField, FormControl } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { MetaData } from 'Common/metadata'

import InfoNotice from '../components/notice/InfoNotice'
import ActiveFilters from './ActiveFilters'
import SearchControlFilter from './SearchControlFilter'

type Props = {
  updateFilter(..._args: unknown[]): unknown
  toggleFilterSelection(..._args: unknown[]): unknown
  handleToggleFilterExpand(..._args: unknown[]): unknown
  disableFilter(..._args: unknown[]): unknown
  searchConfig: any
  searchOptions: Record<string, unknown>

  metaData: MetaData
  auth: Record<string, unknown>
  enabledFilters: unknown[]
  availableFilters: any
  showFilterSelection: boolean
  totalFilters: number
  expandedFilter?: string
}

class SearchControlFilters extends Component<Props> {
  state = { filterSearchQuery: '' }

  handleFilterSearchChange = (e) =>
    this.setState({
      filterSearchQuery: e.target.value,
    })

  render() {
    const {
      searchConfig,
      searchOptions,
      metaData,
      auth,
      enabledFilters,
      availableFilters,
      showFilterSelection,
      totalFilters,
      expandedFilter,
      toggleFilterSelection,
      updateFilter,
      handleToggleFilterExpand,
      disableFilter,
    } = this.props
    const { filterSearchQuery } = this.state
    const { filters } = searchConfig
    if (!filters || isEmpty(filters)) return null
    const filtersInline = searchConfig.filtersInline || false
    const missingFilters = Object.keys(searchOptions).filter(
      (key) =>
        key.startsWith('filter') &&
        !isNil(searchOptions[key]) &&
        isNil((searchConfig.filters || []).find((filter) => filter.id === key)),
    )
    const filterComponents = [
      ...enabledFilters,
      ...availableFilters.filter((filter) =>
        filter.name.toLowerCase().includes(filterSearchQuery.toLowerCase()),
      ),
    ].map((filter, index) => (
      <SearchControlFilter
        key={filter.id}
        {...{
          searchConfig,
          searchOptions,
          metaData,
          auth,
          filter,
          index,
          expandedFilter,
          updateFilter,
          handleToggleFilterExpand,
          disableFilter,
        }}
      />
    ))
    const ModalProps = {
      onBackdropClick: toggleFilterSelection,
    }
    return (
      <div className="hidden-print">
        <ActiveFilters
          {...{
            updateFilter,
            totalFilters,
            filters,
            searchOptions,
            metaData,
            showFilterSelection,
            handleToggleFilterExpand,
            toggleFilterSelection,
          }}
        />
        {filtersInline ? (
          <div>{showFilterSelection && filterComponents}</div>
        ) : (
          <Drawer
            open={showFilterSelection}
            anchor="right"
            color="primary"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            {...{
              ModalProps,
            }}>
            <Grid container spacing={0} xs={12} sx={{ marginBottom: 2, marginTop: 2 }}>
              <Grid xs={2} display="flex" justifyContent="center">
                <IconButton onClick={toggleFilterSelection} size="large">
                  <IconClose />
                </IconButton>
              </Grid>
              <Grid xs={10}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    variant="standard"
                    autoFocus
                    onChange={this.handleFilterSearchChange}
                    label="Search filters..."
                    type="search"
                    value={filterSearchQuery}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {filterComponents}
          </Drawer>
        )}
        {missingFilters.length > 0 ? (
          <div>
            <InfoNotice
              title="Search error"
              content={
                <div>
                  <span>
                    Your search contains filters that do not exist anymore. If you are using a saved
                    search, add the filters manually again and click &quot;Save&quot; to clear this
                    message.
                  </span>
                  <ul>
                    {missingFilters.map((filterId) => (
                      <li key={filterId}>{filterId.replace('filter', '')}</li>
                    ))}
                  </ul>
                </div>
              }
            />
          </div>
        ) : null}
      </div>
    )
  }
}
export default SearchControlFilters
