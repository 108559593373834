import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_CONTACT_LISTS_REQUEST,
  GET_CONTACT_LISTS_SUCCESS,
  GET_CONTACT_LISTS_FAILURE,
} from '../actions/contactListsActions'

const initialState = {
  getStatus: undefined,
  allLists: undefined,
}
type State = {
  getStatus: any
  allLists: any
}
export default (state: State = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_LISTS_REQUEST:
      return { ...state, getStatus: requestStatus.request }

    case GET_CONTACT_LISTS_SUCCESS:
      return { ...state, allLists: action.payload.json, getStatus: requestStatus.success }

    case GET_CONTACT_LISTS_FAILURE:
      return { ...state, getStatus: requestStatus.failure }

    default:
      return state
  }
}
