const messages = {
  needsToBeAnEmail: 'The email address is not valid',
  needsToBeAnUrl: 'The url is not valid',
  needsToBeAnObject: 'Needs to be an object.',
  needsToBeAPhoneNumber: 'The phone number is not valid',
  needsToBeASpecificLength: 'Needs to be a specific length.',
  needsToBeAtLeastASpecificLength: 'Needs to be at least a specific length.',
  needsToBeEntered: 'Needs to be entered.',
  needsToBeEqual: 'Needs to be equal.',
  needsToBeInRange: 'Needs to be within a range.',
  needsToBeInteger: 'Needs to be an integer number',
  needsToBeIntegerOrDecimal: 'Needs to be an integer, decimal number or zero',
  needsToBePositiveNumber: 'Needs to be a positive number or zero',
  needsToBeLesserThenASpecificLength: 'Needs to be less than a specific length.',
  needsToBeOrganisationNumber: 'Wrong format. Should be entered as: 123456-1234 or 1234567-1',
  needsToBeASwedishOrganisationNumber: 'Wrong format. Should be entered as: 123456-1234',
  needsToBeAFinnishOrganisationNumber: 'Wrong format. Should be entered as: 1234567-1',
  needsToBeAFinnishSocialSecurityNumber:
    'Wrong format. Should be entered as: DDMMYY+XXXX or DDMMYY-XXXX or DDMMYYAXXXX',
  needsToBeTrue: 'Needs to be entered',
  needsToBeWithInASpecificLength: 'Needs to be within a specific length.',
  needsToBeDate: 'Wrong format. Should be entered as: 1980-01-01',
}
export default messages
