import { Dialog } from 'Common/core'

import ConfirmationWrapper from '../components/confirmation/ConfirmationWrapper'

type Props = {
  removeSearch(..._args: unknown[]): unknown
  closeModal(): void
  savedSearch?: Record<string, unknown>
}

const SavedSearchRemoval = ({ savedSearch, removeSearch, closeModal }: Props) => {
  if (!savedSearch) return null
  return (
    <Dialog title="Delete saved search" onClose={closeModal}>
      <ConfirmationWrapper
        confirmAction={() => removeSearch(savedSearch.name)}
        message={`Do you really want to delete the saved search "${savedSearch.name}"?`}
        cancelAction={closeModal}
        show>
        <span />
      </ConfirmationWrapper>
    </Dialog>
  )
}

export default SavedSearchRemoval
