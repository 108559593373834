import {
  convertFactorsToPercentages,
  getHoursFromTotalMinutes,
  getMinutesFromTotalMinutes,
} from 'Calculation/utils/calculation'
import { sortBy, getOr } from 'lodash/fp'

import { Concept } from 'Common/types'
import { requestStatus } from 'Common/utils/net/statuses'

import {
  PATCH_CONCEPT_REQUEST,
  PATCH_CONCEPT_SUCCESS,
  PATCH_CONCEPT_FAILURE,
  GET_CONCEPT_REQUEST,
  GET_CONCEPT_SUCCESS,
  GET_CONCEPT_SILENT_SUCCESS,
  GET_CONCEPT_FAILURE,
  GET_CONCEPT_THEMAS_REQUEST,
  GET_CONCEPT_THEMAS_SUCCESS,
  GET_CONCEPT_THEMAS_FAILURE,
  POST_CONCEPT_APPROVE_REQUEST,
  POST_CONCEPT_APPROVE_SUCCESS,
  POST_CONCEPT_APPROVE_FAILURE,
  POST_CONCEPT_EDITION_APPROVE_REQUEST,
  POST_CONCEPT_EDITION_APPROVE_SUCCESS,
  POST_CONCEPT_EDITION_APPROVE_FAILURE,
} from '../actions/ConceptActions'
import {
  POST_CONCEPT_MEMBERS_REQUEST,
  POST_CONCEPT_MEMBERS_SUCCESS,
  POST_CONCEPT_MEMBERS_FAILURE,
  PUT_CONCEPT_MEMBERS_REQUEST,
  PUT_CONCEPT_MEMBERS_SUCCESS,
  PUT_CONCEPT_MEMBERS_FAILURE,
  REMOVE_CONCEPT_MEMBERS_REQUEST,
  REMOVE_CONCEPT_MEMBERS_SUCCESS,
  REMOVE_CONCEPT_MEMBERS_FAILURE,
} from '../actions/ConceptMemberActions'
import {
  PUT_CONCEPT_PRODUCTION_REQUEST,
  PUT_CONCEPT_PRODUCTION_SUCCESS,
  PUT_CONCEPT_PRODUCTION_FAILURE,
  GET_CONCEPT_PRODUCTION_COSTS_REQUEST,
  GET_CONCEPT_PRODUCTION_COSTS_SUCCESS,
  PUT_CONCEPT_PRODUCTION_COSTS_SUCCESS,
  POST_CONCEPT_PRODUCTION_REQUEST,
  POST_CONCEPT_PRODUCTION_SUCCESS,
  POST_CONCEPT_PRODUCTION_FAILURE,
  POST_CONCEPT_PRODUCTION_SILENT_REQUEST,
  POST_CONCEPT_PRODUCTION_SILENT_SUCCESS,
  POST_CONCEPT_PRODUCTION_SILENT_FAILURE,
  DELETE_CONCEPT_PRODUCTION_SILENT_REQUEST,
  DELETE_CONCEPT_PRODUCTION_SILENT_SUCCESS,
  DELETE_CONCEPT_PRODUCTION_SILENT_FAILURE,
  DELETE_CONCEPT_PRODUCTION_REQUEST,
  DELETE_CONCEPT_PRODUCTION_SUCCESS,
  DELETE_CONCEPT_PRODUCTION_FAILURE,
} from '../actions/ProductionActions'

type State = {
  status: {
    getStatus: any
    getError: any
  }
  themasStatus: {
    getStatus: any
    getError: any
  }
  data: Concept
  productionCosts?: any
}

const initialState: State = {
  status: {
    getStatus: undefined,
    getError: undefined,
  },
  themasStatus: {
    getStatus: undefined,
    getError: undefined,
  },
  data: undefined,
}

const decimalGuard = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

const calculateProduction = (production) => {
  return {
    ...production,
    audioRunningTimeHours: getHoursFromTotalMinutes(
      parseInt(production.audioTotalRunningTimeMinutes),
    ),
    audioRunningTimeMinutes: getMinutesFromTotalMinutes(
      parseInt(production.audioTotalRunningTimeMinutes),
    ),
    royaltyLevels: convertFactorsToPercentages(production.royaltyLevels),
    printCostPerCopy: decimalGuard(production.printCostPerCopy),
  }
}

export default (state: State = initialState, action) => {
  switch (action.type) {
    case GET_CONCEPT_REQUEST:
      return {
        ...state,
        id: action.payload,
        data: null,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_CONCEPT_SUCCESS:
    case GET_CONCEPT_SILENT_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.json,
          productions: action.payload.json.productions.map((production) => {
            return { ...calculateProduction(production) }
          }),
        },
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_CONCEPT_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    case GET_CONCEPT_THEMAS_REQUEST:
      return {
        ...state,
        id: action.payload,
        data: { ...state.data },
        themasStatus: { ...state.themasStatus, getStatus: requestStatus.request, getError: null },
      }

    case GET_CONCEPT_THEMAS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          themas: sortBy('sortOrder', getOr([], 'payload.json', action)).filter(
            (t) => !!t.themaCodeType,
          ),
        },
        themasStatus: { ...state.themasStatus, getStatus: requestStatus.success },
      }

    case GET_CONCEPT_THEMAS_FAILURE:
      return {
        ...state,
        themasStatus: {
          ...state.themasStatus,
          getStatus: requestStatus.failure,
          getError: action.payload,
        },
      }

    case GET_CONCEPT_PRODUCTION_COSTS_REQUEST:
      return {
        ...state,
        id: action.payload,
        data: { ...state.data },
        productionCosts: {
          ...state.productionCosts,
          getStatus: requestStatus.request,
          getError: null,
        },
      }

    case GET_CONCEPT_PRODUCTION_COSTS_SUCCESS:
    case PUT_CONCEPT_PRODUCTION_COSTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          productions: state.data.productions.map((production) => ({
            ...production,
            costs:
              action.meta.productionId === production.id
                ? action.payload.json.costs
                : production.costs,
          })),
        },
        status: { ...state.status, updateStatus: requestStatus.success },
      }

    case PATCH_CONCEPT_REQUEST:
      return {
        ...state,
        id: action.payload,
        data: {
          ...state.data,
          productions: state.data.productions.map((production) => {
            return {
              ...calculateProduction(production),
              audioTotalRunningTime:
                production.audioRunningTimeHours * 60 + production.audioRunningTimeMinutes,
            }
          }),
        },
        status: { ...state.status, updateStatus: requestStatus.request, updateError: null },
      }

    case PATCH_CONCEPT_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.json,
          productions: action.payload.json.productions.map((production) =>
            calculateProduction(production),
          ),
        },
        status: { ...state.status, updateStatus: requestStatus.success },
      }

    case PATCH_CONCEPT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          updateStatus: requestStatus.failure,
          updateError: action.payload,
        },
      }

    case REMOVE_CONCEPT_MEMBERS_REQUEST:
    case POST_CONCEPT_MEMBERS_REQUEST:
    case PUT_CONCEPT_MEMBERS_REQUEST:
    case POST_CONCEPT_APPROVE_REQUEST:
    case POST_CONCEPT_EDITION_APPROVE_REQUEST:
    case DELETE_CONCEPT_PRODUCTION_REQUEST:
    case PUT_CONCEPT_PRODUCTION_REQUEST:
    case POST_CONCEPT_PRODUCTION_REQUEST:
    case POST_CONCEPT_PRODUCTION_SILENT_REQUEST:
    case DELETE_CONCEPT_PRODUCTION_SILENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          productions: state.data.productions.map((production) => calculateProduction(production)),
        },
        status: { ...state.status, updateStatus: requestStatus.request, updateError: null },
      }

    case REMOVE_CONCEPT_MEMBERS_SUCCESS:
    case POST_CONCEPT_MEMBERS_SUCCESS:
    case PUT_CONCEPT_MEMBERS_SUCCESS:
    case POST_CONCEPT_APPROVE_SUCCESS:
    case POST_CONCEPT_EDITION_APPROVE_SUCCESS:
    case DELETE_CONCEPT_PRODUCTION_SUCCESS:
    case PUT_CONCEPT_PRODUCTION_SUCCESS:
    case POST_CONCEPT_PRODUCTION_SUCCESS:
    case DELETE_CONCEPT_PRODUCTION_SILENT_SUCCESS: {
      return {
        ...state,
        data: {
          ...action.payload.json,
          productions: [
            ...action.payload.json.productions
              .filter((p) => !p.hasFakeId)
              .map((production) => calculateProduction(production)),
          ],
        },
        status: { ...state.status, updateStatus: requestStatus.success },
      }
    }

    case POST_CONCEPT_PRODUCTION_SILENT_SUCCESS: {
      return {
        ...state,
        data: {
          ...action.payload.json,
          productions: [
            ...action.payload.json.productions.map((production) => calculateProduction(production)),
          ],
        },
        status: { ...state.status, updateStatus: requestStatus.success },
      }
    }

    case REMOVE_CONCEPT_MEMBERS_FAILURE:
    case POST_CONCEPT_MEMBERS_FAILURE:
    case PUT_CONCEPT_MEMBERS_FAILURE:
    case POST_CONCEPT_APPROVE_FAILURE:
    case POST_CONCEPT_EDITION_APPROVE_FAILURE:
    case DELETE_CONCEPT_PRODUCTION_FAILURE:
    case PUT_CONCEPT_PRODUCTION_FAILURE:
    case POST_CONCEPT_PRODUCTION_FAILURE:
    case POST_CONCEPT_PRODUCTION_SILENT_FAILURE:
    case DELETE_CONCEPT_PRODUCTION_SILENT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          updateStatus: requestStatus.failure,
          updateError: action.payload,
        },
      }

    default:
      return state
  }
}
