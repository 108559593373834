import { services } from 'Common/environment'
import request from 'Common/utils/net/request'

export const GET_WORK_REVIEW_QUOTES = 'GET_WORK_REVIEW_QUOTES'
export const GET_WORK_REVIEW_QUOTES_REQUEST = 'GET_WORK_REVIEW_QUOTES_REQUEST'
export const GET_WORK_REVIEW_QUOTES_SUCCESS = 'GET_WORK_REVIEW_QUOTES_SUCCESS'
export const GET_WORK_REVIEW_QUOTES_FAILURE = 'GET_WORK_REVIEW_QUOTES_TEXTS_FAILURE'
export const PUT_WORK_REVIEW_QUOTES = 'PUT_WORK_REVIEW_QUOTES'
export const PUT_WORK_REVIEW_QUOTES_REQUEST = 'PUT_WORK_REVIEW_QUOTES_REQUEST'
export const PUT_WORK_REVIEW_QUOTES_SUCCESS = 'PUT_WORK_REVIEW_QUOTES_SUCCESS'
export const PUT_WORK_REVIEW_QUOTES_FAILURE = 'PUT_WORK_REVIEW_QUOTES_FAILURE'
export const getWorkReviewQuotes = (workId) => (dispatch) => {
  return dispatch({
    type: GET_WORK_REVIEW_QUOTES,
    payload: {
      data: {
        workId,
      },
      promise: request({
        url: services.work(`/v1/works/${workId}/reviewquotes`),
        method: 'GET',
      }),
    },
  })
}
export const putWorkReviewQuotes = (workId, body) => (dispatch) =>
  dispatch({
    type: PUT_WORK_REVIEW_QUOTES,
    payload: {
      data: {
        workId,
      },
      promise: request({
        method: 'PUT',
        body: body,
        url: services.work(`/v1/works/${workId}/reviewquotes`),
      }),
    },
  })
