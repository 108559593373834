import { Controller } from 'react-hook-form'

import { Checkbox, FormControlLabel } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import FieldInfoHover, { FieldInfoProp } from './fieldInfo/FieldInfo'
import FieldInfoPropsNullCheck from './fieldInfo/FieldInfoPropsNullCheck'

type Props = {
  name: string
  control: any
  label?: string
  disabled?: boolean
  fieldInfoProps?: FieldInfoProp
}

export default function CheckboxInputController(props: Props) {
  const { name, control, label, disabled = false, fieldInfoProps } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Grid xs={12} container spacing={0}>
            <Grid xs={11}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    disabled={disabled}
                  />
                }
                label={label}
              />
            </Grid>
            <Grid xs={1} display="flex" justifyContent="flex-end">
              {fieldInfoProps && FieldInfoPropsNullCheck(fieldInfoProps) ? (
                <FieldInfoHover fieldInfoProps={fieldInfoProps} />
              ) : null}
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
