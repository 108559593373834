import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_ASSETS = 'GET_CONTACT_ASSETS'
export const GET_CONTACT_ASSETS_REQUEST = 'GET_CONTACT_ASSETS_REQUEST'
export const GET_CONTACT_ASSETS_SUCCESS = 'GET_CONTACT_ASSETS_SUCCESS'
export const GET_CONTACT_ASSETS_FAILURE = 'GET_CONTACT_ASSETS_FAILURE'
export const DISCONNECT_ASSET_CONTACT = 'DISCONNECT_ASSET_CONTACT'
export const DISCONNECT_ASSET_CONTACT_REQUEST = 'DISCONNECT_ASSET_CONTACT_REQUEST'
export const DISCONNECT_ASSET_CONTACT_SUCCESS = 'DISCONNECT_ASSET_CONTACT_SUCCESS'
export const DISCONNECT_ASSET_CONTACT_FAILURE = 'DISCONNECT_ASSET_CONTACT_FAILURE'
export const CONNECT_ASSET_CONTACT = 'CONNECT_ASSET_CONTACT'
export const CONNECT_ASSET_CONTACT_REQUEST = 'CONNECT_ASSET_CONTACT_REQUEST'
export const CONNECT_ASSET_CONTACT_SUCCESS = 'CONNECT_ASSET_CONTACT_SUCCESS'
export const CONNECT_ASSET_CONTACT_FAILURE = 'CONNECT_ASSET_CONTACT_FAILURE'
const GET_CONTACT_ASSETS_ERROR_MESSAGES = createErrorMessages(
  ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
  messages,
)
export const getContactAssets = (contactId) => (dispatch) =>
  dispatch({
    type: GET_CONTACT_ASSETS,
    payload: {
      data: {
        contactId,
      },
      promise: request({
        method: 'GET',
        url: services.contact(`/v2/contacts/${contactId}/assets`),
        errorMessages: GET_CONTACT_ASSETS_ERROR_MESSAGES,
      }),
    },
  })
const CONNECT_ASSET_CONTACT_ERROR_MESSAGES = createErrorMessages(
  [
    'CONTACT_NOT_FOUND',
    'CONTACT_FORBIDDEN',
    'CONTACT_ASSET_ALREADY_CONNECTED',
    'MAXIMUM_NUMBER_OF_ASSET_TYPE_FOR_CONTACT',
    'ASSET_NOT_FOUND',
    'ASSET_TYPE_DOES_NOT_EXIST',
  ],
  messages,
)
export const connectAssetToContact = (contact, asset) => {
  const { contactId, refreshThumbnail } = contact
  return (dispatch) =>
    dispatch({
      type: CONNECT_ASSET_CONTACT,
      meta: {
        contactId,
        refreshThumbnail,
        asset,
      },
      payload: {
        promise: request({
          url: services.contact(`/v2/contacts/${contactId}/assets`),
          method: 'POST',
          body: asset,
          errorMessages: CONNECT_ASSET_CONTACT_ERROR_MESSAGES,
        }),
      },
    })
}
const DISCONNECT_ASSET_CONTACT_ERROR_MESSAGES = createErrorMessages(
  ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN', 'CONTACT_ASSET_NOT_CONNECTED'],
  messages,
)
export const disconnectAssetFromContact = (contact, asset) => {
  const { contactId } = contact
  return (dispatch) =>
    dispatch({
      type: DISCONNECT_ASSET_CONTACT,
      meta: {
        contactId,
        asset,
      },
      payload: {
        data: contact,
        promise: request({
          url: services.contact(`/v2/contacts/${contactId}/assets/${asset.id}`),
          method: 'DELETE',
          errorMessages: DISCONNECT_ASSET_CONTACT_ERROR_MESSAGES,
        }),
      },
    })
}
