import { SubmissionError } from 'redux-form'

export const ERROR_MESSAGE_GENERIC = 'A technical error occured'
export const ERROR_MESSAGES_HTTP = {
  400: 'A technical error occured (400)',
  401: 'An error occured, access rights missing (401)',
  403: 'An error occured, access rights missing (403)',
  404: 'An error occured, service not found (404)',
  408: 'An error occured, request timeout (408)',
  415: 'Unsupported Media Type (415)',
  500: 'A technical error occured (500)',
  503: 'An error occured, service unavailable (503)',
}
export const ERROR_MESSAGES_COMMON = {
  ERROR_HAS_OCCURRED:
    'An unexpected technical error has occured. Contact support if the error remains.',
}
export const getErrorMessage = (error: any = {}, errorMessages = {}) =>
  (error.errorCode && errorMessages[error.errorCode]) ||
  (error.errorCode && error.errorMessages && error.errorMessages[error.errorCode]) ||
  (error.errorCode && ERROR_MESSAGES_COMMON[error.errorCode]) ||
  errorMessages[error.status] ||
  error.errorMessage ||
  ERROR_MESSAGES_HTTP[error.status] ||
  ERROR_MESSAGE_GENERIC
export const successHandler = (response, resolve) => resolve(response)
export const formErrorHandler = (error, reject, errorMessages) =>
  reject(
    new SubmissionError({
      _error: getErrorMessage(error, errorMessages),
    }),
  )
export const plainErrorHandler = (error, reject, errorMessages) =>
  reject(getErrorMessage(error, errorMessages))
export const formSubmit = (action, errorMessages?: any) =>
  new Promise((resolve, reject) =>
    action()
      .then((response) => successHandler(response, resolve))
      .catch((error) => formErrorHandler(error, reject, errorMessages)),
  )
export const plainSubmit = (action, errorMessages?: any) =>
  new Promise((resolve, reject) =>
    action()
      .then((response) => successHandler(response, resolve))
      .catch((error) => plainErrorHandler(error, reject, errorMessages)),
  )
