import { Card, CardHeader, CardContent } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type Props = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  children?: React.ReactNode
}

const Section = ({ title, subtitle, children }: Props) => {
  return (
    <Grid xs={12}>
      <Card sx={{ marginTop: 1, marginBottom: 1 }}>
        <CardHeader sx={{ textAlign: 'center' }} title={title} subheader={subtitle} />
        <Grid xs={12}>
          <CardContent>
            <Grid xs={12}>{children}</Grid>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  )
}

export default Section
