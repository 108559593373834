import { flatten, getOr } from 'lodash/fp'

import { servicesLoaders } from 'Common/environment'
import {
  booleanFilter,
  combinationOptionFilter,
  contactFilter,
  dateFilter,
  optionFilter,
  missingFilter,
} from 'Common/search/filter/filters'
import { getObjectsInputParser } from 'Common/search/searchInputParsers'
import { getObjectsInputSerializer } from 'Common/search/searchInputSerializers'
import {
  getArrayOutputSerializer,
  getObjectsToListOutputSerializer,
} from 'Common/search/searchOutputSerializers'
import AdvancedSearchView from 'Common/search/view/AdvancedSearchView'
import BasicSearchView from 'Common/search/view/BasicSearchView'
import BooleanCell from 'Common/search/view/cells/BooleanCell'
import DateCell from 'Common/search/view/cells/DateCell'
import EmailListCell from 'Common/search/view/cells/EmailListCell'
import LinkCell from 'Common/search/view/cells/LinkCell'
import PhoneNumberListCell from 'Common/search/view/cells/PhoneNumberListCell'
import { getTextColumns } from 'Common/utils/texts'

import ContactsViewHeader from '../components/Contacts/ContactsViewHeader'
import ContactsViewItem from '../components/Contacts/ContactsViewItem'
import ContactListFilter from '../components/Contacts/filter/ContactListFilter'

const contactListFilter = {
  Component: ContactListFilter,
  getInputParser: getObjectsInputParser,
  getInputSerializer: getObjectsInputSerializer,
  getOutputSerializer: getArrayOutputSerializer,
  displayFormat: (value) => getOr('?', 'name', value),
  typeConfig: {
    objectToValue: (option) => option.id,
  },
}
const CONTACT_CATEGORY = 'Contact'
const ADDRESS_CATEGORY = 'Address'
const TEXTS_CATEGORY = 'Texts'
const ADDRESS_TYPES = {
  Miscellaneous: 'Other address',
  Postal: 'Postal address',
  Visitation: 'Visiting address',
}
const ADDRESS_FIELDS = {
  address1: 'Address field 1',
  address2: 'Address field 2',
  city: 'City',
  country: 'Country',
  zip: 'Postal code',
}

const getAddressColumns = () =>
  flatten(
    Object.keys(ADDRESS_TYPES).map((type) =>
      Object.keys(ADDRESS_FIELDS).map((field) => ({
        id: `address-${type}-${field}`,
        selectField: `${field}${type}`,
        label: `${ADDRESS_TYPES[type]}, ${ADDRESS_FIELDS[field]}`,
        width: 160,
        category: ADDRESS_CATEGORY,
      })),
    ),
  ).reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})

export const searchConfig = {
  id: 'contacts',
  accessModule: 'opus_contacts',
  searchService: servicesLoaders.contact('/v3/search/contacts'),
  searchServiceExport: servicesLoaders.contact('/v3/search/contacts/export'),
  searchLocation: '/contact',
  searchMetaDataId: 'contacts',
  savedSearchesId: 'savedsearches-contact-contactssearch-v2',
  defaultSelectFields: [
    'id',
    'firstName',
    'lastName',
    'simpleEmailAddresses',
    'simplePhoneNumbers',
    'isSecret',
  ],
  pagingSize: 50,
  queryFields: [
    {
      id: 'firstName',
      label: 'First name',
    },
    {
      id: 'lastName',
      label: 'Last name',
    },
    {
      id: 'parentCompany',
      label: 'Company',
    },
  ],
  filters: [
    {
      type: contactListFilter,
      id: 'filterListIds',
      searchId: 'listIds',
      name: 'Selection list',
    },
    {
      type: optionFilter,
      externalFilterGroup: 'productionFilter',
      id: 'filterPublishingHouses',
      searchId: 'publishingHouse',
      metaDataId: 'publishingHouses',
      typeConfig: {
        filterByPublishingHouse: true,
      },
      name: 'Edition - Publishing house',
    },
    {
      type: optionFilter,
      id: 'filterCustomerGroupId',
      searchId: 'customerGroupId',
      metaDataId: 'customerGroups',
      name: 'Customer group',
    },
    {
      type: optionFilter,
      id: 'filterCustomerSubGroupId',
      searchId: 'customerSubGroupId',
      metaDataId: 'customerSubGroups',
      name: 'Customer subgroup',
    },
    {
      type: booleanFilter,
      id: 'filterIsSecret',
      searchId: 'isSecret',
      name: 'Secret contact',
    },
    {
      type: booleanFilter,
      id: 'filterHasContactPresentation',
      searchId: 'hasContactPresentation',
      name: 'Contact presentation',
    },
    {
      type: booleanFilter,
      id: 'filterHasAuthorPhotographStandard',
      searchId: 'hasAuthorPhotographStandard',
      name: 'Author photograph - Standard',
    },
    {
      type: booleanFilter,
      id: 'filterHasAuthorPhotographDownload',
      searchId: 'hasAuthorPhotographDownload',
      name: 'Author photograph - Download',
    },
    {
      type: booleanFilter,
      id: 'filterIsCompanyContact',
      searchId: 'isCompanyContact',
      name: 'Company contact',
    },
    {
      type: booleanFilter,
      id: 'filterIsUser',
      searchId: 'isUser',
      name: 'Mockingbird user',
    },
    {
      type: booleanFilter,
      id: 'filterhasGdprSecuredNotes',
      searchId: 'hasGdprSecuredNotes',
      name: 'Notes',
    },
    {
      type: optionFilter,
      externalFilterGroup: 'productionFilter',
      id: 'filterBindingCodes',
      searchId: 'bindingCode',
      metaDataId: 'bindingCodes',
      name: 'Edition - Binding code',
    },
    {
      type: optionFilter,
      externalFilterGroup: 'productionFilter',
      id: 'filterBrands',
      searchId: 'brand',
      metaDataId: 'brands',
      name: 'Edition - Brand',
    },
    {
      type: dateFilter,
      id: 'filterBirthDay',
      searchId: 'birthDay',
      name: 'Date of birth',
    },
    {
      type: dateFilter,
      externalFilterGroup: 'productionFilter',
      id: 'filterPubDates',
      searchId: 'publishingDate',
      name: 'Edition - Publication date',
    },
    {
      type: combinationOptionFilter,
      externalFilterGroup: 'productionFilter',
      id: 'filterSeasons',
      searchId: 'seasonName',
      typeConfig: {
        keys: ['year', 'season'],
      },
      name: 'Edition - Season',
      options: [
        {
          id: 'year',
          name: 'Year',
          defaultValue: `${new Date().getFullYear()}`,
          metaDataId: 'seasonYears',
        },
        {
          id: 'season',
          name: 'Season',
          defaultValue: 'Spring',
          metaDataId: 'seasonPeriods',
        },
      ],
    },
    {
      type: contactFilter,
      externalFilterGroup: 'roleFilter',
      id: 'filterRoles',
      name: 'Edition - Role',
      typeConfig: {
        hideContact: true,
      },
      outputSerializer: getObjectsToListOutputSerializer('filterRoles', 'productionRoles', {
        propertyId: 'roleId',
      }),
    },
    {
      type: missingFilter,
      id: 'filterHasErpSupplierId',
      searchId: 'erpSupplierId',
      name: 'ERP supplier id',
    },
  ],
}
export const viewsConfig = {
  views: [
    {
      id: 'basic',
      label: 'Basic',
      Component: BasicSearchView,
      componentConfig: {
        HeaderComponent: ContactsViewHeader,
        ItemComponent: ContactsViewItem,
      },
    },
    {
      id: 'advanced',
      label: 'Advanced',
      Component: AdvancedSearchView,
      savedViewsId: 'savedviews-contact-contactssearch-v1',
      defaultColumns: [
        'isSecret',
        'firstName',
        'lastName',
        'simpleEmailAddresses',
        'simplePhoneNumbers',
      ],
      metaDataColumns: [
        {
          metaDataId: 'contactTextTypes',
          mapMetaDataToColumns: getTextColumns(TEXTS_CATEGORY),
        },
      ],
      columns: {
        contactId: {
          id: 'contactId',
          selectField: 'id',
          label: 'Contact id',
          width: 100,
          sortable: true,
          category: CONTACT_CATEGORY,
        },
        customerGroupName: {
          id: 'customerGroupName',
          selectField: 'customerGroupName',
          label: 'Customer group',
          width: 100,
          sortable: true,
          category: CONTACT_CATEGORY,
        },
        erpSupplierId: {
          id: 'erpSupplierId',
          selectField: 'erpSupplierId',
          label: 'Supplier ID ERP',
          width: 100,
          sortable: true,
          category: CONTACT_CATEGORY,
        },
        publishingHouseGroupId: {
          id: 'publishingHouseGroupId',
          selectField: 'publishingHouseGroupId',
          label: 'Publishing house group',
          width: 100,
          sortable: true,
          category: CONTACT_CATEGORY,
        },
        customerSubGroupName: {
          id: 'customerSubGroupName',
          selectField: 'customerSubGroupName',
          label: 'Customer subgroup',
          width: 100,
          sortable: true,
          category: CONTACT_CATEGORY,
        },
        firstName: {
          id: 'firstName',
          selectField: 'firstName',
          label: 'First name',
          width: 150,
          sortable: true,
          category: CONTACT_CATEGORY,
          cellType: LinkCell,
          cellConfig: {
            createLink: (contact) => `/contact/${contact.id}`,
          },
        },
        lastName: {
          id: 'lastName',
          selectField: 'lastName',
          label: 'Last name',
          width: 150,
          sortable: true,
          category: CONTACT_CATEGORY,
          cellType: LinkCell,
          cellConfig: {
            createLink: (contact) => `/contact/${contact.id}`,
          },
        },
        isCompanyContact: {
          id: 'isCompanyContact',
          selectField: 'isCompanyContact',
          label: 'Company contact',
          sortable: false,
          width: 140,
          category: CONTACT_CATEGORY,
          cellType: BooleanCell,
        },
        isUser: {
          id: 'isUser',
          selectField: 'isUser',
          label: 'Mockingbird user',
          width: 130,
          sortable: true,
          category: CONTACT_CATEGORY,
          cellType: BooleanCell,
        },
        simpleEmailAddresses: {
          id: 'simpleEmailAddresses',
          selectField: 'simpleEmailAddresses',
          label: 'Email',
          sortable: false,
          width: 200,
          category: CONTACT_CATEGORY,
          cellType: EmailListCell,
        },
        simplePhoneNumbers: {
          id: 'simplePhoneNumbers',
          selectField: 'simplePhoneNumbers',
          label: 'Phone number',
          sortable: false,
          width: 130,
          category: CONTACT_CATEGORY,
          cellType: PhoneNumberListCell,
        },
        organisationNumber: {
          id: 'organisationNumber',
          selectField: 'organisationNumber',
          label: 'Company registration number',
          sortable: false,
          width: 170,
          category: CONTACT_CATEGORY,
        },
        personalNumber: {
          id: 'personalNumber',
          selectField: 'personalNumber',
          label: 'Social security number',
          sortable: false,
          width: 170,
          category: CONTACT_CATEGORY,
        },
        parentCompany: {
          id: 'parentCompany',
          selectField: 'parentCompany',
          label: 'Company',
          width: 150,
          sortable: true,
          category: CONTACT_CATEGORY,
        },
        gdprSecuredNotes: {
          id: 'gdprSecuredNotes',
          selectField: 'gdprSecuredNotes',
          label: 'Notes',
          sortable: false,
          width: 250,
          category: CONTACT_CATEGORY,
        },
        isSecret: {
          id: 'isSecret',
          selectField: 'isSecret',
          label: 'SECRET',
          width: 80,
          sortable: true,
          category: CONTACT_CATEGORY,
          cellType: BooleanCell,
        },
        birthDay: {
          id: 'birthDay',
          selectField: 'birthDay',
          label: 'Date of birth',
          width: 150,
          sortable: true,
          category: CONTACT_CATEGORY,
          cellType: DateCell,
        },
        ...getAddressColumns(),
      },
    },
  ],
}
