import { get } from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { RootState } from 'Common/store/createStore'

import { filterMetadataByPublishingGroup, filterMetadataByPublishingHouse } from '../metadata/index'
import { getMetaDataSilent as getMetaDataSilentAction } from '../metadata/metaDataActions'
import FormSelectField from './FormSelectField'

type Props = {
  defaultMetaDataField?: string
  metaDataField: string
  publishingHouseGroupId?: string
  publishingHouseId?: string
}

const mapState = (state: RootState, props: Props) => {
  const { publishingHouseGroupId, publishingHouseId } = props
  const loadedMetaData = get(`metaData.data`, state)
  const metaData = publishingHouseGroupId
    ? get(
        `${props.metaDataField}`,
        filterMetadataByPublishingGroup(loadedMetaData, publishingHouseGroupId),
      )
    : publishingHouseId
    ? get(
        `${props.metaDataField}`,
        filterMetadataByPublishingHouse(loadedMetaData, publishingHouseId),
      )
    : get(`metaData.data.${props.metaDataField}`, state)
  const defaultMetaData = props.defaultMetaDataField
    ? publishingHouseGroupId
      ? get(
          `${props.defaultMetaDataField}`,
          filterMetadataByPublishingGroup(loadedMetaData, publishingHouseGroupId),
        )
      : publishingHouseId
      ? get(
          `${props.defaultMetaDataField}`,
          filterMetadataByPublishingHouse(loadedMetaData, publishingHouseId),
        )
      : get(`metaData.data.${props.defaultMetaDataField}`, state)
    : undefined
  return {
    metaData: metaData,
    defaultMetaData: defaultMetaData,
  }
}
const connector = connect(mapState, {
  getMetaDataSilent: getMetaDataSilentAction,
})
type PropsFromRedux = ConnectedProps<typeof connector>

export class FormMetaDataSearch extends Component<Props & PropsFromRedux> {
  UNSAFE_componentWillMount() {
    const { metaData, metaDataField, defaultMetaDataField, getMetaDataSilent } = this.props
    if (metaData) return
    const metaDataToLoad = defaultMetaDataField
      ? [defaultMetaDataField, metaDataField]
      : [metaDataField]
    getMetaDataSilent(metaDataToLoad)
  }

  render() {
    const {
      metaData,
      metaDataField,
      getMetaDataSilent,
      publishingHouseId,
      publishingHouseGroupId,
      defaultMetaData,
      defaultMetaDataField,
      ...otherProps
    } = this.props
    if (!this.props.metaData && !this.props.defaultMetaData) return null
    const metaDataToUse = metaData && metaData.length !== 0 ? metaData : defaultMetaData
    const WrappedFormSelectField: any = FormSelectField
    return <WrappedFormSelectField {...otherProps} metaData={metaDataToUse} />
  }
}

export default connector(FormMetaDataSearch)
