import { getOr } from 'lodash/fp'

import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import { serializeSearchOutput } from 'Common/search/searchOutputSerializers'
import request from 'Common/utils/net/request'

import messages from '../messages'
import { getWork } from './workActions'

export const GET_PRODUCTION = 'GET_PRODUCTION'
export const GET_PRODUCTION_REQUEST = 'GET_PRODUCTION_REQUEST'
export const GET_PRODUCTION_SUCCESS = 'GET_PRODUCTION_SUCCESS'
export const GET_PRODUCTION_FAILURE = 'GET_PRODUCTION_FAILURE'
export const PATCH_PRODUCTION = 'PATCH_PRODUCTION'
export const PATCH_PRODUCTION_REQUEST = 'PATCH_PRODUCTION_REQUEST'
export const PATCH_PRODUCTION_SUCCESS = 'PATCH_PRODUCTION_SUCCESS'
export const PATCH_PRODUCTION_FAILURE = 'PATCH_PRODUCTION_FAILURE'
export const POST_PRODUCTION_ISBN = 'POST_PRODUCTION_ISBN'
export const POST_PRODUCTION_ISBN_REQUEST = 'POST_PRODUCTION_ISBN_REQUEST'
export const POST_PRODUCTION_ISBN_SUCCESS = 'POST_PRODUCTION_ISBN_SUCCESS'
export const POST_PRODUCTION_ISBN_FAILURE = 'POST_PRODUCTION_ISBN_FAILURE'
export const POST_PRODUCTION_NEWPRINT = 'POST_PRODUCTION_NEWPRINT'
export const POST_PRODUCTION_NEWPRINT_REQUEST = 'POST_PRODUCTION_NEWPRINT_REQUEST'
export const POST_PRODUCTION_NEWPRINT_SUCCESS = 'POST_PRODUCTION_NEWPRINT_SUCCESS'
export const POST_PRODUCTION_NEWPRINT_FAILURE = 'POST_PRODUCTION_NEWPRINT_FAILURE'
export const POST_PRODUCTION_ERP = 'POST_PRODUCTION_ERP'
export const POST_PRODUCTION_ERP_REQUEST = 'POST_PRODUCTION_ERP_REQUEST'
export const POST_PRODUCTION_ERP_SUCCESS = 'POST_PRODUCTION_ERP_SUCCESS'
export const POST_PRODUCTION_ERP_FAILURE = 'POST_PRODUCTION_ERP_FAILURE'
export const PUT_PRODUCTION_SHAREDINFO = 'PUT_PRODUCTION_SHAREDINFO'
export const PUT_PRODUCTION_SHAREDINFO_REQUEST = 'PUT_PRODUCTION_SHAREDINFO_REQUEST'
export const PUT_PRODUCTION_SHAREDINFO_SUCCESS = 'PUT_PRODUCTION_SHAREDINFO_SUCCESS'
export const PUT_PRODUCTION_SHAREDINFO_FAILURE = 'PUT_PRODUCTION_SHAREDINFO_FAILURE'
export const POST_PRODUCTION_CANCEL = 'POST_PRODUCTION_CANCEL'
export const POST_PRODUCTION_CANCEL_REQUEST = 'POST_PRODUCTION_CANCEL_REQUEST'
export const POST_PRODUCTION_CANCEL_SUCCESS = 'POST_PRODUCTION_CANCEL_SUCCESS'
export const POST_PRODUCTION_CANCEL_FAILURE = 'POST_PRODUCTION_CANCEL_FAILURE'
export const POST_PRODUCTION_ACTIVATE = 'POST_PRODUCTION_ACTIVATE'
export const POST_PRODUCTION_ACTIVATE_REQUEST = 'POST_PRODUCTION_ACTIVATE_REQUEST'
export const POST_PRODUCTION_ACTIVATE_SUCCESS = 'POST_PRODUCTION_ACTIVATE_SUCCESS'
export const POST_PRODUCTION_ACTIVATE_FAILURE = 'POST_PRODUCTION_ACTIVATE_FAILURE'
export const POST_PRODUCTION_CHANGEDATE = 'POST_PRODUCTION_CHANGEDATE'
export const POST_PRODUCTION_CHANGEDATE_REQUEST = 'POST_PRODUCTION_CHANGEDATE_REQUEST'
export const POST_PRODUCTION_CHANGEDATE_SUCCESS = 'POST_PRODUCTION_CHANGEDATE_SUCCESS'
export const POST_PRODUCTION_CHANGEDATE_FAILURE = 'POST_PRODUCTION_CHANGEDATE_FAILURE'
export const POST_PRODUCTION_PUBLISH = 'POST_PRODUCTION_PUBLISH'
export const POST_PRODUCTION_PUBLISH_REQUEST = 'POST_PRODUCTION_PUBLISH_REQUEST'
export const POST_PRODUCTION_PUBLISH_SUCCESS = 'POST_PRODUCTION_PUBLISH_SUCCESS'
export const POST_PRODUCTION_PUBLISH_FAILURE = 'POST_PRODUCTION_PUBLISH_FAILURE'
export const POST_PRODUCTION_UNPUBLISH = 'POST_PRODUCTION_UNPUBLISH'
export const POST_PRODUCTION_UNPUBLISH_REQUEST = 'POST_PRODUCTION_UNPUBLISH_REQUEST'
export const POST_PRODUCTION_UNPUBLISH_SUCCESS = 'POST_PRODUCTION_UNPUBLISH_SUCCESS'
export const POST_PRODUCTION_UNPUBLISH_FAILURE = 'POST_PRODUCTION_UNPUBLISH_FAILURE'
export const POST_PRODUCTION_COPY_TECHNICAL = 'POST_PRODUCTION_COPY_TECHNICAL'
export const POST_PRODUCTION_COPY_TECHNICAL_REQUEST = 'POST_PRODUCTION_COPY_TECHNICAL_REQUEST'
export const POST_PRODUCTION_COPY_TECHNICAL_SUCCESS = 'POST_PRODUCTION_COPY_TECHNICAL_SUCCESS'
export const POST_PRODUCTION_COPY_TECHNICAL_FAILURE = 'POST_PRODUCTION_COPY_TECHNICAL_FAILURE'
export const POST_PRODUCTION_CHANGE_BINDINGCODE = 'POST_PRODUCTION_CHANGE_BINDINGCODE'
export const POST_PRODUCTION_CHANGE_BINDINGCODE_REQUEST =
  'POST_PRODUCTION_CHANGE_BINDINGCODE_REQUEST'
export const POST_PRODUCTION_CHANGE_BINDINGCODE_SUCCESS =
  'POST_PRODUCTION_CHANGE_BINDINGCODE_SUCCESS'
export const POST_PRODUCTION_CHANGE_BINDINGCODE_FAILURE =
  'POST_PRODUCTION_CHANGE_BINDINGCODE_FAILURE'
export const POST_PRODUCTIONS_PUBLISH = 'POST_PRODUCTIONS_PUBLISH'
export const POST_PRODUCTIONS_PUBLISH_REQUEST = 'POST_PRODUCTIONS_PUBLISH_REQUEST'
export const POST_PRODUCTIONS_PUBLISH_SUCCESS = 'POST_PRODUCTIONS_PUBLISH_SUCCESS'
export const POST_PRODUCTIONS_PUBLISH_FAILURE = 'POST_PRODUCTIONS_PUBLISH_FAILURE'
export const POST_PRODUCTIONS_LISTING_CODE = 'POST_PRODUCTIONS_LISTING_CODE'
export const POST_PRODUCTIONS_LISTING_CODE_REQUEST = 'POST_PRODUCTIONS_LISTING_CODE_REQUEST'
export const POST_PRODUCTIONS_LISTING_CODE_SUCCESS = 'POST_PRODUCTIONS_LISTING_CODE_SUCCESS'
export const POST_PRODUCTIONS_LISTING_CODE_FAILURE = 'POST_PRODUCTIONS_LISTING_CODE_FAILURE'
export const POST_RESEND_DIGITAL_META_DATA = 'POST_RESEND_DIGITAL_META_DATA'
export const POST_RESEND_DIGITAL_META_DATA_REQUEST = 'POST_RESEND_DIGITAL_META_DATA_REQUEST'
export const POST_RESEND_DIGITAL_META_DATA_SUCCESS = 'POST_RESEND_DIGITAL_META_DATA_SUCCESS'
export const POST_RESEND_DIGITAL_META_DATA_FAILURE = 'POST_RESEND_DIGITAL_META_DATA_FAILURE'
export const POST_PRODUCTIONS_MOVE = 'POST_PRODUCTIONS_MOVE'
export const POST_PRODUCTIONS_MOVE_REQUEST = 'POST_PRODUCTIONS_MOVE_REQUEST'
export const POST_PRODUCTIONS_MOVE_SUCCESS = 'POST_PRODUCTIONS_MOVE_SUCCESS'
export const POST_PRODUCTIONS_MOVE_FAILURE = 'POST_PRODUCTIONS_MOVE_FAILURE'
export const GET_PRINT_COSTS = 'GET_PRINT_COSTS'
export const GET_PRINT_COSTS_REQUEST = 'GET_PRINT_COSTS_REQUEST'
export const GET_PRINT_COSTS_SUCCESS = 'GET_PRINT_COSTS_SUCCESS'
export const GET_PRINT_COSTS_FAILURE = 'GET_PRINT_COSTS_FAILURE'
export const PUT_PRINT_COSTS = 'PUT_PRINT_COSTS'
export const PUT_PRINT_COSTS_REQUEST = 'PUT_PRINT_COSTS_REQUEST'
export const PUT_PRINT_COSTS_SUCCESS = 'PUT_PRINT_COSTS_SUCCESS'
export const PUT_PRINT_COSTS_FAILURE = 'PUT_PRINT_COSTS_FAILURE'
export const CLEAR_PRODUCTIONS_MOVE_RESULTS = 'CLEAR_PRODUCTIONS_MOVE_RESULTS'
const GET_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
  messages,
)
export const getProduction = (editionId: number) => (dispatch) =>
  dispatch({
    type: GET_PRODUCTION,
    meta: {
      editionId,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.work(`/v2/editions/${editionId}`),
        errorMessages: GET_PRODUCTION_ERROR_MESSAGES,
      }),
    },
  })
const PATCH_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_BAD_ERP_STATUS_CODE',
    'PRODUCTION_BINDINGCODE_NOT_MATCHING_TECHNICALPRODUCTIONTYPE',
    'PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND',
    'PRODUCTION_ARTICLEGROUP_COSTCENTER_MISMATCH',
    'PRODUCTION_BR_CATEGORY_SUBCATEGORY_MISMATCH',
    'COSTPRICE_IS_READONLY_FOR_POSTED_DELIVERYSPECIFICATIONS',
    'PRINT_HAS_INVALID_QUANTITY_ORDERED',
  ],
  messages,
)
export const patchProduction = (workId: number, productionId: number, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_PRODUCTION,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        method: 'PATCH',
        url: services.work(`/v1/works/${workId}/productions/${productionId}`),
        body: patchSet,
        errorMessages: PATCH_PRODUCTION_ERROR_MESSAGES,
      }),
    },
  })
const POST_PRODUCTION_ISBN_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_FORBIDDEN_ISBN',
    'PRODUCTION_ALREADY_HAS_ISBN',
    'PRODUCTION_MISSING_BRAND',
    'PRODUCTION_INVALID_BRAND',
    'PRODUCTION_COULD_NOT_FIND_NEW_IFCD',
  ],
  messages,
)
export const postIsbn = (workId: number, productionId: number, body: any) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_ISBN,
    meta: {
      workId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/setisbn`),
        method: 'POST',
        body: body,
        errorMessages: POST_PRODUCTION_ISBN_ERROR_MESSAGES,
      }),
    },
  })
const POST_PRODUCTION_CHANGEDATE_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_PUBLISHING_DATE_MUST_BE_WORKDAY',
    'PRODUCTION_FORBIDDEN_CONNECT_TO_ERP',
    'PRODUCTION_MISSING_PUBLISHING_DATE_OR_REVIEW_DATE',
    'PRODUCTION_BAD_ERP_STATUS_CODE',
    'PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND',
    'PRODUCTION_CANNOT_UPDATE_DATES_MULTIPLE_PRINTS',
    'PRODUCTION_CANNOT_UPDATE_PUBLISHING_DATE_AND_REVIEW_DATE_SIMULTANEOUSLY',
    'PRODUCTION_CANNOT_UPDATE_PUBLISHING_DATE_ON_SHAREDINFO_COPY',
    'PRODUCTION_CANNOT_FIND_CULTUREINFO',
  ],
  messages,
)
export const postChangeDates = (workId: number, productionId: number, body) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_CHANGEDATE,
    meta: {
      workId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/changedates`),
        method: 'POST',
        body,
        errorMessages: POST_PRODUCTION_CHANGEDATE_ERROR_MESSAGES,
      }),
    },
  })
const POST_PRODUCTION_NEWPRINT_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_FORBIDDEN_NEW_PRINT',
    'PRODUCTION_MISSING_ISBN',
  ],
  messages,
)
export const postNewPrint = (workId: number, productionId: number, body) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_NEWPRINT,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        method: 'POST',
        url: services.work(`/v1/works/${workId}/productions/${productionId}/newprint`),
        body,
        errorMessages: POST_PRODUCTION_NEWPRINT_ERROR_MESSAGES,
      }),
    },
  })
const POST_PRODUCTION_ERP_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_FORBIDDEN_CONNECT_TO_ERP',
    'PRODUCTION_ALREADY_CONNECTED_TO_ERP',
    'PRODUCTION_MISSING_WORK',
    'PRODUCTION_MISSING_ISBN',
    'PRODUCTION_MISSING_PUBLISHING_DATE',
    'PRODUCTION_MISSING_WRITE_OFF_RULE',
    'PRODUCTION_MISSING_BINDING_CODE',
    'PRODUCTION_MISSING_TAX_CODE',
    'PRODUCTION_MISSING_ARTICLE_GROUP',
  ],
  messages,
)
export const postToERP = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_ERP,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        method: 'POST',
        url: services.work(`/v1/works/${workId}/productions/${productionId}/connectToERP`),
        errorMessages: POST_PRODUCTION_ERP_ERROR_MESSAGES,
        body: undefined,
      }),
    },
  })

const PUT_PRODUCTION_SHAREDINFO_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_BAD_ERP_STATUS_CODE',
    'PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND',
    'PRODUCTION_SHARED_INFO_COPY_IS_ALREADY_MASTER',
    'PRODUCTION_SHARED_INFO_MASTER_IS_ALREADY_COPY',
  ],
  messages,
)
export const putToSharedinfo = (workId: number, productionId: number, body) => (dispatch) =>
  dispatch({
    type: PUT_PRODUCTION_SHAREDINFO,
    meta: {
      workId,
      productionId,
      body,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/sharedInfo`),
        method: 'PUT',
        body: body,
        errorMessages: PUT_PRODUCTION_SHAREDINFO_ERROR_MESSAGES,
      }).then(() => dispatch(getWork(workId))),
    },
  })
const POST_PRODUCTION_CANCEL_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_FORBIDDEN_CONNECT_TO_ERP',
    'PRODUCTION_ALREADY_CANCELLED',
    'PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND',
    'PRODUCTION_BAD_ERP_STATUS_CODE',
  ],
  messages,
)
export const postCancelProduction = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_CANCEL,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/cancel`),
        method: 'POST',
        errorMessages: POST_PRODUCTION_CANCEL_ERROR_MESSAGES,
        body: undefined,
      }),
    },
  })
const POST_PRODUCTION_ACTIVATE_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_NOT_CANCELLED',
    'PRODUCTION_BAD_ERP_STATUS_CODE',
    'PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND',
  ],
  messages,
)
export const postActivateProduction = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_ACTIVATE,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/activate`),
        method: 'POST',
        errorMessages: POST_PRODUCTION_ACTIVATE_ERROR_MESSAGES,
        body: undefined,
      }),
    },
  })
const POST_PRODUCTION_PUBLISH_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_MISSING_REVIEW_DATE',
    'PRODUCTION_MISSING_THEMA',
    'PRODUCTION_MISSING_RESELLER_PRICE',
    'PRODUCTION_NOT_CONNECTED_TO_ERP',
    'PRODUCTION_CANNOT_PUBLISH_ALREADY_PUBLISHED_PRODUCTION',
    'PRODUCTION_FORBIDDEN_PUBLISH',
  ],
  messages,
)
export const postPublishProduction = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_PUBLISH,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/publish`),
        method: 'POST',
        errorMessages: POST_PRODUCTION_PUBLISH_ERROR_MESSAGES,
        body: undefined,
      }),
    },
  })
const POST_PRODUCTION_UNPUBLISH_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_CANNOT_UNPUBLISH_NOT_PUBLISHED_PRODUCTION',
    'PRODUCTION_FORBIDDEN_PUBLISH',
  ],
  messages,
)
export const postUnpublishProduction = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_UNPUBLISH,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/unpublish`),
        method: 'POST',
        errorMessages: POST_PRODUCTION_UNPUBLISH_ERROR_MESSAGES,
        body: undefined,
      }),
    },
  })
const POST_PRODUCTION_COPY_TECHNICAL_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'CANT_COPY_FROM_SAME_PRODUCTION',
    'PRODUCTION_BINDINGCODE_NOT_MATCHING_TECHNICALPRODUCTIONTYPE',
    'FROM_PRODUCTION_MISSING_TECHNICAL_PRODUCTION_TYPE',
    'INSIDE_FORMAT_MISMATCH',
  ],
  messages,
)
export const postCopyTechnicalProduction =
  (workId: number, productionId: number, fromProductionId: number) => (dispatch) =>
    dispatch({
      type: POST_PRODUCTION_COPY_TECHNICAL,
      meta: {
        workId,
        productionId,
      },
      payload: {
        promise: request({
          url: services.work(
            `/v1/works/${workId}/productions/${productionId}/copytechnicalproduction`,
          ),
          method: 'POST',
          body: {
            fromId: fromProductionId,
          },
          errorMessages: POST_PRODUCTION_COPY_TECHNICAL_ERROR_MESSAGES,
        }),
      },
    })
const POST_PRODUCTION_CHANGE_BINDINGCODE_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRODUCTION_CANNOT_CHANGE_TO_SAME_BINDINGCODE',
    'PRODUCTION_CANNOT_CHANGE_TO_BINDING_CODE_OF_DIFFERENT_TYPE',
  ],
  messages,
)
export const postChangeBindingCode =
  (workId: number, productionId: number, bindingCodeId) => (dispatch) =>
    dispatch({
      type: POST_PRODUCTION_CHANGE_BINDINGCODE,
      meta: {
        workId,
        productionId,
        bindingCodeId,
      },
      payload: {
        promise: request({
          url: services.work(`/v1/works/${workId}/productions/${productionId}/changebindingcode`),
          method: 'POST',
          body: {
            bindingCodeId,
          },
          errorMessages: POST_PRODUCTION_CHANGE_BINDINGCODE_ERROR_MESSAGES,
        }),
      },
    })
export const postResendDigitalMetaData = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: POST_RESEND_DIGITAL_META_DATA,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        url: services.work(
          `/v1/works/${workId}/productions/${productionId}/resenddigitalinformationtoallretailers`,
        ),
        method: 'POST',
        body: undefined,
      }),
    },
  })

export const postPublishProductions = (searchConfig) => (dispatch, getState) => {
  const searchOptions = getOr({}, `search[${searchConfig.id}].searchOptions`, getState())
  const itemsTotalCount = getOr({}, `search[${searchConfig.id}].paging.itemsTotalCount`, getState())
  const searchQuery = serializeSearchOutput(searchOptions, searchConfig)
  return dispatch({
    type: POST_PRODUCTIONS_PUBLISH,
    meta: {
      searchConfig,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/productions/publish`),
        method: 'POST',
        body: {
          search: decodeURIComponent(searchQuery),
          itemsTotalCount,
        },
      }),
    },
  })
}

export const postListingCodeProductions = (searchConfig, listingCodeId) => (dispatch, getState) => {
  const searchOptions = getOr({}, `search[${searchConfig.id}].searchOptions`, getState())
  const itemsTotalCount = getOr({}, `search[${searchConfig.id}].paging.itemsTotalCount`, getState())
  const searchQuery = serializeSearchOutput(searchOptions, searchConfig)
  return dispatch({
    type: POST_PRODUCTIONS_LISTING_CODE,
    meta: {
      searchConfig,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/productions/listingCodes`),
        method: 'POST',
        body: {
          search: decodeURIComponent(searchQuery),
          itemsTotalCount,
          listingCodeId,
        },
      }),
    },
  })
}

export const postMoveProductions =
  (sourceWorkId, destinationWorkId, productionId: number) => (dispatch) =>
    dispatch({
      type: POST_PRODUCTIONS_MOVE,
      payload: {
        promise: request({
          url: services.work(`/v1/works/${sourceWorkId}/moveproductions`),
          method: 'POST',
          body: {
            destinationworkid: destinationWorkId,
            productionid: productionId,
          },
        }),
      },
    })
export const getPrintCosts =
  (workId: number, productionId: number, printId: number) => (dispatch) =>
    dispatch({
      type: GET_PRINT_COSTS,
      payload: {
        data: workId,
        promise: request({
          method: 'GET',
          url: services.work(
            `/v1/works/${workId}/productions/${productionId}/prints/${printId}/costs`,
          ),
        }),
      },
    })
export const putPrintCosts =
  (workId: number, productionId: number, printId: number, printCosts) => (dispatch) =>
    dispatch({
      type: PUT_PRINT_COSTS,
      payload: {
        data: workId,
        promise: request({
          method: 'PUT',
          url: services.work(
            `/v1/works/${workId}/productions/${productionId}/prints/${printId}/costs`,
          ),
          body: printCosts,
        }),
      },
    })
export const clearMoveProductionResults = () => (dispatch) => {
  return dispatch({
    type: CLEAR_PRODUCTIONS_MOVE_RESULTS,
    payload: {},
  })
}
