import { Prompt } from 'react-router'

export default function FormPrompt({ dirty }: { dirty: boolean }) {
  return (
    <Prompt
      when={dirty}
      message="You have unsaved information, are you sure you want to leave this page?"
    />
  )
}
