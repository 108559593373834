import { AppDispatch } from 'Common/store/createStore'

import request from '../utils/net/request'
import { buildQuery } from './queryBuilder'

export const GET_MULTI_SEARCH = 'GET_MULTI_SEARCH'
export const GET_MULTI_SEARCH_REQUEST = 'GET_MULTI_SEARCH_REQUEST'
export const GET_MULTI_SEARCH_SUCCESS = 'GET_MULTI_SEARCH_SUCCESS'
export const GET_MULTI_SEARCH_FAILURE = 'GET_MULTI_SEARCH_FAILURE'
export type SearchQuery = {
  endpoint: string
  oDataQuery: any
  searchFields: string
}
export const getMultiSearch =
  ({ searchQueries = [] }: { searchQueries: SearchQuery[] }) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: GET_MULTI_SEARCH,
      meta: {
        searchQueries,
        startTime: Date.now(),
      },
      payload: {
        promise: Promise.all(
          searchQueries.map((searchQuery) =>
            request({
              method: 'GET',
              url: `${searchQuery.endpoint}${buildQuery(searchQuery)}`,
            }),
          ),
        ),
      },
    })
  }
