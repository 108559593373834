import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_PRODUCTION_PRINT_CHANGES = 'GET_PRODUCTION_PRINT_CHANGES'
export const GET_PRODUCTION_PRINT_CHANGES_REQUEST = 'GET_PRODUCTION_PRINT_CHANGES_REQUEST'
export const GET_PRODUCTION_PRINT_CHANGES_SUCCESS = 'GET_PRODUCTION_PRINT_CHANGES_SUCCESS'
export const GET_PRODUCTION_PRINT_CHANGES_FAILURE = 'GET_PRODUCTION_PRINT_CHANGES_FAILURE'
const GET_PRODUCTION_PRINT_CHANGES_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
  messages,
)
export const getProductionPrintChanges = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: GET_PRODUCTION_PRINT_CHANGES,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.work(`/v1/works/${workId}/productions/${productionId}/printchanges`),
        errorMessages: GET_PRODUCTION_PRINT_CHANGES_ERROR_MESSAGES,
      }),
    },
  })
