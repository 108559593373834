import {
  debounce,
  difference,
  getOr,
  isEmpty,
  isEqual,
  isNil,
  noop,
  sortBy,
  union,
  uniqWith,
} from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import Grid from '@mui/material/Unstable_Grid2'

import { browserHistory } from 'Common/base/history'
import { PageWrapper, MainWrapper } from 'Common/core'
import { RootState } from 'Common/store/createStore'

import { filterMetadataByPublishingGroups } from '../metadata/index'
import { postSetting as postSettingAction } from '../settings/settingsActions'
import { requestStatus } from '../utils/net/statuses'
import SavedSearchRemoval from './SavedSearchRemoval'
import SearchControlFilters from './SearchControlFilters'
import SearchControlHeader from './SearchControlHeader'
import SearchControlResults from './SearchControlResults'
import SearchControlViews from './SearchControlViews'
import SearchSaving from './SearchSaving'
import { getSavedSearches, isSavedSearch, removeSavedSearch, saveSearch } from './savedSearches'
import {
  getSearch as getSearchAction,
  updateSearchOptions as updateSearchOptionsAction,
} from './searchActions'
import { parseSearchInput } from './searchInputParsers'
import {
  toggleAll as toggleAllAction,
  toggleSingle as toggleSingleAction,
} from './selectSearchActions'
import { getSearchUrl } from './urls'

export const RESET = '::##RESET##::'

const getEnabledFilters = (filters, searchOptions) =>
  filters.filter((filter) => Array.isArray(searchOptions[filter.id]))

const getTotalFilters = (enabledFilters, searchOptions) =>
  enabledFilters
    ? enabledFilters
        .map((filter) => searchOptions[filter.id])
        .reduce((acc, cur) => acc + cur.length, 0)
    : 0

const getActiveView = (viewsConfig, searchOptions) => {
  const enabledViews = getOr([], 'views', viewsConfig).filter((view) => !view.disabled)
  if (enabledViews.length === 0) return null

  if (searchOptions.view) {
    const requestedView = enabledViews.find((view) => view.id === searchOptions.view)
    if (requestedView) return requestedView
  }

  return enabledViews[0]
}

const isMissingRequiredSelectFields = (view, searchOptions) =>
  view.requiredSelectFields
    ? difference(view.requiredSelectFields, searchOptions.selectFields).length > 0
    : false

const getEnsuredSelectFields = (view, searchOptions) => {
  const { selectFields } = searchOptions
  if (!view.requiredSelectFields) return selectFields

  const _difference = difference(view.requiredSelectFields, selectFields)

  if (_difference.length === 0) return selectFields
  const wrapped_difference: any = _difference
  return union(wrapped_difference, selectFields)
}

const getSearchMetaData = (searchConfig, metaData) =>
  !searchConfig.searchMetaDataId || !metaData.searchConfigurations
    ? null
    : metaData.searchConfigurations.find((_) => _.id === searchConfig.searchMetaDataId)

type Props = {
  searchConfig: any
  // {
  //   savedSearchesId?: string
  //   searchLocation?: string
  //   hideResultsInfo?: boolean
  //   filters?: {
  //     id?: string
  //     name?: string
  //     type?: {
  //       Component: React.ElementType
  //     }
  //   }[]
  //   staticControls?: boolean
  // }
  // searchState: {
  //   searchOptions: Record<string, unknown>
  //   results: unknown[]
  //   paging?: Record<string, unknown>
  //   status?: string
  // }
  // language?: string
  // metaData: MetaData
  // auth: Record<string, unknown>
  // metaDataStatus: Record<string, unknown>
  // location: Location
  searchLabel?: string
  viewsConfig?: any
  viewProps?: Record<string, unknown>
}

const mapState = (state: RootState, props: Props) => {
  const { search, metaData, settings, auth } = state
  const { searchConfig } = props
  const publishingHouseGroups = getOr([], 'data.opus_user.publishingHouseGroups', auth).map(
    ({ id }) => id,
  )
  const languageCodes = metaData.data.publishingHouseGroups
    .filter((phg) => publishingHouseGroups.includes(phg.id))
    .map((phg) => {
      const { cultureInfo } = phg
      const lang = cultureInfo.split('-')
      return lang ? lang[0] : false
    })
  const firstLanguage = languageCodes[0]
  return {
    searchState: search[searchConfig.id] || {},
    metaData: filterMetadataByPublishingGroups(metaData.data, publishingHouseGroups),
    auth,
    metaDataStatus: metaData.status,
    location: browserHistory.location,
    language: firstLanguage,
    settings: settings.data,
  }
}

const mapDisp = {
  getSearch: getSearchAction,
  toggleAll: toggleAllAction,
  toggleSingle: toggleSingleAction,
  updateSearchOptions: updateSearchOptionsAction,
  postSetting: postSettingAction,
}

const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

type State = {
  savedSearches?: any
  lastSavedSearchName?: any
  expandedFilter?: any
  showRemoveSearchModal?: boolean
  showSaveSearchModal?: boolean
  isActivatingSavedSearch?: boolean
  showFilterSelection?: boolean
  saveSearchName: any
}

class SearchControl extends Component<Props & PropsFromRedux, State> {
  static defaultProps = {
    searchLabel: 'Search',
  }

  initialSearchOptions: any

  debouncedGetSearch: any

  constructor(props) {
    super(props)
    this.initialSearchOptions = getOr({}, 'searchState.searchOptions', props)
    const { searchConfig, searchState, settings, getSearch } = props
    const { filters, savedSearchesId, staticControls = false } = searchConfig
    const { searchOptions } = searchState
    this.debouncedGetSearch = debounce(500, getSearch)
    const enabledFilters = getEnabledFilters(filters, searchOptions)
    this.state = {
      savedSearches: savedSearchesId ? getSavedSearches(searchConfig, settings) : [],
      expandedFilter: staticControls && enabledFilters.length > 0 ? enabledFilters[0].id : null,
      showRemoveSearchModal: false,
      showSaveSearchModal: false,
      isActivatingSavedSearch: false,
      showFilterSelection: false,
      saveSearchName: null,
      lastSavedSearchName: null,
    }
  }

  UNSAFE_componentWillMount() {
    const { searchConfig, viewsConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    const activeView = getActiveView(viewsConfig, searchOptions)

    if (isMissingRequiredSelectFields(activeView, searchOptions)) {
      updateSearchOptions(searchConfig, {
        ...searchOptions,
        selectFields: getEnsuredSelectFields(activeView, searchOptions),
      })
    }

    getSearch(searchConfig)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchConfig, viewsConfig, getSearch, updateSearchOptions } = nextProps
    if (!searchConfig.searchLocation) return
    const currentUrl = getSearchUrl(this.props.searchState, searchConfig)
    const newUrl = getSearchUrl(nextProps.searchState, searchConfig)

    if (currentUrl !== newUrl) {
      browserHistory.push(newUrl)
      return
    }

    const locationUrl = `${nextProps.location.pathname}${nextProps.location.search}`

    if (nextProps.location.search !== this.props.location.search && locationUrl !== newUrl) {
      const newSearchOptions = parseSearchInput(nextProps.location.query, searchConfig)
      const newViewId = getOr(null, 'view', newSearchOptions)
      const newView = isNil(newViewId)
        ? null
        : getOr([], 'views', viewsConfig).find((view) => view.id === newViewId)
      const newViewIsMissingSelectFields =
        !isNil(newView) && isMissingRequiredSelectFields(newView, newSearchOptions)
      updateSearchOptions(
        searchConfig,
        nextProps.location.search !== '' && nextProps.location.search !== '?'
          ? {
              ...newSearchOptions,
              selectFields: newViewIsMissingSelectFields
                ? getEnsuredSelectFields(newView, newSearchOptions)
                : newSearchOptions.selectFields,
            }
          : this.initialSearchOptions,
      )
      getSearch(searchConfig)
    }
  }

  componentDidUpdate() {
    const {
      searchState: { searchOptions },
    } = this.props
    const { savedSearches, lastSavedSearchName } = this.state
    const matchedSavedSearch = savedSearches.find((search) =>
      isSavedSearch(search.searchOptions, searchOptions),
    )

    if (matchedSavedSearch && matchedSavedSearch.name !== lastSavedSearchName) {
      this.setState({
        lastSavedSearchName: matchedSavedSearch.name,
      })
    }
  }

  setSearchCombinator = (combinator) => {
    const { searchConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    updateSearchOptions(searchConfig, { ...searchOptions, combinator })
    getSearch(searchConfig)
  }

  setActiveView = (viewId) => {
    const { searchConfig, viewsConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    const activeView = getOr([], 'views', viewsConfig).find((view) => view.id === viewId)
    const updateSearch = isMissingRequiredSelectFields(activeView, searchOptions)
    updateSearchOptions(searchConfig, {
      ...searchOptions,
      selectFields: getEnsuredSelectFields(activeView, searchOptions),
      view: activeView.id,
      offset: updateSearch ? 0 : searchOptions.offset,
    })
    if (!updateSearch) return
    getSearch(searchConfig)
  }

  resetSearch = () => {
    const { searchConfig, searchState, updateSearchOptions, getSearch } = this.props
    const { searchOptions } = searchState
    this.setState(
      {
        expandedFilter: null,
        isActivatingSavedSearch: true,
        lastSavedSearchName: null,
      },
      () => {
        updateSearchOptions(searchConfig, {
          query: '',
          queryField: 'all',
          orderBy: searchOptions.orderBy,
          selectFields: searchOptions.selectFields,
          view: searchOptions.view,
        })
        getSearch(searchConfig).then(() =>
          this.setState({
            lastSavedSearchName: null,
            isActivatingSavedSearch: false,
          }),
        )
      },
    )
  }

  activateSavedSearch = (searchName) => {
    const { searchConfig, searchState, updateSearchOptions, getSearch } = this.props
    const { savedSearches } = this.state
    const { searchOptions } = searchState

    if (searchName === RESET) {
      this.resetSearch()
      return
    }

    const savedSearch = savedSearches.find((search) => search.name === searchName)
    if (!savedSearch) return
    this.setState(
      {
        expandedFilter: null,
        isActivatingSavedSearch: true,
        lastSavedSearchName: searchName,
      },
      () => {
        updateSearchOptions(searchConfig, {
          ...savedSearch.searchOptions,
          selectFields: searchOptions.selectFields,
          view: searchOptions.view,
        })
        getSearch(searchConfig).then(() =>
          this.setState({
            isActivatingSavedSearch: false,
          }),
        )
      },
    )
  }

  disableFilter = (event, filterId) => {
    const { searchConfig, searchState, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    event.stopPropagation()
    this.handleToggleFilterExpand(filterId, false)

    if (isEmpty(searchOptions[filterId])) {
      updateSearchOptions(searchConfig, { ...searchOptions, [filterId]: null })
      return
    }

    this.updateFilter(filterId, null)
  }

  toggleFilterSelection = () =>
    this.setState((state) => ({
      showFilterSelection: !state.showFilterSelection,
    }))

  handleToggleFilterExpand = (filterId, expanded) => {
    this.setState({
      expandedFilter: expanded ? filterId : null,
      showFilterSelection: true,
    })
  }

  handleSaveSearch = (name) => {
    const { searchConfig, searchState, settings, postSetting } = this.props
    const { searchOptions } = searchState
    const updatedSearches = saveSearch(searchConfig, settings, searchOptions, name, postSetting)
    this.setState(
      {
        savedSearches: updatedSearches,
        lastSavedSearchName: name,
      },
      () => {
        this.activateSavedSearch(name)
        this.toggleSaveSearchModal()
      },
    )
  }

  handleRemoveSavedSearch = (name) => {
    const { searchConfig, settings, postSetting } = this.props
    const updatedSearches = removeSavedSearch(searchConfig, settings, name, postSetting)
    this.setState(
      {
        savedSearches: updatedSearches,
        lastSavedSearchName: null,
      },
      () => this.toggleRemoveSearchModal(),
    )
  }

  updateQuery = (event, query) => {
    const { searchConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    updateSearchOptions(searchConfig, { ...searchOptions, query, offset: 0 })
    getSearch(searchConfig)
  }

  updateQueryField = (event) => {
    const queryField = event.target.value
    const { searchConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    updateSearchOptions(searchConfig, { ...searchOptions, queryField, offset: 0 })
    getSearch(searchConfig)
  }

  updateFilter = (filterId, filterValue) => {
    const { searchConfig, searchState, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    const cleanedFilterValue = uniqWith(isEqual, filterValue)
    const anyChange = !isEqual(cleanedFilterValue, searchOptions[filterId])
    if (!anyChange) return
    updateSearchOptions(searchConfig, { ...searchOptions, [filterId]: filterValue, offset: 0 })
    this.debouncedGetSearch(searchConfig)
  }

  updateSorting = (orderBy) => {
    const { searchConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    updateSearchOptions(searchConfig, { ...searchOptions, orderBy, offset: 0 })
    getSearch(searchConfig)
  }

  toggleSingle = (productionId) => {
    const { searchConfig, toggleSingle } = this.props
    toggleSingle(productionId, searchConfig)
  }

  toggleAll = () => {
    const { searchConfig, toggleAll } = this.props
    toggleAll(searchConfig)
  }

  updateSelectFields = (updatedSelectFields) => {
    const { searchConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions } = searchState
    updateSearchOptions(searchConfig, {
      ...searchOptions,
      selectFields: updatedSelectFields,
      offset: 0,
    })
    getSearch(searchConfig)
  }

  loadNextPage = (callback = noop) => {
    const { searchConfig, searchState, getSearch, updateSearchOptions } = this.props
    const { searchOptions, paging } = searchState

    if (!paging || !paging.nextOffset) {
      callback()
      return
    }

    updateSearchOptions(searchConfig, { ...searchOptions, offset: paging.nextOffset })
    getSearch(searchConfig).then(callback)
  }

  toggleRemoveSearchModal = () =>
    this.setState((state) => ({
      showRemoveSearchModal: !state.showRemoveSearchModal,
    }))

  toggleSaveSearchModal = (searchName = null) =>
    this.setState((state) => ({
      showSaveSearchModal: !state.showSaveSearchModal,
      saveSearchName: searchName,
    }))

  render() {
    const {
      searchConfig,
      viewsConfig,
      searchState,
      viewProps,
      settings,
      metaData,
      auth,
      metaDataStatus,
      searchLabel,
      postSetting,
      language,
    } = this.props
    const { savedSearchesId, filters, hideResultsInfo } = searchConfig
    const { searchOptions, results, paging, status } = searchState
    const {
      savedSearches,
      expandedFilter,
      showRemoveSearchModal,
      showSaveSearchModal,
      isActivatingSavedSearch,
      showFilterSelection,
      lastSavedSearchName,
      saveSearchName,
    } = this.state
    const activeView = getActiveView(viewsConfig, searchOptions)
    const hideFilters = getOr(false, 'hideFilters', searchConfig)
    const showFilters = !hideFilters && metaDataStatus.getStatus === requestStatus.success
    const resultsCount = getOr(results.length, 'itemsTotalCount', paging)
    const selectedCount = getOr(0, 'selectedResult.length', searchState)
    const hasActions = !isNil(activeView.ActionsComponent)
    const enabledFilters = sortBy((_) => _.name, getEnabledFilters(filters, searchOptions))
    const availableFilters = sortBy((_: any) => _.name, difference(filters, enabledFilters))
    const totalFilters = getTotalFilters(enabledFilters, searchOptions)
    const matchedSavedSearch = savedSearches.find((search) =>
      isSavedSearch(search.searchOptions, searchOptions),
    )
    const searchMetaData = getSearchMetaData(searchConfig, metaData)
    return (
      <MainWrapper>
        <PageWrapper>
          <Grid container spacing={2} xs={12}>
            <Grid xs={12} className="hidden-print">
              <SearchControlHeader
                updateQuery={this.updateQuery}
                updateQueryField={this.updateQueryField}
                setSearchCombinator={this.setSearchCombinator}
                activateSavedSearch={this.activateSavedSearch}
                toggleSaveSearchModal={this.toggleSaveSearchModal}
                toggleRemoveSearchModal={this.toggleRemoveSearchModal}
                {...{
                  searchConfig,
                  searchOptions,
                  savedSearches,
                  matchedSavedSearch,
                  searchLabel,
                  isActivatingSavedSearch,
                  status,
                  lastSavedSearchName,
                }}
              />
            </Grid>
            {showFilters ? (
              <Grid xs={12} className="hidden-print">
                <SearchControlFilters
                  updateFilter={this.updateFilter}
                  handleToggleFilterExpand={this.handleToggleFilterExpand}
                  disableFilter={this.disableFilter}
                  toggleFilterSelection={this.toggleFilterSelection}
                  {...{
                    searchConfig,
                    searchOptions,
                    expandedFilter,
                    enabledFilters,
                    availableFilters,
                    metaData,
                    auth,
                    showFilterSelection,
                    totalFilters,
                  }}
                />
              </Grid>
            ) : null}
            {!hideResultsInfo ? (
              <Grid xs={12} className="hidden-print">
                <SearchControlResults
                  setActiveView={this.setActiveView}
                  toggleSingle={this.toggleSingle}
                  {...{
                    searchConfig,
                    viewsConfig,
                    activeView,
                    matchedSavedSearch,
                    resultsCount,
                    selectedCount,
                    hasActions,
                  }}
                />
              </Grid>
            ) : null}
            {savedSearchesId && showRemoveSearchModal ? (
              <Grid xs={12} className="hidden-print">
                <SavedSearchRemoval
                  savedSearch={matchedSavedSearch}
                  removeSearch={this.handleRemoveSavedSearch}
                  closeModal={this.toggleRemoveSearchModal}
                />
              </Grid>
            ) : null}
            {savedSearchesId && showSaveSearchModal ? (
              <Grid xs={12} className="hidden-print">
                <SearchSaving
                  saveSearch={this.handleSaveSearch}
                  closeModal={this.toggleSaveSearchModal}
                  searchName={saveSearchName}
                  {...{
                    savedSearches,
                  }}
                />
              </Grid>
            ) : null}
            <Grid xs={12} className="shrink-print">
              <SearchControlViews
                updateSorting={this.updateSorting}
                updateSelectFields={this.updateSelectFields}
                loadNextPage={this.loadNextPage}
                toggleSingle={this.toggleSingle}
                toggleAll={this.toggleAll}
                {...{
                  searchConfig,
                  viewsConfig,
                  searchState,
                  activeView,
                  viewProps,
                  metaData,
                  searchMetaData,
                  settings,
                  postSetting,
                  hasActions,
                  language,
                }}
              />
            </Grid>
          </Grid>
        </PageWrapper>
      </MainWrapper>
    )
  }
}
export default connector(SearchControl)
