import { isEqual } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'

import { TextField, MenuItem, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AddButton } from 'Common/components/buttons'
import { MetaData } from 'Common/metadata'

import FilterSelectionList from './FilterSelectionList'

const initDates = {
  minDate: DateTime.now(),
  maxDate: DateTime.now().plus({ months: 1 }).minus({ days: 1 }),
}

type Props = {
  updateFilter: (..._args: Array<any>) => any
  filter: {
    id: string
    type: {
      displayFormat: (..._args: Array<any>) => any
    }
    displayFormat: (..._args: Array<any>) => any
    typeConfig: Record<string, unknown>
  }
  active: any[]
  metaData: MetaData
  chipColor?: string
}

type State = {
  minDate: DateTime
  maxDate: DateTime
  showMaxDate: boolean
  activityId: string | null | undefined
  activityDateType: string
}

class TimeplanFilter extends Component<Props, State> {
  static defaultProps = {
    active: [],
  }

  state = { ...initDates, showMaxDate: false, activityId: null, activityDateType: 'Actual' }

  onAddOption = () => {
    const { filter, active, updateFilter } = this.props
    const { minDate, maxDate, showMaxDate, activityId, activityDateType } = this.state
    const newMinOptions = {
      min: minDate.toISODate(),
      activityId,
      activityDateType,
    }
    const newMaxOptions = showMaxDate
      ? {
          max: maxDate.toISODate(),
        }
      : {}
    const newOption = { ...newMinOptions, ...newMaxOptions }
    const updatedActive = active.concat(newOption)
    updateFilter(filter.id, updatedActive)
    this.setState({ ...initDates, showMaxDate: false })
  }

  onRemoveOption = (option: any) => {
    const { filter, active, updateFilter } = this.props
    const updatedActive = active.filter((activeOption) => !isEqual(activeOption, option))
    updateFilter(filter.id, updatedActive)
  }

  render() {
    const { filter, active, chipColor, metaData } = this.props
    const { maxDate, minDate, showMaxDate, activityId, activityDateType } = this.state
    return (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <TextField
            variant="standard"
            fullWidth
            select
            label="Activity"
            value={activityId || ''}
            onChange={(event) =>
              this.setState({
                activityId: event.target.value,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}>
            {metaData.timePlanEntryTypes.map((element, index) => (
              <MenuItem key={index} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid xs={12}>
          <TextField
            variant="standard"
            fullWidth
            select
            label="Date type"
            value={activityDateType || ''}
            onChange={(event) =>
              this.setState({
                activityDateType: event.target.value,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}>
            <MenuItem value="Planned">Planned</MenuItem>
            <MenuItem value="Actual">Actual</MenuItem>
          </TextField>
        </Grid>
        {activityId ? (
          <Grid xs={12}>
            <TextField
              variant="standard"
              fullWidth
              type="date"
              label={this.state.showMaxDate ? 'From date' : 'Date'}
              onChange={(event) =>
                this.setState({
                  minDate: DateTime.fromISO(event.target.value),
                  maxDate: DateTime.fromISO(event.target.value)
                    .plus({
                      months: 1,
                    })
                    .minus({
                      days: 1,
                    }),
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
              value={minDate ? minDate.toISODate() : null}
            />
          </Grid>
        ) : null}
        {activityId && showMaxDate ? (
          <Grid xs={12}>
            <TextField
              variant="standard"
              fullWidth
              type="date"
              label="To this date"
              onChange={(event) =>
                this.setState({
                  maxDate: DateTime.fromISO(event.target.value),
                })
              }
              value={maxDate.toISODate()}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: '100%',
              }}
            />
          </Grid>
        ) : null}
        <Grid xs={12}>
          <AddButton
            onClick={this.onAddOption}
            style={{
              marginRight: 10,
              marginBottom: 5,
            }}
            disabled={!activityId}
          />
          <Button
            variant="outlined"
            onClick={() =>
              this.setState((state) => ({
                showMaxDate: !state.showMaxDate,
              }))
            }
            disabled={!activityId}
            color="primary">
            {showMaxDate ? 'Delete interval' : 'Create interval'}
          </Button>
        </Grid>
        <FilterSelectionList
          onRemoveOption={this.onRemoveOption}
          {...{
            filter,
            active,
            metaData,
            chipColor,
          }}
        />
      </Grid>
    )
  }
}

export default TimeplanFilter
