import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION =
  'GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION'
export const GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST =
  'GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST'
export const GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS =
  'GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS'
export const GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE =
  'GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE'
export const POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION =
  'POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION'
export const POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST =
  'POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST'
export const POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS =
  'POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS'
export const POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE =
  'POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE'
export const PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION =
  'PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION'
export const PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST =
  'PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST'
export const PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS =
  'PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS'
export const PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE =
  'PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE'
export const getProductionPrintDeliverySpecifications =
  (workId, productionId, printNumber) => (dispatch) =>
    dispatch({
      type: GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION,
      meta: {
        workId,
        productionId,
        printNumber,
      },
      payload: {
        promise: request({
          method: 'GET',
          url: services.work(
            `/v2/works/${workId}/productions/${productionId}/printnumbers/${printNumber}/deliveryspecifications`,
          ),
        }),
      },
    })
const POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRINT_NOT_FOUND',
    'PUBLISHINGHOUSE_NOT_FOUND',
    'DELIVERYSPECIFICATION_IS_MISSING_DELIVERYITEMS',
    'DELIVERYSPECIFICATION_HAS_INVALID_TYPE',
    'DELIVERYITEM_HAS_INVALID_CATEGORY',
    'DELIVERYITEM_HAS_FORBIDDEN_CATEGORY',
    'DELIVERYITEM_MAX_ALLOWED_QUANTITY_EXCEEDED',
    'DELIVERYSPECIFICATION_PRINTER_IS_MISSING_SCHILLING_LEVNO',
    'DELIVERYTYPE_IS_MISSING_DELIVERY_ADDRESS',
    'DELIVERYSPECIFICATION_PRINTER_IS_NOT_PRODUCTION_MEMBER',
    'DELIVERYSPECIFICATION_ALREADY_EXISTS',
    'DELIVERYSPECIFICATION_HAS_MULTIPLE_DELIVERYITEMS_WITH_SAME_CATEGORY',
    'DELIVERYSPECIFICATION_MISSING_DELIVERY_ADDRESS',
    'DELIVERYSPECIFICATION_IS_MISSING_INTERNAL_CONTACT',
    'DELIVERYSPECIFICATION_HAS_INVALID_INTERNAL_CONTACT',
    'DELIVERYITEM_HAS_INVALID_PRELIMINARY_COST_PRICE',
    'DELIVERYITEM_BOOKED_COST_PRICE_IS_READONLY',
    'DELIVERYITEM_ACTUAL_DELIVERYDATE_IS_READONLY',
    'DELIVERYITEM_HAS_INVALID_PLANNED_DELIVERYDATE',
    'DELIVERYADDRESS_HAS_INVALID_POSTALCODE',
    'PRODUCTION_NOT_CONNECTED_TO_ERP',
    'ERP_COVER_NOT_CONNECTED',
  ],
  messages,
)
export const postProductionPrintDeliverySpecifications =
  (workId, productionId, printNumber, body) => (dispatch) =>
    dispatch({
      type: POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION,
      meta: {
        workId,
        productionId,
        printNumber,
      },
      payload: {
        promise: request({
          method: 'POST',
          body,
          url: services.work(
            `/v2/works/${workId}/productions/${productionId}/printnumbers/${printNumber}/deliveryspecifications`,
          ),
          errorMessages: POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_ERROR_MESSAGES,
        }),
      },
    })
const PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'PRINT_NOT_FOUND',
    'PUBLISHINGHOUSE_NOT_FOUND',
    'DELIVERYSPECIFICATION_IS_MISSING_DELIVERYITEMS',
    'DELIVERYSPECIFICATION_HAS_INVALID_TYPE',
    'DELIVERYITEM_HAS_INVALID_CATEGORY',
    'DELIVERYITEM_HAS_FORBIDDEN_CATEGORY',
    'DELIVERYITEM_MAX_ALLOWED_QUANTITY_EXCEEDED',
    'DELIVERYSPECIFICATION_PRINTER_IS_MISSING_SCHILLING_LEVNO',
    'DELIVERYTYPE_IS_MISSING_DELIVERY_ADDRESS',
    'DELIVERYSPECIFICATION_PRINTER_IS_NOT_PRODUCTION_MEMBER',
    'DELIVERYSPECIFICATION_ALREADY_EXISTS',
    'DELIVERYSPECIFICATION_HAS_MULTIPLE_DELIVERYITEMS_WITH_SAME_CATEGORY',
    'DELIVERYSPECIFICATION_MISSING_DELIVERY_ADDRESS',
    'DELIVERYSPECIFICATION_IS_MISSING_INTERNAL_CONTACT',
    'DELIVERYSPECIFICATION_HAS_INVALID_INTERNAL_CONTACT',
    'DELIVERYITEM_HAS_INVALID_PRELIMINARY_COST_PRICE',
    'DELIVERYITEM_BOOKED_COST_PRICE_IS_READONLY',
    'DELIVERYITEM_ACTUAL_DELIVERYDATE_IS_READONLY',
    'DELIVERYITEM_HAS_INVALID_PLANNED_DELIVERYDATE',
    'DELIVERYADDRESS_HAS_INVALID_POSTALCODE',
    'PRODUCTION_NOT_CONNECTED_TO_ERP',
    'DELIVERYITEM_MISMATCH',
    'DELIVERYSPECIFICATION_PRINTER_IS_READONLY',
    'ERP_COVER_NOT_CONNECTED',
  ],
  messages,
)
export const putProductionPrintDeliverySpecifications =
  (workId, productionId, printNumber, body, deliverySpecificationId) => (dispatch) =>
    dispatch({
      type: PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION,
      meta: {
        workId,
        productionId,
        printNumber,
        deliverySpecificationId,
      },
      payload: {
        promise: request({
          method: 'PUT',
          body,
          url: services.work(
            `/v2/works/${workId}/productions/${productionId}/printnumbers/${printNumber}/deliveryspecifications/${deliverySpecificationId}`,
          ),
          errorMessages: PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_ERROR_MESSAGES,
        }),
      },
    })
