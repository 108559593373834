import { getOr } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import { filterMemberByAuthor } from 'Common/utils/roles'

import PublishingListViewItemDetails, {
  getTimePlanValue,
  getContactValues,
} from './PublishingListViewItemDetails'

const HiddenDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const styles = {
  productionRow: {
    borderTop: '1px solid #e0e0e0',
  },
}

type Props = {
  result: any
  showDetails: boolean
}

class PublishingListViewItem extends Component<Props> {
  render() {
    const { result, showDetails } = this.props
    return (
      <Grid xs={12}>
        <Grid container spacing={2} xs={12} style={styles.productionRow}>
          <Grid xs={8} sm={4}>
            <Link
              underline="none"
              component={NavLink}
              to={`/book/${result.workId}/edition/${result.id}`}>
              {result.title}
            </Link>
            <HiddenDiv>{getOr('', 'publishingHouseName', result)}</HiddenDiv>
          </Grid>
          <Grid xs={8} sm={2}>
            {getContactValues(result.members.filter(filterMemberByAuthor), true)}
          </Grid>
          <Grid xs={8} sm={2}>
            {getOr('-', 'bindingCodeName', result)}
          </Grid>
          <Grid xs={8} sm={2}>
            {getTimePlanValue('7', result)}
          </Grid>
          <Grid xs={8} sm={2}>
            {result.publishingDate ? DateTime.fromISO(result.publishingDate).toISODate() : '-'}
          </Grid>
          {showDetails ? (
            <PublishingListViewItemDetails
              {...{
                result,
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    )
  }
}

export default PublishingListViewItem
