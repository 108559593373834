import { createContext, useContext, useState } from 'react'

import { AlertTitle, Alert, Snackbar, Slide } from '@mui/material'

type Severity = 'success' | 'error' | 'warning' | 'info'

interface SnackBarProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

export interface SnackBarProps {
  message?: string
  title?: string
  severity: Severity
  open: boolean
}

export class SnackBarHelper {
  setProps: any

  props: SnackBarProps

  constructor(state: any) {
    ;[this.props, this.setProps] = state
  }

  toggleSnackBar() {
    this.setProps({ ...this.props, open: !this.props.open })
  }

  show({ message, title, severity }: Partial<SnackBarProps>) {
    this.setProps({ ...this.props, message, title, severity, open: true })
  }

  hide() {
    this.setProps({ ...this.props, open: false })
  }
}

export const SnackBarContext = createContext<SnackBarHelper | undefined>(undefined)

export function SnackBarProvider({ children }: SnackBarProviderProps) {
  const state = useState({})
  const helper = new SnackBarHelper(state)

  return (
    <SnackBarContext.Provider value={helper}>
      <SnackbarToast />
      {children}
    </SnackBarContext.Provider>
  )
}

export function useSnackBar() {
  const context = useContext(SnackBarContext)
  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider')
  }
  return context
}

function TransitionBottom(props) {
  return <Slide {...props} direction="up" />
}

export function SnackbarToast() {
  const snackBarHelper = useSnackBar()
  const { open, severity, title = null, message = null } = snackBarHelper.props

  const handleClose = () => {
    snackBarHelper.hide()
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      TransitionComponent={TransitionBottom}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert severity={severity} sx={{ width: '100%', minWidth: 400, whiteSpace: 'pre-line' }}>
        {title != null ? <AlertTitle>{title}</AlertTitle> : null}
        {message != null ? <>{message}</> : null}
      </Alert>
    </Snackbar>
  )
}
