import { getOr } from 'lodash/fp'

import { requestStatus } from 'Common/utils/net/statuses'

import { POST_ACTIVITY_SUCCESS } from '../actions/activityActions'
import {
  GET_LAUNCHPLAN_REQUEST,
  GET_LAUNCHPLAN_SUCCESS,
  GET_LAUNCHPLAN_FAILURE,
  PATCH_LAUNCHPLAN_REQUEST,
  PATCH_LAUNCHPLAN_SUCCESS,
  PATCH_LAUNCHPLAN_FAILURE,
  GET_LAUNCHPLAN_ACTIVITIES_REQUEST,
  GET_LAUNCHPLAN_ACTIVITIES_SUCCESS,
  GET_LAUNCHPLAN_ACTIVITIES_FAILURE,
  POST_LAUNCHPLAN_ACTIVITY_PACKAGE_REQUEST,
  POST_LAUNCHPLAN_ACTIVITY_PACKAGE_SUCCESS,
  POST_LAUNCHPLAN_ACTIVITY_PACKAGE_FAILURE,
} from '../actions/launchPlanActions'
import { GET_WORK_REQUEST } from '../actions/workActions'

const initialState = {
  activities: [],
  status: {
    getError: undefined,
    getStatus: undefined,
    patchStatus: undefined,
    patchError: undefined,
    getActivitiesStatus: undefined,
  },
  data: {},
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_REQUEST:
      return { ...initialState }

    case GET_LAUNCHPLAN_REQUEST:
      return {
        ...state,
        data: {},
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_LAUNCHPLAN_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_LAUNCHPLAN_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    case PATCH_LAUNCHPLAN_REQUEST:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
      }

    case PATCH_LAUNCHPLAN_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, patchStatus: requestStatus.success },
      }

    case PATCH_LAUNCHPLAN_FAILURE:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.failure, patchError: action.payload },
      }

    case GET_LAUNCHPLAN_ACTIVITIES_REQUEST:
      return {
        ...state,
        workId: action.meta.workId,
        activities: [],
        status: {
          ...state.status,
          getActivitiesStatus: requestStatus.request,
          getActivitiesError: null,
        },
      }

    case POST_ACTIVITY_SUCCESS:
      return { ...state, activities: [action.payload.json, ...state.activities] }

    case GET_LAUNCHPLAN_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: getOr([], 'activities', action.payload.json),
        status: { ...state.status, getActivitiesStatus: requestStatus.success },
      }

    case GET_LAUNCHPLAN_ACTIVITIES_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          getActivitiesStatus: requestStatus.failure,
          getActivitiesError: action.payload,
        },
      }

    case POST_LAUNCHPLAN_ACTIVITY_PACKAGE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          postActivitiesStatus: requestStatus.request,
          postActivitiesError: null,
        },
      }

    case POST_LAUNCHPLAN_ACTIVITY_PACKAGE_SUCCESS:
      return {
        ...state,
        activities: getOr([], 'activities', action.payload.json),
        status: { ...state.status, postActivitiesStatus: requestStatus.success },
      }

    case POST_LAUNCHPLAN_ACTIVITY_PACKAGE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          postActivitiesStatus: requestStatus.failure,
          postActivitiesError: action.payload,
        },
      }

    default:
      return state
  }
}
