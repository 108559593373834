import classNames from 'classnames'
import { getOr } from 'lodash/fp'
import { Component, Fragment } from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'

import SortableTableHeader from 'Common/components/table/SortableTableHeader'
import { getDefaultState, getSortedList, updateSorting } from 'Common/components/table/tableHelpers'
import { AddButton, EditButton } from 'Common/core'

import AddMetaDataItemModal from './AddMetaDataItemModal'
import CustomPropertiesItemsList from './CustomPropertiesItemsList'
import EditMetaDataItemModal from './EditMetaDataItemModal'

const PREFIX = 'MetaDataItemsList'

const classes = {
  row: `${PREFIX}-row`,
  col: `${PREFIX}-col`,
  link: `${PREFIX}-link`,
}

const StyledGrid = styled(Grid)({
  [`& .${classes.row}`]: {
    borderTop: '1px solid #e0e0e0',
  },
  [`& .${classes.col}`]: {
    wordBreak: 'break-word',
  },
  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },
})

const nrOfPropsSorter = (item: any = {}) => (item.customPropertyItems || []).length

type Props = {
  typeId: number
  items?: unknown[]
  customPropertyTypes?: unknown[]
}

type State = {
  detailsState: any
  sorting: any
  editItem: any
  showAddModal: boolean
  showEditModal: boolean
}

class MetaDataItemsList extends Component<Props, State> {
  static defaultProps = {
    items: [],
    customPropertyTypes: [],
  }

  updateSorting: any

  constructor(props: Props) {
    super(props)
    this.updateSorting = updateSorting.bind(this)
    this.state = {
      ...getDefaultState(),
      detailsState: {},
      showAddModal: false,
      showEditModal: false,
      editItem: null,
    }
  }

  toggleShowDetails = (id) =>
    this.setState((state) => ({
      detailsState: { ...state.detailsState, [id]: state.detailsState[id] === true ? false : true },
    }))

  toggleAddModal = () =>
    this.setState((state) => ({
      showAddModal: !state.showAddModal,
    }))

  toggleEditModal = (item = null) =>
    this.setState({
      showEditModal: item !== null,
      editItem: item,
    })

  render() {
    const { typeId, items, customPropertyTypes } = this.props
    const { sorting, detailsState, showAddModal, showEditModal, editItem } = this.state
    const sortedItems = getSortedList(items, sorting)
    return (
      <StyledGrid container spacing={2} xs={12}>
        {showAddModal ? (
          <AddMetaDataItemModal
            onCloseModal={this.toggleAddModal}
            {...{
              typeId,
            }}
          />
        ) : null}
        {showEditModal && editItem ? (
          <EditMetaDataItemModal item={editItem} onCloseModal={this.toggleEditModal} />
        ) : null}
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <AddButton onClick={this.toggleAddModal}>Add item</AddButton>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow key={'header'}>
              <TableCell>
                <SortableTableHeader
                  label="ID"
                  field="id"
                  sorting={sorting}
                  updateSorting={this.updateSorting}
                />
              </TableCell>
              <TableCell>
                <SortableTableHeader
                  label="Name"
                  field="name"
                  sorting={sorting}
                  updateSorting={this.updateSorting}
                />
              </TableCell>
              <TableCell>
                <SortableTableHeader
                  label="External ID"
                  field="externalId"
                  sorting={sorting}
                  updateSorting={this.updateSorting}
                />
              </TableCell>
              <TableCell>
                <SortableTableHeader
                  label="Active"
                  field="isActive"
                  sorting={sorting}
                  updateSorting={this.updateSorting}
                />
              </TableCell>
              <TableCell>
                <SortableTableHeader
                  label="Nr of props"
                  field={nrOfPropsSorter}
                  sorting={sorting}
                  updateSorting={this.updateSorting}
                />
              </TableCell>
              <TableCell>Sort order</TableCell>
              <TableCell>Description</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => (
              <Fragment key={`${item.id}-container`}>
                <TableRow
                  key={`${item.id}-head`}
                  style={{
                    textDecoration: item.isActive ? 'unset' : 'line-through',
                  }}>
                  <TableCell>
                    <span
                      className={classNames('linkPrimary', classes.link)}
                      onClick={() => this.toggleShowDetails(item.id)}>
                      {item.id}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classNames('linkPrimary', classes.link)}
                      onClick={() => this.toggleShowDetails(item.id)}>
                      {item.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classNames('linkPrimary', classes.link)}
                      onClick={() => this.toggleShowDetails(item.id)}>
                      {item.externalId}
                    </span>
                  </TableCell>
                  <TableCell>{item.isActive ? 'True' : 'False'}</TableCell>
                  <TableCell>{(item.customPropertyItems || []).length}</TableCell>
                  <TableCell>{item.sortOrder}</TableCell>
                  <TableCell>{getOr('-', 'description', item)}</TableCell>
                  <TableCell align="right">
                    <EditButton onClick={() => this.toggleEditModal(item)}>Edit</EditButton>
                  </TableCell>
                </TableRow>
                <TableRow key={`${item.id}-content`}>
                  <TableCell colSpan={8}>
                    {detailsState[item.id] === true ? (
                      <CustomPropertiesItemsList
                        items={item.customPropertyItems}
                        metadataItemId={item.id}
                        {...{
                          customPropertyTypes,
                          typeId,
                        }}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
        {items.length === 0 ? <Grid xs={12}>No items added.</Grid> : null}
      </StyledGrid>
    )
  }
}
export default MetaDataItemsList
