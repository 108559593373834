import { convertPercentagesToFactors } from 'Calculation/utils/calculation'

import { services } from 'Common/environment'
import request from 'Common/utils/net/request'

export const PUT_PRODUCTION_ROYALTY_LEVELS = 'PUT_PRODUCTION_ROYALTY_LEVELS'
export const PUT_PRODUCTION_ROYALTY_LEVELS_REQUEST = 'PUT_PRODUCTION_ROYALTY_LEVELS_REQUEST'
export const PUT_PRODUCTION_ROYALTY_LEVELS_SUCCESS = 'PUT_PRODUCTION_ROYALTY_LEVELS_SUCCESS'
export const PUT_PRODUCTION_ROYALTY_LEVELS_FAILURE = 'PUT_PRODUCTION_ROYALTY_LEVELS_FAILURE'
export const GET_PRODUCTION_ROYALTY_LEVELS = 'GET_PRODUCTION_ROYALTY_LEVELS'
export const GET_PRODUCTION_ROYALTY_LEVELS_REQUEST = 'GET_PRODUCTION_ROYALTY_LEVELS_REQUEST'
export const GET_PRODUCTION_ROYALTY_LEVELS_SUCCESS = 'GET_PRODUCTION_ROYALTY_LEVELS_SUCCESS'
export const GET_PRODUCTION_ROYALTY_LEVELS_FAILURE = 'GET_PRODUCTION_ROYALTY_LEVELS_FAILURE'
export const GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS = 'GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS'
export const GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_REQUEST =
  'GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_REQUEST'
export const GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_SUCCESS =
  'GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_SUCCESS'
export const GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_FAILURE =
  'GET_PRODUCTION_DEFAULT_ROYALTY_LEVEL_FAILURE'

export const putProductionRoyaltyLevels =
  (workId: number, productionId: number, royaltyLevels: any[]) => (dispatch) => {
    const converted = convertPercentagesToFactors(royaltyLevels)
    dispatch({
      type: PUT_PRODUCTION_ROYALTY_LEVELS,
      payload: {
        data: {
          workId,
          productionId,
          values: converted,
        },
        promise: request({
          method: 'PUT',
          url: services.work(`/v1/works/${workId}/productions/${productionId}/royaltyLevels`),
          body: converted,
        }),
      },
    })
  }
export const getProductionRoyaltyLevels = (workId: number, productionId: number) => (dispatch) =>
  dispatch({
    type: GET_PRODUCTION_ROYALTY_LEVELS,
    payload: {
      data: {
        workId,
        productionId,
      },
      promise: request({
        method: 'GET',
        url: services.work(`/v1/works/${workId}/productions/${productionId}/royaltyLevels`),
      }),
    },
  })

export const getDefaultRoyaltyLevels =
  (bindingCodeId, contractTypeId, isMainEdition, publishingHouseId) => (dispatch) =>
    dispatch({
      type: GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS,
      payload: {
        data: {
          bindingCodeId,
          contractTypeId,
          isMainEdition,
          publishingHouseId,
        },
        promise: request({
          method: 'GET',
          url: services.concept(
            `/v1/defaults/royaltylevels?bindingCode=${bindingCodeId}&contractType=${contractTypeId}&mainEdition=${isMainEdition}&publishingHouseId=${publishingHouseId}`,
          ),
        }),
      },
    })
