import { Cell } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'
import { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import Link from '@mui/material/Link'

import DefaultCell from './DefaultCell'

type Props = {
  column: {
    selectField: string
    cellConfig: {
      createLink(..._args: unknown[]): unknown
    }
  }
  resultStatus: string
  result?: Record<string, unknown>
  viewProps?: Record<string, unknown>
}

class LinkCell extends PureComponent<Props> {
  render() {
    const { column, result, resultStatus, viewProps, ...otherProps } = this.props
    const value = getOr('-', `document.${column.selectField}`, result)

    if (resultStatus === 'pending' || value === '-') {
      return <DefaultCell {...this.props} />
    }

    const {
      cellConfig: { createLink },
    } = column
    return (
      <Cell {...otherProps}>
        <Link
          style={{
            whiteSpace: 'nowrap',
          }}
          underline="hover"
          to={createLink(result.document)}
          component={NavLink}>
          {value}
        </Link>
      </Cell>
    )
  }
}

export default LinkCell
