import { postMetaDataItem as postMetaDataItemAction } from 'Admin/actions/metaDataTypeActions'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'

import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import { Form, Field, AddButton, Dialog } from 'Common/core'
import FormCheckbox from 'Common/form/FormCheckbox'
import FormErrorDialog from 'Common/form/FormErrorDialog'
import FormTextField from 'Common/form/FormTextField'
import { quickValidation, notEmpty, isInteger, isPositiveNumber } from 'Common/form/formvalidation'
import { formSubmit } from 'Common/utils/net/submit'

type Props = {
  onCloseModal(): void
  typeId: number
}

const FORM_NAME = 'addmetadataitemform'

const mapState = () => {
  const initialValues = {
    sortOrder: 0,
    isActive: true,
    showExternal: true,
  }
  return {
    initialValues,
  }
}

const connector = connect(mapState, {
  postMetaDataItem: postMetaDataItemAction,
})

const form = reduxForm<any, any>({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: quickValidation({
    name: [notEmpty()],
    externalId: [notEmpty()],
    sortOrder: [isPositiveNumber(), isInteger()],
  }),
})

type PropsFromRedux = ConnectedProps<typeof connector>

class AddMetaDataItemModal extends Component<Props & InjectedFormProps & PropsFromRedux> {
  submit = (values) => {
    const { typeId, postMetaDataItem, onCloseModal } = this.props
    return formSubmit(() => postMetaDataItem(typeId, values)).then(() => onCloseModal())
  }

  render() {
    const { submitting, dirty, onCloseModal, handleSubmit } = this.props
    const doSubmit = handleSubmit(this.submit)
    return (
      <Dialog
        title="Add metadata item"
        actions={[
          <AddButton
            key="action"
            onClick={doSubmit}
            disabled={!dirty || submitting}
            submitting={submitting}>
            Create
          </AddButton>,
        ]}
        onClose={onCloseModal}>
        <Form onSubmit={doSubmit}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Field
                id="Metadata.AddMetaDataItem.Name"
                name="name"
                component={FormTextField}
                label="Name"
                required
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddMetaDataItem.ExternalId"
                name="externalId"
                component={FormTextField}
                label="External Id"
                required
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddMetaDataItem.SortOrder"
                name="sortOrder"
                component={FormTextField}
                label="Sort order"
                type="number"
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddMetaDataItem.IsActive"
                name="isActive"
                component={FormCheckbox}
                label="Active"
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddMetaDataItem.ShowExternal"
                name="showExternal"
                component={FormCheckbox}
                label="Show external"
              />
            </Grid>

            {submitting ? <Spinner /> : null}
          </Grid>
        </Form>
        <FormErrorDialog {...this.props} />
      </Dialog>
    )
  }
}

export default connector(form(AddMetaDataItemModal))
