import { get } from 'lodash/fp'

import { requestStatus } from 'Common/utils/net/statuses'

import {
  POST_MEMBER_PRODUCTION_REQUEST,
  POST_MEMBER_PRODUCTION_SUCCESS,
  POST_MEMBER_PRODUCTION_FAILURE,
  DELETE_MEMBER_PRODUCTION_SUCCESS,
  PUT_MEMBER_PRODUCTION_SUCCESS,
} from '../actions/memberActions'
import {
  GET_PRODUCTION_REQUEST,
  GET_PRODUCTION_SUCCESS,
  GET_PRODUCTION_FAILURE,
  PATCH_PRODUCTION_REQUEST,
  PATCH_PRODUCTION_SUCCESS,
  PATCH_PRODUCTION_FAILURE,
  POST_PRODUCTION_ISBN_SUCCESS,
  POST_PRODUCTION_ISBN_FAILURE,
  POST_PRODUCTION_ISBN_REQUEST,
  POST_PRODUCTION_NEWPRINT_SUCCESS,
  POST_PRODUCTION_NEWPRINT_FAILURE,
  POST_PRODUCTION_NEWPRINT_REQUEST,
  POST_PRODUCTION_ERP_REQUEST,
  POST_PRODUCTION_ERP_SUCCESS,
  POST_PRODUCTION_ERP_FAILURE,
  POST_PRODUCTION_CANCEL_REQUEST,
  POST_PRODUCTION_CANCEL_SUCCESS,
  POST_PRODUCTION_CANCEL_FAILURE,
  POST_PRODUCTION_ACTIVATE_REQUEST,
  POST_PRODUCTION_ACTIVATE_SUCCESS,
  POST_PRODUCTION_ACTIVATE_FAILURE,
  POST_PRODUCTION_CHANGEDATE_SUCCESS,
  POST_PRODUCTION_CHANGEDATE_FAILURE,
  POST_PRODUCTION_PUBLISH_REQUEST,
  POST_PRODUCTION_PUBLISH_SUCCESS,
  POST_PRODUCTION_PUBLISH_FAILURE,
  POST_PRODUCTION_UNPUBLISH_REQUEST,
  POST_PRODUCTION_UNPUBLISH_SUCCESS,
  POST_PRODUCTION_UNPUBLISH_FAILURE,
  POST_PRODUCTION_COPY_TECHNICAL_REQUEST,
  POST_PRODUCTION_COPY_TECHNICAL_SUCCESS,
  POST_PRODUCTION_COPY_TECHNICAL_FAILURE,
  POST_PRODUCTION_CHANGE_BINDINGCODE_REQUEST,
  POST_PRODUCTION_CHANGE_BINDINGCODE_SUCCESS,
  POST_PRODUCTION_CHANGE_BINDINGCODE_FAILURE,
  POST_PRODUCTIONS_MOVE_REQUEST,
  POST_PRODUCTIONS_MOVE_SUCCESS,
  POST_PRODUCTIONS_MOVE_FAILURE,
  CLEAR_PRODUCTIONS_MOVE_RESULTS,
} from '../actions/productionActions'
import {
  CONNECT_ASSET_PRODUCTION_SUCCESS,
  DISCONNECT_ASSET_PRODUCTION_SUCCESS,
  CONNECT_ASSET_PRODUCTION_REQUEST,
  DISCONNECT_ASSET_PRODUCTION_REQUEST,
} from '../actions/productionAssetsActions'
import {
  PUT_PRODUCTION_EXPORT_RULES_REQUEST,
  PUT_PRODUCTION_EXPORT_RULES_SUCCESS,
  PUT_PRODUCTION_EXPORT_RULES_FAILURE,
} from '../actions/productionExportRulesActions'
import {
  POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_REQUEST,
  POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_SUCCESS,
  POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_FAILURE,
  POST_PRODUCTION_TIMEPLAN_ENTRY_REQUEST,
  POST_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS,
  POST_PRODUCTION_TIMEPLAN_ENTRY_FAILURE,
  PUT_PRODUCTION_TIMEPLAN_ENTRY_REQUEST,
  PUT_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS,
  PUT_PRODUCTION_TIMEPLAN_ENTRY_FAILURE,
  DELETE_PRODUCTION_TIMEPLAN_ENTRY_REQUEST,
  DELETE_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS,
  DELETE_PRODUCTION_TIMEPLAN_ENTRY_FAILURE,
} from '../actions/productionTimeplanActions'

const initialState = {
  status: {
    getStatus: undefined,
    getError: undefined,
  },
  data: undefined,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_REQUEST:
      return {
        ...state,
        data: null,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_PRODUCTION_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_PRODUCTION_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    case DISCONNECT_ASSET_PRODUCTION_REQUEST:
    case CONNECT_ASSET_PRODUCTION_REQUEST:
      return { ...state, data: { ...state.data, coverImageAssetId: 0, refreshThumbnail: false } }

    case DISCONNECT_ASSET_PRODUCTION_SUCCESS:
      return { ...state, data: { ...state.data, refreshThumbnail: true } }

    case CONNECT_ASSET_PRODUCTION_SUCCESS: {
      const assets = action.payload.json ? action.payload.json : null
      const shouldRefreshThumbnail = assets.includes((element) => element.id === '9') // remove hardcoded value

      return {
        ...state,
        data: {
          ...state.data,
          coverImageAssetId: action.meta.asset.id,
          refreshThumbnail: shouldRefreshThumbnail,
        },
      }
    }

    case PATCH_PRODUCTION_REQUEST:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
      }

    case PATCH_PRODUCTION_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, patchStatus: requestStatus.success, patchError: null },
      }

    case PATCH_PRODUCTION_FAILURE:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.failure, patchError: action.payload },
      }

    case PUT_MEMBER_PRODUCTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, putStatus: requestStatus.success },
      }

    case DELETE_MEMBER_PRODUCTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, deleteStatus: requestStatus.success },
      }

    case POST_PRODUCTIONS_MOVE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, postStatus: requestStatus.success },
      }

    case POST_PRODUCTION_ISBN_REQUEST:
    case POST_PRODUCTION_ERP_REQUEST:
    case POST_PRODUCTION_NEWPRINT_REQUEST:
    case POST_MEMBER_PRODUCTION_REQUEST:
    case POST_PRODUCTION_CANCEL_REQUEST:
    case POST_PRODUCTION_ACTIVATE_REQUEST:
    case POST_PRODUCTION_PUBLISH_REQUEST:
    case POST_PRODUCTION_UNPUBLISH_REQUEST:
    case POST_PRODUCTION_COPY_TECHNICAL_REQUEST:
    case POST_PRODUCTION_CHANGE_BINDINGCODE_REQUEST:
    case PUT_PRODUCTION_EXPORT_RULES_REQUEST:
    case POST_PRODUCTIONS_MOVE_REQUEST:
      return {
        ...state,
        status: { ...state.status, postStatus: requestStatus.request, postError: null },
      }

    case POST_PRODUCTION_ISBN_SUCCESS:
    case POST_PRODUCTION_ERP_SUCCESS:
    case POST_PRODUCTION_NEWPRINT_SUCCESS:
    case POST_MEMBER_PRODUCTION_SUCCESS:
    case POST_PRODUCTION_CHANGEDATE_SUCCESS:
    case POST_PRODUCTION_CANCEL_SUCCESS:
    case POST_PRODUCTION_ACTIVATE_SUCCESS:
    case POST_PRODUCTION_PUBLISH_SUCCESS:
    case POST_PRODUCTION_UNPUBLISH_SUCCESS:
    case POST_PRODUCTION_COPY_TECHNICAL_SUCCESS:
    case POST_PRODUCTION_CHANGE_BINDINGCODE_SUCCESS:
    case PUT_PRODUCTION_EXPORT_RULES_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, postStatus: requestStatus.success },
      }

    case POST_PRODUCTION_ISBN_FAILURE:
    case POST_PRODUCTION_ERP_FAILURE:
    case POST_PRODUCTION_NEWPRINT_FAILURE:
    case POST_MEMBER_PRODUCTION_FAILURE:
    case POST_PRODUCTION_CHANGEDATE_FAILURE:
    case POST_PRODUCTION_CANCEL_FAILURE:
    case POST_PRODUCTION_ACTIVATE_FAILURE:
    case POST_PRODUCTION_PUBLISH_FAILURE:
    case POST_PRODUCTION_UNPUBLISH_FAILURE:
    case POST_PRODUCTION_COPY_TECHNICAL_FAILURE:
    case POST_PRODUCTION_CHANGE_BINDINGCODE_FAILURE:
    case PUT_PRODUCTION_EXPORT_RULES_FAILURE:
    case POST_PRODUCTIONS_MOVE_FAILURE:
      return {
        ...state,
        status: { ...state.status, postStatus: requestStatus.failure, postError: action.payload },
      }

    case POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_REQUEST:
    case POST_PRODUCTION_TIMEPLAN_ENTRY_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          timePlanPostStatus: requestStatus.request,
          timePlanPostError: null,
        },
      }

    case PUT_PRODUCTION_TIMEPLAN_ENTRY_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          timePlanPutStatus: requestStatus.request,
          timePlanPutError: null,
        },
      }

    case DELETE_PRODUCTION_TIMEPLAN_ENTRY_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          timePlanDeleteStatus: requestStatus.request,
          timePlanDeleteError: null,
        },
      }

    case POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_SUCCESS:
    case POST_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          activePrint: {
            ...state.data.activePrint,
            timePlan: { ...get('payload.json.activePrint.timePlan', action) },
          },
        },
        status: {
          ...state.status,
          timePlanPostStatus: requestStatus.success,
          timePlanPostError: null,
        },
      }

    case PUT_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          activePrint: {
            ...state.data.activePrint,
            timePlan: { ...get('payload.json.activePrint.timePlan', action) },
          },
        },
        status: {
          ...state.status,
          timePlanPutStatus: requestStatus.success,
          timePlanPutError: null,
        },
      }

    case DELETE_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          activePrint: {
            ...state.data.activePrint,
            timePlan: { ...get('payload.json.activePrint.timePlan', action) },
          },
        },
        status: {
          ...state.status,
          timePlanDeleteStatus: requestStatus.success,
          timePlanDeleteError: null,
        },
      }

    case POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_FAILURE:
    case POST_PRODUCTION_TIMEPLAN_ENTRY_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          timePlanPostStatus: requestStatus.failure,
          timePlanPostError: action.payload,
        },
      }

    case PUT_PRODUCTION_TIMEPLAN_ENTRY_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          timePlanPutStatus: requestStatus.failure,
          timePlanPutError: action.payload,
        },
      }

    case DELETE_PRODUCTION_TIMEPLAN_ENTRY_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          timePlanDeleteStatus: requestStatus.failure,
          timePlanDeleteError: action.payload,
        },
      }

    case CLEAR_PRODUCTIONS_MOVE_RESULTS:
      return { ...state, data: { ...state.data, moveProductionResults: [] } }

    default:
      return state
  }
}
