import { postListingCodeProductions as postListingCodeProductionsAction } from 'Work/actions/productionActions'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import messages from 'Work/messages'
import { getOr, uniq, first } from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'

import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import InfoNotice from 'Common/components/notice/InfoNotice'
import { CloseButton, Dialog, SaveButton } from 'Common/core'
import FormSelectField from 'Common/form/FormSelectField'
import { quickValidation, notEmpty } from 'Common/form/formvalidation'
import { getSearch as getSearchAction } from 'Common/search/searchActions'
import { Edition, RootState } from 'Common/types'
import { formSubmit } from 'Common/utils/net/submit'

type Props = {
  closeModal: () => void
  error: string
}
type State = {
  selectedContact: Record<string, unknown> | null
  selectedRole: Record<string, unknown> | null
  isNewContact: boolean
  savedProductions: Edition[]
  warningAccepted: boolean
}

const FORM_NAME = 'add_listing_code_to_editions'

const mapState = (state: RootState) => {
  const { metaData, search } = state
  const productionsSearch = getOr([], productionsSearchConfig.id, search)
  const { itemsTotalCount } = productionsSearch.paging
  const editions = getOr([], 'productions.results', search)

  const publishingHouseGroups = uniq(
    editions.map((edition) => edition.document.publishingHouseGroup).filter(() => true),
  )

  const hasMultiplePublishingHouseGroups =
    (publishingHouseGroups && publishingHouseGroups.length !== 1) || false
  const initialValues = {
    productionIds: getOr([], 'productions.selectedResult', search),
  }
  return {
    initialValues,
    hasMultiplePublishingHouseGroups,
    publishingHouseGroups,
    metaData: metaData.data,
    itemsTotalCount,
    editions,
  }
}

const connector = connect(mapState, {
  postListingCodeProductions: postListingCodeProductionsAction,
  getSearch: getSearchAction,
})
const form = reduxForm<any, any>({
  form: FORM_NAME,
  validate: quickValidation({
    listingCodeId: [notEmpty()],
    productionIds: [notEmpty()],
  }),
})

type PropsFromRedux = ConnectedProps<typeof connector>

class AddListingCodeEditions extends Component<Props & InjectedFormProps & PropsFromRedux, State> {
  state = {
    warningAccepted: false,
    selectedContact: null,
    selectedRole: null,
    isNewContact: false,
    savedProductions: [],
  }

  acceptWarning = () =>
    this.setState({
      warningAccepted: true,
    })

  static defaultProps = {
    hasMultiplePublishingHouseGroups: false,
  }

  submit = (values) => {
    const { postListingCodeProductions } = this.props
    const { listingCodeId } = values
    return formSubmit(() => postListingCodeProductions(productionsSearchConfig, listingCodeId))
  }

  render() {
    const {
      closeModal,
      metaData,
      getSearch,
      editions,
      error,
      handleSubmit,
      submitting,
      valid,
      submitSucceeded,
      hasMultiplePublishingHouseGroups,
      publishingHouseGroups,
      itemsTotalCount,
    } = this.props
    const { warningAccepted } = this.state
    const closeAndSearchAction = () => {
      getSearch(productionsSearchConfig)
      closeModal()
    }

    const selectedCloseAction = () => {
      submitSucceeded ? closeAndSearchAction() : closeModal()
    }

    const doSubmit = handleSubmit(this.submit)
    const actions = [
      <CloseButton
        variant="outlined"
        key="cancel"
        onClick={selectedCloseAction}
        disabled={submitting}
        style={{
          marginRight: 10,
        }}>
        {submitSucceeded ? messages.common.action.close : messages.common.action.cancel}
      </CloseButton>,
      <SaveButton
        key="save"
        onClick={doSubmit}
        disabled={
          editions.length === 0 ||
          !valid ||
          submitting ||
          submitSucceeded ||
          hasMultiplePublishingHouseGroups ||
          !warningAccepted
        }
      />,
    ]
    const selectedPublishingHouseGroup = publishingHouseGroups ? first(publishingHouseGroups) : []
    const title = (
      <Typography>
        <span>Add listing code on {itemsTotalCount} editions</span>
      </Typography>
    )
    return (
      <Dialog
        {...{
          actions,
          title,
        }}>
        {hasMultiplePublishingHouseGroups ? (
          <Typography>Editions from multiple publishing house groups selected</Typography>
        ) : !hasMultiplePublishingHouseGroups && !warningAccepted ? (
          <InfoNotice
            title="Are you sure you want to proceed with a batch operation?"
            content={
              <div>
                <Typography variant="body2">
                  {`You are about to perform a batch operation that will affect all entities in your search result (${itemsTotalCount} editions). The operation is irreversible.`}
                </Typography>
                <Button
                  style={{
                    float: 'left',
                    marginTop: 20,
                  }}
                  color="primary"
                  variant="outlined"
                  key="accept-warning"
                  onClick={this.acceptWarning}>
                  Continue
                </Button>
              </div>
            }
          />
        ) : warningAccepted && editions.length !== 0 && !hasMultiplePublishingHouseGroups ? (
          <form onSubmit={doSubmit}>
            <Grid container spacing={2} xs={12}>
              {!submitting && !submitSucceeded ? (
                <Grid xs={12} sm={6}>
                  <Field
                    name="listingCodeId"
                    component={FormSelectField}
                    label="Governing code"
                    metaData={metaData.listingCodes.filter((listingCode) =>
                      listingCode.publishingHouseGroupIds.includes(selectedPublishingHouseGroup),
                    )}
                  />
                </Grid>
              ) : null}
              {submitting ? (
                <Grid xs={12}>
                  <div
                    style={{
                      textAlign: 'center',
                    }}>
                    <Spinner />
                    <span>Saving... </span>
                  </div>
                </Grid>
              ) : null}
              {!submitting && submitSucceeded ? (
                <Typography variant="body2">Success updating editions.</Typography>
              ) : null}
              {error ? (
                <Grid xs={12}>
                  <div
                    style={{
                      color: '#f44336',
                      whiteSpace: 'pre-line',
                    }}>
                    {error}
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </form>
        ) : null}
      </Dialog>
    )
  }
}

export default connector(form(AddListingCodeEditions))
