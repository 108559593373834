import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Typography } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import { Dialog } from 'Common/core'
import { RootState } from 'Common/store/createStore'

import { getContact as getContactAction } from '../../Contacts/ContactsActions'
import { requestStatus } from '../../utils/net/statuses'
import Spinner, { SPINNER_SIZES } from '../loader/Spinner'
import ContactDetails from './ContactDetails'

type Props = {
  toggleModal: () => void
  show: boolean
  id?: number
  userId?: string
}

const mapState = (state: RootState) => {
  const { contacts } = state
  return {
    contacts,
  }
}

const connector = connect(mapState, {
  getContact: getContactAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

class ContactModal extends Component<Props & PropsFromRedux> {
  UNSAFE_componentWillMount() {
    const {
      id,
      contacts: { active },
      getContact,
    } = this.props

    if (id && (!active || active.id !== id)) {
      getContact(id)
    }
  }

  renderModalContent() {
    const { contacts } = this.props
    const activeContactId = getOr(false, 'active.id', contacts)

    if (contacts.activeStatus !== requestStatus.success) {
      return (
        <Grid xs={12} display="flex" justifyContent="center">
          {contacts.activeStatus === requestStatus.failure ? (
            contacts.activeStatusText ? (
              String(contacts.activeStatusText)
            ) : (
              'An error occured'
            )
          ) : (
            <Spinner size={SPINNER_SIZES.LARGE} />
          )}
        </Grid>
      )
    }

    return (
      <Grid container spacing={2} xs={12}>
        <Grid xs={12}>
          <ContactDetails
            {...{
              contacts,
            }}
          />
        </Grid>
        {activeContactId ? (
          <Grid xs={12}>
            <Typography variant="body2">
              Detailed contact information is available{' '}
              <Link underline="none" component={NavLink} to={`/contact/${activeContactId}`}>
                here
              </Link>
              .
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  render() {
    const { show, toggleModal } = this.props
    if (!show) return null
    return (
      <Dialog title="Contact information" onClose={toggleModal}>
        {this.renderModalContent()}
      </Dialog>
    )
  }
}

export default connector(ContactModal)
