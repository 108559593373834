import IconInfo from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'

type Props = {
  showInfoButton: boolean
  clickIcon: () => void
}

export default function FormFieldInfoToggler(props: Props) {
  const { showInfoButton, clickIcon } = props
  return showInfoButton ? (
    <>
      {showInfoButton ? (
        <IconButton onClick={clickIcon} aria-label="Info" size="large">
          <IconInfo />
        </IconButton>
      ) : null}
    </>
  ) : null
}
