import { filter, flow, intersection, map, sortBy, uniq } from 'lodash/fp'
import { Component } from 'react'

import IconFilterList from '@mui/icons-material/FilterList'
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Button,
  TextField,
  Menu,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { EditButton, UndoButton } from 'Common/components/buttons'

const ALL_CATEGORY = 'All columns'

const hasUpdated = (activeColumns, updatedActiveColumns) =>
  activeColumns.length !== updatedActiveColumns.length ||
  intersection(activeColumns, updatedActiveColumns).length !== activeColumns.length

const sortAndFilterColumnList = (selectedCategory: any, columns: any, columnFilter: any = '') =>
  flow([
    filter((column: any) =>
      selectedCategory.text === ALL_CATEGORY ? true : selectedCategory.text === column.category,
    ),
    map((column: any) => column.id),
    filter((id: any) =>
      columnFilter ? columns[id]?.label?.toLowerCase().includes(columnFilter?.toLowerCase()) : true,
    ),
    sortBy((id: any) => columns[id].label),
  ])(columns)

const styles = {
  listContainer: {
    height: 300,
    width: '100%',
    overflow: 'auto',
    marginTop: 20,
    marginBottom: 20,
    border: '1px solid #cacaca',
  },
  checkbox: {
    top: 4,
  },
  button: {
    width: 200,
    alignSelf: 'flex-end',
    marginLeft: '20px',
  },
  buttonIcon: {
    marginRight: '10px',
  },
}

type Props = {
  handleSave(...args: unknown[]): unknown
  handleClose(...args: unknown[]): unknown
  columns: any
  activeColumns: string[]
}

type State = {
  updatedActiveColumns: any
  columnList: any
  categoryMenuOpen: any
  columnFilter: any
  selectedCategory: any
  categoryButtonElement: any
  categories: any
}

class AdvancedViewSelection extends Component<Props, State> {
  constructor(props) {
    super(props)
    const { columns, activeColumns } = props
    const categories = this.createCategories(
      flow([map((col: any) => col.category), filter((_) => _), uniq])(columns),
    )
    const [selectedCategory] = categories
    this.state = {
      columnList: sortAndFilterColumnList(selectedCategory, columns),
      updatedActiveColumns: activeColumns.map((_) => _),
      categoryMenuOpen: false,
      columnFilter: '',
      selectedCategory,
      categoryButtonElement: null,
      categories,
    }
  }

  onCheck = (id, isChecked) => {
    this.setState((state) => ({
      updatedActiveColumns: isChecked
        ? [...state.updatedActiveColumns, id]
        : state.updatedActiveColumns.filter((columnId) => columnId !== id),
    }))
  }

  onSave = () => {
    const { handleSave, handleClose } = this.props
    const { updatedActiveColumns } = this.state
    handleSave(updatedActiveColumns)
    handleClose()
  }

  onFilterColumns = (event) => {
    const columnFilter = event.target.value
    this.setState((state) => ({
      columnFilter,
      columnList: sortAndFilterColumnList(state.selectedCategory, this.props.columns, columnFilter),
    }))
  }

  handleClick = (event) => {
    this.setState({
      categoryButtonElement: event.currentTarget,
    })
  }

  handleClose = (event) => {
    const { value } = event.target

    if (typeof value === 'undefined') {
      this.setState({
        categoryButtonElement: null,
      })
    } else {
      const selectedCategory = this.getSelectedCategory(value)
      this.setState((state) => ({
        categoryButtonElement: null,
        selectedCategory,
        columnList: sortAndFilterColumnList(
          selectedCategory,
          this.props.columns,
          state.columnFilter,
        ),
      }))
    }
  }

  createCategories = (categories) => {
    let index = 0
    return [ALL_CATEGORY, ...categories].map((category) => ({
      index: index++,
      text: category,
    }))
  }

  getSelectedCategory = (value) => {
    const { categories } = this.state
    const [result] = categories.filter((c) => c.index === parseInt(value))
    return result
  }

  render() {
    const { columns, activeColumns, handleClose } = this.props
    const {
      columnList,
      updatedActiveColumns,
      categoryButtonElement,
      selectedCategory,
      categories,
    } = this.state
    return (
      <Grid container spacing={0} rowSpacing={2} xs={12}>
        <Grid container spacing={2} xs={12}>
          <Grid>
            <TextField variant="standard" onChange={this.onFilterColumns} label="Filter" />
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Button
              aria-owns={categoryButtonElement ? 'category-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              style={styles.button}
              variant="contained"
              color="primary"
              startIcon={<IconFilterList style={styles.buttonIcon} />}>
              {selectedCategory.text}
            </Button>
            <Menu
              id="category-menu"
              anchorEl={categoryButtonElement}
              open={Boolean(categoryButtonElement)}
              onClose={this.handleClose}>
              {categories.map((category) => (
                <MenuItem onClick={this.handleClose} key={category.index} value={category.index}>
                  {category.text}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12}>
          <Grid xs={6}>
            <Typography variant="h5">Available columns</Typography>
            <div style={styles.listContainer}>
              <List>
                {columnList.map((id) => (
                  <ListItem
                    key={id}
                    dense
                    onClick={() => this.onCheck(id, !updatedActiveColumns.includes(id))}>
                    <Checkbox
                      checked={updatedActiveColumns.includes(id)}
                      disableRipple
                      color="primary"
                    />
                    <ListItemText>{columns[id].label}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid xs={6}>
            <Typography variant="h5">Chosen columns</Typography>
            <div style={styles.listContainer}>
              <List>
                {updatedActiveColumns
                  .map((colId) => columns[colId])
                  .map((col) => (
                    <ListItem dense key={col.id} onClick={() => this.onCheck(col.id, false)}>
                      <Checkbox checked disableRipple color="primary" />
                      <ListItemText>{col.label}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12} justifyContent="center">
          <Grid>
            <EditButton
              onClick={this.onSave}
              disabled={!hasUpdated(activeColumns, updatedActiveColumns)}>
              Update
            </EditButton>
          </Grid>
          <Grid>
            <UndoButton onClick={handleClose} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default AdvancedViewSelection
