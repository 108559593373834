import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_PRODUCTION_BOOK_CLUBS = 'GET_PRODUCTION_BOOK_CLUBS'
export const GET_PRODUCTION_BOOK_CLUBS_REQUEST = 'GET_PRODUCTION_BOOK_CLUBS_REQUEST'
export const GET_PRODUCTION_BOOK_CLUBS_SUCCESS = 'GET_PRODUCTION_BOOK_CLUBS_SUCCESS'
export const GET_PRODUCTION_BOOK_CLUBS_FAILURE = 'GET_PRODUCTION_BOOK_CLUBS_FAILURE'
export const POST_PRODUCTION_BOOK_CLUB = 'POST_PRODUCTION_BOOK_CLUB'
export const POST_PRODUCTION_BOOK_CLUB_REQUEST = 'POST_PRODUCTION_BOOK_CLUB_REQUEST'
export const POST_PRODUCTION_BOOK_CLUB_SUCCESS = 'POST_PRODUCTION_BOOK_CLUB_SUCCESS'
export const POST_PRODUCTION_BOOK_CLUB_FAILURE = 'POST_PRODUCTION_BOOK_CLUB_FAILURE'
export const PATCH_PRODUCTION_BOOK_CLUB = 'PATCH_PRODUCTION_BOOK_CLUB'
export const PATCH_PRODUCTION_BOOK_CLUB_REQUEST = 'PATCH_PRODUCTION_BOOK_CLUB_REQUEST'
export const PATCH_PRODUCTION_BOOK_CLUB_SUCCESS = 'PATCH_PRODUCTION_BOOK_CLUB_SUCCESS'
export const PATCH_PRODUCTION_BOOK_CLUB_FAILURE = 'PATCH_PRODUCTION_BOOK_CLUB_FAILURE'
export const DELETE_PRODUCTION_BOOK_CLUB = 'DELETE_PRODUCTION_BOOK_CLUB'
export const DELETE_PRODUCTION_BOOK_CLUB_REQUEST = 'DELETE_PRODUCTION_BOOK_CLUB_REQUEST'
export const DELETE_PRODUCTION_BOOK_CLUB_SUCCESS = 'DELETE_PRODUCTION_BOOK_CLUB_SUCCESS'
export const DELETE_PRODUCTION_BOOK_CLUB_FAILURE = 'DELETE_PRODUCTION_BOOK_CLUB_FAILURE'
const GET_PRODUCTION_BOOK_CLUBS_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
  messages,
)
export const getProductionBookClubs = (workId, productionId) => (dispatch) =>
  dispatch({
    type: GET_PRODUCTION_BOOK_CLUBS,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.work(`/v1/works/${workId}/productions/${productionId}/bookclubs`),
        errorMessages: GET_PRODUCTION_BOOK_CLUBS_ERROR_MESSAGES,
      }),
    },
  })
const POST_PRODUCTION_BOOK_CLUB_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'PRODUCTION_MISSING_ISBN_ID'],
  messages,
)
export const postProductionBookClub = (workId, productionId, bookClub) => (dispatch) =>
  dispatch({
    type: POST_PRODUCTION_BOOK_CLUB,
    meta: {
      workId,
      productionId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/bookclubs`),
        method: 'POST',
        body: bookClub,
        errorMessages: POST_PRODUCTION_BOOK_CLUB_ERROR_MESSAGES,
      }),
    },
  })
const PATCH_PRODUCTION_BOOK_CLUB_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'PRODUCTION_MISSING_ISBN_ID'],
  messages,
)
export const patchProductionBookClub = (workId, productionId, patchSet, id) => (dispatch) =>
  dispatch({
    type: PATCH_PRODUCTION_BOOK_CLUB,
    meta: {
      workId,
      productionId,
      id,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/bookclubs/${id}`),
        method: 'PATCH',
        body: patchSet,
        errorMessages: PATCH_PRODUCTION_BOOK_CLUB_ERROR_MESSAGES,
      }),
    },
  }).then(() => dispatch(getProductionBookClubs(workId, productionId)))
const DELETE_PRODUCTION_BOOK_CLUB_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'PRODUCTION_MISSING_ISBN_ID'],
  messages,
)
export const deleteProductionBookClub = (workId, productionId, id) => (dispatch) =>
  dispatch({
    type: DELETE_PRODUCTION_BOOK_CLUB,
    meta: {
      workId,
      productionId,
      id,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/works/${workId}/productions/${productionId}/bookclubs/${id}`),
        method: 'DELETE',
        errorMessages: DELETE_PRODUCTION_BOOK_CLUB_ERROR_MESSAGES,
      }),
    },
  }).then(() => dispatch(getProductionBookClubs(workId, productionId)))
