import { difference, isEqual, getOr, omit, pick } from 'lodash/fp'

const cleanSavedView = (savedView, viewConfig) => {
  if (!savedView) return savedView
  const missingColumns: any = difference(savedView.activeColumns, Object.keys(viewConfig.columns))
  return {
    ...savedView,
    activeColumns: savedView.activeColumns.filter((columnId) => !missingColumns.includes(columnId)),
    columnWidths: omit(missingColumns, savedView.columnWidths),
    missingColumns,
  }
}

export const getSavedViews = (viewConfig, settings) =>
  getOr([], viewConfig.savedViewsId, settings).map((view) => cleanSavedView(view, viewConfig))
export const saveView = (viewConfig, settings, name, activeColumns, columnWidths, postSetting) => {
  const updatedViews = getSavedViews(viewConfig, settings)
    .filter((view) => view.name !== name)
    .concat({
      name,
      activeColumns,
      columnWidths,
    })
  postSetting(viewConfig.savedViewsId, updatedViews)
  return updatedViews
}
export const removeSavedView = (viewConfig, settings, name, postSetting) => {
  const updatedViews = getSavedViews(viewConfig, settings).filter((view) => view.name !== name)
  postSetting(viewConfig.savedViewsId, updatedViews)
  return updatedViews
}
export const getWorkingView = (viewConfig) => {
  if (!viewConfig.savedViewsId) return null
  const workingView = window.localStorage.getItem(`${viewConfig.savedViewsId}-working`)
  return workingView === null ? null : cleanSavedView(JSON.parse(workingView), viewConfig)
}
export const saveWorkingView = (savedViewsId, workingView) =>
  savedViewsId &&
  window.localStorage.setItem(`${savedViewsId}-working`, JSON.stringify(workingView))
export const isWorkingViewUnchangedSavedView = (workingView, savedViews) =>
  workingView.name
    ? isEqual(
        pick(
          ['activeColumns', 'columnWidths'],
          savedViews.find((savedView) => savedView.name === workingView.name),
        ),
        pick(['activeColumns', 'columnWidths'], workingView),
      )
    : false
