import { NavLink } from 'react-router-dom'

import Link from '@mui/material/Link'

import { servicesLoaders } from 'Common/environment'
import { dateFilter, optionFilter, combinationOptionFilter } from 'Common/search/filter/filters'
import AdvancedSearchView from 'Common/search/view/AdvancedSearchView'
import BasicSearchView from 'Common/search/view/BasicSearchView'
import DateCell from 'Common/search/view/cells/DateCell'
import LinkCell from 'Common/search/view/cells/LinkCell'
import ListCell from 'Common/search/view/cells/ListCell'
import MemberCell from 'Common/search/view/cells/MemberCell'
import { getMembersColumns } from 'Common/utils/roles'

import ActivityViewHeader from '../components/Activities/ActivityViewHeader'
import ActivityViewItem from '../components/Activities/ActivityViewItem'
import ActivitySearchOperations from '../components/Activities/Search/ActivitySearchOperations'

const ROLES_CATEGORY = 'Roles'
export const searchConfig = {
  id: 'activities',
  searchService: servicesLoaders.work('/v2/search/activities'),
  searchServiceExport: servicesLoaders.work('/v2/search/activities/export'),
  searchLocation: '/book/activities',
  searchMetaDataId: 'activities',
  accessModule: 'opus_work',
  savedSearchesId: 'savedsearches-work-activities-v1',
  defaultSelectFields: [
    'id',
    'name',
    'categoryName',
    'publishingDate',
    'statusId',
    'statusName',
    'workIds',
    'publishingHouseGroupId',
  ],
  defaultOrderBy: 'publishingDate desc',
  pagingSize: 50,
  queryFields: [
    {
      id: 'workIds',
      label: 'ID, work',
    },
  ],
  filters: [
    {
      type: optionFilter,
      id: 'filterCategories',
      searchId: 'categoryId',
      metaDataId: 'activityCategories',
      name: 'Category',
    },
    {
      type: optionFilter,
      id: 'filterStatuses',
      searchId: 'statusId',
      metaDataId: 'activityStatus',
      name: 'Status',
    },
    {
      type: optionFilter,
      id: 'filterSharingLevels',
      searchId: 'sharingLevelId',
      metaDataId: 'activitySharinglevels',
      name: 'Sharing',
    },
    {
      type: dateFilter,
      id: 'filterRegDates',
      searchId: 'registrationDate',
      name: 'Act. reg. date',
    },
    {
      type: dateFilter,
      id: 'filterActivityStartDate',
      searchId: 'activityStartDate',
      name: 'Act date, start',
    },
    {
      type: dateFilter,
      id: 'filterActivityEndDate',
      searchId: 'activityEndDate',
      name: 'Act date, end',
    },
    {
      type: dateFilter,
      id: 'filterPubDates',
      searchId: 'publishingDate',
      name: 'Act. pub. date',
    },
    {
      type: dateFilter,
      externalFilterGroup: 'workfilter',
      id: 'filterMainEdition_publishingDate',
      searchId: 'mainEdition_publishingDate',
      name: 'Main edition - Publication date',
    },
    {
      type: optionFilter,
      externalFilterGroup: 'workfilter',
      id: 'filterMainEdition_publishingHouses',
      searchId: 'mainEdition_publishingHouseId',
      metaDataId: 'publishingHouses',
      typeConfig: {
        filterByPublishingHouse: true,
      },
      name: 'Main edition - Publishing house',
    },
    {
      type: combinationOptionFilter,
      externalFilterGroup: 'workfilter',
      id: 'filterMainEdition_Seasons',
      searchId: 'mainEdition_seasonName',
      typeConfig: {
        keys: ['year', 'season'],
      },
      name: 'Main edition - Season',
      options: [
        {
          id: 'year',
          name: 'Season, year',
          defaultValue: `${new Date().getFullYear()}`,
          metaDataId: 'seasonYears',
        },
        {
          id: 'season',
          name: 'Season, period',
          defaultValue: 'Spring',
          metaDataId: 'seasonPeriods',
        },
      ],
    },
  ],
}
export const viewsConfig = {
  views: [
    {
      id: 'basic',
      label: 'Basic',
      Component: BasicSearchView,
      componentConfig: {
        HeaderComponent: ActivityViewHeader,
        ItemComponent: ActivityViewItem,
      },
    },
    {
      id: 'advanced',
      label: 'Advanced',
      Component: AdvancedSearchView,
      ActionsComponent: ActivitySearchOperations,
      savedViewsId: 'savedviews-work-activities-v1',
      defaultColumns: [
        'name',
        'categoryName',
        'publishingDate',
        'statusName',
        'publishingHouseGroupId',
      ],
      metaDataColumns: [
        {
          metaDataId: 'contactRoles',
          mapMetaDataToColumns: getMembersColumns(ROLES_CATEGORY, MemberCell),
        },
      ],
      columns: {
        name: {
          id: 'name',
          selectField: 'name',
          label: 'Name',
          width: 250,
          sortable: true,
          cellType: LinkCell,
          cellConfig: {
            createLink: (activity) => `/book/activities/${activity.id}`,
          },
        },
        publishingDate: {
          id: 'publishingDate',
          selectField: 'publishingDate',
          label: 'Act. pub. date',
          width: 100,
          sortable: true,
          cellType: DateCell,
        },
        categoryName: {
          id: 'categoryName',
          selectField: 'categoryName',
          label: 'Category',
          width: 130,
          sortable: true,
        },
        registrationDate: {
          id: 'registrationDate',
          selectField: 'registrationDate',
          label: 'Act. reg. date',
          width: 100,
          sortable: true,
          cellType: DateCell,
        },
        activityStartDate: {
          id: 'activityStartDate',
          selectField: 'activityStartDate',
          label: 'Act date, start',
          width: 100,
          sortable: true,
          cellType: DateCell,
        },
        activityEndDate: {
          id: 'activityEndDate',
          selectField: 'activityEndDate',
          label: 'Act date, end',
          width: 100,
          sortable: true,
          cellType: DateCell,
        },
        statusName: {
          id: 'statusName',
          selectField: 'statusName',
          label: 'Status',
          width: 80,
          sortable: true,
        },
        sharingLevelName: {
          id: 'sharingLevelName',
          selectField: 'sharingLevelName',
          label: 'Sharing',
          width: 100,
          sortable: true,
        },
        comment: {
          id: 'comment',
          selectField: 'comment',
          label: 'Comments',
          width: 200,
          sortable: false,
        },
        partner: {
          id: 'partner',
          selectField: 'partner',
          label: 'Partner',
          width: 140,
          sortable: false,
        },
        works: {
          id: 'works',
          selectField: 'works',
          label: 'Works',
          width: 200,
          sortable: false,
          cellType: ListCell,
          cellConfig: {
            transform: (works) =>
              works.map((work) => (
                <Link
                  key={work.id}
                  component={NavLink}
                  to={`/book/${work.id}`}
                  style={{
                    whiteSpace: 'nowrap',
                  }}>
                  {work.title}
                </Link>
              )),
          },
        },
        workIds: {
          id: 'workIds',
          selectField: 'workIds',
          label: 'ID, works',
          width: 200,
          sortable: false,
          cellType: ListCell,
          cellConfig: {
            transform: (workIds) => workIds.map((workId) => workId),
          },
        },
      },
    },
  ],
}
