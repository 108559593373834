import { services } from 'Common/environment'

import messages from '../messages'
import createErrorMessages from '../messages/createErrorMessages'
import request from '../utils/net/request'

export const GET_CONTACT = 'GET_CONTACT'
export const GET_CONTACT_REQUEST = 'GET_CONTACT_REQUEST'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS'
export const GET_CONTACT_FAILURE = 'GET_CONTACT_FAILURE'
export const PATCH_CONTACT = 'PATCH_CONTACT'
export const PATCH_CONTACT_REQUEST = 'PATCH_CONTACT_REQUEST'
export const PATCH_CONTACT_SUCCESS = 'PATCH_CONTACT_SUCCESS'
export const PATCH_CONTACT_FAILURE = 'PATCH_CONTACT_FAILURE'
export const POST_CONTACT = 'POST_CONTACT'
export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST'
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS'
export const POST_CONTACT_FAILURE = 'POST_CONTACT_FAILURE'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE'
export const MERGE_CONTACTS = 'MERGE_CONTACTS'
export const MERGE_CONTACTS_REQUEST = 'MERGE_CONTACTS_REQUEST'
export const MERGE_CONTACTS_SUCCESS = 'MERGE_CONTACTS_SUCCESS'
export const MERGE_CONTACTS_FAILURE = 'MERGE_CONTACTS_FAILURE'
export type CompanyContact = {
  name1?: string
  name2?: string
}
export type PersonContact = {
  firstName: string
  id: number
  isUser: boolean
  lastName: string
}
export type ContactEmailType = 'workEmail'
export type PhoneNumberType = 'phoneNumber'
export type PhoneNumber = {
  type: PhoneNumberType
  value: string
}
export type Address = {
  address1: string
  address2: string
  zip: string
  city: string
  type: string
}
export type EmailAddress = {
  type: ContactEmailType
  value: string
}
export type Contact = any

// export type Contact = {
//   addresses: Address[]
//   awards: any[]
//   company?: CompanyContact
//   customerGroup: MetaDataItem
//   customerSubGroup: MetaDataItem
//   emailAddresses: EmailAddress[]
//   erpCustomerId?: string
//   erpSupplierId: string
//   hasAuthorPhotographDownload: boolean
//   hasAuthorPhotographStandard: boolean
//   hasContactPresentation: boolean
//   id: number
//   isCompanyContact: boolean
//   isSecret: boolean
//   isUser: boolean
//   person?: PersonContact
//   phoneNumbers: PhoneNumber[]
//   propertyRules: any
//   publishingHouseGroup: PublishingHouseGroup
//   publishingHouseGroupId: PublishingHouseGroupId
//   publishingHouseId: PublishingHouseId
//   texts: Text[]
//   updated: Date
//   updatedBy: UserId | 'batch'
//   urls: any[]
//   userId: any
// }
const GET_CONTACT_ERROR_MESSAGES = createErrorMessages(
  ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
  messages,
)
export const getContact = (id: number) => (dispatch) =>
  dispatch({
    type: GET_CONTACT,
    payload: {
      data: id,
      promise: request({
        method: 'GET',
        url: services.contact(`/v2/contacts/${id}`),
        errorMessages: GET_CONTACT_ERROR_MESSAGES,
      }),
    },
  })
const PATCH_CONTACT_ERROR_MESSAGES = createErrorMessages(
  ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
  messages,
)
export const patchContact = (id: number, contact: Contact) => (dispatch) =>
  dispatch({
    type: PATCH_CONTACT,
    payload: {
      data: contact.id,
      promise: request({
        method: 'PATCH',
        url: services.contact(`/v2/contacts/${id}`),
        body: contact,
        errorMessages: PATCH_CONTACT_ERROR_MESSAGES,
      }),
    },
  })
const DELETE_CONTACT_ERROR_MESSAGES = createErrorMessages(
  [
    'CONTACT_NOT_FOUND',
    'CONTACT_FORBIDDEN',
    'CONTACT_DELETE_STILL_CONNECTED_TO_WORK',
    'CONTACT_DELETE_STILL_CONNECTED_TO_ASSET',
    'CONTACT_DELETE_STILL_CONNECTED_TO_PRODUCTION',
    'CONTACT_DELETE_STILL_CONNECTED_TO_CONCEPT',
    'CONTACT_DELETE_STILL_CONNECTED_TO_LISTS',
  ],
  messages,
)
export const deleteContact = (id: number) => (dispatch) =>
  dispatch({
    type: DELETE_CONTACT,
    payload: {
      data: id,
      promise: request({
        method: 'DELETE',
        url: services.contact(`/v2/contacts/${id}`),
        errorMessages: DELETE_CONTACT_ERROR_MESSAGES,
      }),
    },
  })
export const mergeContacts = (sourceId: number, destinationId: number) => (dispatch) => {
  const contract = {
    sourceContactId: sourceId,
    destinationContactId: destinationId,
  }
  return dispatch({
    type: MERGE_CONTACTS,
    payload: {
      data: contract,
      promise: request({
        method: 'POST',
        body: contract,
        url: services.contact(`/v2/contacts/merge`),
      }),
    },
  })
}
const POST_CONTACT_ERROR_MESSAGES = createErrorMessages(
  ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN', 'CONTACT_MISSING_PUBLISHING_HOUSE_GROUP'],
  messages,
)
export const postContact = (contact: Contact) => (dispatch) =>
  dispatch({
    type: POST_CONTACT,
    payload: {
      data: contact,
      promise: request({
        method: 'POST',
        url: services.contact(`/v2/contacts`),
        body: contact,
        errorMessages: POST_CONTACT_ERROR_MESSAGES,
      }),
    },
  })
