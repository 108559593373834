import { getMetaDataTypes as getMetaDataTypesAction } from 'Admin/actions/metaDataTypesActions'
import MetaDataType from 'Admin/containers/metadata/MetaDataType'
import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import IconBuild from '@mui/icons-material/Build'
import { IconButton, Tooltip, Typography } from '@mui/material'

import { Dialog } from 'Common/core'
import { RootState } from 'Common/store/createStore'

const connector = connect(
  (state: RootState) => {
    const { metaDataTypes } = state
    return {
      metaDataTypes: metaDataTypes.data,
    }
  },
  {
    getMetaDataTypes: getMetaDataTypesAction,
  },
)

type Props = {
  metaDataType?: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

const MetaDataTypeAdminWrapper = connector(
  ({ metaDataTypes, metaDataType, getMetaDataTypes }: PropsFromRedux & Props) => {
    useEffect(() => {
      if (!metaDataType || metaDataTypes.length) return
      getMetaDataTypes()
    }, [metaDataType])
    if (!metaDataTypes) return null
    const selectedMetaDataType = metaDataTypes.find(
      (getMetaDataType) => getMetaDataType.name === metaDataType,
    )
    return selectedMetaDataType && metaDataType ? (
      <MetaDataType metaDataTypeId={selectedMetaDataType.id} />
    ) : (
      <Typography variant="body1">Meta data type not found. Is it static or enum? </Typography>
    )
  },
)

type MetaDataTypeAdminDialogProps = {
  showMetaDataAdmin?: boolean
  metaDataType?: string
}

const MetaDataTypeAdminDialog = (props: MetaDataTypeAdminDialogProps) => {
  const { showMetaDataAdmin, metaDataType } = props
  const [showMetaDataTypeDialog, setShowMetaDataTypeDialog] = useState(false)
  return showMetaDataAdmin && metaDataType ? (
    <>
      <Tooltip title={`Edit ${metaDataType}`} placement="right">
        <IconButton size="small" onClick={() => setShowMetaDataTypeDialog(true)} aria-label="Edit">
          <IconBuild />
        </IconButton>
      </Tooltip>
      {showMetaDataTypeDialog ? (
        <Dialog
          maxWidth="xl"
          onClose={() => setShowMetaDataTypeDialog(false)}
          title={`Edit Metadata`}>
          <MetaDataTypeAdminWrapper
            {...{
              metaDataType,
            }}
          />
        </Dialog>
      ) : null}
    </>
  ) : null
}

export default MetaDataTypeAdminDialog
