import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { debounce } from 'lodash/fp'
import { CSSProperties, useCallback } from 'react'
import Autosuggest from 'react-autosuggest'
import { connect, ConnectedProps } from 'react-redux'

import { MenuItem, ListItemText, TextField, FormControl, Paper } from '@mui/material'

import { RootState } from 'Common/store/createStore'
import {
  searchThema as searchThemaAction,
  clearThemaSearch as clearThemaSearchAction,
} from 'Common/thema/themaActions'
import { SelectedThemaCodeFilter } from 'Common/thema/types'
import { requestStatus } from 'Common/utils/net/statuses'
import { getMyLanguageCodes } from 'Common/utils/user/auth'

const styles = {
  container: {
    position: 'relative',
  } as CSSProperties,
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '8px',
    left: 0,
    right: 0,
  } as CSSProperties,
  suggestion: {
    display: 'block',
  } as CSSProperties,
  suggestionsList: {
    listStyleType: 'none',
    maxHeight: 300,
    overflowY: 'auto',
  } as CSSProperties,
}

const renderInputComponent = (inputProps) => {
  const {
    classes,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    inputRef = () => {},
    ref,
    label,
    ...other
  }: {
    classes: any
    inputRef: (..._args: Array<any>) => any
    label: string
    ref: (..._args: Array<any>) => any
  } = inputProps
  return (
    <FormControl variant="standard" fullWidth>
      <TextField
        variant="standard"
        autoFocus
        fullWidth
        label={label}
        InputProps={{
          inputRef: (node) => {
            ref(node)
            inputRef()
          },
        }}
        {...other}
      />
    </FormControl>
  )
}

const renderSuggestionsContainer = (options) => {
  return (
    <Paper {...options.containerProps} square>
      {options.children}
    </Paper>
  )
}

const getSuggestionValue = (suggestion) => suggestion

const DisplaySuggested = (props: {
  suggestedValue: string
  query: string
  fontWeight?: number
}) => {
  const { suggestedValue, query, fontWeight = 600 } = props
  if (!suggestedValue) return null

  const matchesCodeValue = match(suggestedValue, query)
  const partsCodeValue = parse(suggestedValue, matchesCodeValue)
  return (
    <>
      {partsCodeValue.map((partEmail, index) =>
        partEmail.highlight ? (
          <span
            key={String(index)}
            style={{
              fontWeight: fontWeight,
            }}>
            {partEmail.text}
          </span>
        ) : (
          <strong
            key={String(index)}
            style={{
              fontWeight: 300,
            }}>
            {partEmail.text}
          </strong>
        ),
      )}
    </>
  )
}

const renderSuggestion = (
  suggestion: { primaryText: string; secondaryText: string; disabled: boolean },
  { query, isHighlighted },
) => {
  const { primaryText, secondaryText, disabled } = suggestion
  return (
    <MenuItem selected={isHighlighted} component="div" disabled={disabled}>
      <ListItemText
        primary={<DisplaySuggested fontWeight={500} suggestedValue={secondaryText} query={query} />}
        secondary={<DisplaySuggested suggestedValue={primaryText} query={query} />}
      />
    </MenuItem>
  )
}

type Props = {
  onAddOption: (themaCode: SelectedThemaCodeFilter) => any
  themaSearchValue: string | null
  setThemaSearchValue: any
}

const mapState = (state: RootState) => {
  const { auth, metaData, themaCodes } = state
  const languageCodes = getMyLanguageCodes(auth, metaData.data.publishingHouseGroups)
  const [firstLanguage] = languageCodes

  return {
    firstLanguage,
    themaCodes: themaCodes.data,
    themaCodesStatus: themaCodes.status.getStatus,
  }
}

const mapDisp = {
  searchThema: searchThemaAction,
  clearThemaSearch: clearThemaSearchAction,
}

const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

const ThemaCodePicker = (props: Props & PropsFromRedux) => {
  const {
    searchThema,
    clearThemaSearch,
    themaSearchValue,
    setThemaSearchValue,
    themaCodesStatus,
    themaCodes,
    onAddOption,
    firstLanguage,
  } = props

  const debounceSearchThema = useCallback(
    debounce(300, (searchConfig) => searchThema(searchConfig, true)),
    [],
  )

  const onUpdateInput = (_event, change) => {
    if (change.method === 'type') {
      const { newValue } = change

      setThemaSearchValue(newValue)

      const searchConfig = {
        filter: {
          languageCode: firstLanguage,
        },
        search: newValue,
        top: 200,
        orderBy: ['codeValue'],
      }

      if (newValue.length >= 2) {
        debounceSearchThema(searchConfig)
      } else {
        clearThemaSearch()
      }
    }

    if (change.method === 'click' || change.method === 'enter') {
      onNewRequest(change.newValue)
    }
  }

  const onNewRequest = (value) => {
    const selectedThemaCode = themaCodes.find((c) => c.document.codeValue === value.secondaryText)
    setThemaSearchValue(selectedThemaCode.document.codeDescription)
    onAddOption({
      codeDescription: selectedThemaCode.document.codeDescription,
      codeValue: selectedThemaCode.document.codeValue,
    })
  }

  const getSuggestions = () => {
    return themaCodesStatus === requestStatus.request
      ? [
          {
            primaryText: 'Searching...',
            secondaryText: 'LOADING',
            disabled: true,
          },
        ]
      : themaCodes.map((themaCode) => ({
          primaryText: themaCode.document.codeDescription,
          secondaryText: themaCode.document.codeValue,
        }))
  }

  const inputProps = {
    label: 'Search Thema',
    autoFocus: true,
    value: themaSearchValue,
    onChange: onUpdateInput,
  }
  const autosuggestProps = {
    renderInputComponent,
    label: `Search Thema`,
    suggestions: getSuggestions(),
    getSuggestionValue: getSuggestionValue,
    renderSuggestion: renderSuggestion,
    renderSuggestionsContainer: renderSuggestionsContainer,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuggestionsClearRequested: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuggestionsFetchRequested: () => {},
  }
  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={inputProps}
      theme={{
        container: styles.container,
        suggestionsContainerOpen: styles.suggestionsContainerOpen,
        suggestionsList: styles.suggestionsList,
        suggestion: styles.suggestion,
      }}
    />
  )
}

export default connector(ThemaCodePicker)
