import IconLock from '@mui/icons-material/Lock'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

const UNKNOWN_READ_ONLY_REASON = 'This field is locked because unknown reason.'
const READ_ONLY_REASONS = {
  'NON-WRITABLE': 'This field is locked because this is not an editable field.',
  'SHARED-INFO': 'This field is locked because the information is imported from another edition.',
  'VALUE-ALREADY-SET':
    'This field is locked because the information cannot be changed once isbn is set.',
  'PRODUCTION-CANCELLED': 'This field is locked because the edition is canceled.',
  'CALCULATED-FIELD': 'This field is locked because this is a calculated field.',
  'CONNECTED-TO-ERP':
    'This field is locked because it cannot be edited because it is connected to ERP.',
  'REQUIRES-ERP-CONNECTION':
    'This field is locked because editing is not possible until a connection to ERP exists.',
  'MISSING-ERP-ACCESS': 'This field is locked because you do not have ERP access rights.',
  'NOT-IMPLEMENTED': 'This field is locked because the editing functionality is not finished yet.',
}

const getReadOnlyReason = (reasons = []) => {
  if (reasons.length === 0) return UNKNOWN_READ_ONLY_REASON
  return `${READ_ONLY_REASONS[reasons[0]] || UNKNOWN_READ_ONLY_REASON}`
}

type Props = {
  readOnlyReasons: string[]
}

const FormFieldInfoReadOnly = ({ readOnlyReasons }: Props) => (
  <Grid
    xs={12}
    sx={{
      borderTop: '1px dashed black',
      backgroundColor: 'warning.main',
    }}>
    <Grid xs={12}>
      <Grid container spacing={1} xs={12}>
        <Grid xs={2} display="flex" justifyContent="center">
          <IconLock />
        </Grid>
        <Grid xs={10}>
          <Typography fontWeight="bold">LOCKED FIELD</Typography>
        </Grid>
        <Grid xsOffset={2} xs={10}>
          <Typography variant="body2"> {getReadOnlyReason(readOnlyReasons)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

export default FormFieldInfoReadOnly
