import { getOr } from 'lodash/fp'

import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import { serializeSearchOutput } from 'Common/search/searchOutputSerializers'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const POST_MEMBER_WORK = 'POST_MEMBER_WORK'
export const POST_MEMBER_WORK_REQUEST = 'POST_MEMBER_WORK_REQUEST'
export const POST_MEMBER_WORK_SUCCESS = 'POST_MEMBER_WORK_SUCCESS'
export const POST_MEMBER_WORK_FAILURE = 'POST_MEMBER_WORK_FAILURE'
export const POST_MEMBER_PRODUCTION = 'POST_MEMBER_PRODUCTION'
export const POST_MEMBER_PRODUCTION_REQUEST = 'POST_MEMBER_PRODUCTION_REQUEST'
export const POST_MEMBER_PRODUCTION_SUCCESS = 'POST_MEMBER_PRODUCTION_SUCCESS'
export const POST_MEMBER_PRODUCTION_FAILURE = 'POST_MEMBER_PRODUCTION_FAILURE'
export const CONNECT_MEMBER_EDITIONS = 'CONNECT_MEMBER_EDITIONS'
export const CONNECT_MEMBER_EDITIONS_REQUEST = 'CONNECT_MEMBER_EDITIONS_REQUEST'
export const CONNECT_MEMBER_EDITIONS_SUCCESS = 'CONNECT_MEMBER_EDITIONS_SUCCESS'
export const CONNECT_MEMBER_EDITIONS_FAILURE = 'CONNECT_MEMBER_EDITIONS_FAILURE'
export const DELETE_MEMBER_WORK = 'DELETE_MEMBER_WORK'
export const DELETE_MEMBER_WORK_REQUEST = 'DELETE_MEMBER_WORK_REQUEST'
export const DELETE_MEMBER_WORK_SUCCESS = 'DELETE_MEMBER_WORK_SUCCESS'
export const DELETE_MEMBER_WORK_FAILURE = 'DELETE_MEMBER_WORK_FAILURE'
export const DELETE_MEMBER_PRODUCTION = 'DELETE_MEMBER_PRODUCTION'
export const DELETE_MEMBER_PRODUCTION_REQUEST = 'DELETE_MEMBER_PRODUCTION_REQUEST'
export const DELETE_MEMBER_PRODUCTION_SUCCESS = 'DELETE_MEMBER_PRODUCTION_SUCCESS'
export const DELETE_MEMBER_PRODUCTION_FAILURE = 'DELETE_MEMBER_PRODUCTION_FAILURE'
export const PUT_MEMBER_WORK = 'PUT_MEMBER_WORK'
export const PUT_MEMBER_WORK_REQUEST = 'PUT_MEMBER_WORK_REQUEST'
export const PUT_MEMBER_WORK_SUCCESS = 'PUT_MEMBER_WORK_SUCCESS'
export const PUT_MEMBER_WORK_FAILURE = 'PUT_MEMBER_WORK_FAILURE'
export const PUT_MEMBER_PRODUCTION = 'PUT_MEMBER_PRODUCTION'
export const PUT_MEMBER_PRODUCTION_REQUEST = 'PUT_MEMBER_PRODUCTION_REQUEST'
export const PUT_MEMBER_PRODUCTION_SUCCESS = 'PUT_MEMBER_PRODUCTION_SUCCESS'
export const PUT_MEMBER_PRODUCTION_FAILURE = 'PUT_MEMBER_PRODUCTION_FAILURE'
const POST_MEMBER_WORK_ERROR_MESSAGES = createErrorMessages(
  [
    'WORK_NOT_FOUND',
    'WORK_FORBIDDEN',
    'MEMBERS_CONTACT_NOT_FOUND',
    'MEMBERS_CONTACT_ROLE_NOT_FOUND',
  ],
  messages,
)
const POST_MEMBER_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
  [
    'PRODUCTION_NOT_FOUND',
    'PRODUCTION_FORBIDDEN',
    'MEMBERS_CONTACT_NOT_FOUND',
    'MEMBERS_CONTACT_ROLE_NOT_FOUND',
    'MEMBERS_CREATOR_MUST_BE_ADDED_TO_WORK',
  ],
  messages,
)
const CONNECT_MEMBER_EDITIONS_MESSAGES = createErrorMessages([], messages)

export const connectMemberEditions = (searchConfig, contactId, roleId) => (dispatch, getState) => {
  const searchOptions = getOr({}, `search[${searchConfig.id}].searchOptions`, getState())
  const itemsTotalCount = getOr({}, `search[${searchConfig.id}].paging.itemsTotalCount`, getState())
  const searchQuery = serializeSearchOutput(searchOptions, searchConfig)
  return dispatch({
    type: CONNECT_MEMBER_EDITIONS,
    meta: {
      searchConfig,
      contactId,
      roleId,
    },
    payload: {
      promise: request({
        method: 'POST',
        url: services.work(`/v1/productions/members`),
        body: {
          contactId,
          roleId,
          search: decodeURIComponent(searchQuery),
          itemsTotalCount,
        },
        errorMessages: CONNECT_MEMBER_EDITIONS_MESSAGES,
      }),
    },
  })
}

export const postMember = (
  workId,
  productionId,
  contactId,
  roleId,
  productionIdInAdditionToWork,
) => {
  const body = {
    id: contactId,
    roleId,
  }
  const url = productionId
    ? services.work(`/v1/works/${workId}/productions/${productionId}/members`)
    : services.work(
        `/v1/works/${workId}/members${
          productionIdInAdditionToWork ? `?addOnProductions=${productionIdInAdditionToWork}` : ''
        }`,
      )
  return (dispatch) =>
    dispatch({
      type: productionId ? POST_MEMBER_PRODUCTION : POST_MEMBER_WORK,
      meta: {
        workId,
        productionId,
        contactId,
        roleId,
      },
      payload: {
        promise: request({
          method: 'POST',
          body: body,
          url,
          errorMessages: productionId
            ? POST_MEMBER_PRODUCTION_ERROR_MESSAGES
            : POST_MEMBER_WORK_ERROR_MESSAGES,
        }),
      },
    })
}
const DELETE_MEMBER_WORK_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN', 'MEMBERS_NOT_FOUND'],
  messages,
)
const DELETE_MEMBER_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'MEMBERS_NOT_FOUND'],
  messages,
)
export const deleteMember = (workId, productionId, contactId, deleteOnAllProductions = false) => {
  const url = productionId
    ? services.work(`/v1/works/${workId}/productions/${productionId}/members/${contactId}`)
    : services.work(
        `/v1/works/${workId}/members/${contactId}?deleteOnAllProductions=${deleteOnAllProductions}`,
      )
  return (dispatch) =>
    dispatch({
      type: productionId ? DELETE_MEMBER_PRODUCTION : DELETE_MEMBER_WORK,
      meta: {
        workId,
        productionId,
        contactId,
      },
      payload: {
        promise: request({
          method: 'DELETE',
          url,
          errorMessages: productionId
            ? DELETE_MEMBER_PRODUCTION_ERROR_MESSAGES
            : DELETE_MEMBER_WORK_ERROR_MESSAGES,
        }),
      },
    })
}
const PUT_MEMBER_WORK_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN', 'MEMBERS_NOT_FOUND'],
  messages,
)
const PUT_MEMBER_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'MEMBERS_NOT_FOUND'],
  messages,
)
export const putMember = (workId, productionId, body) => {
  const url = productionId
    ? services.work(`/v1/works/${workId}/productions/${productionId}/members/${body.id}`)
    : services.work(`/v1/works/${workId}/members/${body.id}`)
  return (dispatch) =>
    dispatch({
      type: productionId ? PUT_MEMBER_PRODUCTION : PUT_MEMBER_WORK,
      meta: {
        workId,
        productionId,
        body,
      },
      payload: {
        promise: request({
          method: 'PUT',
          body: body,
          url,
          errorMessages: productionId
            ? PUT_MEMBER_PRODUCTION_ERROR_MESSAGES
            : PUT_MEMBER_WORK_ERROR_MESSAGES,
        }),
      },
    })
}
