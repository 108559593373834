import { Component, Fragment } from 'react'

import Link from '@mui/material/Link'

import ContactModal from './ContactModal'

type Props = {
  id?: number
  firstName: string
  lastName: string
  lastNameFirst: boolean
  roleFirst: boolean
  role?: {
    name: string
  }
}

type State = {
  showModal: boolean
}

class Contact extends Component<Props, State> {
  static defaultProps = {
    firstName: '',
    lastName: '',
    lastNameFirst: false,
    roleFirst: false,
  }

  state = {
    showModal: false,
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    const { id, firstName, lastName, lastNameFirst, role, roleFirst } = this.props
    const { showModal } = this.state
    const name = (
      lastNameFirst && lastName
        ? `${lastName}, ${firstName}`
        : `${firstName}${lastName ? ' ' + lastName : ''}`
    ).trim()
    const displayName = roleFirst
      ? `${role && role.name ? role.name + ': ' : ''} ${name}`
      : `${name}${role && role.name ? ' (' + role.name + ')' : ''}`
    return (
      <Fragment>
        <Link
          color="primary"
          underline="hover"
          sx={{ textAlign: 'left', cursor: 'pointer' }}
          onClick={this.toggleModal}>
          {displayName}
        </Link>
        {showModal ? (
          <ContactModal
            show={showModal}
            toggleModal={this.toggleModal}
            {...{
              id,
            }}
          />
        ) : null}
      </Fragment>
    )
  }
}

export default Contact
