import { getContactLists as getContactListsAction } from 'Contact/actions/contactListsActions'
import { isEqual, pick } from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { MenuItem, FormControl, Select, InputLabel } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import { MetaData } from 'Common/metadata'
import FilterSelectionList from 'Common/search/filter/FilterSelectionList'
import { RootState } from 'Common/types'

type Props = {
  updateFilter(..._args: unknown[]): unknown
  filter: {
    id: string
    name?: string
  }
  active: unknown[]
  metaData: MetaData
  chipColor?: string
}

const mapState = (state: RootState) => {
  const { contactLists } = state
  return {
    contactLists,
  }
}

const connector = connect(mapState, {
  getContactLists: getContactListsAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

class ContactListFilter extends Component<Props & PropsFromRedux> {
  static defaultProps = {
    active: [],
  }

  UNSAFE_componentWillMount() {
    const { getContactLists } = this.props
    getContactLists()
  }

  onAddOption = (id) => {
    const { filter, active, updateFilter, contactLists } = this.props
    const newOption = pick(
      ['id', 'name'],
      contactLists.allLists.find((list) => list.id === id),
    )
    updateFilter(
      filter.id,
      active.concat({
        id: `${newOption.id}`,
        name: newOption.name,
      }),
    )
  }

  onRemoveOption = (option) => {
    const { filter, active, updateFilter } = this.props
    const updatedActive = active.filter((activeOption) => !isEqual(activeOption, option))
    updateFilter(filter.id, updatedActive)
  }

  render() {
    const { filter, active, contactLists, metaData, chipColor } = this.props

    if (contactLists.getStatus !== 'success') {
      return (
        <div
          style={{
            textAlign: 'center',
          }}>
          <Spinner />
        </div>
      )
    }

    return (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>{filter.name}</InputLabel>
            <Select variant="standard" onChange={(event) => this.onAddOption(event.target.value)}>
              {contactLists.allLists.map((element, index) => (
                <MenuItem key={index} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FilterSelectionList
            onRemoveOption={this.onRemoveOption}
            {...{
              filter,
              active,
              metaData,
              chipColor,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default connector(ContactListFilter)
