import { uniq } from 'lodash/fp'

const assetMetaDataFields = ['assetConnectionTypes', 'thumbnailWidthSizes']
const conceptMetaDataKey = [
  'accessLevels',
  'contractTypes',
  'costTypes',
  'effortTypes',
  'imageTypes',
  'insidePapers',
  'interestAges',
  'layoutExpressions',
  'productionLanguages', // FIXME Change when original publication languages meta data exist
  'productionTypes',
  'retailerChannels',
  'searchConfigurations',
  'seasonPeriods',
  'seasonYears',
  'targetGroups',
  'themaCodes',
  'userAccessLevels',
  'publishingLists',
]
const workMetaDataFields = [
  'activityCategories',
  'activitySharinglevels',
  'activityStatus',
  'activityTemplatePackages',
  'articleGroups',
  'backlistSeasonPeriods',
  'backlistSeasonYears',
  'bonnierRightsCategory',
  'bonnierRightsSubCategory',
  'bookBindingBinders',
  'bookBindingBindings',
  'bookBindingSpineTypes',
  'bookClubMaterials',
  'bookClubs',
  'contractFormats',
  'contractTypes',
  'costCenters',
  'coverPlacements',
  'customerSubGroups',
  'deliveryItemCategories',
  'deliveryTypes',
  'dispositionCodes',
  'educationalQualifierCodes',
  'educationalSubjects',
  'educationalSubjectTypes',
  'effortTypes',
  'erpStatusCodes',
  'foePapers',
  'imageSheetPages',
  'imageSheetPapers',
  'imageSheetPrintings',
  'insideAlternateFormatHeights',
  'insideAlternateFormatWidths',
  'insideBulks',
  'insidePapers',
  'insidePrintings',
  'insideTrimmedFormatHeights',
  'insideTrimmedFormatWidths',
  'insideWeights',
  'interestAges',
  'internalCategories',
  'jacketPapers',
  'laminations',
  'libraryCodes',
  'libraryCodePrefixes',
  'linkTypes',
  'listingCodes',
  'mainGroups',
  'materialForPrintings',
  'months',
  'postingGroups',
  'priceGroupPockets',
  'printedCoverPapers',
  'printings',
  'prioLevels',
  'productionLanguages',
  'productionStatuses',
  'retailerChannels',
  'salesChannels',
  'salesStatus',
  'salesTypes',
  'sharedInfo-ebook',
  'sharedInfo-editorial',
  'sharedInfo-publishing',
  'softCoverPapers',
  'subGroups',
  'surfaceFinishes',
  'targetGroups',
  'targetGroupThemes',
  'taxCodes',
  'technicalProductionTypes',
  'textTypes',
  'themaCodes',
  'timePlanEntryTypes',
  'timePlanPackages',
  'weeks',
  'yesAndNo',
  'publishingLists',
  'accountingMappings',
]
const adminMetaDataFields = ['claimTypes']
const contactMetaDataFields = [
  'contactAddressTypes',
  'contactTextTypes',
  'customerGroups',
  'customerSubGroups',
]
const metaDataFields = uniq([
  'assetContractTypes',
  'assetTypes',
  'conceptStatuses',
  'awards',
  'bindingCodes',
  'brands',
  'contactDetailDataNames',
  'contactRoles',
  'countries',
  'publishingHouseGroups',
  'publishingHouses',
  'searchConfigurations',
  'seasonPeriods',
  'seasonYears',
  'seasons',
  ...adminMetaDataFields,
  ...contactMetaDataFields,
  ...workMetaDataFields,
  ...assetMetaDataFields,
  ...conceptMetaDataKey,
])
export default metaDataFields
