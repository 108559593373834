import { services } from 'Common/environment'
import { AppDispatch } from 'Common/types'
import request from 'Common/utils/net/request'

export const GET_PRODUCTION_ESTIMATED_SALES = 'GET_PRODUCTION_ESTIMATED_SALES'
export const GET_PRODUCTION_ESTIMATED_SALES_REQUEST = 'GET_PRODUCTION_ESTIMATED_SALES_REQUEST'
export const GET_PRODUCTION_ESTIMATED_SALES_SUCCESS = 'GET_PRODUCTION_ESTIMATED_SALES_SUCCESS'
export const GET_PRODUCTION_ESTIMATED_SALES_FAILURE = 'GET_PRODUCTION_ESTIMATED_SALES_FAILURE'
export const PATCH_PRODUCTION_ESTIMATED_SALES = 'PATCH_PRODUCTION_ESTIMATED_SALES'
export const PATCH_PRODUCTION_ESTIMATED_SALES_REQUEST = 'PATCH_PRODUCTION_ESTIMATED_SALES_REQUEST'
export const PATCH_PRODUCTION_ESTIMATED_SALES_SUCCESS = 'PATCH_PRODUCTION_ESTIMATED_SALES_SUCCESS'
export const PATCH_PRODUCTION_ESTIMATED_SALES_FAILURE = 'PATCH_PRODUCTION_ESTIMATED_SALES_FAILURE'

export const getProductionEstimatedSales =
  (workId: number, productionId: number) => (dispatch: AppDispatch) =>
    dispatch({
      type: GET_PRODUCTION_ESTIMATED_SALES,
      payload: {
        data: {
          workId,
          productionId,
        },
        promise: request({
          method: 'GET',
          url: services.work(`/v1/works/${workId}/productions/${productionId}/estimatedsales`),
        }),
      },
    })
export const patchProductionEstimatedSales =
  (workId: number, productionId: number, patchSet: any) => (dispatch: AppDispatch) =>
    dispatch({
      type: PATCH_PRODUCTION_ESTIMATED_SALES,
      payload: {
        data: {
          workId,
          productionId,
        },
        promise: request({
          method: 'PATCH',
          url: services.work(`/v1/works/${workId}/productions/${productionId}/estimatedsales`),
          body: patchSet,
        }),
      },
    })
