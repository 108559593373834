import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
  GET_PRODUCTION_SALES_TARGET_REQUEST,
  GET_PRODUCTION_SALES_TARGET_SUCCESS,
  GET_PRODUCTION_SALES_TARGET_FAILURE,
  PUT_PRODUCTION_SALES_TARGET_REQUEST,
  PUT_PRODUCTION_SALES_TARGET_SUCCESS,
  PUT_PRODUCTION_SALES_TARGET_FAILURE,
} from '../actions/productionSalesTargetActions'

const initialState = {
  status: {
    getStatus: undefined,
    getError: undefined,
  },
  data: undefined,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_REQUEST:
      return { ...initialState }

    case GET_PRODUCTION_SALES_TARGET_REQUEST:
      return {
        ...state,
        workId: action.payload.workId,
        productionId: action.payload.productionId,
        status: { ...state.status, getStatus: requestStatus.request },
      }

    case GET_PRODUCTION_SALES_TARGET_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_PRODUCTION_SALES_TARGET_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    case PUT_PRODUCTION_SALES_TARGET_REQUEST:
      return { ...state, status: { ...state.status, putStatus: requestStatus.request } }

    case PUT_PRODUCTION_SALES_TARGET_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, putStatus: requestStatus.success },
      }

    case PUT_PRODUCTION_SALES_TARGET_FAILURE:
      return {
        ...state,
        status: { ...state.status, putStatus: requestStatus.failure, putError: action.payload },
      }

    default:
      return state
  }
}
