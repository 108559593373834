import { isEqual } from 'lodash/fp'
import { Component } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { MetaData } from 'Common/metadata'

import { getFilterColorFromIndex, FilterChip } from './filter/filters'

const getFilterColor = (isExpanded, totalFilters) => {
  if (totalFilters === 0) return '#eeeeee'
  return isExpanded ? '#e6ee9c' : '#c0ca33'
}

const getOptionDisplay = ({ filter, value }, metaData) => {
  const displayFormat = filter.displayFormat || filter.type.displayFormat
  const displayName =
    filter.hideActiveFilterName || filter.type.hideActiveFilterName
      ? ''
      : `${filter.name.toUpperCase()} - `
  return displayFormat
    ? `${displayName}${displayFormat(value, filter, metaData)}`
    : `${displayName}${value}`
}

type Props = {
  updateFilter(..._args: unknown[]): unknown
  toggleFilterSelection(..._args: unknown[]): unknown
  handleToggleFilterExpand(..._args: unknown[]): unknown
  searchOptions: any
  totalFilters: number
  metaData: MetaData
  filters?: {
    id: string
    name: string
    type: {
      Component: React.ElementType
      displayFormat?(..._args: unknown[]): unknown
    }
    typeConfig?: Record<string, unknown>
    displayFormat?(..._args: unknown[]): unknown
  }[]
  showFilterSelection: boolean
}

class ActiveFilters extends Component<Props> {
  state = {
    isExpanded: false,
  }

  onRemoveOption = (option) => {
    const { searchOptions, updateFilter } = this.props
    const { id } = option.filter
    const active = searchOptions[id]
    const updatedActive = active.filter((activeOption) => !isEqual(activeOption, option.value))
    updateFilter(id, updatedActive)
  }

  render() {
    const {
      totalFilters,
      filters,
      searchOptions,
      metaData,
      handleToggleFilterExpand,
      toggleFilterSelection,
      showFilterSelection,
    } = this.props
    const { isExpanded } = this.state
    const allFilterOptions =
      totalFilters === 0
        ? []
        : filters
            .filter((filter) => Array.isArray(searchOptions[filter.id]))
            .map((filter, index) =>
              searchOptions[filter.id].map((option) => ({
                filter,
                value: option,
                index,
              })),
            )
            .reduce((acc, cur) => acc.concat(cur))
    return (
      <Accordion>
        <AccordionSummary
          sx={{
            backgroundColor: getFilterColor(isExpanded, totalFilters),
            display: 'flex',
            displayPrint: 'none',
          }}
          expandIcon={<ExpandMoreIcon />}>
          <Grid container xs={12} spacing={1}>
            <Grid xs display="flex" alignItems="center">
              <Typography variant="subtitle1">
                {totalFilters === 0 ? '' : `ACTIVE FILTERS (${allFilterOptions.length})`}
              </Typography>
            </Grid>
            <Grid display="flex" justifyContent="flex-end">
              <Button
                fullWidth
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation()
                  toggleFilterSelection()
                }}
                color="primary">
                {showFilterSelection ? 'Hide filters' : 'Filters'}
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {allFilterOptions.map((option, index) => (
              <FilterChip
                key={index}
                clickable
                onClick={() => handleToggleFilterExpand(option.filter.id, true)}
                onDelete={() => this.onRemoveOption(option)}
                chipColor={getFilterColorFromIndex(option.index)}
                label={getOptionDisplay(option, metaData)}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default ActiveFilters
