import { services } from 'Common/environment'

import request from '../utils/net/request'

export const GET_PRODUCTION_HISTORY = 'GET_PRODUCTION_HISTORY'
export const GET_PRODUCTION_HISTORY_REQUEST = 'GET_PRODUCTION_HISTORY_REQUEST'
export const GET_PRODUCTION_HISTORY_SUCCESS = 'GET_PRODUCTION_HISTORY_SUCCESS'
export const GET_PRODUCTION_HISTORY_FAILURE = 'GET_PRODUCTION_HISTORY_FAILURE'
export const getProductionHistory = (workId, productionId, fields) => (dispatch) =>
  dispatch({
    type: GET_PRODUCTION_HISTORY,
    payload: {
      data: {
        workId,
        productionId,
      },
      promise: request({
        method: 'GET',
        url: services.work(
          `/v1/works/${workId}/productions/${productionId}/history${
            fields ? `?fields=${fields}` : ''
          }`,
        ),
      }),
    },
  })
