import { Retailer } from 'Common/types'
import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_RETAILERS_REQUEST,
  GET_RETAILERS_SUCCESS,
  GET_RETAILERS_FAILURE,
} from '../actions/retailerActions'

const initialState = {
  status: {
    getStatus: undefined,
    getError: undefined,
  },
  retailers: [],
  data: undefined,
}

type State = {
  retailers: Retailer[]
  status: {
    getStatus: any
    getError: any
  }
  data: any
}

export default (state: State = initialState, action) => {
  switch (action.type) {
    case GET_RETAILERS_REQUEST:
      return {
        ...state,
        data: {},
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_RETAILERS_SUCCESS:
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_RETAILERS_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    default:
      return state
  }
}
