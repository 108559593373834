import { getOr, isEmpty, isUndefined } from 'lodash/fp'
import * as React from 'react'

import IconDelete from '@mui/icons-material/Delete'
import { FormControl, TextField, MenuItem, InputAdornment } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'

import MetaDataTypeAdminDialog from '../../Admin/components/metadata/type/MetaDataTypeAdminDialog'
import commonMessages from '../messages'
import { FormFieldInfoToggler, FormFieldInfo } from './fieldinfo'
import { getFieldInfo, getLabelText, getErrorText, getHasError } from './formFieldHelpers'

const PREFIX = 'FormSelectField'

const classes = {
  selectIcon: `${PREFIX}-selectIcon`,
  selectRoot: `${PREFIX}-selectRoot`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.selectIcon}`]: {
    left: 0,
  },

  [`& .${classes.selectRoot}`]: {
    paddingLeft: theme.spacing(3),
  },
}))

type Props = {
  input: any
  meta: any
  defaultValue?: Record<string, unknown>
  label?: string
  style?: Record<string, unknown>
  infoText?: string
  onHandleChange?(..._args: unknown[]): unknown
  children?: React.ReactNode
  metaData?: any
  iconStyle?: Record<string, unknown>
  showMetaDataAdmin?: boolean
  metaDataType?: string
  invalidMenuItem?: Record<string, unknown>
  required?: boolean
  disabled?: boolean
  id?: string
  remove?(..._args: unknown[]): unknown
  propertyRules?: Record<string, unknown>
  allowEmpty?: boolean
  disableUnderline?: boolean
  disableLabel?: boolean
  history?: string
  dispatch?: any
  value?: any
}

type State = {
  showInfoTextDialog?: boolean
  clickedInfoButton: boolean
  formMetaData: any
}

export class Component extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
    required: false,
    propertyRules: {},
    allowEmpty: false,
    defaultValue: {
      id: '',
      name: '',
    },
  }

  state = {
    formMetaData: this.props.metaData,
    clickedInfoButton: false,
    showInfoTextDialog: false,
  }

  UNSAFE_componentWillMount() {
    this.setupMetaData(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.metaData === this.props.metaData) return
    this.setupMetaData(nextProps)
  }

  onChange(event) {
    if (this.props.onHandleChange) {
      this.props.onHandleChange(event.target.value)
    } else {
      this.props.input.onChange(event.target.value)
    }
  }

  setupMetaData(props) {
    const {
      metaData,
      input,
      propertyRules: { metadataFilter },
    } = props

    if (metaData) {
      const filteredMetaData = (
        !metadataFilter
          ? metaData
          : metaData.filter((md) => {
              const filterValue = getOr(null, `customProperties['${metadataFilter.id}']`, md)
              if (!filterValue) return false
              return Array.isArray(filterValue)
                ? filterValue.includes(metadataFilter.value)
                : filterValue === metadataFilter.value
            })
      ).filter((md) => md.isActive !== false)
      const validMeta = filteredMetaData.find((element) => element.id === input.value)

      if (validMeta || isEmpty(input.value)) {
        this.setState({
          formMetaData: filteredMetaData,
        })
      } else {
        const validDisabledMeta = metaData.find((element) => element.id === input.value) || {}
        const disabledMeta = {
          ...validDisabledMeta,
          id: getOr(input.value, 'id', validDisabledMeta),
          name: getOr(input.value, 'name', validDisabledMeta),
          disabled: true,
        }
        this.setState({
          formMetaData: [disabledMeta, ...filteredMetaData],
        })
      }
    }
  }

  clickIcon = () => {
    this.setState({
      clickedInfoButton: !this.state.clickedInfoButton,
    })
  }

  renderChildren() {
    const { metaData, children, allowEmpty } = this.props
    if (!metaData) return children
    return [
      allowEmpty
        ? {
            id: null,
            name: commonMessages.action.remove,
          }
        : '',
    ]
      .filter((_) => _)
      .concat(this.state.formMetaData)
      .map((element: any, index) => (
        <MenuItem disabled={element.disabled} value={element.id} key={index}>
          {element.name}
        </MenuItem>
      ))
  }

  render() {
    const {
      input,
      meta,
      id,
      infoText,
      remove,
      propertyRules,
      metaData,
      onHandleChange,
      allowEmpty,
      showMetaDataAdmin,
      metaDataType,
      disableUnderline,
      disableLabel,
      dispatch,
      history,
      ...otherProps
    } = this.props
    const { required, label, style, disabled } = this.props
    const { name } = input
    const { error, touched } = meta
    const { showInfoTextDialog, clickedInfoButton } = this.state
    const fieldInfo = getFieldInfo(propertyRules, infoText)
    const isDisabled = disabled || fieldInfo.isReadOnly
    const hasRemove = !isUndefined(remove) && !isDisabled
    const nestedProps = {
      ...input,
      ...otherProps,
      required,
      label: disableLabel ? '' : getLabelText(label, propertyRules),
      helperText: getErrorText(touched, error),
      error: getHasError(touched, error),
      style: { ...style },
      disabled: isDisabled,
    }
    return (
      <StyledGrid>
        <FormControl variant="standard" fullWidth>
          <TextField
            variant="standard"
            fullWidth
            select
            id={id || `FormSelectField-${name}-${label}`.replace(/[^A-Za-z0-9-]/gi, '')}
            {...nestedProps}
            onChange={this.onChange.bind(this)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onBlur={() => {}}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              classes: {
                select: classes.selectRoot,
                icon: classes.selectIcon,
              },
            }}
            InputProps={{
              disableUnderline: disableUnderline,
              endAdornment: (
                <InputAdornment position="end">
                  <FormFieldInfoToggler
                    clickIcon={this.clickIcon}
                    showInfoButton={fieldInfo.showInfoButton}
                  />
                  <MetaDataTypeAdminDialog
                    {...{
                      metaDataType,
                      showMetaDataAdmin,
                    }}
                  />
                </InputAdornment>
              ),
            }}>
            {this.renderChildren()}
          </TextField>
        </FormControl>
        {hasRemove ? (
          <span>
            <span
              style={{
                display: 'inline-block',
                verticalAlign: 'bottom',
                paddingLeft: 5,
                paddingBottom: 10,
              }}>
              <IconDelete className="link" onClick={remove} />
            </span>
          </span>
        ) : null}
        <FormFieldInfo
          {...{
            fieldInfo,
            propertyRules,
            history,
            infoText,
            showInfoTextDialog,
            clickedInfoButton,
          }}
        />
      </StyledGrid>
    )
  }
}
export default Component
