export const getTimePlanPlannedColumns = (category, cellType) => (timePlanEntryTypes) =>
  timePlanEntryTypes.reduce((acc, timePlanEntryType) => {
    return {
      ...acc,
      [`timeplan-planned-${timePlanEntryType.id}`]: {
        id: `timeplan-planned-${timePlanEntryType.id}`,
        selectField: `activePrint_TimePlan_${timePlanEntryType.id}_Planned`,
        label: `${timePlanEntryType.name || timePlanEntryType.id}, planned`,
        width: 180,
        category,
        cellType,
        sortable: true,
      },
    }
  }, {})
export const getTimePlanActualColumns = (category, cellType) => (timePlanEntryTypes) =>
  timePlanEntryTypes.reduce((acc, timePlanEntryType) => {
    return {
      ...acc,
      [`timeplan-actual-${timePlanEntryType.id}`]: {
        id: `timeplan-actual-${timePlanEntryType.id}`,
        selectField: `activePrint_TimePlan_${timePlanEntryType.id}_Actual`,
        label: `${timePlanEntryType.name || timePlanEntryType.id}, actual`,
        width: 180,
        category,
        cellType,
        sortable: true,
      },
    }
  }, {})
