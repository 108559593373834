import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import messages from '../../messages'
import { requestStatus } from '../../utils/net/statuses'
import Spinner, { SPINNER_SIZES } from './Spinner'

const getDisplayError = (statusesInFailure, firstFailedStatus) => {
  if (statusesInFailure.length > 0) {
    if (!firstFailedStatus || !firstFailedStatus.error || !firstFailedStatus.error.status) {
      return {
        header: messages.error.genericError,
        paragraph: 'Either a page did not exist or something went wrong while loading the page.',
      }
    }

    switch (firstFailedStatus.error.status) {
      case 404:
        return {
          header: messages.error.genericError,
          paragraph: 'The specified page could not be found.',
        }

      case 403:
        return {
          header: 'Access rights are missing',
          paragraph: 'You do not have access rights.',
        }

      case 412:
        return {
          header: 'Missing information',
          paragraph: firstFailedStatus.error.errorMessage,
        }

      case 500:
        return {
          header: messages.error.genericError,
          paragraph: 'Something went wrong while loading the page.',
        }

      default:
        return {
          header: messages.error.genericError,
          paragraph: 'An unexpected error occured while loading the page.',
        }
    }
  }
}

const getStatusesByType = (statuses, statusType) =>
  statuses.filter((status) => status.requestStatus === statusType)

type Props = {
  statuses?: {
    requestStatus?: string
    error?: {
      status?: number
    }
  }[]
  children?: any
  singleVisible?: boolean
  noHeight?: boolean
}

export const StatusLoader = (props: Props) => {
  const { statuses, children, singleVisible } = props
  const statusesInRequest = getStatusesByType(statuses, requestStatus.request)
  const statusesNotStarted = getStatusesByType(statuses, undefined)
  const statusesInFailure = getStatusesByType(statuses, requestStatus.failure)
  const [firstFailedStatus] = statusesInFailure
  const displayError = getDisplayError(statusesInFailure, firstFailedStatus)

  if (!displayError && (statusesInRequest.length > 0 || statusesNotStarted.length > 0)) {
    return <Spinner size={SPINNER_SIZES.LARGE} singleVisible={singleVisible} />
  }

  if (displayError && (displayError.header || displayError.paragraph)) {
    return (
      <Grid container spacing={2} xs={12}>
        <Grid xs={12} display="flex" justifyContent="center">
          <Typography variant="h4">{displayError.header}</Typography>
        </Grid>
        <Grid xs={12} display="flex" justifyContent="center">
          <Typography variant="body1">{displayError.paragraph}</Typography>
        </Grid>
      </Grid>
    )
  }

  return children
}

export default StatusLoader
