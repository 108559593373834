import { get, isNil } from 'lodash/fp'
import { Component } from 'react'

const columnsFromMetaData = (columns, metaData) =>
  columns.reduce((acc, column) => {
    const metaDataColumn = get(column.metaDataId, metaData)
    return { ...acc, ...column.mapMetaDataToColumns(metaDataColumn) }
  }, {})

const defaultColumnsFromMetaDataColumns = (defaultMetaDataColumns, metaData) => {
  return defaultMetaDataColumns.reduce((acc, column) => {
    const metaDataColumns = get(column.metaDataId, metaData)
      .filter(column.filterMetaDataBy)
      .map(column.mapMetaDataToColumns)
    return [...acc, ...metaDataColumns]
  }, [])
}

const getColumns = (columns, metaDataColumns, metaData) =>
  metaDataColumns
    ? { ...(columns || []), ...columnsFromMetaData(metaDataColumns, metaData) }
    : { ...(columns || []) }

const getDefaultColumns = (defaultColumns, defaultMetaDataColumns, metaData) =>
  isNil(defaultMetaDataColumns)
    ? [...(defaultColumns || [])]
    : [
        ...(defaultColumns || []),
        ...defaultColumnsFromMetaDataColumns(defaultMetaDataColumns, metaData),
      ]

type Props = {
  activeView?: any
  metaData?: Record<string, unknown>
  updateSorting?: any
  updateSelectFields?: any
  loadNextPage?: any
  toggleSingle?: any
  toggleAll?: any
}

class SearchControlViews extends Component<Props> {
  render() {
    const { activeView, metaData } = this.props
    if (!activeView || !metaData) return null
    const modifiedActiveView = {
      ...activeView,
      columns: getColumns(activeView.columns, activeView.metaDataColumns, metaData),
      defaultColumns: getDefaultColumns(
        activeView.defaultColumns,
        activeView.defaultMetaDataColumns,
        metaData,
      ),
    }
    return <activeView.Component viewConfig={modifiedActiveView} {...this.props} />
  }
}

export default SearchControlViews
