import { map } from 'lodash/fp'

import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'
import {
  getMyUserId,
  getMyContactIds,
  getMyPublishingHouseIdsApprover,
} from 'Common/utils/user/auth'

import messages from '../messages'

export const POST_CONCEPT = 'POST_CONCEPT'
export const POST_CONCEPT_REQUEST = 'POST_CONCEPT_REQUEST'
export const POST_CONCEPT_SUCCESS = 'POST_CONCEPT_SUCCESS'
export const POST_CONCEPT_FAILURE = 'POST_CONCEPT_FAILURE'
export const POST_CONCEPT_INVITESIGNEES = 'POST_CONCEPT_INVITESIGNEES'
export const POST_CONCEPT_INVITESIGNEES_REQUEST = 'POST_CONCEPT_INVITESIGNEES_REQUEST'
export const POST_CONCEPT_INVITESIGNEES_SUCCESS = 'POST_CONCEPT_INVITESIGNEES_SUCCESS'
export const POST_CONCEPT_INVITESIGNEES_FAILURE = 'POST_CONCEPT_INVITESIGNEES_FAILURE'
export const POST_CONCEPT_SIGN = 'POST_CONCEPT_SIGN'
export const POST_CONCEPT_SIGN_REQUEST = 'POST_CONCEPT_SIGN_REQUEST'
export const POST_CONCEPT_SIGN_SUCCESS = 'POST_CONCEPT_SIGN_SUCCESS'
export const POST_CONCEPT_SIGN_FAILURE = 'POST_CONCEPT_SIGN_FAILURE'
export const POST_CONCEPT_APPROVE = 'POST_CONCEPT_APPROVE'
export const POST_CONCEPT_APPROVE_REQUEST = 'POST_CONCEPT_APPROVE_REQUEST'
export const POST_CONCEPT_APPROVE_SUCCESS = 'POST_CONCEPT_APPROVE_SUCCESS'
export const POST_CONCEPT_APPROVE_FAILURE = 'POST_CONCEPT_APPROVE_FAILURE'
export const POST_CONCEPT_EDITION_APPROVE = 'POST_CONCEPT_EDITION_APPROVE'
export const POST_CONCEPT_EDITION_APPROVE_REQUEST = 'POST_CONCEPT_EDITION_APPROVE_REQUEST'
export const POST_CONCEPT_EDITION_APPROVE_SUCCESS = 'POST_CONCEPT_EDITION_APPROVE_SUCCESS'
export const POST_CONCEPT_EDITION_APPROVE_FAILURE = 'POST_CONCEPT_EDITION_APPROVE_FAILURE'
export const PATCH_CONCEPT = 'PATCH_CONCEPT'
export const PATCH_CONCEPT_REQUEST = 'PATCH_CONCEPT_REQUEST'
export const PATCH_CONCEPT_SUCCESS = 'PATCH_CONCEPT_SUCCESS'
export const PATCH_CONCEPT_FAILURE = 'PATCH_CONCEPT_FAILURE'
export const GET_CONCEPT = 'GET_CONCEPT'
export const GET_CONCEPT_REQUEST = 'GET_CONCEPT_REQUEST'
export const GET_CONCEPT_SUCCESS = 'GET_CONCEPT_SUCCESS'
export const GET_CONCEPT_FAILURE = 'GET_CONCEPT_FAILURE'
export const GET_CONCEPT_SILENT = 'GET_CONCEPT_SILENT'
export const GET_CONCEPT_SILENT_REQUEST = 'GET_CONCEPT_SILENT_REQUEST'
export const GET_CONCEPT_SILENT_SUCCESS = 'GET_CONCEPT_SILENT_SUCCESS'
export const GET_CONCEPT_SILENT_FAILURE = 'GET_CONCEPT_SILENT_FAILURE'
export const GET_CONCEPT_THEMAS = 'GET_CONCEPT_THEMAS'
export const GET_CONCEPT_THEMAS_REQUEST = 'GET_CONCEPT_THEMAS_REQUEST'
export const GET_CONCEPT_THEMAS_SUCCESS = 'GET_CONCEPT_THEMAS_SUCCESS'
export const GET_CONCEPT_THEMAS_FAILURE = 'GET_CONCEPT_THEMAS_FAILURE'
export const GET_MY_CONCEPTS = 'GET_MY_CONCEPTS'
export const GET_MY_CONCEPTS_REQUEST = 'GET_MY_CONCEPTS_REQUEST'
export const GET_MY_CONCEPTS_SUCCESS = 'GET_MY_CONCEPTS_SUCCESS'
export const GET_MY_CONCEPTS_FAILURE = 'GET_MY_CONCEPTS_FAILURE'
export const GET_MY_RELATED_CONCEPTS = 'GET_MY_RELATED_CONCEPTS'
export const GET_MY_RELATED_CONCEPTS_REQUEST = 'GET_MY_RELATED_CONCEPTS_REQUEST'
export const GET_MY_RELATED_CONCEPTS_SUCCESS = 'GET_MY_RELATED_CONCEPTS_SUCCESS'
export const GET_MY_RELATED_CONCEPTS_FAILURE = 'GET_MY_RELATED_CONCEPTS_FAILURE'
export const GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL =
  'GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL'
export const GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL_REQUEST =
  'GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL_REQUEST'
export const GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL_SUCCESS =
  'GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL_SUCCESS'
export const GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL_FAILURE =
  'GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL_FAILURE'
export const GET_DEFAULT_DISTRIBUTION = 'GET_DEFAULT_DISTRIBUTION'
export const GET_DEFAULT_DISTRIBUTION_REQUEST = 'GET_DEFAULT_DISTRIBUTION_REQUEST'
export const GET_DEFAULT_DISTRIBUTION_SUCCESS = 'GET_DEFAULT_DISTRIBUTION_SUCCESS'
export const GET_DEFAULT_DISTRIBUTION_FAILURE = 'GET_DEFAULT_DISTRIBUTION_FAILURE'
export const POST_CLONE_CONCEPT = 'POST_CLONE_CONCEPT'
export const POST_CLONE_CONCEPT_REQUEST = 'POST_CLONE_CONCEPT_REQUEST'
export const POST_CLONE_CONCEPT_SUCCESS = 'POST_CLONE_CONCEPT_SUCCESS'
export const POST_CLONE_CONCEPT_FAILURE = 'POST_CLONE_CONCEPT_FAILURE'
const GET_CONCEPT_ERROR_MESSAGES = createErrorMessages(
  ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN'],
  messages,
)
export const getConcept = (id) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT,
    payload: {
      data: id,
      promise: request({
        method: 'GET',
        url: services.concept(`/v1/concepts/${id}`),
        errorMessages: GET_CONCEPT_ERROR_MESSAGES,
      }),
    },
  })
// for refreshing concept in the background
export const getConceptSilent = (id) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_SILENT,
    payload: {
      data: id,
      promise: request({
        url: services.concept(`/v1/concepts/${id}`),
        errorMessages: GET_CONCEPT_ERROR_MESSAGES,
        method: 'GET',
      }),
    },
  })
export const getConceptThemas = (conceptId) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_THEMAS,
    payload: {
      data: conceptId,
      promise: request({
        method: 'GET',
        url: services.concept(`/v1/concepts/${conceptId}/themas`),
      }),
    },
  })
const PATCH_CONCEPT_ERROR_MESSAGES = createErrorMessages(
  ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN'],
  messages,
)
export const patchConcept = (conceptId, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_CONCEPT,
    payload: {
      data: conceptId,
      promise: request({
        method: 'PATCH',
        url: services.concept(`/v1/concepts/${conceptId}`),
        body: patchSet,
        errorMessages: PATCH_CONCEPT_ERROR_MESSAGES,
      }),
    },
  })
const POST_CONCEPT_ERROR_MESSAGES = createErrorMessages(['MISSING_PUBLISHING_HOUSE'], messages)
export const postConcept = (concept) => (dispatch) =>
  dispatch({
    type: POST_CONCEPT,
    payload: {
      data: concept,
      promise: request({
        method: 'POST',
        url: services.concept(`/v1/concepts`),
        body: concept,
        errorMessages: POST_CONCEPT_ERROR_MESSAGES,
      }),
    },
  })
export const getMyConcepts = (auth) => {
  const userId = getMyUserId(auth)
  const filter = `$filter=(status eq 'active' or status eq 'pendingApproval') and (owner eq '${userId}')`
  const select =
    'members,publishingHouse,accessLevel,contactRoles,title,roles,status,completion,id,owner'
  return (dispatch) => {
    return dispatch({
      type: GET_MY_CONCEPTS,
      payload: {
        promise: request({
          method: 'GET',
          url: services.concept(
            `/v2/search/concepts?${filter}&$orderBy=updated desc&$select=${select}&limit=99`,
          ),
        }),
      },
    })
  }
}

const getContactsFilter = (contactIds) =>
  map((contactId) => `trustedContacts/any(t: t eq '${contactId}')`)(contactIds).join(' or ')

const getApproverFilter = (approverIds) =>
  map((publishingHouseId) => `publishingHouse eq '${publishingHouseId}'`)(approverIds).join(' or ')

export const getMyRelatedConcepts = (auth) => {
  const contactIds = getMyContactIds(auth)
  const approverIds = getMyPublishingHouseIdsApprover(auth, 'opus_concept')

  if (contactIds.length === 0 && approverIds.length > 0) {
    return {
      type: GET_MY_RELATED_CONCEPTS_SUCCESS,
      payload: {
        json: {
          results: [],
        },
      },
    }
  }

  let filter = "$filter=(status eq 'active' or status eq 'pendingApproval')"
  let additionalFilters = ''

  if (contactIds.length > 0) {
    const contactsFilter = getContactsFilter(contactIds)
    additionalFilters += contactsFilter
  }

  if (approverIds.length > 0) {
    const approverFilter = getApproverFilter(approverIds)
    additionalFilters += `${
      contactIds.length > 0 ? ' or ' : ''
    }((${approverFilter}) and status eq 'pendingApproval')`
  }

  if (additionalFilters.length > 0) {
    filter += ` and (${additionalFilters})`
  }

  const select =
    'members,publishingHouse,accessLevel,contactRoles,title,roles,status,completion,id,owner'
  return (dispatch) => {
    return dispatch({
      type: GET_MY_RELATED_CONCEPTS,
      payload: {
        promise: request({
          method: 'GET',
          url: services.concept(
            `/v2/search/concepts?${filter}&$orderBy=updated desc&$select=${select}&limit=99`,
          ),
        }),
      },
    })
  }
}
const POST_CONCEPT_APPROVE_ERROR_MESSAGES = createErrorMessages(
  [
    'CONCEPT_NOT_FOUND',
    'CONCEPT_FORBIDDEN',
    'MISSING_PUBLISHING_HOUSE',
    'CONCEPT_FORBIDDEN_APPROVE',
  ],
  messages,
)
export const postApproveConcept = (conceptId, conceptEditionIds) => (dispatch) =>
  dispatch({
    type: POST_CONCEPT_APPROVE,
    payload: {
      data: conceptId,
      promise: request({
        method: 'POST',
        url: services.concept(`/v1/concepts/${conceptId}/approve`),
        body: {
          conceptEditionIds,
        },
        errorMessages: POST_CONCEPT_APPROVE_ERROR_MESSAGES,
      }),
    },
  })
export const postApproveConceptEdition = (conceptId, conceptEditionId) => (dispatch) =>
  dispatch({
    type: POST_CONCEPT_EDITION_APPROVE,
    payload: {
      data: {
        conceptId,
        conceptEditionId,
      },
      promise: request({
        method: 'POST',
        url: services.concept(`/v1/concepts/${conceptId}/approve/edition/${conceptEditionId}`),
        body: {
          conceptEditionId,
        },
      }),
    },
  })
export const getDefaultRoyaltyLevels =
  (bindingCodeId, contractTypeId, isMainEdition, publishingHouseId) => (dispatch) =>
    dispatch({
      type: GET_CONCEPT_PRODUCTION_DEFAULT_ROYALTY_LEVEL,
      payload: {
        data: {
          bindingCodeId,
          contractTypeId,
          isMainEdition,
        },
        promise: request({
          method: 'GET',
          url: services.concept(
            `/v1/defaults/royaltylevels?bindingCode=${bindingCodeId}&contractType=${contractTypeId}&mainEdition=${isMainEdition}&publishingHouseId=${publishingHouseId}`,
          ),
        }),
      },
    })

export const getDefaultDistributions: any =
  (bindingCodeId, publishingHouseId, estimatedSales): any =>
  (dispatch): any =>
    dispatch({
      type: GET_DEFAULT_DISTRIBUTION,
      payload: {
        data: {
          bindingCodeId,
          publishingHouseId,
          estimatedSales,
        },
        promise: request({
          url: services.concept(
            `/v1/defaults/distributions?bindingcode=${bindingCodeId}&publishinghouseid=${publishingHouseId}&estimatedsales=${estimatedSales}`,
          ),
          method: 'GET',
        }),
      },
    })

export const postClone = (id) => (dispatch) =>
  dispatch({
    type: POST_CLONE_CONCEPT,
    payload: {
      data: id,
      promise: request({
        method: 'POST',
        url: services.concept(`/v1/concepts/${id}/clone`),
        body: undefined,
      }),
    },
  })
