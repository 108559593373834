import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_METADATA_TYPES_REQUEST,
  GET_METADATA_TYPES_SUCCESS,
  GET_METADATA_TYPES_FAILURE,
} from '../actions/metaDataTypesActions'

const initialState = {
  data: [],
  status: {
    getStatus: undefined,
    getError: undefined,
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_METADATA_TYPES_REQUEST:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_METADATA_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload.json.types,
        status: { ...state.status, getStatus: requestStatus.success },
      }

    case GET_METADATA_TYPES_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    default:
      return state
  }
}
