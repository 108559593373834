import { isEqual } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'

import { TextField, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AddButton } from 'Common/components/buttons'
import { MetaData } from 'Common/metadata'

import FilterSelectionList from './FilterSelectionList'

const initDates = {
  minDate: DateTime.now(),
  maxDate: DateTime.now().plus({ months: 1 }).minus({ days: 1 }),
}

type Props = {
  updateFilter: (..._args: Array<any>) => any
  filter: {
    id: string
    type: {
      displayFormat: (..._args: Array<any>) => any
    }
    displayFormat: (..._args: Array<any>) => any
    typeConfig: Record<string, unknown>
  }
  active: any[]
  metaData: MetaData
  chipColor?: string
}

type DateFilterState = {
  minDate: DateTime
  maxDate: DateTime
  showMaxDate: boolean
}

class DateFilter extends Component<Props, DateFilterState> {
  static defaultProps = {
    active: [],
  }

  state = { ...initDates, showMaxDate: false }

  onAddOption = () => {
    const { filter, active, updateFilter } = this.props
    const { minDate, maxDate, showMaxDate } = this.state
    const newMinOptions = {
      min: minDate.toISODate(),
    }
    const newMaxOptions = showMaxDate
      ? {
          max: maxDate.toISODate(),
        }
      : {}
    const newOption = { ...newMinOptions, ...newMaxOptions }
    const updatedActive = active.concat(newOption)
    updateFilter(filter.id, updatedActive)
    this.setState({ ...initDates, showMaxDate: false })
  }

  onRemoveOption = (option: any) => {
    const { filter, active, updateFilter } = this.props
    const updatedActive = active.filter((activeOption) => !isEqual(activeOption, option))
    updateFilter(filter.id, updatedActive)
  }

  render() {
    const { filter, active, chipColor, metaData } = this.props
    const { maxDate, minDate, showMaxDate } = this.state
    return (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <TextField
            variant="standard"
            fullWidth
            type="date"
            label={this.state.showMaxDate ? 'From date' : 'Date'}
            onChange={(event) => {
              this.setState({
                minDate: DateTime.fromISO(event.target.value),
                maxDate: DateTime.fromISO(event.target.value)
                  .plus({ months: 1 })
                  .minus({ days: 1 }),
              })
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={minDate ? minDate.toFormat('yyyy-MM-dd') : null}
          />
        </Grid>
        {showMaxDate ? (
          <Grid xs={12}>
            <TextField
              variant="standard"
              fullWidth
              type="date"
              label="To this date"
              onChange={(event) =>
                this.setState({
                  maxDate: DateTime.fromISO(event.target.value),
                })
              }
              value={maxDate.toISODate()}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: '100%',
              }}
            />
          </Grid>
        ) : null}
        <Grid xs={12}>
          <AddButton
            onClick={this.onAddOption}
            style={{
              marginRight: 10,
              marginBottom: 5,
            }}
          />
          <Button
            variant="outlined"
            onClick={() =>
              this.setState((state) => ({
                showMaxDate: !state.showMaxDate,
              }))
            }
            color="primary">
            {showMaxDate ? 'Delete interval' : 'Create interval'}
          </Button>
        </Grid>
        <FilterSelectionList
          onRemoveOption={this.onRemoveOption}
          {...{
            filter,
            active,
            metaData,
            chipColor,
          }}
        />
      </Grid>
    )
  }
}

export default DateFilter
