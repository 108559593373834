import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const POST_CONCEPT_MEMBERS = 'POST_CONCEPT_MEMBERS'
export const POST_CONCEPT_MEMBERS_REQUEST = 'POST_CONCEPT_MEMBERS_REQUEST'
export const POST_CONCEPT_MEMBERS_SUCCESS = 'POST_CONCEPT_MEMBERS_SUCCESS'
export const POST_CONCEPT_MEMBERS_FAILURE = 'POST_CONCEPT_MEMBERS_FAILURE'
export const PUT_CONCEPT_MEMBERS = 'PUT_CONCEPT_MEMBERS'
export const PUT_CONCEPT_MEMBERS_REQUEST = 'PUT_CONCEPT_MEMBERS_REQUEST'
export const PUT_CONCEPT_MEMBERS_SUCCESS = 'PUT_CONCEPT_MEMBERS_SUCCESS'
export const PUT_CONCEPT_MEMBERS_FAILURE = 'PUT_CONCEPT_MEMBERS_FAILURE'
export const REMOVE_CONCEPT_MEMBERS = 'REMOVE_CONCEPT_MEMBERS'
export const REMOVE_CONCEPT_MEMBERS_REQUEST = 'REMOVE_CONCEPT_MEMBERS_REQUEST'
export const REMOVE_CONCEPT_MEMBERS_SUCCESS = 'REMOVE_CONCEPT_MEMBERS_SUCCESS'
export const REMOVE_CONCEPT_MEMBERS_FAILURE = 'REMOVE_CONCEPT_MEMBERS_FAILURE'
const POST_CONCEPT_MEMBERS_ERROR_MESSAGES = createErrorMessages(
  ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN'],
  messages,
)
export const postConceptMember = (conceptId, contactId, roleId, accessLevel) => {
  const body = {
    id: contactId,
    roleId,
    accessLevel,
  }
  return (dispatch) =>
    dispatch({
      type: POST_CONCEPT_MEMBERS,
      payload: {
        data: body,
        promise: request({
          url: services.concept(`/v1/concepts/${conceptId}/members`),
          body,
          method: 'POST',
          errorMessages: POST_CONCEPT_MEMBERS_ERROR_MESSAGES,
        }),
      },
    })
}
const REMOVE_CONCEPT_MEMBERS_ERROR_MESSAGES = createErrorMessages(
  ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN', 'CONCEPT_MEMBER_NOT_FOUND'],
  messages,
)
export const removeConceptMember = (conceptId, memberId) => (dispatch) =>
  dispatch({
    type: REMOVE_CONCEPT_MEMBERS,
    payload: {
      data: memberId,
      promise: request({
        url: services.concept(`/v1/concepts/${conceptId}/members/${memberId}`),
        method: 'DELETE',
        errorMessages: REMOVE_CONCEPT_MEMBERS_ERROR_MESSAGES,
      }),
    },
  })
const PUT_CONCEPT_MEMBERS_ERROR_MESSAGES = createErrorMessages(
  ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN', 'CONCEPT_MEMBER_NOT_FOUND'],
  messages,
)
export const putConceptMember = (conceptId, memberId, body) => (dispatch) =>
  dispatch({
    type: PUT_CONCEPT_MEMBERS,
    payload: {
      data: body,
      promise: request({
        method: 'PUT',
        url: services.concept(`/v1/concepts/${conceptId}/members/${memberId}`),
        body,
        errorMessages: PUT_CONCEPT_MEMBERS_ERROR_MESSAGES,
      }),
    },
  })
