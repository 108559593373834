import { postCustomPropertyItem as postCustomPropertyItemAction } from 'Admin/actions/metaDataTypeActions'
import messages from 'Admin/messages'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'

import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import { Form, Field, AddButton, Dialog } from 'Common/core'
import { getLabel } from 'Common/environment'
import FormErrorDialog from 'Common/form/FormErrorDialog'
import FormSelectField from 'Common/form/FormSelectField'
import FormTextField from 'Common/form/FormTextField'
import { quickValidation, notEmpty, isInteger, isPositiveNumber } from 'Common/form/formvalidation'
import { formSubmit } from 'Common/utils/net/submit'

type Props = {
  onCloseModal: any
  typeId: number
  metadataItemId: number
  customPropertyTypes?: unknown[]
}

const mapState = () => {
  const initialValues = {
    sortOrder: 0,
  }
  return {
    initialValues,
  }
}

const mapDispatch = {
  postCustomPropertyItem: postCustomPropertyItemAction,
}

const connector = connect(() => mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const FORM_NAME = 'addcustompropertyitemmodal'

const form = reduxForm<any, any>({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: quickValidation({
    value: [notEmpty()],
    sortOrder: [isInteger(), isPositiveNumber()],
  }),
})

class AddCustomPropertyItemModal extends Component<Props & PropsFromRedux & InjectedFormProps> {
  submit = (values) => {
    const { typeId, postCustomPropertyItem, metadataItemId, onCloseModal } = this.props
    return formSubmit(() => postCustomPropertyItem(typeId, metadataItemId, values)).then(() =>
      onCloseModal(),
    )
  }

  render() {
    const { submitting, dirty, onCloseModal, customPropertyTypes, handleSubmit } = this.props
    const doSubmit = handleSubmit(this.submit)
    return (
      <Dialog
        title="Add custom property item"
        actions={[
          <AddButton
            key="create"
            onClick={doSubmit}
            submitting={submitting}
            disabled={!dirty || submitting}>
            {messages.common.action.create}
          </AddButton>,
        ]}
        onClose={onCloseModal}>
        <Form onSubmit={doSubmit}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyItem.CustomPropertyTypeId"
                name="customPropertyTypeId"
                component={FormSelectField}
                label={
                  getLabel('Metadata.AddCustomPropertyItem.CustomPropertyTypeId.Label') ?? 'Type'
                }
                metaData={customPropertyTypes}
                required
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyItem.Value"
                name="value"
                component={FormTextField}
                label={getLabel('Metadata.AddCustomPropertyItem.Value.Label') ?? 'Value'}
                required
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyItem.SortOrder"
                name="sortOrder"
                component={FormTextField}
                label="Sort order"
                type="number"
              />
            </Grid>

            {submitting ? <Spinner /> : null}
          </Grid>
        </Form>
        <FormErrorDialog {...this.props} />
      </Dialog>
    )
  }
}

export default connector(form(AddCustomPropertyItemModal))
