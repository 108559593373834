import fileSaver from 'file-saver'
import { isString } from 'lodash/fp'
import { Component } from 'react'

import IconDownload from '@mui/icons-material/CloudDownload'
import { Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { services } from 'Common/environment'

import messages from '../../messages'
import createErrorMessages from '../../messages/createErrorMessages'
import request from '../../utils/net/request'
import { plainSubmit } from '../../utils/net/submit'
import GenericErrorDialog from '../error/GenericErrorDialog'
import Spinner, { SPINNER_SIZES } from '../loader/Spinner'
import { getFileName as getAssetFileNameOriginal } from './AssetDownload'

const originalString = 'Original'
const getUrl = (id, size) =>
  size === originalString ? `/v1/assets/${id}/file` : `/v1/thumbs/${id}?maxWidth=${size}`

const getFallbackFileName = (id, size) => `${size}-${id}.jpg`

const getFileName = (res, id, size) => {
  const contentDisposition = res.headers.get('content-disposition')
  if (!contentDisposition || !contentDisposition.includes('filename'))
    return getFallbackFileName(id, size)
  const result = contentDisposition
    .split(';')
    .filter((str) => str.includes('filename='))
    .map((str) => str.trim().replace('filename=', ''))
  return result.length > 0 ? `${size}-${result[0]}` : getFallbackFileName(id, size)
}
type Props = {
  id: number
  size: any
  dispatch?: any
  label?: string
  color?: any
  style?: any
}

class AssetThumbnailDownload extends Component<Props> {
  static defaultProps = {
    label: 'Download',
  }

  state = {
    downloading: false,
    error: null,
  }

  download = () => {
    const { id, size } = this.props
    const isOriginal = size === originalString
    this.setState(
      {
        downloading: true,
      },
      () =>
        plainSubmit(
          () =>
            request({
              url: services.assets(getUrl(id, size)),
              method: 'GET',
            }).then(({ response }) =>
              response
                .blob()
                .then((blob) => {
                  fileSaver.saveAs(
                    blob,
                    isOriginal
                      ? getAssetFileNameOriginal(response, id)
                      : getFileName(response, id, size),
                  )
                })
                .then(() =>
                  this.setState({
                    downloading: false,
                  }),
                ),
            ),
          createErrorMessages(
            [
              'ASSET_FORBIDDEN',
              'ASSET_NOT_FOUND',
              'ASSET_FILE_NOT_FOUND',
              'ASSET_FILE_TYPE_NOT_ALLOWED',
            ],
            messages,
          ),
        ).catch((error) =>
          this.setState({
            error: isString(error) ? error : 'An error occured while downloading the image.',
            downloading: false,
          }),
        ),
    )
  }

  closeErrorDialog = () =>
    this.setState({
      error: null,
    })

  render() {
    const { id, size, dispatch, label, ...otherProps } = this.props
    const { downloading, error } = this.state
    return (
      <Grid xs={12}>
        <Button
          color="primary"
          variant="outlined"
          disabled={downloading}
          onClick={this.download}
          fullWidth
          endIcon={downloading ? <Spinner size={SPINNER_SIZES.BUTTON} /> : <IconDownload />}
          {...otherProps}>
          {label}
        </Button>
        <GenericErrorDialog
          handleClose={this.closeErrorDialog}
          {...{
            error,
          }}
        />
      </Grid>
    )
  }
}

export default AssetThumbnailDownload
