import { Cell } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'
import { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import { Link } from '@mui/material'

type Props = {
  column: {
    selectField: string
  }
  resultStatus: string
  result?: Record<string, unknown>
  viewProps?: Record<string, unknown>
}

class EmailListCell extends PureComponent<Props> {
  render() {
    const { column, result, resultStatus, viewProps, ...otherProps } = this.props
    const valueList: any = getOr([], `document.${column.selectField}`, result)
    const value =
      valueList.length > 0
        ? valueList.map((valueItem, index) => (
            <span key={valueItem}>
              <Link
                component={NavLink}
                to={`mailto:${valueItem}`}
                className="linkPrimary"
                style={{
                  textDecoration: 'none',
                }}>
                {valueItem}
              </Link>
              {index + 1 < valueList.length ? <span>, </span> : null}
            </span>
          ))
        : '-'
    return (
      <Cell {...otherProps}>
        <span
          style={{
            whiteSpace: 'nowrap',
          }}>
          {resultStatus === 'pending' ? 'Loading...' : value}
        </span>
      </Cell>
    )
  }
}

export default EmailListCell
