import { services } from 'Common/environment'
import request from 'Common/utils/net/request'

export const GET_METADATA_TYPE = 'GET_METADATA_TYPE'
export const GET_METADATA_TYPE_REQUEST = 'GET_METADATA_TYPE_REQUEST'
export const GET_METADATA_TYPE_SUCCESS = 'GET_METADATA_TYPE_SUCCESS'
export const GET_METADATA_TYPE_FAILURE = 'GET_METADATA_TYPE_FAILURE'
export const PATCH_METADATA_TYPE = 'PATCH_METADATA_TYPE'
export const PATCH_METADATA_TYPE_REQUEST = 'PATCH_METADATA_TYPE_REQUEST'
export const PATCH_METADATA_TYPE_SUCCESS = 'PATCH_METADATA_TYPE_SUCCESS'
export const PATCH_METADATA_TYPE_FAILURE = 'PATCH_METADATA_TYPE_FAILURE'
export const DELETE_METADATA_TYPE = 'DELETE_METADATA_TYPE'
export const DELETE_METADATA_TYPE_REQUEST = 'DELETE_METADATA_TYPE_REQUEST'
export const DELETE_METADATA_TYPE_SUCCESS = 'DELETE_METADATA_TYPE_SUCCESS'
export const DELETE_METADATA_TYPE_FAILURE = 'DELETE_METADATA_TYPE_FAILURE'
export const POST_METADATA_ITEM = 'POST_METADATA_ITEM'
export const POST_METADATA_ITEM_REQUEST = 'POST_METADATA_ITEM_REQUEST'
export const POST_METADATA_ITEM_SUCCESS = 'POST_METADATA_ITEM_SUCCESS'
export const POST_METADATA_ITEM_FAILURE = 'POST_METADATA_ITEM_FAILURE'
export const PATCH_METADATA_ITEM = 'PATCH_METADATA_ITEM'
export const PATCH_METADATA_ITEM_REQUEST = 'PATCH_METADATA_ITEM_REQUEST'
export const PATCH_METADATA_ITEM_SUCCESS = 'PATCH_METADATA_ITEM_SUCCESS'
export const PATCH_METADATA_ITEM_FAILURE = 'PATCH_METADATA_ITEM_FAILURE'
export const DELETE_METADATA_ITEM = 'DELETE_METADATA_ITEM'
export const DELETE_METADATA_ITEM_REQUEST = 'DELETE_METADATA_ITEM_REQUEST'
export const DELETE_METADATA_ITEM_SUCCESS = 'DELETE_METADATA_ITEM_SUCCESS'
export const DELETE_METADATA_ITEM_FAILURE = 'DELETE_METADATA_ITEM_FAILURE'
export const POST_CUSTOMPROPERTY_TYPE = 'POST_CUSTOMPROPERTY_TYPE'
export const POST_CUSTOMPROPERTY_TYPE_REQUEST = 'POST_CUSTOMPROPERTY_TYPE_REQUEST'
export const POST_CUSTOMPROPERTY_TYPE_SUCCESS = 'POST_CUSTOMPROPERTY_TYPE_SUCCESS'
export const POST_CUSTOMPROPERTY_TYPE_FAILURE = 'POST_CUSTOMPROPERTY_TYPE_FAILURE'
export const PATCH_CUSTOMPROPERTY_TYPE = 'PATCH_CUSTOMPROPERTY_TYPE'
export const PATCH_CUSTOMPROPERTY_TYPE_REQUEST = 'PATCH_CUSTOMPROPERTY_TYPE_REQUEST'
export const PATCH_CUSTOMPROPERTY_TYPE_SUCCESS = 'PATCH_CUSTOMPROPERTY_TYPE_SUCCESS'
export const PATCH_CUSTOMPROPERTY_TYPE_FAILURE = 'PATCH_CUSTOMPROPERTY_TYPE_FAILURE'
export const DELETE_CUSTOMPROPERTY_TYPE = 'DELETE_CUSTOMPROPERTY_TYPE'
export const DELETE_CUSTOMPROPERTY_TYPE_REQUEST = 'DELETE_CUSTOMPROPERTY_TYPE_REQUEST'
export const DELETE_CUSTOMPROPERTY_TYPE_SUCCESS = 'DELETE_CUSTOMPROPERTY_TYPE_SUCCESS'
export const DELETE_CUSTOMPROPERTY_TYPE_FAILURE = 'DELETE_CUSTOMPROPERTY_TYPE_FAILURE'
export const POST_CUSTOMPROPERTY_ITEM = 'POST_CUSTOMPROPERTY_ITEM'
export const POST_CUSTOMPROPERTY_ITEM_REQUEST = 'POST_CUSTOMPROPERTY_ITEM_REQUEST'
export const POST_CUSTOMPROPERTY_ITEM_SUCCESS = 'POST_CUSTOMPROPERTY_ITEM_SUCCESS'
export const POST_CUSTOMPROPERTY_ITEM_FAILURE = 'POST_CUSTOMPROPERTY_ITEM_FAILURE'
export const PATCH_CUSTOMPROPERTY_ITEM = 'PATCH_CUSTOMPROPERTY_ITEM'
export const PATCH_CUSTOMPROPERTY_ITEM_REQUEST = 'PATCH_CUSTOMPROPERTY_ITEM_REQUEST'
export const PATCH_CUSTOMPROPERTY_ITEM_SUCCESS = 'PATCH_CUSTOMPROPERTY_ITEM_SUCCESS'
export const PATCH_CUSTOMPROPERTY_ITEM_FAILURE = 'PATCH_CUSTOMPROPERTY_ITEM_FAILURE'
export const DELETE_CUSTOMPROPERTY_ITEM = 'DELETE_CUSTOMPROPERTY_ITEM'
export const DELETE_CUSTOMPROPERTY_ITEM_REQUEST = 'DELETE_CUSTOMPROPERTY_ITEM_REQUEST'
export const DELETE_CUSTOMPROPERTY_ITEM_SUCCESS = 'DELETE_CUSTOMPROPERTY_ITEM_SUCCESS'
export const DELETE_CUSTOMPROPERTY_ITEM_FAILURE = 'DELETE_CUSTOMPROPERTY_ITEM_FAILURE'
export const getMetaDataType = (id) => (dispatch) =>
  dispatch({
    type: GET_METADATA_TYPE,
    meta: {
      id,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.metadata(`/v2/types/${id}`),
      }),
    },
  })
export const patchMetaDataType = (id, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_METADATA_TYPE,
    meta: {
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${id}`),
        method: 'PATCH',
        body: patchSet,
      }),
    },
  })
export const deleteMetaDataType = (id) => (dispatch) =>
  dispatch({
    type: DELETE_METADATA_TYPE,
    meta: {
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${id}`),
        method: 'DELETE',
      }),
    },
  })
export const postMetaDataItem = (typeId, values) => (dispatch) =>
  dispatch({
    type: POST_METADATA_ITEM,
    meta: {
      typeId,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/items`),
        method: 'POST',
        body: values,
      }),
    },
  })
export const patchMetaDataItem = (typeId, id, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_METADATA_ITEM,
    meta: {
      typeId,
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/items/${id}`),
        method: 'PATCH',
        body: patchSet,
      }),
    },
  })
export const deleteMetaDataItem = (typeId, id) => (dispatch) =>
  dispatch({
    type: DELETE_METADATA_ITEM,
    meta: {
      typeId,
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/items/${id}`),
        method: 'DELETE',
      }),
    },
  })
export const postCustomPropertyType = (typeId, values) => (dispatch) =>
  dispatch({
    type: POST_CUSTOMPROPERTY_TYPE,
    meta: {
      typeId,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/props`),
        method: 'POST',
        body: values,
      }),
    },
  })
export const patchCustomPropertyType = (typeId, id, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_CUSTOMPROPERTY_TYPE,
    meta: {
      typeId,
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/props/${id}`),
        method: 'PATCH',
        body: patchSet,
      }),
    },
  })
export const deleteCustomPropertyType = (typeId, id) => (dispatch) =>
  dispatch({
    type: DELETE_CUSTOMPROPERTY_TYPE,
    meta: {
      typeId,
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/props/${id}`),
        method: 'DELETE',
      }),
    },
  })
export const postCustomPropertyItem = (typeId, metaDataItemId, values) => (dispatch) =>
  dispatch({
    type: POST_CUSTOMPROPERTY_ITEM,
    meta: {
      typeId,
      metaDataItemId,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/items/${metaDataItemId}/props`),
        method: 'POST',
        body: values,
      }),
    },
  })
export const patchCustomPropertyItem = (typeId, metaDataItemId, id, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_CUSTOMPROPERTY_ITEM,
    meta: {
      typeId,
      metaDataItemId,
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/items/${metaDataItemId}/props/${id}`),
        method: 'PATCH',
        body: patchSet,
      }),
    },
  })
export const deleteCustomPropertyItem = (typeId, metaDataItemId, id) => (dispatch) =>
  dispatch({
    type: DELETE_CUSTOMPROPERTY_ITEM,
    meta: {
      typeId,
      metaDataItemId,
      id,
    },
    payload: {
      promise: request({
        url: services.metadata(`/v2/types/${typeId}/items/${metaDataItemId}/props/${id}`),
        method: 'DELETE',
      }),
    },
  })
