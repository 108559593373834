import { Component } from 'react'

import IconInfo from '@mui/icons-material/InfoOutlined'
import IconVisible from '@mui/icons-material/Visibility'
import IconHidden from '@mui/icons-material/VisibilityOff'
import { IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import DraftComponent from '../texts/DraftComponent'
import { FormFieldInfo } from './fieldinfo'
import { getFieldInfo } from './formFieldHelpers'

type Props = {
  label: any
  input: any
  textType: any
  onDelete?(..._args: unknown[]): unknown
  disabled?: boolean
  infoText?: string
  propertyRules?: any
}

type State = {
  isHidden: boolean
  clickedInfoButton: boolean
  showInfoTextDialog?: boolean
}

export class FormTextEditorField extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
    propertyRules: {},
  }

  state = {
    isHidden: false,
    clickedInfoButton: false,
    showInfoTextDialog: false,
  }

  toggleHidden = () =>
    this.setState((state) => ({
      isHidden: !state.isHidden,
    }))

  clickInfoTextIcon = () => {
    this.setState({
      clickedInfoButton: !this.state.clickedInfoButton,
    })
  }

  render() {
    const {
      label,
      infoText,
      propertyRules,
      textType,
      disabled,
      onDelete,
      input: { value, onChange },
    } = this.props
    const { isHidden, clickedInfoButton } = this.state
    const fieldInfo = getFieldInfo(propertyRules, infoText)
    const isDisabled = disabled || fieldInfo.isReadOnly
    const maxLength = (textType.customProperties && textType.customProperties.maxLength) || 0
    return (
      <Grid container spacing={0} rowSpacing={1} xs={12}>
        <Grid container spacing={2} xs={12}>
          <Grid xs={9} display="flex" alignItems="center">
            <Typography variant="h5">{label}</Typography>
          </Grid>
          <Grid xs={3} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <IconButton onClick={this.toggleHidden} aria-label="Info" size="large">
              {isHidden ? <IconHidden /> : <IconVisible />}
            </IconButton>
            {fieldInfo.showInfoButton ? (
              <IconButton onClick={this.clickInfoTextIcon} aria-label="Info" size="large">
                <IconInfo />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
        <Grid xs={12}>
          <FormFieldInfo
            {...{
              fieldInfo,
              propertyRules,
              infoText,
              clickedInfoButton,
            }}
          />
        </Grid>
        {isHidden ? null : (
          <Grid container spacing={2} xs={12}>
            <Grid xs={12}>
              <DraftComponent
                value={value}
                disabled={isDisabled}
                onValueChange={(newValue) => onChange(newValue)}
                onDelete={onDelete}
              />
            </Grid>
            <Grid
              xs={12}
              style={{
                color: value.length > maxLength && maxLength !== 0 ? '#f44336' : '',
              }}>
              Number of characters: {value.length} {maxLength > 0 ? `/ ${maxLength}` : ''}{' '}
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}
export default FormTextEditorField
