import { getOr } from 'lodash/fp'
import { Component } from 'react'
import ReactDOM from 'react-dom'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { FormFieldInfo, FormFieldInfoToggler } from './fieldinfo'
import { getFieldInfo, getLabelText, getErrorText, getHasError } from './formFieldHelpers'

const defaultStyle = {
  width: '100%',
}

const shouldExpandOnFocus = (multiline?: any, expandOnFocus?: any, disabled?: any) => {
  return multiline && expandOnFocus && !disabled
}

const getClassName = (multiline, noExpandOnFocus, focused, nrOfRows, className, disabled) => {
  if (!shouldExpandOnFocus(multiline, noExpandOnFocus, disabled)) return className
  let classesToAdd = ''

  if (nrOfRows && nrOfRows > 3) {
    classesToAdd = focused
      ? 'opusTextFieldExpandOnFocus'
      : 'opusTextFieldExpandOnFocus opusTextFieldExpandOnFocusBlurred'
  }

  return className ? `${className} ${classesToAdd}` : classesToAdd
}

const getNrOfRows = (height, floatingLabelText) => {
  const intHeight = parseInt(height.replace('px', ''), 10)
  return (intHeight - (floatingLabelText ? 72 : 48)) / 24
}

type Props = {
  input: any
  meta: any
  muiRef?(..._args: unknown[]): unknown
  label?: React.ReactNode
  style?: Record<string, unknown>
  required?: boolean
  disabled?: boolean
  floatingLabelText?: string
  multiline?: boolean
  expandOnFocus?: boolean
  showInfoButton?: boolean
  infoText?: string
  history?: string
  className?: string
  id?: string
  type?: string
  propertyRules?: Record<string, unknown>
  maxLength?: number
  showMetaDataAdmin?: boolean
  dispatch?: any
}

type State = {
  nrOfRows?: number
  focused: boolean
  clickedInfoButton: boolean
  showInfoTextDialog: boolean
}

export class FormTextField extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
    showMetaDataAdmin: false,
    required: false,
    expandOnFocus: true,
    propertyRules: {},
    type: 'text',
  }

  state = {
    focused: false,
    clickedInfoButton: false,
    showInfoTextDialog: false,
    nrOfRows: undefined,
  }

  private _nestedElement: any

  /* eslint react/no-did-update-set-state: "off" */
  componentDidUpdate() {
    const { multiline, expandOnFocus, label } = this.props
    if (!shouldExpandOnFocus(multiline, expandOnFocus)) return

    if (this.state.nrOfRows === undefined && this._nestedElement) {
      this.setState({
        nrOfRows: getNrOfRows(this._nestedElement.style.height, label),
      })
    }
  }

  handleBlur = () => {
    const { multiline, expandOnFocus, label } = this.props
    if (!shouldExpandOnFocus(multiline, expandOnFocus)) return
    this.setState({
      focused: false,
      nrOfRows: getNrOfRows(this._nestedElement.style.height, label),
      clickedInfoButton: false,
    })
  }

  handleFocus = () => {
    const { multiline, expandOnFocus } = this.props
    if (!shouldExpandOnFocus(multiline, expandOnFocus)) return
    this.setState({
      focused: true,
    })
  }

  /* eslint react/no-find-dom-node: "off" */
  saveNestedTextarea = (e) => {
    const { multiline, expandOnFocus, floatingLabelText, muiRef } = this.props
    muiRef && muiRef(e)
    if (!shouldExpandOnFocus(multiline, expandOnFocus)) return
    this._nestedElement = ReactDOM.findDOMNode(e)

    if (this.state.nrOfRows === undefined && this._nestedElement) {
      this.setState({
        nrOfRows: getNrOfRows(this._nestedElement.style.height, floatingLabelText),
      })
    }
  }

  clickIcon = () => {
    this.setState({
      clickedInfoButton: !this.state.clickedInfoButton,
    })
  }

  render() {
    const {
      input,
      meta,
      id,
      infoText,
      history,
      expandOnFocus,
      propertyRules,
      muiRef,
      showMetaDataAdmin,
      dispatch,
      ...otherProps
    } = this.props
    const { required, label, multiline, className, disabled, style, type, maxLength } = this.props
    const { name } = input
    const { error, touched } = meta
    const { nrOfRows, showInfoTextDialog, clickedInfoButton } = this.state
    const mergedStyle = { ...defaultStyle, ...style }
    const fieldInfo = getFieldInfo(propertyRules, infoText, history)
    const isDisabled = disabled || fieldInfo.isReadOnly
    const nestedProps = {
      ...input,
      ...otherProps,
      required,
      label: getLabelText(label, propertyRules),
      helperText: getErrorText(touched, error),
      error: getHasError(touched, error),
      className: getClassName(
        multiline,
        expandOnFocus,
        this.state.focused,
        nrOfRows,
        className,
        isDisabled,
      ),
      style: { ...mergedStyle },
      disabled: isDisabled,
      maxLength: getOr(maxLength, 'maxLength', propertyRules),
    }
    return (
      <Grid xs={12}>
        <TextField
          variant="standard"
          id={id || `FormTextField-${name}-${label}-${type}`.replace(/[^A-Za-z0-9-]/gi, '')}
          ref={this.saveNestedTextarea}
          {...nestedProps}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          multiline={multiline}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormFieldInfoToggler
                  clickIcon={this.clickIcon}
                  showInfoButton={fieldInfo.showInfoButton}
                />
              </InputAdornment>
            ),
          }}></TextField>

        <FormFieldInfo
          {...{
            fieldInfo,
            propertyRules,
            history,
            infoText,
            showInfoTextDialog,
            clickedInfoButton,
          }}
        />
      </Grid>
    )
  }
}
export default FormTextField
