import { Environment, EnvironmentKey } from 'Common/environment'

const developmentEnv = {
  endpoints: {
    assets: 'https://test-opus-asset-appservice.azurewebsites.net',
    calculation: 'https://test-opus-calculation-appservice.azurewebsites.net',
    concept: 'https://test-opus-concept-appservice.azurewebsites.net',
    contact: 'https://test-opus-contact-appservice.azurewebsites.net',
    identityserver: 'https://test-opus-identity-appservice.azurewebsites.net/core',
    metadata: 'https://test-opus-metadata-appservice.azurewebsites.net',
    users: 'https://test-opus-identity-appservice.azurewebsites.net/api',
    work: 'https://test-opus-work-appservice.azurewebsites.net',
    royalty: 'https://test-opus-royalty-appservice.azurewebsites.net',
    distribution: 'https://test-opus-distribution-appservice.azurewebsites.net',
    schilling: 'https://test-opus-integration-schilling-appservice.azurewebsites.net',
    storage: undefined,
  },
  features: {
    oneflow: true,
    erpIntegration: true,
  },
  settings: {
    currency: 'SEK',
  },
}

const testWsEnv = {
  endpoints: {
    assets: 'https://testws-asset-app.azurewebsites.net',
    calculation: 'https://testws-calculation-app.azurewebsites.net',
    concept: 'https://testws-concept-app.azurewebsites.net',
    contact: 'https://testws-contact-app.azurewebsites.net',
    identityserver: 'https://testws-identity-app.azurewebsites.net/core',
    metadata: 'https://testws-metadata-app.azurewebsites.net',
    users: 'https://testws-identity-app.azurewebsites.net/api',
    work: 'https://testws-work-app.azurewebsites.net',
    royalty: 'https://testws-royalty-app.azurewebsites.net',
    distribution: '',
    storage: undefined,
    schilling: undefined,
  },
  features: {
    oneflow: false,
    erpIntegration: true,
  },
  settings: {
    currency: 'EUR',
  },
}

const testNfgEnv = {
  endpoints: {
    assets: 'https://testnfg-opus-asset-appservice.azurewebsites.net',
    calculation: 'https://testnfg-opus-calculation-appservice.azurewebsites.net',
    concept: 'https://testnfg-opus-concept-appservice.azurewebsites.net',
    contact: 'https://testnfg-opus-contact-appservice.azurewebsites.net',
    identityserver: 'https://testnfg-opus-identity-appservice.azurewebsites.net/core',
    metadata: 'https://testnfg-opus-metadata-appservice.azurewebsites.net',
    users: 'https://testnfg-opus-identity-appservice.azurewebsites.net/api',
    work: 'https://testnfg-opus-work-appservice.azurewebsites.net',
    royalty: '',
    distribution: 'https://testnfg-opus-distribution-appservice.azurewebsites.net',
    storage: undefined,
    schilling: undefined,
  },
  features: {
    oneflow: false,
    erpIntegration: true,
  },
  settings: {
    currency: 'SEK',
  },
}

export const environments: Record<EnvironmentKey, Environment> = {
  test: developmentEnv,
  testWs: testWsEnv,
  testNfg: testNfgEnv,
}
