import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import { Activity, AppDispatch } from 'Common/types'
import request from 'Common/utils/net/request'
import { PatchBody } from 'Common/utils/net/request'

import messages from '../messages'

export const GET_ACTIVITY = 'GET_ACTIVITY'
export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST'
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS'
export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE'
export const POST_ACTIVITY = 'POST_ACTIVITY'
export const POST_ACTIVITY_REQUEST = 'POST_ACTIVITY_REQUEST'
export const POST_ACTIVITY_SUCCESS = 'POST_ACTIVITY_SUCCESS'
export const POST_ACTIVITY_FAILURE = 'POST_ACTIVITY_FAILURE'
export const PATCH_ACTIVITY = 'PATCH_ACTIVITY'
export const PATCH_ACTIVITY_REQUEST = 'PATCH_ACTIVITY_REQUEST'
export const PATCH_ACTIVITY_SUCCESS = 'PATCH_ACTIVITY_SUCCESS'
export const PATCH_ACTIVITY_FAILURE = 'PATCH_ACTIVITY_FAILURE'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST'
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS'
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE'
export const POST_ACTIVITY_TO_WORK = 'POST_ACTIVITY_TO_WORK'
export const POST_ACTIVITY_TO_WORK_REQUEST = 'POST_ACTIVITY_TO_WORK_REQUEST'
export const POST_ACTIVITY_TO_WORK_SUCCESS = 'POST_ACTIVITY_TO_WORK_SUCCESS'
export const POST_ACTIVITY_TO_WORK_FAILURE = 'POST_ACTIVITY_TO_WORK_FAILURE'
export const DELETE_ACTIVITY_FROM_WORK = 'DELETE_ACTIVITY_FROM_WORK'
export const DELETE_ACTIVITY_FROM_WORK_REQUEST = 'DELETE_ACTIVITY_FROM_WORK_REQUEST'
export const DELETE_ACTIVITY_FROM_WORK_SUCCESS = 'DELETE_ACTIVITY_FROM_WORK_SUCCESS'
export const DELETE_ACTIVITY_FROM_WORK_FAILURE = 'DELETE_ACTIVITY_FROM_WORK_FAILURE'
export const POST_ACTIVITIES_STATUS = 'POST_ACTIVITIES_STATUS'
export const POST_ACTIVITIES_STATUS_REQUEST = 'POST_ACTIVITIES_STATUS_REQUEST'
export const POST_ACTIVITIES_STATUS_SUCCESS = 'POST_ACTIVITIES_STATUS_SUCCESS'
export const POST_ACTIVITIES_STATUS_FAILURE = 'POST_ACTIVITIES_STATUS_FAILURE'

export type ActionType =
  | 'GET_ACTIVITY'
  | 'POST_ACTIVITY'
  | 'PATCH_ACTIVITY'
  | 'DELETE_ACTIVITY'
  | 'POST_ACTIVITY_TO_WORK'
  | 'DELETE_ACTIVITY_FROM_WORK'
  | 'POST_ACTIVITIES_STATUS'
const GET_ACTIVITY_ERROR_MESSAGES = createErrorMessages(
  ['ACTIVITY_NOT_FOUND', 'ACTIVITY_FORBIDDEN'],
  messages,
)
export type Action = {
  type: ActionType
}
export const getActivity = (id: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: GET_ACTIVITY,
    meta: {
      id,
    },
    payload: {
      promise: request({
        method: 'GET',
        url: services.work(`/v1/activities/${id}`),
        errorMessages: GET_ACTIVITY_ERROR_MESSAGES,
      }),
    },
  })

const POST_ACTIVITY_ERROR_MESSAGES = createErrorMessages(
  [
    'WORK_NOT_FOUND',
    'WORK_FORBIDDEN',
    'ACTIVITY_MISSING_NAME',
    'ACTIVITY_CANNOT_CREATE_MISSING_WORK',
  ],
  messages,
)
export const postActivity = (activity: Activity) => (dispatch: AppDispatch) =>
  dispatch({
    type: POST_ACTIVITY,
    payload: {
      data: activity,
      promise: request({
        url: services.work('/v1/activities'),
        method: 'POST',
        body: activity,
        errorMessages: POST_ACTIVITY_ERROR_MESSAGES,
      }),
    },
  })

const PATCH_ACTIVITY_ERROR_MESSAGES = createErrorMessages(
  ['ACTIVITY_NOT_FOUND', 'ACTIVITY_FORBIDDEN', 'ACTIVITY_CANNOT_PATCH_OLD_ACTIVITY'],
  messages,
)
export const patchActivity = (id: string, patchSet: PatchBody) => (dispatch: AppDispatch) =>
  dispatch({
    type: PATCH_ACTIVITY,
    meta: {
      id,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/activities/${id}`),
        method: 'PATCH',
        body: patchSet,
        errorMessages: PATCH_ACTIVITY_ERROR_MESSAGES,
      }),
    },
  })
const DELETE_ACTIVITY_ERROR_MESSAGES = createErrorMessages(
  ['ACTIVITY_NOT_FOUND', 'ACTIVITY_FORBIDDEN'],
  messages,
)
export const deleteActivity = (id: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: DELETE_ACTIVITY,
    meta: {
      id,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/activities/${id}`),
        method: 'DELETE',
        errorMessages: DELETE_ACTIVITY_ERROR_MESSAGES,
      }),
    },
  })
const POST_ACTIVITY_TO_WORK_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN', 'ACTIVITY_NOT_FOUND'],
  messages,
)
export const postActivityToWork = (id: string, workId: number) => (dispatch: AppDispatch) =>
  dispatch({
    type: POST_ACTIVITY_TO_WORK,
    meta: {
      id,
      workId,
    },
    payload: {
      promise: request({
        url: services.work(`/v1/activities/${id}/works`),
        method: 'POST',
        body: {
          id: workId,
        },
        errorMessages: POST_ACTIVITY_TO_WORK_ERROR_MESSAGES,
      }),
    },
  })
const DELETE_ACTIVITY_FROM_WORK_ERROR_MESSAGES = createErrorMessages(
  ['WORK_NOT_FOUND', 'WORK_FORBIDDEN', 'ACTIVITY_NOT_FOUND', 'ACTIVITY_CANNOT_DELETE_LAST_WORK'],
  messages,
)
export const deleteActivityFromWork = (id: string, workId: number) => (dispatch: AppDispatch) =>
  dispatch({
    type: DELETE_ACTIVITY_FROM_WORK,
    meta: {
      id,
      workId,
    },
    payload: {
      promise: request({
        method: 'DELETE',
        url: services.work(`/v1/activities/${id}/works/${workId}`),
        errorMessages: DELETE_ACTIVITY_FROM_WORK_ERROR_MESSAGES,
      }),
    },
  })
export const postActivityStatusActivities =
  (activityIds: string[], activityStatusId: string[]) => (dispatch: AppDispatch) =>
    dispatch({
      type: POST_ACTIVITIES_STATUS,
      meta: {
        activityStatusId,
        activityIds,
      },
      payload: {
        promise: request({
          method: 'POST',
          url: services.work(`/v1/activities/activitystatus`),
          body: {
            activityStatusId,
            activityIds,
          },
        }),
      },
    })
