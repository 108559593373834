import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Typography, Box, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { browserHistory } from 'Common/base/history'

const ModuleUnathorizedPage = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: 500,
      }}>
      <Grid xs={10} sm={6}>
        <Typography component="div">
          <Box textAlign="center" fontSize="h1.fontSize">
            ⛔
          </Box>
        </Typography>
      </Grid>
      <Grid xs={10} sm={6}>
        <Typography component="div">
          <Box textAlign="center" fontWeight="fontWeightBold" fontSize="h6.fontSize">
            Access rights are missing
          </Box>
        </Typography>
      </Grid>
      <Grid xs={10} sm={6}>
        <Typography component="div" gutterBottom>
          <Box textAlign="center" fontSize="p.fontSize">
            Additional access rights are required to use this part of Mockingbird.
          </Box>
          <Box textAlign="center" fontSize="p.fontSize">
            Contact your Mockingbird Support to request access.
          </Box>
        </Typography>
      </Grid>
      <Grid xs={10} sm={6}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => browserHistory.goBack()}
          variant="outlined">
          Go back
        </Button>
      </Grid>
    </Grid>
  )
}

export default ModuleUnathorizedPage
