import { AppDispatch } from 'Common/store/createStore'

import { SearchConfig } from './searchConfig'

export const TOGGLE_SINGLE = 'TOGGLE_SINGLE'
export const TOGGLE_ALL = 'TOGGLE_ALL'

export const toggleSingle =
  (selectedResultId: string, searchConfig: SearchConfig) => (dispatch: AppDispatch) =>
    dispatch({
      type: TOGGLE_SINGLE,
      meta: {
        selectedResultId,
        searchConfig,
      },
    })

export const toggleAll = (searchConfig: SearchConfig) => (dispatch: AppDispatch) =>
  dispatch({
    type: TOGGLE_ALL,
    meta: {
      searchConfig,
    },
  })
