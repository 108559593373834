import { getOr } from 'lodash/fp'

import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_CONTACT_ASSETS_REQUEST,
  GET_CONTACT_ASSETS_SUCCESS,
  GET_CONTACT_ASSETS_FAILURE,
  DISCONNECT_ASSET_CONTACT_REQUEST,
  DISCONNECT_ASSET_CONTACT_SUCCESS,
  CONNECT_ASSET_CONTACT_REQUEST,
  CONNECT_ASSET_CONTACT_SUCCESS,
} from '../actions/contactAssetsActions'
import {
  GET_CONTACT_COMPANY_CONTACTS_REQUEST,
  GET_CONTACT_COMPANY_CONTACTS_SUCCESS,
  GET_CONTACT_COMPANY_CONTACTS_FAILURE,
} from '../actions/contactCompanyContactsActions'
import {
  GET_CONTACT_DETAILS_REQUEST,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_DETAILS_FAILURE,
  PATCH_CONTACT_DETAILS_REQUEST,
  PATCH_CONTACT_DETAILS_SUCCESS,
  PATCH_CONTACT_DETAILS_FAILURE,
} from '../actions/contactDetailsActions'
import {
  GET_CONTACT_DETAILS_LINKS_REQUEST,
  GET_CONTACT_DETAILS_LINKS_SUCCESS,
  GET_CONTACT_DETAILS_LINKS_FAILURE,
  PUT_CONTACT_DETAILS_LINKS_REQUEST,
  PUT_CONTACT_DETAILS_LINKS_SUCCESS,
  PUT_CONTACT_DETAILS_LINKS_FAILURE,
} from '../actions/contactDetailsLinksActions'
import {
  GET_ACTIVE_CONTACT_LISTS_REQUEST,
  GET_ACTIVE_CONTACT_LISTS_SUCCESS,
  GET_ACTIVE_CONTACT_LISTS_FAILURE,
  PATCH_ACTIVE_CONTACT_LISTS_REQUEST,
  PATCH_ACTIVE_CONTACT_LISTS_SUCCESS,
  PATCH_ACTIVE_CONTACT_LISTS_FAILURE,
} from '../actions/contactListsActions'
import {
  GET_CONTACT_RESELLER_INFO_REQUEST,
  GET_CONTACT_RESELLER_INFO_SUCCESS,
  GET_CONTACT_RESELLER_INFO_FAILURE,
  PATCH_CONTACT_RESELLER_INFO_REQUEST,
  PATCH_CONTACT_RESELLER_INFO_SUCCESS,
  PATCH_CONTACT_RESELLER_INFO_FAILURE,
} from '../actions/contactResellerActions'

const initialState = {
  refreshThumbnail: false,
  profile: null,
  getContactLinkStatus: undefined,
  contactListsGetStatus: undefined,
  companyContactsGetStatus: undefined,
  resellerInfoGetStatus: undefined,
  getStatus: undefined,
  assetsGetStatus: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_DETAILS_REQUEST:
      return { ...state, profile: null, getStatus: requestStatus.request }

    case GET_CONTACT_DETAILS_SUCCESS: {
      return {
        ...initialState,
        profile: { ...state.profile, ...action.payload.json },
        getStatus: requestStatus.success,
        getContactLinkStatus: state.getContactLinkStatus,
      }
    }

    case GET_CONTACT_DETAILS_FAILURE:
      return { ...state, getStatus: requestStatus.failure }

    case PATCH_CONTACT_DETAILS_REQUEST:
      return { ...state, patchStatus: requestStatus.request }

    case PATCH_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        profile: { ...action.payload.json, contactLinks: state.profile.contactLinks },
        patchStatus: requestStatus.success,
      }

    case PATCH_CONTACT_DETAILS_FAILURE:
      return { ...state, patchStatus: requestStatus.failure }

    case GET_CONTACT_DETAILS_LINKS_REQUEST:
      return {
        ...state,
        profile: { ...state.profile },
        getContactLinkStatus: requestStatus.request,
      }

    case GET_CONTACT_DETAILS_LINKS_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, contactLinks: action.payload.json.contactLinks },
        getContactLinkStatus: requestStatus.success,
      }

    case GET_CONTACT_DETAILS_LINKS_FAILURE:
      return { ...state, getContactLinkStatus: requestStatus.failure }

    case PUT_CONTACT_DETAILS_LINKS_REQUEST:
      return { ...state, patchContactLinkStatus: requestStatus.request }

    case PUT_CONTACT_DETAILS_LINKS_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, contactLinks: action.payload.json.contactLinks },
        patchContactLinkStatus: requestStatus.success,
      }

    case PUT_CONTACT_DETAILS_LINKS_FAILURE:
      return { ...state, patchContactLinkStatus: requestStatus.failure }

    case GET_CONTACT_ASSETS_REQUEST:
      return { ...state, assetsGetStatus: requestStatus.request }

    case GET_CONTACT_ASSETS_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, assets: action.payload.json },
        assetsGetStatus: requestStatus.success,
      }

    case GET_CONTACT_ASSETS_FAILURE:
      return { ...state, assetsGetStatus: requestStatus.failure }

    case CONNECT_ASSET_CONTACT_REQUEST:
    case DISCONNECT_ASSET_CONTACT_REQUEST:
      return { ...state, refreshThumbnail: false }

    case DISCONNECT_ASSET_CONTACT_SUCCESS: {
      const refreshThumbnail =
        getOr(getOr(null, 'meta.asset.assetType.id', action), 'meta.asset.assetTypeId', action) ===
        '4'
      return {
        ...state,
        profile: {
          ...state.profile,
          assets: action.payload.json,
          imageAssetId: refreshThumbnail ? 0 : state.profile.imageAssetId,
        },
        refreshThumbnail,
        assetsGetStatus: requestStatus.success,
      }
    }

    case CONNECT_ASSET_CONTACT_SUCCESS: {
      const refreshThumbnail =
        getOr(getOr(null, 'meta.asset.assetType.id', action), 'meta.asset.assetTypeId', action) ===
        '4'
      return {
        ...state,
        profile: {
          ...state.profile,
          assets: action.payload.json,
          imageAssetId: refreshThumbnail ? action.meta.asset.id : state.profile.imageAssetId,
        },
        refreshThumbnail,
        assetsGetStatus: requestStatus.success,
      }
    }

    case GET_CONTACT_RESELLER_INFO_REQUEST:
      return { ...state, resellerInfoGetStatus: requestStatus.request }

    case GET_CONTACT_RESELLER_INFO_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, resellerInfo: action.payload.json },
        resellerInfoGetStatus: requestStatus.success,
      }

    case GET_CONTACT_RESELLER_INFO_FAILURE:
      return { ...state, resellerInfoGetStatus: requestStatus.failure }

    case PATCH_CONTACT_RESELLER_INFO_REQUEST:
      return { ...state, resellerInfoPatchStatus: requestStatus.request }

    case PATCH_CONTACT_RESELLER_INFO_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, resellerInfo: action.payload.json },
        resellerInfoPatchStatus: requestStatus.success,
      }

    case PATCH_CONTACT_RESELLER_INFO_FAILURE:
      return { ...state, resellerInfoPatchStatus: requestStatus.failure }

    case GET_ACTIVE_CONTACT_LISTS_REQUEST:
      return { ...state, contactListsGetStatus: requestStatus.request }

    case GET_ACTIVE_CONTACT_LISTS_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, lists: getOr([], 'lists', action.payload.json) },
        contactListsGetStatus: requestStatus.success,
      }

    case GET_ACTIVE_CONTACT_LISTS_FAILURE:
      return { ...state, contactListsGetStatus: requestStatus.failure }

    case PATCH_ACTIVE_CONTACT_LISTS_REQUEST:
      return { ...state, contactListsPatchStatus: requestStatus.request }

    case PATCH_ACTIVE_CONTACT_LISTS_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, lists: getOr([], 'lists', action.payload.json) },
        contactListsPatchStatus: requestStatus.success,
      }

    case PATCH_ACTIVE_CONTACT_LISTS_FAILURE:
      return { ...state, contactListsPatchStatus: requestStatus.failure }

    case GET_CONTACT_COMPANY_CONTACTS_REQUEST:
      return { ...state, companyContactsGetStatus: requestStatus.request }

    case GET_CONTACT_COMPANY_CONTACTS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          companyContacts: getOr([], 'companyContacts', action.payload.json),
        },
        companyContactsGetStatus: requestStatus.success,
      }

    case GET_CONTACT_COMPANY_CONTACTS_FAILURE:
      return { ...state, companyContactsGetStatus: requestStatus.failure }

    default:
      return state
  }
}
