import { isEmpty } from 'lodash/fp'
import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import { RemoveButton } from 'Common/components/buttons'
import { Dialog } from 'Common/core'

import ContactPicker from './ContactPicker'

type Props = {
  onClose(..._args: unknown[]): unknown
  onChange(..._args: unknown[]): unknown
  searchId: string
  currentContact: Record<string, unknown>
  contactLabel: string
  searchFilters?: unknown[]
  searchOptions?: Record<string, unknown>
  title?: string
}

class ContactPickerDialog extends Component<Props> {
  onChange = (contact) => {
    const { onChange } = this.props
    if (isEmpty(contact)) return
    onChange(contact)
  }

  render() {
    const {
      currentContact,
      searchId,
      contactLabel,
      searchFilters,
      searchOptions,
      onChange,
      onClose,
      title,
    } = this.props
    const isContactSet = !isEmpty(currentContact)
    const actions = [
      isContactSet ? (
        <RemoveButton
          key="remove"
          onClick={() => onChange()}
          style={{
            float: 'left',
          }}>{`Remove ${contactLabel}`}</RemoveButton>
      ) : null,
    ]
    return (
      <Dialog
        title={title ? title : `Search or remove connection of ${contactLabel}`}
        actions={actions}
        onClose={onClose}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ContactPicker
              onChange={this.onChange}
              {...{
                searchId,
                contactLabel,
                searchFilters,
                searchOptions,
              }}
            />
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}
export default ContactPickerDialog
