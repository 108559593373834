import { Cell } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'
import { PureComponent } from 'react'

type Props = {
  column: {
    selectField: string
    cellConfig?: {
      transform(..._args: unknown[]): unknown
    }
  }
  resultStatus: string
  result?: any
  viewProps?: Record<string, unknown>
  language?(..._args: unknown[]): unknown
}

class ListCell extends PureComponent<Props> {
  render() {
    const { column, result, resultStatus, viewProps, language, ...otherProps } = this.props
    const transform = getOr((_) => _, 'cellConfig.transform', column)
    const filter = getOr(() => (_) => _, 'cellConfig.filter', column)
    const filteredList: any = getOr([], `document.${column.selectField}`, result).filter(
      filter(language),
    )
    const valueList = transform(filteredList)
    const value =
      valueList.length > 0 ? (
        valueList.map((value, index) => (
          <span key={index}>
            {value}
            {index + 1 !== valueList.length && ', '}
          </span>
        ))
      ) : (
        <span>-</span>
      )
    return (
      <Cell {...otherProps}>
        <span
          style={{
            whiteSpace: 'nowrap',
          }}>
          {resultStatus === 'pending' ? 'Loading...' : value}
        </span>
      </Cell>
    )
  }
}

export default ListCell
