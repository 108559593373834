import { convertPercentagesToFactors } from 'Calculation/utils/calculation'

import { services } from 'Common/environment'
import { Concept } from 'Common/types'
import request from 'Common/utils/net/request'

export const GET_CONCEPT_CALCULATION = 'GET_CONCEPT_CALCULATION'
export const GET_CONCEPT_CALCULATION_REQUEST = 'GET_CONCEPT_CALCULATION_REQUEST'
export const GET_CONCEPT_CALCULATION_SUCCESS = 'GET_CONCEPT_CALCULATION_SUCCESS'
export const GET_CONCEPT_CALCULATION_FAILURE = 'GET_CONCEPT_CALCULATION_FAILURE'
export const GET_CONCEPT_PRE_CALCULATION = 'GET_CONCEPT_PRE_CALCULATION'
export const GET_CONCEPT_PRE_CALCULATION_REQUEST = 'GET_CONCEPT_PRE_CALCULATION_REQUEST'
export const GET_CONCEPT_PRE_CALCULATION_SUCCESS = 'GET_CONCEPT_PRE_CALCULATION_SUCCESS'
export const GET_CONCEPT_PRE_CALCULATION_FAILURE = 'GET_CONCEPT_PRE_CALCULATION_FAILURE'
export const GET_CALCULATION_GROUP = 'GET_CALCULATION_GROUP'
export const GET_CALCULATION_GROUP_REQUEST = 'GET_CALCULATION_GROUP_REQUEST'
export const GET_CALCULATION_GROUP_SUCCESS = 'GET_CALCULATION_GROUP_SUCCESS'
export const GET_CALCULATION_GROUP_FAILURE = 'GET_CALCULATION_GROUP_FAILURE'
export const GET_CONCEPT_CALCULATION_GROUP = 'GET_CONCEPT_CALCULATION_GROUP'
export const GET_CONCEPT_CALCULATION_GROUP_REQUEST = 'GET_CONCEPT_CALCULATION_GROUP_REQUEST'
export const GET_CONCEPT_CALCULATION_GROUP_SUCCESS = 'GET_CONCEPT_CALCULATION_GROUP_SUCCESS'
export const GET_CONCEPT_CALCULATION_GROUP_FAILURE = 'GET_CONCEPT_CALCULATION_GROUP_FAILURE'
export const GET_CONCEPT_EDITION_CHARACTERS = 'GET_CONCEPT_EDITION_CHARACTERS'
export const GET_CONCEPT_MINUTES_FROM_CHARACTERS = 'GET_CONCEPT_MINUTES_FROM_CHARACTERS'

export type State = any

export const getConceptCalculation = (concept: any) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_CALCULATION,
    meta: {
      conceptId: concept.id,
    },
    payload: {
      promise: request({
        method: 'POST',
        url: services.calculation(`/v1/concepts`),
        body: {
          ...concept,
          productions: concept.productions.map((production) => ({
            ...production,
            royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
          })),
        },
      }),
    },
  })
export const getPreConceptCalculation = (concept: Concept) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_PRE_CALCULATION,
    meta: {
      conceptId: concept.id,
      concept,
    },
    payload: {
      promise: request({
        url: services.calculation(`/v1/concepts`),
        method: 'POST',
        body: {
          ...concept,
          productions: concept.productions
            .filter((p) => !p.hideFromCalculation)
            .map((production) => ({
              ...production,
              royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
            })),
        },
      }),
    },
  })
export const getConceptEditionCharacters = (concept: Concept, editionId: number) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_EDITION_CHARACTERS,
    payload: {
      promise: request({
        url: services.calculation(`/v1/concepts/editioncharacters/${editionId}`),
        method: 'POST',
        body: {
          ...concept,
        },
      }),
    },
  })
export const getConceptMinutesFromCharacters = (numberOfCharacters: number) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_MINUTES_FROM_CHARACTERS,
    payload: {
      promise: request({
        url: services.calculation(`/v1/concepts/characterstominutes/${numberOfCharacters}`),
        method: 'GET',
      }),
    },
  })
export const getCalculationGroup = (calculationGroupId: number) => (dispatch) =>
  dispatch({
    type: GET_CALCULATION_GROUP,
    meta: {
      calculationGroupId: calculationGroupId,
    },
    payload: {
      promise: request({
        url: services.calculation(`/v1/calculationGroups/${calculationGroupId}`),
        method: 'GET',
      }),
    },
  })
export const getConceptCalculationGroup = (conceptId: number) => (dispatch) =>
  dispatch({
    type: GET_CONCEPT_CALCULATION_GROUP,
    meta: {
      conceptId,
    },
    payload: {
      promise: request({
        url: services.calculation(`/v1/calculationGroups/concept/${conceptId}`),
        method: 'GET',
      }),
    },
  })
