import { getOr } from 'lodash/fp'
import { useState } from 'react'

import { MenuItem, FormControl, Select, Chip } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'

import { State } from '../../auth/authReducer'
import { MetaData, MetaDataItem } from '../../metadata'
import { ClaimType } from '../../types'
import { getMyPublishingHouseIds } from '../../utils/user/auth'

const PREFIX = 'OptionFilter'

const classes = {
  formControl: `${PREFIX}-formControl`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  noLabel: `${PREFIX}-noLabel`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },

  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  },
}))

type Props = {
  updateFilter: (..._args: Array<any>) => any
  filter: any
  // {
  //   id: string
  //   metaDataId: MetaDataKey
  //   name: string
  //   typeConfig: {
  //     filterByBrands: boolean
  //     disableSelectAll: boolean
  //     filterByPublishingHouse: boolean
  //   }
  // }
  accessModule: ClaimType
  active: any[]
  metaData: MetaData
  auth: State
  chipColor?: string
}

const OptionFilter = (props: Props) => {
  const [open, setOpen] = useState(false)

  const onToggleOption = (options: string[]) => {
    const { filter, updateFilter } = props
    updateFilter(filter.id, options)
    setOpen(false)
  }

  const { filter, active, metaData, chipColor, auth, accessModule } = props
  const options = metaData[filter.metaDataId]
  const brands = getOr([], 'data.opus_user.brands', auth)
  const { typeConfig = {} } = filter
  const { filterByPublishingHouse = false, filterByBrands = false } = typeConfig
  const myPublishingHouseIds = getMyPublishingHouseIds(auth, accessModule)
  const filteredOptions: MetaDataItem[] = options
    .filter((option) => (filterByPublishingHouse ? myPublishingHouseIds.includes(option.id) : true))
    .filter((option) => (filterByBrands ? brands.map((_) => _.id).includes(option.id) : true))
    .filter((_) => _)
  return (
    <StyledGrid container spacing={2}>
      <Grid xs={12}>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            label={filter.name}
            multiple
            value={active || ''}
            onChange={(event?: any) => onToggleOption(event.target.value)}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            renderValue={(selected: any) => (
              <div className={classes.chips}>
                {filteredOptions
                  .filter((available: any) => selected.includes(available.id))
                  .map((value) => (
                    <Chip
                      key={value.id}
                      label={value.name}
                      className={classes.chip}
                      style={{
                        backgroundColor: chipColor,
                      }}
                    />
                  ))}
              </div>
            )}>
            {filteredOptions.map((availableOption, index) => (
              <MenuItem key={index} value={availableOption.id}>
                {availableOption.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </StyledGrid>
  )
}

export default OptionFilter
