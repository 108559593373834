import { NavLink } from 'react-router-dom'

import { Link } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'

const PREFIX = 'BatchFailedViewItem'

const classes = {
  row: `${PREFIX}-row`,
  col: `${PREFIX}-col`,
}

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.row}`]: {
    borderTop: '1px solid #e0e0e0',
  },

  [`& .${classes.col}`]: {
    wordBreak: 'break-all',
  },
}))

type Props = {
  result: {
    id: number
    workId: number
    title: string
    isbn: string
  }
  searchOptions: Record<string, unknown>
  classes: any
  viewProps: {
    productionWarnings: {
      id: number
      isSuccess: boolean
      warnings: {
        messageCode: string
        message: string
      }[]
    }[]
  }
}

const BatchFailedViewItem = (props: Props) => {
  const { result, viewProps } = props
  const { productionWarnings } = viewProps
  const currentWarnings = productionWarnings.find((pw) => pw.id == result.id)
  return (
    <StyledGrid container spacing={2} xs={12}>
      <Grid className={classes.col} xs={2}>
        {result.isbn ? result.isbn : '-'}
      </Grid>

      <Grid className={classes.col} xs={3}>
        <Link
          component={NavLink}
          to={`/book/${result.workId}/edition/${result.id}`}
          className="linkPrimary"
          style={{
            textDecoration: 'none',
          }}>
          {result.title ? result.title : result.isbn}
        </Link>
      </Grid>
      <Grid className={classes.col} xs={7}>
        {currentWarnings ? currentWarnings.warnings.map((cw) => cw.message).join('. ') : ''}
      </Grid>
    </StyledGrid>
  )
}
export default BatchFailedViewItem
