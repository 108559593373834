const defaultTheme = {
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    primary: {
      main: '#3aa7bd',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#453abd',
    },
    warning: {
      main: '#fff176',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: '#a5d6a7',
    },
  },
}

export default defaultTheme
