import { v4 as uuid } from 'uuid'

import store from '../../store/createStore'

export const getHeaders = () => {
  const token = store.getState().auth.data.token
  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'correlation-id': uuid(),
    'Accept-Language': 'sv-SE',
    Authorization: `Bearer ${token}`,
  }
  return { ...baseHeaders }
}

export const getHeadersFileUpload = () => {
  const token = store.getState().auth.data.token
  const baseHeaders = {
    Accept: 'application/json',
    'correlation-id': uuid(),
    'Accept-Language': 'sv-SE',
    Authorization: `Bearer ${token}`,
  }
  return { ...baseHeaders }
}

export const getHeadersWithEmail = (value: string) => {
  const token = store.getState().auth.data.token
  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'correlation-id': uuid(),
    'Accept-Language': 'sv-SE',
    Authorization: `Bearer ${token}`,
    email: value,
  }
  return { ...baseHeaders }
}

const parseJson = async (response) => {
  try {
    return await response.json()
  } catch (error) {
    return ''
  }
}

export const fetchGet = async (endpoint: string, headers: any = getHeaders()) => {
  return fetch(endpoint, {
    method: 'GET',
    headers: headers,
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return await parseJson(response)
  })
}

export const fetchGetBlob = async (endpoint: string, headers: any = getHeaders()) => {
  return fetch(endpoint, {
    method: 'GET',
    headers: headers,
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return response
  })
}

export const fetchGetDownload = async (endpoint: string, headers: any = getHeaders()) => {
  return fetch(endpoint, {
    method: 'GET',
    headers: headers,
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return resolveBlob(response)
  })
}

export const fetchPost = async (
  endpoint: string,
  payload: string | FormData,
  headers: any = getHeaders(),
) => {
  return fetch(endpoint, {
    method: 'POST',
    body: payload,
    headers: headers,
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return await parseJson(response)
  })
}

export const fetchPostFile = async (
  endpoint: string,
  payload: any,
  headers: any = getHeadersFileUpload(),
) => {
  return fetch(endpoint, {
    method: 'POST',
    body: payload,
    headers: headers,
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return await parseJson(response)
  })
}

export const fetchPatch = async (endpoint: string, payload: string) => {
  return fetch(endpoint, {
    method: 'PATCH',
    body: payload,
    headers: getHeaders(),
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return await parseJson(response)
  })
}

export const fetchPut = async (endpoint: string, payload: string, headers = getHeaders()) => {
  return fetch(endpoint, {
    method: 'PUT',
    body: payload,
    headers: headers,
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return await parseJson(response)
  })
}

export const fetchDelete = async (endpoint: string) => {
  return fetch(endpoint, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(async (response) => {
    if (!response.ok) {
      let error
      try {
        const data = await response.json()
        error = data
      } catch (e) {
        error = await response
      }
      if (error.status === undefined) {
        error.status = response.status
      }
      return Promise.reject(error)
    }
    return await parseJson(response)
  })
}

const resolveBlob = async (response) => {
  const headerval = response.headers.get('content-disposition')
  if (headerval != null) {
    let fileName = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '')
    fileName = decodeURI(fileName)
    const url = window.URL.createObjectURL(await response.blob())
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
  }
}
