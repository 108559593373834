import {
  Close as CloseIcon,
  RemoveCircleOutline as RemoveOutlineIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddOutlineIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
  EditOutlined,
  SaveAlt as ExportIcon,
} from '@mui/icons-material'
import { Button as MUIButton, CircularProgress } from '@mui/material'

export type ButtonProps = {
  children?: React.ReactNode
  submitting?: boolean
  disabled?: boolean
  fullWidth?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  color?: any
  style?: any
  onClick: any
}

const buttonCreator = (Icon, defaultLabel, color = 'primary') => {
  return function Button(props: ButtonProps) {
    const { children, submitting, ...rest } = props
    return (
      <MUIButton
        sx={{ display: 'flex', displayPrint: 'none' }}
        key="button"
        variant="outlined"
        color={color}
        {...rest}
        endIcon={submitting ? <CircularProgress size="small" /> : Icon ? <Icon /> : null}>
        {children ? children : defaultLabel}
      </MUIButton>
    )
  }
}

export const PlainButton = buttonCreator(null, 'Save', 'primary')
export const ExportButton = buttonCreator(ExportIcon, 'Export', 'primary')
export const SaveButton = buttonCreator(SaveIcon, 'Save', 'primary')
export const ApplyFilterButton = buttonCreator(SaveIcon, 'Apply', 'primary')
export const EditButton = buttonCreator(EditOutlined, 'Edit', 'primary')
export const DeleteButton = buttonCreator(DeleteIcon, 'Delete', 'error')
export const AddButton = buttonCreator(AddOutlineIcon, 'Add', 'primary')
export const CreateButton = buttonCreator(AddOutlineIcon, 'Create', 'primary')
export const RemoveButton = buttonCreator(RemoveOutlineIcon, 'Remove', 'error')
export const UndoButton = buttonCreator(UndoIcon, 'Reset', 'error')
export const CancelButton = buttonCreator(UndoIcon, 'Cancel', 'error')
export const CloseButton = buttonCreator(CloseIcon, 'Close', 'error')
