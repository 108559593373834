import { isEmpty, isString, isUndefined } from 'lodash/fp'

import { PropertyRule } from 'Common/types'

export const getFieldInfo = (
  propertyRules: PropertyRule = {},
  infoText: string,
  history?: string,
) => {
  const isReadOnly = propertyRules.isReadOnly ? true : false
  const isSharedTo = !isUndefined(propertyRules.isSharedTo)
  const hasInfoText = !isEmpty(infoText) && infoText !== '-'
  const hasHistory = !isEmpty(history) && history !== ''
  return {
    showInfoButton: isReadOnly || isSharedTo || hasHistory || hasInfoText,
    isReadOnly,
    isSharedTo,
    hasInfoText,
    hasHistory,
  }
}
export function getErrorText(touched: boolean, error: string): string | null {
  return touched && error ? error : null
}
export function getHasError(touched: boolean, error: string): boolean {
  return getErrorText(touched, error) !== null
}
export const getLabelText = (
  label: React.ReactNode | Record<string, any>,
  propertyRules: PropertyRule,
) => {
  if (!isString(label)) return label
  return `${propertyRules.isRecommended ? '=>' : ''} ${label}`.trim()
}
export const getFormInfoStyleWidth = (conditions: boolean[] = [], currentWidth = '100%') => {
  const widthReduce = conditions.map((_) => (_ ? 30 : 0)).reduce((a, b) => a + b, 0)
  return widthReduce === 0 ? currentWidth : `calc(${currentWidth} - ${widthReduce}px)`
}
