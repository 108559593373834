import { DrawerItem } from 'Common/components/navigation/drawerListItem'

import { UPDATE_SUBMENU_OPEN, UPDATE_SUBMENU_HAS_ITEMS } from './menuActions'

const initialState = {
  drawer: [],
  submenu: {
    isOpen: false,
    hasItems: false,
  },
}

type State = {
  drawer: DrawerItem[]
  submenu: {
    isOpen: boolean
    hasItems: boolean
  }
}

export default (state: State = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case UPDATE_SUBMENU_OPEN:
      return { ...state, submenu: { ...state.submenu, ...payload } }

    case UPDATE_SUBMENU_HAS_ITEMS:
      return { ...state, submenu: { ...state.submenu, ...payload } }

    case '@@router/LOCATION_CHANGE':
      return { ...state, submenu: { ...state.submenu, isOpen: false } }

    default:
      return state
  }
}
