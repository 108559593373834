import { getOr, isFunction, reverse, sortBy } from 'lodash/fp'

export const getDefaultState = (): any => ({
  sorting: {
    desc: false,
  },
})
// needs to be a non-arrow function so we can bind 'this'
export function updateSorting(field, defaultValue) {
  this.setState((state) => {
    if (state.sorting.field === field && state.sorting.desc) return getDefaultState()
    return {
      sorting: {
        field,
        desc: state.sorting.field === field ? !state.sorting.desc : false,
        defaultValue,
      },
    }
  })
}

export const getSortedList = (list, sorting?: any) => {
  if (!sorting || !sorting.field) return list
  const fieldIsFunction = isFunction(sorting.field)
  const sorted = sortBy(
    (listItem) =>
      fieldIsFunction
        ? sorting.field(listItem)
        : getOr(sorting.defaultValue, sorting.field, listItem),
    list,
  )
  return sorting.desc ? reverse(sorted) : sorted
}
