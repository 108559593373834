import { DateTime } from 'luxon'
import { Component } from 'react'

import { TextField, FormControl, InputAdornment } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { PropertyRule } from 'Common/types'

import { FormFieldInfo, FormFieldInfoToggler } from './fieldinfo'
import { getFieldInfo, getLabelText, getErrorText, getHasError } from './formFieldHelpers'

type Props = {
  input: any
  meta: any
  label: string
  onHandleChange: (..._args: Array<any>) => any
  showMetaDataAdmin: boolean
  required: boolean
  id: string
  disabled: boolean
  infoText: string
  propertyRules: PropertyRule
  allowEmpty: boolean
  history?: string
  style: any
  dispatch?: any
}
export type State = {
  clickedInfoButton: boolean
  showInfoTextDialog: boolean
}
export class FormDatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      clickedInfoButton: false,
      showInfoTextDialog: false,
    }
  }

  onChange = (event) => {
    const date = DateTime.fromSQL(event.target.value)
    if (this.props.onHandleChange) {
      this.props.onHandleChange(date)
    } else {
      this.props.input.onChange(date)
    }
  }

  clickIcon = () => {
    this.setState({
      clickedInfoButton: !this.state.clickedInfoButton,
    })
  }

  render() {
    const {
      input,
      meta,
      id,
      infoText,
      propertyRules = {},
      showMetaDataAdmin,
      onHandleChange,
      history,
      disabled = false,
      required = false,
      label,
      dispatch,
      ...otherProps
    } = this.props
    const { showInfoTextDialog, clickedInfoButton } = this.state
    const { name } = input
    const fieldInfo = getFieldInfo(propertyRules, infoText)
    const isDisabled = disabled || fieldInfo.isReadOnly
    const { error, touched } = meta
    const nestedProps = {
      ...input,
      ...otherProps,
      value: DateTime.fromISO(input.value).toISODate() || '',
      label: getLabelText(label, propertyRules),
      helperText: getErrorText(touched, error),
      error: getHasError(touched, error),
      disabled: isDisabled,
      required,
      onChange: this.onChange.bind(this),
      InputProps: {
        inputProps: { min: '0001-01-01', max: '2099-12-31' },
        endAdornment: (
          <InputAdornment position="end">
            <FormFieldInfoToggler
              clickIcon={this.clickIcon}
              showInfoButton={fieldInfo.showInfoButton}
            />
          </InputAdornment>
        ),
      },
      InputLabelProps: {
        shrink: true,
      },
    }
    return (
      <Grid xs={12}>
        <FormControl variant="standard" fullWidth>
          <TextField
            variant="standard"
            fullWidth
            id={id || `FormDatePicker-${name}-${label}`.replace(/[^A-Za-z0-9-]/gi, '')}
            type="date"
            {...nestedProps}
          />
        </FormControl>

        <FormFieldInfo
          {...{
            fieldInfo,
            propertyRules,
            history,
            infoText,
            showInfoTextDialog,
            clickedInfoButton,
          }}
        />
      </Grid>
    )
  }
}
export default FormDatePicker
