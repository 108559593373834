import { Component } from 'react'

import SearchControl from 'Common/search/SearchControl'

import {
  searchConfig as failedBatchSearchConfig,
  viewsConfig as failedBatchViewConfig,
} from './failedBatchUpdateBaseConfig'

type Props = {
  updateSearchOptions: (..._args: Array<any>) => any
  productionWarnings: any[]
}
export default class BatchErrorResult extends Component<Props> {
  UNSAFE_componentWillMount() {
    const { updateSearchOptions, productionWarnings } = this.props
    updateSearchOptions(failedBatchSearchConfig, {
      selectFields: failedBatchSearchConfig.defaultSelectFields,
      query: productionWarnings.map((pw) => pw.id).join(', '),
      queryField: 'id',
      combinator: 'or',
    })
  }

  static defaultProps = {
    selectedEditions: [],
  }

  render() {
    const { productionWarnings } = this.props
    return (
      <SearchControl
        searchConfig={failedBatchSearchConfig}
        viewsConfig={failedBatchViewConfig}
        viewProps={{
          productionWarnings,
        }}
      />
    )
  }
}
