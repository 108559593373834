import { searchConfig as assetsSearchConfig } from 'Asset/config/assetsConfig'
import { searchConfig as contactsSearchConfig } from 'Contact/config/contactsConfig'
import { searchConfig as activitiesSearchConfig } from 'Work/config/activitiesConfig'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import { searchConfig as worksSearchConfig } from 'Work/config/worksConfig'
import { isNil } from 'lodash/fp'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import RightsDialog from 'Common/components/RightsDialog'
import ErrorBoundary from 'Common/components/error/ErrorBoundary'
import MainNavigation from 'Common/components/navigation/MainNavigation'
import PrimaryAppBar from 'Common/components/navigation/PrimaryAppBar'
import { updateSubmenuOpen as updateSubmenuOpenAction } from 'Common/menu/menuActions'
import { RootState } from 'Common/store/createStore'
import 'Common/styles/embla.css'
import 'Common/styles/opusGlobalMain.scss'

import Routes from '../components/router/Routes'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

type BaseAppProps = {
  logout(..._args: unknown[]): unknown
  children: React.ReactNode
}

const connector = connect(
  (state: RootState) => {
    const { auth, menu, error, settings, search } = state
    const userInfo = auth.data
    return {
      auth,
      menu,
      userInfo,
      error,
      settings: settings.data,
      activities: search[activitiesSearchConfig.id] || {},
      works: search[worksSearchConfig.id] || {},
      productions: search[productionsSearchConfig.id] || {},
      assets: search[assetsSearchConfig.id] || {},
      contacts: search[contactsSearchConfig.id] || {},
    }
  },
  {
    updateSubmenuOpen: updateSubmenuOpenAction,
  },
)

type PropsFromRedux = ConnectedProps<typeof connector>

export const baseAppInit = () => {
  type BaseAppState = {
    open: boolean
    showMobileTopNav: boolean
  }

  class BaseApp extends Component<BaseAppProps & PropsFromRedux, BaseAppState> {
    state = {
      open: true,
      showMobileTopNav: false,
    }

    componentDidMount() {
      const rootLoader = document.getElementById('rootLoader')
      if (window.innerWidth < 600) {
        localStorage.setItem('mainNavigationIsOpen', 'false')
      } else {
        localStorage.setItem('mainNavigationIsOpen', 'true')
      }
      const mainNavigationIsOpen = localStorage.getItem('mainNavigationIsOpen')
      if (mainNavigationIsOpen)
        this.setState({
          open: JSON.parse(mainNavigationIsOpen),
        })
      if (rootLoader) {
        rootLoader.style.opacity = '0'
      }
    }

    handleDrawerOpen = () => {
      localStorage.setItem('mainNavigationIsOpen', 'true')
      const mainNavigationIsOpen = localStorage.getItem('mainNavigationIsOpen')
      mainNavigationIsOpen &&
        this.setState({
          open: JSON.parse(mainNavigationIsOpen),
        })
    }

    toggleMobileSubNavigation = (forcedState?: boolean) => {
      const { updateSubmenuOpen, menu } = this.props
      const openState = isNil(forcedState) ? !menu.submenu.isOpen : forcedState
      updateSubmenuOpen(openState)
    }

    handleDrawerClose = () => {
      localStorage.setItem('mainNavigationIsOpen', 'false')
      const mainNavigationIsOpen = localStorage.getItem('mainNavigationIsOpen')
      mainNavigationIsOpen &&
        this.setState({
          open: JSON.parse(mainNavigationIsOpen),
        })
    }

    renderRightsDialog() {
      if (this.props.userInfo.opus_user_id) return null
      return <RightsDialog />
    }

    render() {
      const {
        productions,
        works,
        activities,
        contacts,
        assets,
        auth,
        userInfo,
        menu,
        logout,
        settings,
      } = this.props
      const isSubmenuOpen = menu.submenu.isOpen
      const hasSubmenu = menu.submenu.hasItems

      return (
        <ErrorBoundary point="BaseApp.render">
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <PrimaryAppBar
              handleDrawerOpen={this.handleDrawerOpen}
              open={this.state.open}
              toggleMobileSubNavigation={this.toggleMobileSubNavigation}
              mobileSubNavigationOpen={isSubmenuOpen}
              hasSubmenu={hasSubmenu}
              {...{
                logout,
                userInfo,
                settings,
                auth,
              }}
            />
            <Stack direction={'row'} spacing={0} sx={{ width: '100%' }}>
              <MainNavigation
                {...{
                  productions,
                  works,
                  activities,
                  contacts,
                  assets,
                  auth,
                }}
                open={this.state.open}
                handleDrawerClose={this.handleDrawerClose}
              />
              <Box component="main" sx={{ flexGrow: 1 }}>
                <Offset className="hidden-print" />
                <Routes
                  {...{
                    auth,
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </ErrorBoundary>
      )
    }
  }
  return connector(BaseApp)
}
