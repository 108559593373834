import { requestStatus } from 'Common/utils/net/statuses'

import {
  GET_METADATA_TYPE_REQUEST,
  GET_METADATA_TYPE_SUCCESS,
  GET_METADATA_TYPE_FAILURE,
  PATCH_METADATA_TYPE_REQUEST,
  PATCH_METADATA_TYPE_SUCCESS,
  PATCH_METADATA_TYPE_FAILURE,
  DELETE_METADATA_TYPE_REQUEST,
  DELETE_METADATA_TYPE_SUCCESS,
  DELETE_METADATA_TYPE_FAILURE,
  POST_METADATA_ITEM_REQUEST,
  POST_METADATA_ITEM_SUCCESS,
  POST_METADATA_ITEM_FAILURE,
  PATCH_METADATA_ITEM_REQUEST,
  PATCH_METADATA_ITEM_SUCCESS,
  PATCH_METADATA_ITEM_FAILURE,
  DELETE_METADATA_ITEM_REQUEST,
  DELETE_METADATA_ITEM_SUCCESS,
  DELETE_METADATA_ITEM_FAILURE,
  POST_CUSTOMPROPERTY_TYPE_REQUEST,
  POST_CUSTOMPROPERTY_TYPE_SUCCESS,
  POST_CUSTOMPROPERTY_TYPE_FAILURE,
  PATCH_CUSTOMPROPERTY_TYPE_REQUEST,
  PATCH_CUSTOMPROPERTY_TYPE_SUCCESS,
  PATCH_CUSTOMPROPERTY_TYPE_FAILURE,
  DELETE_CUSTOMPROPERTY_TYPE_REQUEST,
  DELETE_CUSTOMPROPERTY_TYPE_SUCCESS,
  DELETE_CUSTOMPROPERTY_TYPE_FAILURE,
  POST_CUSTOMPROPERTY_ITEM_REQUEST,
  POST_CUSTOMPROPERTY_ITEM_SUCCESS,
  POST_CUSTOMPROPERTY_ITEM_FAILURE,
  PATCH_CUSTOMPROPERTY_ITEM_REQUEST,
  PATCH_CUSTOMPROPERTY_ITEM_SUCCESS,
  PATCH_CUSTOMPROPERTY_ITEM_FAILURE,
  DELETE_CUSTOMPROPERTY_ITEM_REQUEST,
  DELETE_CUSTOMPROPERTY_ITEM_SUCCESS,
  DELETE_CUSTOMPROPERTY_ITEM_FAILURE,
} from '../actions/metaDataTypeActions'

const initialState = {
  data: {
    items: [],
  },
  status: {
    getStatus: undefined,
    getError: undefined,
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_METADATA_TYPE_REQUEST:
      return {
        ...state,
        data: {},
        status: { ...state.status, getStatus: requestStatus.request, getError: null },
      }

    case GET_METADATA_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }
    }

    case GET_METADATA_TYPE_FAILURE:
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }

    case PATCH_METADATA_TYPE_REQUEST:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
      }

    case PATCH_METADATA_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, patchStatus: requestStatus.success },
      }
    }

    case PATCH_METADATA_TYPE_FAILURE:
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.failure, patchError: action.payload },
      }

    case DELETE_METADATA_TYPE_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteStatus: requestStatus.request, deleteError: null },
      }

    case DELETE_METADATA_TYPE_SUCCESS: {
      return {
        ...state,
        data: null,
        status: { ...state.status, deleteStatus: requestStatus.success },
      }
    }

    case DELETE_METADATA_TYPE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          deleteStatus: requestStatus.failure,
          deleteError: action.payload,
        },
      }

    case POST_METADATA_ITEM_REQUEST:
      return {
        ...state,
        status: { ...state.status, postItemStatus: requestStatus.request, postItemError: null },
      }

    case POST_METADATA_ITEM_SUCCESS:
      return {
        ...state,
        data: { ...state.data, items: action.payload.json.items },
        status: { ...state.status, postItemStatus: requestStatus.success },
      }

    case POST_METADATA_ITEM_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          postItemStatus: requestStatus.failure,
          postItemError: action.payload,
        },
      }

    case PATCH_METADATA_ITEM_REQUEST:
      return {
        ...state,
        status: { ...state.status, patchItemStatus: requestStatus.request, patchItemError: null },
      }

    case PATCH_METADATA_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: (state.data.items || []).map((item) =>
            item.id !== action.meta.id ? item : action.payload.json,
          ),
        },
        status: { ...state.status, patchItemStatus: requestStatus.success },
      }

    case PATCH_METADATA_ITEM_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          patchItemStatus: requestStatus.failure,
          patchItemError: action.payload,
        },
      }

    case DELETE_METADATA_ITEM_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteItemStatus: requestStatus.request, deleteItemError: null },
      }

    case DELETE_METADATA_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: (state.data.items || []).filter((item) => item.id !== action.meta.id),
        },
        status: { ...state.status, deleteItemStatus: requestStatus.success },
      }

    case DELETE_METADATA_ITEM_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          deleteItemStatus: requestStatus.failure,
          deleteItemError: action.payload,
        },
      }

    case POST_CUSTOMPROPERTY_TYPE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          postCustomPropTypeStatus: requestStatus.request,
          postCustomPropTypeError: null,
        },
      }

    case POST_CUSTOMPROPERTY_TYPE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, customPropertyTypes: action.payload.json.customPropertyTypes },
        status: { ...state.status, postCustomPropTypeStatus: requestStatus.success },
      }

    case POST_CUSTOMPROPERTY_TYPE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          postCustomPropTypeStatus: requestStatus.failure,
          postCustomPropTypeError: action.payload,
        },
      }

    case PATCH_CUSTOMPROPERTY_TYPE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          patchCustomPropTypeStatus: requestStatus.request,
          patchCustomPropTypeError: null,
        },
      }

    case PATCH_CUSTOMPROPERTY_TYPE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, patchCustomPropTypeStatus: requestStatus.success },
      }

    case PATCH_CUSTOMPROPERTY_TYPE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          patchCustomPropTypeStatus: requestStatus.failure,
          patchCustomPropTypeError: action.payload,
        },
      }

    case DELETE_CUSTOMPROPERTY_TYPE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          deleteCustomPropTypeStatus: requestStatus.request,
          deleteCustomPropTypeError: null,
        },
      }

    case DELETE_CUSTOMPROPERTY_TYPE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, deleteCustomPropTypeStatus: requestStatus.success },
      }

    case DELETE_CUSTOMPROPERTY_TYPE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          deleteCustomPropTypeStatus: requestStatus.failure,
          deleteCustomPropTypeError: action.payload,
        },
      }

    case POST_CUSTOMPROPERTY_ITEM_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          postCustomPropertyItemStatus: requestStatus.request,
          postCustomPropertyItemError: null,
        },
      }

    case POST_CUSTOMPROPERTY_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: (state.data.items || []).map((item) =>
            item.id !== action.meta.metaDataItemId
              ? item
              : { ...item, customPropertyItems: action.payload.json.customPropertyItems },
          ),
        },
        status: { ...state.status, postCustomPropertyItemStatus: requestStatus.success },
      }

    case POST_CUSTOMPROPERTY_ITEM_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          postCustomPropertyItemStatus: requestStatus.failure,
          postCustomPropertyItemError: action.payload,
        },
      }

    case PATCH_CUSTOMPROPERTY_ITEM_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          patchCustomPropertyItemStatus: requestStatus.request,
          patchCustomPropertyItemError: null,
        },
      }

    case PATCH_CUSTOMPROPERTY_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: (state.data.items || []).map((item) =>
            item.id !== action.meta.metaDataItemId
              ? item
              : {
                  ...item,
                  customPropertyItems: (item.customPropertyItems || []).map((customPropertyItem) =>
                    customPropertyItem.id !== action.meta.id
                      ? customPropertyItem
                      : action.payload.json,
                  ),
                },
          ),
        },
        status: { ...state.status, patchCustomPropertyItemStatus: requestStatus.success },
      }

    case PATCH_CUSTOMPROPERTY_ITEM_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          patchCustomPropertyItemStatus: requestStatus.failure,
          patchCustomPropertyItemError: action.payload,
        },
      }

    case DELETE_CUSTOMPROPERTY_ITEM_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          deleteCustomPropertyItemStatus: requestStatus.request,
          deleteCustomPropertyItemError: null,
        },
      }

    case DELETE_CUSTOMPROPERTY_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: (state.data.items || []).map((item) =>
            item.id !== action.meta.metaDataItemId
              ? item
              : {
                  ...item,
                  customPropertyItems: item.customPropertyItems.filter(
                    (customPropertyItem) => customPropertyItem.id !== action.meta.id,
                  ),
                },
          ),
        },
        status: { ...state.status, deleteCustomPropertyItemStatus: requestStatus.success },
      }

    case DELETE_CUSTOMPROPERTY_ITEM_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          deleteCustomPropertyItemStatus: requestStatus.failure,
          deleteCustomPropertyItemError: action.payload,
        },
      }

    default:
      return state
  }
}
