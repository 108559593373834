import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_PRODUCTION_TEXTS = 'GET_PRODUCTION_TEXTS'
export const GET_PRODUCTION_TEXTS_REQUEST = 'GET_PRODUCTION_TEXTS_REQUEST'
export const GET_PRODUCTION_TEXTS_SUCCESS = 'GET_PRODUCTION_TEXTS_SUCCESS'
export const GET_PRODUCTION_TEXTS_FAILURE = 'GET_PRODUCTION_TEXTS_FAILURE'
export const PATCH_PRODUCTION_TEXTS = 'PATCH_PRODUCTION_TEXTS'
export const PATCH_PRODUCTION_TEXTS_REQUEST = 'PATCH_PRODUCTION_TEXTS_REQUEST'
export const PATCH_PRODUCTION_TEXTS_SUCCESS = 'PATCH_PRODUCTION_TEXTS_SUCCESS'
export const PATCH_PRODUCTION_TEXTS_FAILURE = 'PATCH_PRODUCTION_TEXTS_FAILURE'
const GET_PRODUCTION_TEXTS_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
  messages,
)
export const getProductionTexts = (workId, productionId) => (dispatch) =>
  dispatch({
    type: GET_PRODUCTION_TEXTS,
    payload: {
      data: {
        workId,
        productionId,
      },
      promise: request({
        method: 'GET',
        url: services.work(`/v1/works/${workId}/productions/${productionId}/texts`),
        errorMessages: GET_PRODUCTION_TEXTS_ERROR_MESSAGES,
      }),
    },
  })
const PATCH_PRODUCTION_TEXTS_ERROR_MESSAGES = createErrorMessages(
  ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'PRODUCTION_INVALID_TEXT_TYPE'],
  messages,
)
export const patchProductionTexts = (workId, productionId, patchSet) => (dispatch) =>
  dispatch({
    type: PATCH_PRODUCTION_TEXTS,
    payload: {
      data: {
        workId,
        productionId,
      },
      promise: request({
        method: 'PATCH',
        body: patchSet,
        url: services.work(`/v1/works/${workId}/productions/${productionId}/texts`),
        errorMessages: PATCH_PRODUCTION_TEXTS_ERROR_MESSAGES,
      }),
    },
  })
