import IconHistory from '@mui/icons-material/History'
import IconInfo from '@mui/icons-material/InfoOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import {
  Divider,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[16],
    maxWidth: 500,
  },
}))

export type FieldInfoProp = {
  readOnlyReason?: string
  isSharedTo?: boolean
  infoText?: string
  history?: string
}

type Props = {
  fieldInfoProps: FieldInfoProp
}

export default function FieldInfoHover(props: Props) {
  const { fieldInfoProps } = props

  return (
    <LightTooltip
      placement="bottom-end"
      leaveDelay={200}
      title={
        <Stack spacing={1}>
          {fieldInfoProps.readOnlyReason ? (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" flexItem />}>
              <LockOutlinedIcon />
              <Typography variant="caption">{fieldInfoProps.readOnlyReason}</Typography>
            </Stack>
          ) : null}
          {fieldInfoProps.isSharedTo ? (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" flexItem />}>
              <ShareOutlinedIcon />
              <Typography variant="caption">Shared with other editions</Typography>
            </Stack>
          ) : null}
          {fieldInfoProps.infoText ? (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" flexItem />}>
              <IconInfo />
              <Typography variant="caption">{fieldInfoProps.infoText}</Typography>
            </Stack>
          ) : null}
          {fieldInfoProps.history ? (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" flexItem />}>
              <IconHistory />
              <Typography variant="caption">{fieldInfoProps.history}</Typography>
            </Stack>
          ) : null}
        </Stack>
      }>
      <IconButton disableRipple>
        <IconInfo />
      </IconButton>
    </LightTooltip>
  )
}
