import { isEqual, zipObject } from 'lodash/fp'
import { Component } from 'react'

import { MenuItem, FormControl, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AddButton } from 'Common/components/buttons'
import { MetaData } from 'Common/metadata'

import FilterSelectionList from './FilterSelectionList'

type Props = {
  updateFilter(..._args: unknown[]): unknown
  filter: {
    id: string
    options: {
      id: string
      name: string
      defaultValue?: string
      metaDataId: string
    }[]
    type: {
      displayFormat(..._args: unknown[]): unknown
    }
    typeConfig: Record<string, unknown>
    displayFormat?(..._args: unknown[]): unknown
  }
  active: unknown[]
  metaData: MetaData
  chipColor?: string
}

type State = {
  options: any
}

class CombinationOptionFilter extends Component<Props, State> {
  static defaultProps = {
    active: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      options: zipObject(
        props.filter.options.map((_) => _.id),
        props.filter.options.map((_) => _.defaultValue),
      ),
    }
  }

  onAddOption = () => {
    const { filter, active, updateFilter } = this.props
    const updatedActive = active.concat(
      zipObject(
        filter.options.map((_) => _.id),
        filter.options.map((_) => this.state.options[_.id]),
      ),
    )
    updateFilter(filter.id, updatedActive)
  }

  onRemoveOption = (option) => {
    const { filter, active, updateFilter } = this.props
    const updatedActive = active.filter((activeOption) => !isEqual(activeOption, option))
    updateFilter(filter.id, updatedActive)
  }

  render() {
    const { filter, active, metaData, chipColor } = this.props
    return (
      <Grid container spacing={2}>
        {filter.options.map((option) => (
          <Grid xs={12} key={option.id}>
            <FormControl variant="standard" fullWidth>
              <TextField
                variant="standard"
                label={option.name}
                select
                value={this.state.options[option.id]}
                onChange={(event) =>
                  this.setState({
                    options: {
                      ...this.state.options,
                      ...{
                        [option.id]: event.target.value,
                      },
                    },
                  })
                }>
                {metaData[option.metaDataId].map((element, index) => (
                  <MenuItem key={index} value={element.name}>
                    {element.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        ))}
        <Grid xs={12}>
          <AddButton onClick={this.onAddOption} />
        </Grid>

        <FilterSelectionList
          onRemoveOption={this.onRemoveOption}
          {...{
            filter,
            active,
            metaData,
            chipColor,
          }}
        />
      </Grid>
    )
  }
}

export default CombinationOptionFilter
