import { postCustomPropertyType as postCustomPropertyTypeAction } from 'Admin/actions/metaDataTypeActions'
import messages from 'Admin/messages'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'

import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import { AddButton, Form, Field, Dialog } from 'Common/core'
import { FormCheckbox, FormSelectField, FormTextField } from 'Common/form'
import FormErrorDialog from 'Common/form/FormErrorDialog'
import { formSubmit } from 'Common/utils/net/submit'

type Props = {
  onCloseModal(): void
  typeId: number
}

const mapDisp = {
  postCustomPropertyType: postCustomPropertyTypeAction,
}

const connector = connect(() => ({}), mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

const FORM_NAME = 'addcustompropertytypeform'

const form = reduxForm<any, any>({
  form: FORM_NAME,
  enableReinitialize: true,
})
class AddCustomPropertyTypeModal extends Component<Props & InjectedFormProps & PropsFromRedux> {
  submit = (values) => {
    const { typeId, postCustomPropertyType, onCloseModal } = this.props
    return formSubmit(() => postCustomPropertyType(typeId, values)).then(() => onCloseModal())
  }

  render() {
    const { submitting, dirty, onCloseModal, handleSubmit } = this.props
    const doSubmit = handleSubmit(this.submit)
    return (
      <Dialog
        title="Add custom property type"
        actions={[
          <AddButton
            key="action"
            onClick={doSubmit}
            disabled={!dirty || submitting}
            submitting={submitting}>
            {messages.common.action.create}
          </AddButton>,
        ]}
        onClose={onCloseModal}>
        <Form onSubmit={doSubmit}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyType.Name"
                name="name"
                component={FormTextField}
                label="Name"
                required
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyType.Description"
                name="description"
                component={FormTextField}
                label="Description"
                required
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyType.CustomPropertyItemType"
                name="customPropertyItemType"
                component={FormSelectField}
                metaData={[
                  { id: '0', name: 'String' },
                  { id: '1', name: 'Int' },
                  { id: '2', name: 'Decimal' },
                  { id: '3', name: 'Bool' },
                ]}
                label="Custom Property Item Type"
              />
            </Grid>
            <Grid xs={6}>
              <Field
                id="Metadata.AddCustomPropertyType.IsArray"
                name="isArray"
                component={FormCheckbox}
                label="Is Array"
              />
            </Grid>

            {submitting ? <Spinner /> : null}
          </Grid>
        </Form>
        <FormErrorDialog {...this.props} />
      </Dialog>
    )
  }
}

export default connector(form(AddCustomPropertyTypeModal))
