import { Paper, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import SectionCompletion from './SectionCompletion'

type Props = {
  title: string
  category?: string
  completion?: number
  description?: string
}

const SectionHeader = ({ title, category, completion = -1, description }: Props) => {
  const hasCompletion = completion >= 0
  const hasDescription = description && description !== '-'
  return (
    <Paper>
      <Grid xs={12}>
        <Grid xs={12}>
          {category ? (
            <Grid container spacing={1} rowSpacing={1} xs={12}>
              <Grid>
                <Typography variant="h5" color="text.secondary">{`${category} /`}</Typography>
              </Grid>
              <Grid>
                <Typography variant="h5"> {title}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h5">{title}</Typography>
          )}
        </Grid>
        {hasCompletion ? (
          <Grid xs={12}>
            <Typography variant="subtitle1">
              <SectionCompletion
                {...{
                  completion,
                }}
              />
            </Typography>
          </Grid>
        ) : null}
        {hasDescription ? (
          <Grid xs={12}>
            {description
              ? typeof description === 'string' &&
                description.split('\n').map((text, index) => (
                  <Typography key={index} variant="body1">
                    {text}
                  </Typography>
                ))
              : null}
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  )
}

export default SectionHeader
