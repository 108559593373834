import { Cell } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'
import { PureComponent } from 'react'

type Props = {
  column: {
    selectField: string
    cellConfig?: {
      dataField?: string
    }
  }
  resultStatus: string
  result?: Record<string, any>
  viewProps?: Record<string, any>
}

class DefaultCell extends PureComponent<Props> {
  render() {
    const { column, result, resultStatus, viewProps, ...otherProps } = this.props
    const { selectField, cellConfig } = column
    const dataField = getOr(null, 'dataField', cellConfig)
    const value = getOr('-', `document.${dataField || selectField}`, result)
    return (
      <Cell {...otherProps}>
        <span
          style={{
            whiteSpace: 'nowrap',
          }}>
          {resultStatus === 'pending' ? 'Loading...' : value}
        </span>
      </Cell>
    )
  }
}

export default DefaultCell
