import { Component } from 'react'

import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

export const SPINNER_SIZES = {
  BUTTON: {
    size: 25,
    thickness: 1.75,
    style: {
      verticalAlign: 'middle',
      marginLeft: 8,
    },
  },
  XSMALL: {
    size: 20,
    thickness: 2,
    style: {
      margin: 10.5,
    },
  },
  MEDIUM: {
    size: 29.75,
    thickness: 1.75,
    style: {
      margin: 10.125,
    },
  },
  LARGE: {
    size: 119,
    thickness: 7,
    style: {
      margin: 10.5,
    },
  },
}

type Props = {
  size?: any
  submitting?: boolean
  singleVisible?: boolean
}

class Spinner extends Component<Props> {
  state = {
    isSlow: false,
  }

  private _timeoutId: any

  toggleIsSlow = () => {
    this._timeoutId = setTimeout(() => {
      this.setState({ isSlow: true })
    }, 5000)
  }

  componentDidMount() {
    this.toggleIsSlow()
  }

  componentWillUnmount() {
    clearTimeout(this._timeoutId)
  }

  render() {
    const { size = SPINNER_SIZES.MEDIUM, submitting = true, singleVisible = true } = this.props
    return submitting ? (
      <Grid
        container
        spacing={2}
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={singleVisible ? 'statusLoaderSingle' : ''}>
        <CircularProgress {...size} />
        {size === SPINNER_SIZES.LARGE ? (
          <p hidden={!this.state.isSlow}>Hold on, it is taking longer than usual...</p>
        ) : null}
      </Grid>
    ) : null
  }
}

export default Spinner
