import { requestStatus } from 'Common/utils/net/statuses'

import { GET_WORK_REQUEST } from '../actions/workActions'
import {
  GET_WORK_REVIEW_QUOTES_REQUEST,
  GET_WORK_REVIEW_QUOTES_SUCCESS,
  GET_WORK_REVIEW_QUOTES_FAILURE,
  PUT_WORK_REVIEW_QUOTES_REQUEST,
  PUT_WORK_REVIEW_QUOTES_SUCCESS,
  PUT_WORK_REVIEW_QUOTES_FAILURE,
} from '../actions/workReviewQuotesActions'

const initialState = {
  status: {
    getStatus: undefined,
    patchStatus: undefined,
    getError: undefined,
    patchError: undefined,
  },
  data: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_REQUEST: {
      return { ...initialState }
    }

    case PUT_WORK_REVIEW_QUOTES_REQUEST: {
      return { ...state, status: { ...state.status, patchStatus: requestStatus.request } }
    }

    case GET_WORK_REVIEW_QUOTES_REQUEST: {
      return { ...state, status: { ...state.status, getStatus: requestStatus.request } }
    }

    case PUT_WORK_REVIEW_QUOTES_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...action.payload.json },
        status: { ...state.status, patchStatus: requestStatus.success },
      }
    }

    case GET_WORK_REVIEW_QUOTES_SUCCESS: {
      return {
        ...state,
        data: action.payload.json,
        status: { ...state.status, getStatus: requestStatus.success },
      }
    }

    case PUT_WORK_REVIEW_QUOTES_FAILURE: {
      return {
        ...state,
        status: { ...state.status, patchStatus: requestStatus.failure, patchError: action.payload },
      }
    }

    case GET_WORK_REVIEW_QUOTES_FAILURE: {
      return {
        ...state,
        status: { ...state.status, getStatus: requestStatus.failure, getError: action.payload },
      }
    }

    default: {
      return state
    }
  }
}
