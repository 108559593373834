import { getOr } from 'lodash/fp'
import { v4 as uuid } from 'uuid'

import store from '../../store/createStore'
import { isInBrowser } from '../browser'

const inBrowser = isInBrowser()
export type GetRequest = {
  method: 'GET'
  url: string
  body?: Record<string, any>
  errorMessages?: any
  isDataRequest?: any
  headers?: Record<string, string>
}
export type DeleteRequest = {
  method: 'DELETE'
  url: string
  body?: Record<string, any>
  errorMessages?: any
  isDataRequest?: any
  headers?: Record<string, string>
}
export type PutRequest = {
  method: 'PUT'
  url: string
  body: Record<string, any>
  errorMessages?: any
  isDataRequest?: any
  headers?: Record<string, string>
}
export type PatchRequest = {
  method: 'PATCH'
  url: string
  body: Record<string, any>
  errorMessages?: any
  isDataRequest?: any
  headers?: Record<string, string>
}
export type PostRequest = {
  method: 'POST'
  url: string
  body: Record<string, any>
  errorMessages?: any
  isDataRequest?: any
  headers?: Record<string, string>
}
export type Request = GetRequest | DeleteRequest | PutRequest | PatchRequest | PostRequest

export type PatchBody = {
  op: string
  path: string
  value: string
}[]

const getHeaders = (extraHeaders, isDataRequest) => {
  const token = store.getState().auth.data?.token
  const baseHeaders = {
    Accept: 'application/json',
    'correlation-id': uuid(),
    'Accept-Language': 'sv-SE',
    Authorization: `Bearer ${token}`,
  }
  return isDataRequest
    ? { ...baseHeaders, ...extraHeaders }
    : { ...baseHeaders, 'Content-Type': 'application/json', ...extraHeaders }
}

// this is pretty stupid but needed since fetch in node requires an absolute url. better solution?

/* eslint no-bitwise: "off" */
const wrappedGetFetch = (url, method = 'GET', body, requestId, extraHeaders, isDataRequest) => {
  const finalUrl = inBrowser ? url : `http://localhost:3000${url}`
  const options = {
    headers: getHeaders(extraHeaders, isDataRequest),
    method: method.toUpperCase(),
    body: body ? ((isDataRequest ? body : JSON.stringify(body)) as null) : null,
  }
  return fetch(finalUrl, options)
}

const createError = (errorMessages, response, json?: any) => {
  const error: any = new Error(response.statusText)
  error.status = response.status
  error.statusText = response.statusText
  error.errorCode = getOr(null, 'error.messageCode', json)
  error.errorMessage = getOr(null, 'error.message', json)
  error.errorMessages = errorMessages

  return error
}

const getResponseHandler = (errorMessages, requestId, resolve, reject) => (response) => {
  const success = response.status >= 200 && response.status < 300
  const contentType = response.headers.get('content-type')

  if (contentType && contentType.includes('application/json')) {
    return response.json().then((json) =>
      success
        ? resolve({
            response,
            json,
          })
        : reject(createError(errorMessages, response, json)),
    )
  }

  return success
    ? resolve({
        response,
      })
    : reject(createError(errorMessages, requestId, response))
}

export const RawRequest = (request: Request): Promise<any> => {
  const requestId = uuid()
  const { method, url, body, headers } = request
  return wrappedGetFetch(url, method, body, requestId, headers, request.isDataRequest)
}

export default (request: Request): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!request.url) {
      return reject(new Error('There is no URL provided for the request.'))
    }

    const requestId = uuid()

    const { method, url, body, headers, errorMessages } = request

    return wrappedGetFetch(url, method, body, requestId, headers, request.isDataRequest).then(
      getResponseHandler(errorMessages, requestId, resolve, reject),
    )
  })
}
