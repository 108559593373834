import { Component } from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import SortableTableHeader from 'Common/components/table/SortableTableHeader'
import { getDefaultState, getSortedList, updateSorting } from 'Common/components/table/tableHelpers'
import { AddButton } from 'Common/core'

import AddCustomPropertyTypeModal from './AddCustomPropertyTypeModal'

type Props = {
  customPropertyTypes?: any
  typeId: number
}

type State = {
  showAddModal: boolean
  showEditModal: boolean
  editType: any
  sorting: any
}
const StyledTableRow = TableRow
class CustomPropertiesTypeList extends Component<Props, State> {
  static defaultProps = {
    customPropertyTypes: [],
  }

  updateSorting: any

  constructor(props) {
    super(props)
    this.updateSorting = updateSorting.bind(this)
    this.state = { ...getDefaultState(), showAddModal: false, showEditModal: false, editType: null }
  }

  toggleAddModal = () =>
    this.setState((state) => ({
      showAddModal: !state.showAddModal,
    }))

  toggleEditModal = (type = null) =>
    this.setState({
      showEditModal: type !== null,
      editType: type,
    })

  render() {
    const { customPropertyTypes, typeId } = this.props
    const { sorting, showAddModal } = this.state
    const sortedCustomPropertyTypes = getSortedList(customPropertyTypes, sorting)
    return (
      <Grid container spacing={2} xs={12}>
        {showAddModal ? (
          <AddCustomPropertyTypeModal
            onCloseModal={this.toggleAddModal}
            {...{
              typeId,
            }}
          />
        ) : null}
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <AddButton onClick={this.toggleAddModal}>Add property type</AddButton>
        </Grid>
        <Grid xs={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <SortableTableHeader
                    label="ID"
                    field="id"
                    sorting={sorting}
                    updateSorting={this.updateSorting}
                  />
                </TableCell>
                <TableCell>
                  <SortableTableHeader
                    label="Name"
                    field="name"
                    sorting={sorting}
                    updateSorting={this.updateSorting}
                  />
                </TableCell>
                <TableCell>
                  <SortableTableHeader
                    label="Type"
                    field="customPropertyItemType"
                    sorting={sorting}
                    updateSorting={this.updateSorting}
                  />
                </TableCell>
                <TableCell>
                  <SortableTableHeader
                    label="Array"
                    field="isArray"
                    sorting={sorting}
                    updateSorting={this.updateSorting}
                  />
                </TableCell>
                <TableCell>
                  <SortableTableHeader
                    label="Description"
                    field="description"
                    sorting={sorting}
                    updateSorting={this.updateSorting}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedCustomPropertyTypes.map((type) => (
                <StyledTableRow key={type.id}>
                  <TableCell>{type.id}</TableCell>
                  <TableCell>{type.name}</TableCell>
                  <TableCell>{type.customPropertyItemType}</TableCell>
                  <TableCell>{type.isArray ? 'True' : 'False'}</TableCell>
                  <TableCell>{type.description}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {customPropertyTypes.length === 0 ? (
          <Grid xs={12}>No custom property types added.</Grid>
        ) : null}
      </Grid>
    )
  }
}

export default CustomPropertiesTypeList
