import { getOr } from 'lodash/fp'

import InfoNotice from '../../components/notice/InfoNotice'

type Props = {
  viewConfig: Record<string, unknown>
  workingView: {
    missingColumns?: unknown[]
  }
}

const AdvancedViewMissingColumns = (props: Props) => {
  const { viewConfig, workingView } = props
  if (getOr([], 'missingColumns', workingView).length === 0) return null
  return (
    <InfoNotice
      title="Problems with the saved view"
      content={
        <div>
          <span>
            The following columns are added to the current view, but do not exist anymore. Change
            views or resave the current view and this error message will go away.
          </span>
          <ul>
            {workingView.missingColumns.map((columnId: any) => (
              <li key={columnId}>{getOr(columnId, `columns[${columnId}].label`, viewConfig)}</li>
            ))}
          </ul>
        </div>
      }
    />
  )
}

export default AdvancedViewMissingColumns
