import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { MenuItem, Popover, Button } from '@mui/material'

import { RootState } from 'Common/types'

import UpdateActivityStatus from '../actions/UpdateActivityStatus'

const mapState = (state: RootState) => {
  const { auth } = state
  return {
    auth,
  }
}

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type State = {
  showMenu: boolean
  showUpdateActivityStatusDialog: boolean
}

class ActivitySearchOperations extends Component<PropsFromRedux, State> {
  state = {
    showMenu: false,
    showUpdateActivityStatusDialog: false,
  }

  private _isMounted: boolean

  actionMenuAnchor: HTMLSpanElement

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getMenuItems = () => {
    const items = [
      {
        title: 'Update status',
        action: this.togglAddContactToEditionsDialog,
        isAvailable: true,
      },
    ]
    return items.filter((_) => _.isAvailable)
  }

  toggleMenu = (reason) =>
    this.setState((state) => ({
      showMenu: reason === 'clickAway' ? false : !state.showMenu,
    }))

  togglAddContactToEditionsDialog = () => {
    if (!this._isMounted) return
    this.setState((state) => ({
      showUpdateActivityStatusDialog: !state.showUpdateActivityStatusDialog,
      showMenu: false,
    }))
  }

  render() {
    const { showMenu, showUpdateActivityStatusDialog } = this.state
    const menuItems = this.getMenuItems()
    if (menuItems.length === 0) return null
    return (
      <div className="col">
        {showUpdateActivityStatusDialog ? (
          <UpdateActivityStatus closeModal={this.togglAddContactToEditionsDialog} />
        ) : null}
        <span
          ref={(el) => {
            this.actionMenuAnchor = el
          }}>
          <Button variant="outlined" onClick={this.toggleMenu} color="primary">
            Actions
          </Button>
        </span>
        <Popover open={showMenu} anchorEl={this.actionMenuAnchor} onClose={this.toggleMenu}>
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem.title} onClick={menuItem.action}>
              {menuItem.title}
            </MenuItem>
          ))}
        </Popover>
      </div>
    )
  }
}

export default connector(ActivitySearchOperations)
